// convert google place result to address component object
export const parseAddressComponents = components => {
  const parsed = {};
  const useShortNameFields = [
    'administrative_area_level_1' // state - need abbreviation
  ];

  components.forEach(item => {
    item.types.forEach(type => {
      const foundShortName = useShortNameFields.findIndex(field => field === type) > -1;

      // use short name if desired
      // default = long name
      if (foundShortName) {
        parsed[type] = item.short_name;
      } else {
        parsed[type] = item.long_name;
      }
    });
  });

  return parsed;
};

export default { parseAddressComponents };
