import { CALL_API } from '../../middleware/api';

export const GET_LEGAL_TERMS_REQUEST = 'GET_LEGAL_TERMS_REQUEST';
export const GET_LEGAL_TERMS_SUCCESS = 'GET_LEGAL_TERMS_SUCCESS';
export const GET_LEGAL_TERMS_FAILURE = 'GET_LEGAL_TERMS_FAILURE';

export const getLegalTerms = () => {
  return {
    [CALL_API]: {
      types: [GET_LEGAL_TERMS_REQUEST, GET_LEGAL_TERMS_SUCCESS, GET_LEGAL_TERMS_FAILURE],
      authenticated: true,
      endpoint: 'v1/patient/legalTerms',
      method: 'GET'
    }
  };
};
