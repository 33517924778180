import {
  CREATE_DEPENDENT_FAILURE,
  CREATE_DEPENDENT_REQUEST,
  CREATE_DEPENDENT_SUCCESS,
  UPDATE_DEPENDENT_FAILURE,
  UPDATE_DEPENDENT_REQUEST,
  UPDATE_DEPENDENT_SUCCESS
} from './dependent.actions';
import produce from 'immer';

const initialState = {
  isLoading: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case CREATE_DEPENDENT_SUCCESS:
      console.log('CREATE DEPENDENT SUCCESS', action.response);
      draft.isLoading = false;
      break;

    case CREATE_DEPENDENT_FAILURE:
      console.log('CREATE DEPENDENT FAILURE', action.messages);
      draft.isLoading = false;
      break;

    case CREATE_DEPENDENT_REQUEST:
      draft.isLoading = true;
      break;

    case UPDATE_DEPENDENT_SUCCESS:
      console.log('UPDATE DEPENDENT SUCCESS', action.response);
      draft.isLoading = false;
      break;

    case UPDATE_DEPENDENT_FAILURE:
      console.log('UPDATE DEPENDENT FAILURE', action.messages);
      draft.isLoading = false;
      break;

    case UPDATE_DEPENDENT_REQUEST:
      draft.isLoading = true;
      break;

    default:
      break;
  }
}, initialState);
