import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { isNil, has, isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import ReactRouterPropTypes from 'react-router-prop-types';
import { setSelectedFacility, setShowFacilitySelection } from './facility.actions';
import CustomDialog from '../../common/customDialog/customDialog.component';
import Languages from '../language/languages';
import facilityUtil from '../../utilities/facilityUtil';

const BUTTON_WIDTH = 300;

class FacilitySelectionModal extends Component {
  // need to set facility in redux
  // also in local storage to preserve selection on refresh
  handleSelectFacility = facility => {
    this.props.setShowFacilitySelection(false);
    this.props.setSelectedFacility(facility);
    facilityUtil.setFacilityId(!isNil(facility) ? facility.id : null);
    this.props.history.push('/');
  };

  handleClose = () => {
    this.props.setShowFacilitySelection(false);
  };

  getFacilityListContent = () => {
    const { profile, classes, selectedLanguageKey } = this.props;
    const language = Languages[selectedLanguageKey].strings;

    if (profile && has(profile, 'facilities') && !isEmpty(profile.facilities)) {
      return (
        <div className={classes.container}>
          <div className={classes.personalAccount}>
            <div className={classes.personalAccountText}>{language.personalAccountLogin}</div>
            {/* PERSONAL ACCOUNT */}
            <Button
              classes={{ root: classes.actionButton }}
              color="primary"
              variant="outlined"
              onClick={() => this.handleSelectFacility(null)}
            >
              {language.personalAccount}
            </Button>
          </div>

          <div className={classes.facilities}>
            <div className={classes.facilityText}>{language.accountFacilities}</div>
            {/* OTHER FACILITIES */}
            {profile &&
              profile.facilities.map((f, i) => (
                <Button
                  key={i}
                  className={classes.facilityButton}
                  classes={{ root: classes.actionButton }}
                  color="primary"
                  variant="outlined"
                  onClick={() => this.handleSelectFacility(f)}
                >
                  {f.name}
                </Button>
              ))}
          </div>
        </div>
      );
    }

    return null;
  };

  formHasErrors = () => {
    return Object.keys(this.state.formErrors).some(key => !!this.state.formErrors[key]);
  };

  render() {
    const { handleClose, open } = this.props;

    return (
      <CustomDialog
        open={open}
        handleClose={handleClose}
        content={this.getFacilityListContent()}
        title={Languages[this.props.selectedLanguageKey].strings.alertMessageTitles.selectFacility}
        disableActions
        maxWidth="sm"
        isShowCloseIcon
      />
    );
  }
}

const styles = theme => ({
  actionButton: {
    width: BUTTON_WIDTH,
  },
  container: {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  personalAccount: {
    marginBottom: '2rem',
    textAlign: 'center',
  },
  personalAccountText: {
    color: theme.palette.primary.darkGray,
    fontSize: '1rem',
    marginBottom: '1rem',
  },
  facilities: {
    textAlign: 'center',
  },
  facilityText: {
    color: theme.palette.primary.darkGray,
    fontSize: '1rem',
    marginBottom: '1rem',
  },
  facilityButton: {
    marginBottom: '1rem',
  },
});

FacilitySelectionModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  open: PropTypes.bool.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,

  handleClose: PropTypes.func.isRequired,
  setSelectedFacility: PropTypes.func.isRequired,
  setShowFacilitySelection: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    open: state.facility.isShowSelectFacility,
    profile: state.profile.profile,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    setSelectedFacility,
    setShowFacilitySelection,
  })
)(FacilitySelectionModal);
