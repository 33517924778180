import React, { Component } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Languages from '../language/languages';

class MedicalHistoryRadioButtons extends Component {
  handleChange = evt => {
    const value = evt.target.value === 'true';
    this.props.handleChange(value);
  };

  render() {
    const { value, classes, selectedLanguageKey } = this.props;

    return (
      <FormControl component="div" className={classes.formControl}>
        <RadioGroup
          aria-label="medicalHistory"
          name="medicalHistory"
          className={classes.group}
          value={value}
          onChange={this.handleChange}
        >
          <FormControlLabel
            classes={{ label: classes.formControlLabelText }}
            value="true"
            control={<Radio classes={{ root: classes.radioRoot }} color="primary" disableRipple />}
            label={Languages[selectedLanguageKey].strings.yes}
          />
          <FormControlLabel
            style={{ marginLeft: '1rem' }}
            classes={{ label: classes.formControlLabelText }}
            value="false"
            control={<Radio classes={{ root: classes.radioRoot }} color="primary" disableRipple />}
            label={Languages[selectedLanguageKey].strings.no}
          />
        </RadioGroup>
      </FormControl>
    );
  }
}

const styles = theme => ({
  group: {
    display: 'flex',
    flexDirection: 'row',
  },
  formControl: {
    marginBottom: '0.5rem',
  },
  formControlLabelText: {
    fontSize: 16,
    color: theme.palette.primary.darkGray,
  },
  radioRoot: {
    padding: '0 8px 0 0',
  },
});

MedicalHistoryRadioButtons.propTypes = {
  classes: PropTypes.object.isRequired,

  selectedLanguageKey: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,

  handleChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(MedicalHistoryRadioButtons);
