export const GET_CURRENT_POSITION = 'GET_CURRENT_POSITION';
export const GET_CURRENT_POSITION_FAILURE = 'GET_CURRENT_POSITION_FAILURE';

export const getCurrentPosition = () => {
  return (dispatch, getState) => {
    return new Promise(resolve => {
      if (navigator.geolocation && navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then(result => {
          if (result.state !== 'denied') {
            navigator.geolocation.getCurrentPosition(
              location => {
                dispatch({
                  type: GET_CURRENT_POSITION,
                  location
                });
                resolve(location);
              },
              error => {
                dispatch({
                  type: GET_CURRENT_POSITION_FAILURE,
                  error
                });
                resolve();
              }
            );
          } else {
            dispatch({
              type: GET_CURRENT_POSITION_FAILURE,
              error: 'Geolocation permission has been denied'
            });
            resolve();
          }
        });
      } else {
        resolve();
      }
    });
  };
};
