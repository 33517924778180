import { has } from 'lodash';
import toastTypes from '../../types/toastTypes';
import { config as brandConfig } from '@brand';

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_RESPONSIVE_BREAKPOINT = 'SET_RESPONSIVE_BREAKPOINT';

export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE';
export const SET_TOAST_POSITION = 'SET_TOAST_POSITION';

export const TOGGLE_DRAWER_MENU = 'TOGGLE_DRAWER_MENU';

export const SET_DIRTY_FORM = 'SET_DIRTY_FORM';

export const SET_LAYOUT_INITALIZING = 'SET_LAYOUT_INITALIZING';

export const SET_LAYOUT_BEFORE_UNLOAD = 'SET_LAYOUT_BEFORE_UNLOAD';
export const CLEAR_LAYOUT_BEFORE_UNLOAD = 'CLEAR_LAYOUT_BEFORE_UNLOAD';

export const GET_MESSAGE_BANNER_REQUEST = 'GET_MESSAGE_BANNER_REQUEST';
export const GET_MESSAGE_BANNER_SUCCESS = 'GET_MESSAGE_BANNER_SUCCESS';
export const GET_MESSAGE_BANNER_FAILURE = 'GET_MESSAGE_BANNER_FAILURE';

export const setPageTitle = (title, breadcrumbLink, breadcrumbTitle) => {
  return {
    type: SET_PAGE_TITLE,
    payload: title,
    breadcrumb: breadcrumbLink,
    breadcrumbTitle,
  };
};

export const setResponsiveBreakpoint = breakpoint => {
  return {
    type: SET_RESPONSIVE_BREAKPOINT,
    payload: breakpoint,
  };
};

export const showToast = (
  message,
  variant = toastTypes.INFO,
  autoHide = true,
  toastAction,
  detailMessage
) => {
  return {
    type: SHOW_TOAST_MESSAGE,
    message,
    variant,
    autoHide,
    toastAction,
    detailMessage,
  };
};

export const handleRefresh = () => {
  window.location.reload();
};

export const hideToast = () => {
  return {
    type: HIDE_TOAST_MESSAGE,
  };
};

export const setToastPosition = position => {
  return {
    type: SET_TOAST_POSITION,
    position,
  };
};

export const toggleDrawerMenu = () => {
  return {
    type: TOGGLE_DRAWER_MENU,
  };
};

export const setDirtyForm = flag => {
  return {
    type: SET_DIRTY_FORM,
    payload: { flag },
  };
};

export const setLayoutInitializing = payload => {
  return {
    type: SET_LAYOUT_INITALIZING,
    payload,
  };
};

export const setLayoutBeforeUnload = payload => {
  return {
    type: SET_LAYOUT_BEFORE_UNLOAD,
    payload,
  };
};

export const clearLayoutBeforeUnload = () => {
  return {
    type: CLEAR_LAYOUT_BEFORE_UNLOAD,
  };
};

const getMessageBannerSuccess = payload => {
  // if no message text is received, set a null banner object
  // indicates that no message is currently set
  if (!has(payload, 'text')) {
    payload = null;
  }

  return {
    type: GET_MESSAGE_BANNER_SUCCESS,
    payload,
  };
};

export const getMessageBanner = () => {
  // use action creator function for async call
  return dispatch => {
    try {
      const breakCacheParam = (Math.random() * 1e16).toString(36);
      const url = brandConfig.serverOutageUrl;
      return fetch(`${url}?cacheBreaker=${breakCacheParam}`).then(response => {
        if (response.ok && response.status === 200) {
          response.json().then(data => {
            if (has(data, 'patient')) {
              dispatch(getMessageBannerSuccess(data.patient));
            }
          });
        }
      });
    } catch (e) {
      console.log('could not fetch message banner: ', e);
    }
  };
};
