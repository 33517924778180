import React, { useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { injectStripe } from 'react-stripe-elements';
import PropTypes from 'prop-types';

const StripeInput = ({ component: Component, ...other }) => {
  const inputRef = useRef(null);

  return <Component ref={inputRef} {...other} />;
};

StripeInput.propTypes = {
  component: PropTypes.func.isRequired,
};

class StripeTextField extends React.Component {
  state = {
    errorMessage: null,
  };

  setErrorMessage = errorMessage => {
    this.setState({ errorMessage });
  };

  handleElementChange = value => {
    if (value.error) {
      this.setErrorMessage(value.error.message);
    } else {
      this.setErrorMessage(null);
    }

    this.props.onChange(value.complete);
  };

  render() {
    const { fullWidth, label, margin, component } = this.props;
    const hasError = this.state.errorMessage !== null;

    return (
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputProps: { component },
          inputComponent: StripeInput,
        }}
        error={hasError}
        fullWidth={fullWidth}
        helperText={hasError ? this.state.errorMessage || 'Invalid' : ''}
        label={label}
        margin={margin}
        onChange={this.handleElementChange}
        variant="outlined"
      />
    );
  }
}

StripeTextField.propTypes = {
  component: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

StripeTextField.defaultProps = {
  fullWidth: true,
  margin: 'normal',
};

export default injectStripe(StripeTextField, { withRef: true });
