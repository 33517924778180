import React, { Component } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import Languages from '../language/languages';

const DATE_FORMAT = 'MM/DD/YYYY';

const styles = theme => ({
  segmentWrapper: {
    marginBottom: '1rem'
  }
});

class PrescriptionInfo extends Component {
  getPharmcyAddress(pharmacy) {
    const { Address1, Address2, City, State, ZipCode } = pharmacy;

    return `${Address1} ${Address2 ? `, ${Address2}` : ''} ${City}, ${State} ${ZipCode}`;
  }

  render() {
    const { classes, prescription, selectedLanguageKey } = this.props;
    const prescriptionLanguage = Languages[selectedLanguageKey].strings.prescriptionInfo;

    return (
      <div>
        <div className={classes.segmentWrapper}>
          <Typography variant="h6">{prescription.DisplayName}</Typography>
          {prescription.GenericProductName && (
            <Typography variant="body1">{`${prescriptionLanguage.genericName} ${prescription.GenericProductName}`}</Typography>
          )}
        </div>

        {prescription.DateInactive && (
          <div className={classes.segmentWrapper}>
            <Typography variant="body1">{`${prescriptionLanguage.dateInactive} ${moment(prescription.DateInactive).format(
              DATE_FORMAT
            )}`}</Typography>
          </div>
        )}

        <div className={classes.segmentWrapper}>
          {prescription.Quantity && <Typography variant="body1">{`${prescriptionLanguage.quantity} ${prescription.Quantity}`}</Typography>}
          {prescription.DaysSupply && (
            <Typography variant="body1">{`${prescriptionLanguage.daysSupply} ${prescription.DaysSupply}`}</Typography>
          )}
          {prescription.DispenseUnitDescription && (
            <Typography variant="body1">{`${prescriptionLanguage.dispenseUnit} ${prescription.DispenseUnitDescription}`}</Typography>
          )}
          {prescription.Refills && <Typography variant="body1">{`${prescriptionLanguage.refills} ${prescription.Refills}`}</Typography>}
          {prescription.OTC !== null && (
            <Typography variant="body1">{`${prescriptionLanguage.otc} ${prescription.OTC ? 'Yes' : 'No'}`}</Typography>
          )}
        </div>

        <div className={classes.segmentWrapper}>
          {prescription.Directions && (
            <Typography variant="body1">{`${prescriptionLanguage.directions} ${prescription.Directions}`}</Typography>
          )}
          {prescription.Comment && <Typography variant="body1">{`${prescriptionLanguage.comment} ${prescription.Comment}`}</Typography>}
          {prescription.PharmacyNotes && (
            <Typography variant="body1">{`${prescriptionLanguage.pharmacyNotes} ${prescription.PharmacyNotes}`}</Typography>
          )}
        </div>

        {prescription.pharmacy && (
          <div className={classes.segmentWrapper}>
            <Typography variant="body1">{`${prescriptionLanguage.pharmacy} ${prescription.pharmacy.StoreName}`}</Typography>
            <Typography variant="body1">{`${prescriptionLanguage.address} ${this.getPharmcyAddress(prescription.pharmacy)}`}</Typography>
          </div>
        )}
      </div>
    );
  }
}

PrescriptionInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  prescription: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(PrescriptionInfo);
