/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import classNames from 'classnames';
import { Typography, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextFieldValidator from '../../../common/inputValidators/textFieldValidator.component';
import SelectInputValidator from '../../../common/inputValidators/selectInputValidator.component';
import PhotoSet from '../../../common/photoSet/photoSet.component';
import Languages from '../../language/languages';
import { getRelationshipOptions, relationshipTypes } from '../../../types/insuranceRelationships';
import GooglePlacesTextValidator from '../../../common/inputValidators/googlePlacesTextValidator.component';
import { getUSStateOptions } from '../../../utilities/provinceUtils';
import MaskedTextValidator from '../../../common/inputValidators/maskedTextValidator.component';
import {
  validZip,
  DATE_INPUT_DATE_FORMAT,
  DATE_FIELD_MASK,
  TEXT_INPUT_CHAR_LIMIT,
} from '../../../utilities/fieldValidation';
import DateFieldValidator from '../../../common/inputValidators/dateFieldValidator.component';
import { genderTypes, getLongGender } from '../../../types/genderTypes';

const genderOptions = [
  {
    value: genderTypes.MALE,
    label: getLongGender(genderTypes.MALE),
  },
  {
    value: genderTypes.FEMALE,
    label: getLongGender(genderTypes.FEMALE),
  },
  {
    value: genderTypes.ASK,
    label: getLongGender(genderTypes.ASK),
  },
];

const VisitInsuranceForm = ({
  classes,
  insuranceInfo: {
    attachments,
    carrierName,
    policyNumber,
    relationshipToInsured,
    subscriberAddressLine1,
    subscriberAddressLine2,
    subscriberAddressCity,
    subscriberAddressState,
    subscriberAddressZip,
    subscriberDob,
    subscriberGender,
    subscriberId,
    subscriberName,
  },
  isUploadingFiles,
  selectedLanguageKey,
  handleAddAttachment,
  handleRemoveAttachment,
  handleValidationChange,
  onFieldChange,
}) => {
  const language = Languages[selectedLanguageKey].strings;

  const truncateText = (value, limit = TEXT_INPUT_CHAR_LIMIT) => {
    return value.substring(0, limit - 1);
  };

  const handleAddressSelection = selection => {
    // propagate selection to address1 and other fields
    const addressLine1 = `${selection.street_number ? `${selection.street_number} ` : ''}${
      selection.route ? selection.route : ''
    }`;
    onFieldChange('subscriberAddressLine1')(truncateText(addressLine1));

    // clear address line 2 - not integrated with autocomplete
    onFieldChange('subscriberAddressLine2')('');

    const addressCity = `${selection.locality ? selection.locality : ''}`;
    onFieldChange('subscriberAddressCity')(truncateText(addressCity));

    const addressState = `${
      selection.administrative_area_level_1 ? selection.administrative_area_level_1 : ''
    }`;
    onFieldChange('subscriberAddressState')(truncateText(addressState));

    const addressZip = `${selection.postal_code ? selection.postal_code : ''}`;
    onFieldChange('subscriberAddressZip')(truncateText(addressZip));
  };

  return (
    <Paper className={classes.formContainer}>
      <Typography className={classes.descriptionText} style={{ marginBottom: '1rem' }}>
        {language.insuranceDescription}
      </Typography>
      <TextFieldValidator
        label={language.inputLabels.carrierName}
        className={classes.formControl}
        value={carrierName || ''}
        onChange={onFieldChange('carrierName')}
        validators={[]}
        margin="normal"
        variant="outlined"
        onValidationChange={handleValidationChange('carrierName')}
      />
      <TextFieldValidator
        label={language.inputLabels.subscriberId}
        className={classes.formControl}
        value={subscriberId || ''}
        onChange={onFieldChange('subscriberId')}
        margin="normal"
        validators={[]}
        variant="outlined"
        onValidationChange={handleValidationChange('subscriberId')}
      />
      <TextFieldValidator
        label={language.inputLabels.policyNumber}
        className={classes.formControl}
        value={policyNumber || ''}
        onChange={onFieldChange('policyNumber')}
        margin="normal"
        validators={[]}
        variant="outlined"
        onValidationChange={handleValidationChange('policyNumber')}
      />
      <SelectInputValidator
        label={language.inputLabels.relationshipToInsured}
        className={classes.formControl}
        value={relationshipToInsured || ''}
        onChange={onFieldChange('relationshipToInsured')}
        validators={[]}
        margin="normal"
        variant="outlined"
        onValidationChange={handleValidationChange('relationshipToInsured')}
        options={getRelationshipOptions()}
      />
      {relationshipToInsured !== null &&
        relationshipToInsured !== relationshipTypes.RELATIONSHIP_SELF.value && (
          <>
            <TextFieldValidator
              label={language.inputLabels.subscriberName}
              className={classes.formControl}
              value={subscriberName || ''}
              onChange={onFieldChange('subscriberName')}
              validators={[]}
              margin="normal"
              variant="outlined"
              onValidationChange={handleValidationChange('subscriberName')}
            />
            <DateFieldValidator
              className={classes.formControl}
              clearable
              format={DATE_INPUT_DATE_FORMAT}
              keyboard
              label={language.inputLabels.subscriberDateOfBirth}
              mask={DATE_FIELD_MASK}
              maxDate={moment().format(DATE_INPUT_DATE_FORMAT)}
              openTo="year"
              style={{ marginTop: '1rem', marginBottom: '0.5rem' }}
              validators={[]}
              value={subscriberDob ? moment(subscriberDob).format(DATE_INPUT_DATE_FORMAT) : null}
              handleDateChange={onFieldChange('subscriberDob')}
              onValidationChange={handleValidationChange('subscriberDob')}
            />
            <SelectInputValidator
              className={classes.formControl}
              label={language.inputLabels.subscriberGender}
              margin="normal"
              options={genderOptions}
              validators={[]}
              value={subscriberGender || ''}
              variant="outlined"
              onChange={onFieldChange('subscriberGender')}
              onValidationChange={handleValidationChange('subscriberGender')}
            />
            <GooglePlacesTextValidator
              onChange={onFieldChange('subscriberAddressLine1')}
              validators={[]}
              label={language.inputLabels.subscriberAddressLineOne}
              inputClassName={classNames([classes.formControl, classes.autocomplete])}
              value={subscriberAddressLine1 || ''}
              onSelect={handleAddressSelection}
              onValidationChange={handleValidationChange('subscriberAddressLine1')}
            />
            <TextFieldValidator
              label={language.inputLabels.subscriberAddressLineTwo}
              className={classes.formControl}
              value={subscriberAddressLine2 || ''}
              onChange={onFieldChange('subscriberAddressLine2')}
              validators={[]}
              margin="normal"
              variant="outlined"
              onValidationChange={handleValidationChange('subscriberAddressLine2')}
            />
            <TextFieldValidator
              label={language.inputLabels.subscriberCity}
              className={classes.formControl}
              value={subscriberAddressCity || ''}
              onChange={onFieldChange('subscriberAddressCity')}
              validators={[]}
              margin="normal"
              variant="outlined"
              onValidationChange={handleValidationChange('subscriberAddressCity')}
            />
            <SelectInputValidator
              label={language.inputLabels.subscriberState}
              className={classes.formControl}
              value={subscriberAddressState || ''}
              onChange={onFieldChange('subscriberAddressState')}
              validators={[]}
              margin="normal"
              variant="outlined"
              onValidationChange={handleValidationChange('subscriberAddressState')}
              options={getUSStateOptions()}
            />
            <MaskedTextValidator
              label={language.inputLabels.subscriberZipCode}
              className={classes.formControl}
              value={subscriberAddressZip || ''}
              onChange={onFieldChange('subscriberAddressZip')}
              validators={[validZip]}
              margin="normal"
              variant="outlined"
              mask="99999"
              onValidationChange={handleValidationChange('subscriberAddressZip')}
            />
          </>
        )}
      <div className={classes.photoTextContainer}>
        <Typography className={classes.photoTitle}>{language.insuranceAddPhoto}</Typography>
        <Typography className={classes.descriptionText}>
          {language.insurancePhotoDescription}
        </Typography>
      </div>
      <PhotoSet
        photos={attachments || []}
        addPhotos={handleAddAttachment}
        removePhoto={handleRemoveAttachment}
        containerStyle={{ marginTop: '1rem' }}
        isLoading={isUploadingFiles}
        disabled={isUploadingFiles || (attachments && attachments.length === 4)} // allow only 4 uploads
      />
    </Paper>
  );
};

const styles = theme => ({
  formContainer: {
    margin: '1rem 1rem 6rem',
    padding: '1rem',
  },
  formControl: {
    width: '100%',
  },
  photoTextContainer: {
    marginTop: '1rem',
  },
  photoTitle: {
    color: theme.palette.primary.darkGray,
    fontSize: 18,
  },
  descriptionText: {
    color: theme.palette.primary.darkGray,
    fontSize: 14,
  },
});

VisitInsuranceForm.propTypes = {
  classes: PropTypes.object.isRequired,

  insuranceInfo: PropTypes.object.isRequired,
  isUploadingFiles: PropTypes.bool,
  selectedLanguageKey: PropTypes.string.isRequired,

  handleAddAttachment: PropTypes.func.isRequired,
  handleRemoveAttachment: PropTypes.func.isRequired,
  handleValidationChange: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

VisitInsuranceForm.defaultProps = {
  isUploadingFiles: false,
};

export default withStyles(styles)(VisitInsuranceForm);
