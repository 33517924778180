import { CALL_API } from '../../middleware/api';

export const GET_TWILIO_VIDEO_ROOM_AUTH_REQUEST = 'GET_TWILIO_VIDEO_ROOM_AUTH_REQUEST';
export const GET_TWILIO_VIDEO_ROOM_AUTH_SUCCESS = 'GET_TWILIO_VIDEO_ROOM_AUTH_SUCCESS';
export const GET_TWILIO_VIDEO_ROOM_AUTH_FAILURE = 'GET_TWILIO_VIDEO_ROOM_AUTH_FAILURE';

export const RESET_TWILIO_VIDEO_ROOM_AUTH = 'RESET_TWILIO_VIDEO_ROOM_AUTH';

export const getTwilioVideoRoomAuth = () => {
  return {
    [CALL_API]: {
      types: [
        GET_TWILIO_VIDEO_ROOM_AUTH_REQUEST,
        GET_TWILIO_VIDEO_ROOM_AUTH_SUCCESS,
        GET_TWILIO_VIDEO_ROOM_AUTH_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/patient/video/token`,
      method: 'GET',
    },
  };
};

export const resetTwilioVideoRoomAuth = () => {
  return {
    type: RESET_TWILIO_VIDEO_ROOM_AUTH,
  };
};
