export const logEvent = function(name, data = {}) {
  try {
    window.gtag('event', name, data);
  } catch (e) {
    if (window.gtag) {
      window.gtag('event', 'log_event_error');
    }
  }
};

export const initializeGA = tagId => {
  if (tagId) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
      window.dataLayer.push(arguments);
    };

    window.gtag('js', new Date());
    window.gtag('config', 'G-2XVBQ6PHLL');
  }
};

export default { logEvent, initializeGA };
