import EN_US from './EN_US';
import FR from './FR';

export default {
  en_us: {
    name: 'English US',
    strings: EN_US
  },
  fr: {
    name: 'French',
    strings: FR
  }
};
