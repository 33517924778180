import { Snackbar, Button, withStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose } from 'recompose';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import toastTypes from '../../types/toastTypes';
import { hideToast, handleRefresh } from './layout.actions';

const HIDE_TOAST_DURATION = 5000;
const MIN_TOAST_WIDTH = 300;

const Toast = ({
  classes,
  autoHide,
  isShowToast,
  toastAction,
  toastMessage,
  variant,
  detailMessage,
  hideToast: onClose,
}) => {
  const [isShowDetailMessage, setShowDetailMessage] = useState(false);

  return (
    <div>
      <Snackbar
        autoHideDuration={autoHide ? HIDE_TOAST_DURATION : null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isShowToast}
        onClose={hideToast}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: classes[variant],
          },
        }}
        message={
          <div className={classes.messageContainer} id="message-id">
            <div className={classes.messageContents}>
              <Typography className={classes.message}>{toastMessage}</Typography>
              {isShowDetailMessage && (
                <Typography className={classNames(classes.message, classes.messageDetail)}>
                  {detailMessage}
                </Typography>
              )}
            </div>
            <div className={classes.actionsWrapper}>
              {!isEmpty(detailMessage) && (
                <Button
                  className={classes.action}
                  onClick={() => setShowDetailMessage(!isShowDetailMessage)}
                  variant="outlined"
                >
                  {isShowDetailMessage ? 'Less' : 'More'}
                </Button>
              )}
              {toastAction && (
                <Button
                  className={classes.action}
                  onClick={toastAction.handleClick}
                  variant="outlined"
                >
                  {toastAction.text}
                </Button>
              )}
            </div>
          </div>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
};

const styles = theme => ({
  [toastTypes.ERROR]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  [toastTypes.INFO]: {
    backgroundColor: theme.palette.primary.dark,
  },
  messageContainer: {
    alignItems: 'center',
    display: 'flex',
    minWidth: MIN_TOAST_WIDTH,
  },
  message: {
    color: theme.palette.common.white,
  },
  messageDetail: {
    marginTop: theme.spacing.unit,
  },
  messageContents: {
    flex: 1,
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  action: {
    color: 'white',
    marginLeft: '1rem',
  },
});

Toast.propTypes = {
  classes: PropTypes.object.isRequired,

  autoHide: PropTypes.bool,
  isShowToast: PropTypes.bool.isRequired,
  toastAction: PropTypes.object,
  toastMessage: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    toastTypes.ERROR,
    toastTypes.INFO,
    toastTypes.SUCCESS,
    toastTypes.WARNING,
  ]).isRequired,

  hideToast: PropTypes.func.isRequired,
  detailMessage: PropTypes.string,
};

Toast.defaultProps = {
  toastAction: null,
  autoHide: false,
  detailMessage: '',
};

const mapStateToProps = state => {
  return {
    isShowToast: state.layout.isShowToast,
    toastMessage: state.layout.toastMessage,
    variant: state.layout.variant,
    toastAction: state.layout.toastAction,
    detailMessage: state.layout.detailMessage,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    hideToast,
    handleRefresh,
  })
)(Toast);
