import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ChevronLeft';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import PowerSettingsIcon from '@material-ui/icons/PowerSettingsNew';
import PersonIcon from '@material-ui/icons/Person';
import { isNil, has, isEmpty } from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import logo from '@brand/images/icons/logo.png';
import { DRAWER_WIDTH } from './drawerMenu.component';
import auth from '../../auth/auth';
import { clearAuth } from '../login/login.actions';
import { setDirtyForm } from './layout.actions';
import CustomDialog from '../../common/customDialog/customDialog.component';
import Languages from '../language/languages';
import { setShowFacilitySelection, clearSelectedFacility } from '../facility/facility.actions';
import LayoutUtils from '../../utilities/layoutUtils';
import { getMarketingContent } from '../../utilities/entranceUtils';
import { MARKETING_CONTENT_TYPES } from '../../types/marketingContentTypes';

export const HEADER_HEIGHT = 56;

const Header = ({
  classes,
  location: { pathname },
  history,
  hasDirtyForm,
  isDrawerMenuOpen,
  marketingLogo,
  profile,
  selectedFacility,
  selectedLanguageKey,
  shouldShowBackButton,
  clearAuth: handleClearAuth,
  clearSelectedFacility: handleClearSelectedFacility,
  setDirtyForm: handleSetDirtyForm,
  setShowFacilitySelection: handleSetShowFacilitySelection,
}) => {
  const language = Languages[selectedLanguageKey].strings;
  const [isShowLogoutDialog, setShowLogoutDialog] = useState(false);

  const handleLogout = useCallback(async () => {
    await handleClearAuth();
    handleClearSelectedFacility();

    window.localStorage.removeItem('FACILITY_ID');
    auth.logout();
  }, [handleClearAuth, handleClearSelectedFacility]);

  const handleToggleLogoutDialog = useCallback(() => {
    setShowLogoutDialog(!isShowLogoutDialog);
  }, [isShowLogoutDialog, setShowLogoutDialog]);

  const handleShowFacilitySelect = useCallback(() => {
    handleSetShowFacilitySelection(true);
  }, [handleSetShowFacilitySelection]);

  const handleBackButtonClick = useCallback(() => {
    if (hasDirtyForm) {
      console.log('Had dirty form... manually assessing prompt');
      /* eslint-disable-next-line no-alert */
      if (window.confirm(language.alertMessages.backButtonConfirm)) {
        handleSetDirtyForm(false);
        history.goBack();
      }
    } else {
      history.goBack();
    }
  }, [hasDirtyForm, handleSetDirtyForm, history, language]);

  const isFacilitatedLogin = !isNil(selectedFacility);
  const hasFacilities = has(profile, 'facilities') && !isEmpty(profile.facilities);
  const isAtHomePage = pathname === '/';
  // Regex test here is looking for /visit/:visitId
  const isActiveVisitPage = LayoutUtils.checkIsActiveVisitPage(pathname);

  return (
    <>
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: isDrawerMenuOpen,
        })}
      >
        <Toolbar disableGutters={!isDrawerMenuOpen} classes={{ root: classes.toolbarRoot }}>
          {shouldShowBackButton && (
            <IconButton
              color="inherit"
              aria-label="Back"
              className={classes.headerButton}
              onClick={handleBackButtonClick}
            >
              <BackIcon />
            </IconButton>
          )}
          <Grid container justify="center" className={classes.gridContainer}>
            <Grid item xs={12} className={classes.toolbarGridItem}>
              <img
                style={{
                  height: '100%',
                  marginLeft: shouldShowBackButton ? 0 : '3rem',
                }}
                src={!isEmpty(marketingLogo) ? marketingLogo[0].imageUrl : logo}
                alt=""
              />
              <div className={classes.actionContainer}>
                {/* show switch accounts on  */}
                {!isFacilitatedLogin && hasFacilities && isAtHomePage && (
                  <Button
                    size="small"
                    className={classes.actionButton}
                    variant="text"
                    onClick={handleShowFacilitySelect}
                  >
                    <Typography className={classes.buttonText}>{language.switchAccount}</Typography>
                    <PersonIcon className={classes.icon} />
                  </Button>
                )}
                {!isActiveVisitPage && (
                  <Button
                    size="small"
                    className={classes.actionButton}
                    variant="text"
                    onClick={handleToggleLogoutDialog}
                  >
                    <Typography className={classes.buttonText}>
                      {language.buttonText.logout}
                    </Typography>
                    <PowerSettingsIcon className={classes.icon} />
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <CustomDialog
        open={isShowLogoutDialog}
        handleClose={handleToggleLogoutDialog}
        content={
          <Typography variant="subtitle1" id="modal-title">
            {language.alertMessages.confirmLogout}
          </Typography>
        }
        title={language.logout}
        handleAction={handleLogout}
      />
    </>
  );
};

const styles = (theme) => ({
  toolbarContents: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  toolbarGridItem: {
    display: 'flex',
    marginTop: 0,
    height: 32,
    width: '100%',
  },
  gridContainer: {
    [theme.breakpoints.up('sm')]: {
      position: 'unset',
    },
    zIndex: -1,
  },
  pageTitleTypography: {
    fontWeight: 600,
    fontSize: '1.3rem',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: HEADER_HEIGHT,
  },
  toolbarRoot: {
    height: HEADER_HEIGHT,
    minHeight: 'unset',
    zIndex: -1,
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: HEADER_HEIGHT,
  },
  headerButton: {
    marginLeft: 12,
    marginRight: 12,
    zIndex: 100,
  },
  actionButton: {
    cursor: 'pointer',
    height: '100%',
    justifySelf: 'flex-end',
    marginRight: '1rem',
    zIndex: 100,
  },
  hide: {
    display: 'none',
  },
  buttonText: {
    alignSelf: 'flex-end',
    color: theme.palette.common.white,
    marginRight: '0.5rem',
  },
  icon: {
    color: theme.palette.common.white,
  },
  actionContainer: {
    position: 'absolute',
    right: 0,
  },
});

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  hasDirtyForm: PropTypes.bool.isRequired,
  isDrawerMenuOpen: PropTypes.bool.isRequired,
  marketingLogo: PropTypes.array,
  profile: PropTypes.object.isRequired,
  selectedFacility: PropTypes.object,
  selectedLanguageKey: PropTypes.string.isRequired,
  shouldShowBackButton: PropTypes.bool.isRequired,

  clearAuth: PropTypes.func.isRequired,
  clearSelectedFacility: PropTypes.func.isRequired,
  setShowFacilitySelection: PropTypes.func.isRequired,
  setDirtyForm: PropTypes.func.isRequired,
};

Header.defaultProps = {
  marketingLogo: [],
  selectedFacility: null,
};

const mapStateToProps = (state) => {
  const { entrance, facility, language, layout, login, profile } = state;

  return {
    hasDirtyForm: layout.hasDirtyForm,
    isDrawerMenuOpen: layout.isDrawerMenuOpen,
    loginState: login.loginState,
    marketingLogo: getMarketingContent(
      entrance.entrance,
      MARKETING_CONTENT_TYPES.DESKTOP_WHITE_LOGO,
    ),
    profile: profile.profile,
    selectedFacility: facility.selectedFacility,
    selectedLanguageKey: language.selectedLanguageKey,
    user: login.user,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    clearAuth,
    clearSelectedFacility,
    setDirtyForm,
    setShowFacilitySelection,
  }),
  withStyles(styles),
)(Header);
