import produce from 'immer';
import {
  CLEAR_PRIOR_VISIT,
  CLEAR_PRIOR_VISITS,
  GET_PRIOR_VISITS_FAILURE,
  GET_PRIOR_VISITS_REQUEST,
  GET_PRIOR_VISITS_SUCCESS,
  GET_PRIOR_VISIT_NOTES_FAILURE,
  GET_PRIOR_VISIT_NOTES_REQUEST,
  GET_PRIOR_VISIT_NOTES_SUCCESS,
  GET_PRIOR_VISIT_PRESCRIPTIONS_FAILURE,
  GET_PRIOR_VISIT_PRESCRIPTIONS_REQUEST,
  GET_PRIOR_VISIT_PRESCRIPTIONS_SUCCESS,
  SET_PRIOR_VISIT,
  GET_VISIT_NOTE_PDF_REQUEST,
  GET_VISIT_NOTE_PDF_SUCCESS,
  GET_VISIT_NOTE_PDF_FAILURE,
} from './priorVisits.actions';

const initialState = {
  priorVisits: [],
  priorVisit: {},
  isLoadingPriorVisits: false,
  isDownloadingPdf: false,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_PRIOR_VISITS_SUCCESS:
      // console.log('GET PRIOR VISITS SUCCESS', action.response.data);

      /*
      { id: 'vis_SNjZPgypDAcZ',
       patientId: 'pat_aiWei4kieNae',
       chiefComplaint: 'My leg hurts',
       mobileNumber: null,
       location: {},
       state: 'completed',
       providerId: 'pr_gooRie3oothi' }
       
      */

      draft.priorVisits = [...action.response.data].sort(function(a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.created) - new Date(a.created);
      });

      draft.isLoadingPriorVisits = false;
      break;

    case GET_PRIOR_VISITS_FAILURE:
      console.log('GET PRIOR VISITS FAILURE', action.messages);
      draft.isLoadingPriorVisits = false;
      break;

    case GET_PRIOR_VISITS_REQUEST:
      draft.isLoadingPriorVisits = true;
      break;

    case GET_PRIOR_VISIT_PRESCRIPTIONS_SUCCESS:
      console.log('GET PRIOR VISIT PRESCRIPTIONS SUCCESS', action.response.data);
      draft.priorVisit.prescriptions = action.response.data;
      draft.isLoadingPriorVisits = false;
      break;

    case GET_PRIOR_VISIT_PRESCRIPTIONS_FAILURE:
      console.log('GET PRIOR VISIT FAILURE', action.messages);
      draft.isLoadingPriorVisits = false;
      break;

    case GET_PRIOR_VISIT_PRESCRIPTIONS_REQUEST:
      draft.isLoadingPriorVisits = true;
      break;

    case GET_PRIOR_VISIT_NOTES_SUCCESS:
      // console.log('GET PRIOR VISIT NOTES SUCCESS', action.response.data);
      draft.priorVisit.notes = action.response.data;
      draft.isLoadingPriorVisits = false;
      break;

    case GET_PRIOR_VISIT_NOTES_FAILURE:
      console.log('GET PRIOR VISIT FAILURE', action.messages);
      draft.isLoadingPriorVisits = false;
      break;

    case GET_PRIOR_VISIT_NOTES_REQUEST:
      draft.isLoadingPriorVisits = true;
      break;

    case CLEAR_PRIOR_VISITS:
      draft.priorVisits = [];
      break;

    case SET_PRIOR_VISIT:
      draft.priorVisit = action.response;
      break;

    case CLEAR_PRIOR_VISIT:
      draft.priorVisit = {};
      break;

    case GET_VISIT_NOTE_PDF_SUCCESS:
      draft.isDownloadingPdf = false;
      break;

    case GET_VISIT_NOTE_PDF_FAILURE:
      draft.isDownloadingPdf = false;
      break;

    case GET_VISIT_NOTE_PDF_REQUEST:
      draft.isDownloadingPdf = true;
      break;

    default:
      break;
  }
}, initialState);
