/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import { isEmpty, isNil, debounce, has } from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';

import { setPageTitle, showToast } from '../layout/layout.actions';
import CustomDialog from '../../common/customDialog/customDialog.component';
import {
  getPaymentMethods,
  savePaymentMethod,
  GET_PAYMENT_METHODS_SUCCESS,
  SAVE_PAYMENT_METHOD_SUCCESS,
} from '../profile/profile.actions';
import { getPricing, GET_PRICING_SUCCESS, setPricingCodes } from '../visit/visit.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import PaymentMethodForm from './forms/patientPaymentMethodForm.component';
import paymentTypes from '../../types/paymentTypes';
import ToastTypes from '../../types/toastTypes';
import Languages from '../language/languages';
import { logEvent } from '../../utilities/googleAnalytics';

import { config as brandConfig } from '@brand';

const { entranceId } = brandConfig;

class PaymentMethodContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: null,
      formIsDirty: false,
      isShowCancelDialog: false,
      isInitializing: true,
      couponCode: !isEmpty(props.pricingCodes.coupon) ? props.pricingCodes.coupon : '',
      groupCode: !isEmpty(props.pricingCodes.group) ? props.pricingCodes.group : '',
      groupIdentifier: !isEmpty(props.pricingCodes.identifier) ? props.pricingCodes.identifier : '',
      stripeToken: null,
      cardBrand: !isNil(props.selectedPaymentMethod) ? props.selectedPaymentMethod.brand : null,
      cardNumber: !isNil(props.selectedPaymentMethod) ? props.selectedPaymentMethod.last4 : null,
      selectedPaymentMethod: paymentTypes.CREDIT_CARD,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.paymentMethods)) {
      this.setState({
        selectedPaymentMethod: nextProps.paymentMethods[0],
        cardNumber: nextProps.paymentMethods[0].last4,
        cardBrand: nextProps.paymentMethods[0].brand,
      });
    }
  }

  async componentDidMount() {
    const paymentResult = await this.props.getPaymentMethods();
    const pricingResult = await this.props.getPricing(
      this.state.couponCode,
      this.state.groupCode,
      this.state.groupIdentifier
    );

    this.props.setPageTitle(
      Languages[this.props.selectedLanguageKey].strings.pageTitles.paymentMethod
    );

    if (
      paymentResult.type === GET_PAYMENT_METHODS_SUCCESS &&
      pricingResult.type === GET_PRICING_SUCCESS
    ) {
      this.setState({ isInitializing: false });
    }
  }

  handleSetToken = token => {
    this.setState({
      stripeToken: token,
      cardBrand: token.card.brand,
      cardNumber: token.card.last4,
    });
  };

  handleSavePaymentMethod = async () => {
    const { stripeToken, groupCode, groupIdentifier, couponCode } = this.state;

    this.props.setPricingCodes(couponCode, groupCode, groupIdentifier);

    if (!isNil(stripeToken)) {
      const response = await this.props.savePaymentMethod({
        token: stripeToken.id,
      });
      // clear stripe token after save
      if (response.type === SAVE_PAYMENT_METHOD_SUCCESS) {
        logEvent('card_entered', {
          patientId: this.props.profile.id,
          entryId: entranceId,
          accountId: this.props.profile.id,
        });
        this.props.history.replace(`/patient/${this.props.location.state.patientId}/visit`);
      } else {
        this.props.showToast(
          Languages[this.props.selectedLanguageKey].strings.showToastMessages.updatePaymentFailure,
          ToastTypes.ERROR,
          true,
          null,
          response.messages[0]
        );
        return false;
      }
    } else {
      this.props.history.replace(`/patient/${this.props.location.state.patientId}/visit`);
    }
  };

  handlePaymentFieldChange = field => event => {
    const updateState = { ...this.state };

    // if clearing employer code, also clear identifier
    if (field === 'groupCode' && event.target.value === '') {
      updateState.groupIdentifier = '';
    }

    updateState[field] = event.target.value.toUpperCase();
    this.setState(updateState, () => {
      this.debouncedGetPricing();
    });
  };

  debouncedGetPricing = debounce(
    () => {
      const { couponCode, groupCode, groupIdentifier } = this.state;
      this.props.getPricing(couponCode, groupCode, groupCode !== '' ? groupIdentifier : '');
    },
    1000,
    { trailing: true }
  );

  formHasErrors() {
    const hasNoPaymentMethods = isEmpty(this.props.paymentMethods);
    const hasNoStripeToken = isNil(this.state.stripeToken);
    const hasPricingError = !isEmpty(this.props.pricing.price.message);
    const isZeroPriceVisit = this.isZeroPriceVisit();

    return ((hasNoPaymentMethods && hasNoStripeToken) || hasPricingError) && !isZeroPriceVisit;
  }

  isZeroPriceVisit = () => {
    return has(this.props.pricing, 'price.price') && this.props.pricing.price.price === 0;
  };

  render() {
    const { classes, isLoading, selectedLanguageKey } = this.props;
    const { formIsDirty, isShowCancelDialog, isInitializing } = this.state;
    const language = Languages[selectedLanguageKey].strings;

    if (isLoading || isInitializing) return <LoadingOverlay />;

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.stepperContent}>
            <Elements>
              <PaymentMethodForm
                cardBrand={this.state.cardBrand}
                cardNumber={this.state.cardNumber}
                couponCode={this.state.couponCode}
                groupCode={this.state.groupCode}
                groupIdentifier={this.state.groupIdentifier}
                handleSetToken={this.handleSetToken}
                onFieldChange={this.handlePaymentFieldChange}
                pricing={this.props.pricing}
              />
            </Elements>
          </div>
        </div>
        <div className={classes.formActions}>
          <Button
            className={classes.actionButton}
            onClick={formIsDirty ? this.handleShowCancelDialog : () => this.props.history.goBack()}
          >
            {language.buttonText.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.actionButton}
            onClick={this.handleSavePaymentMethod}
            disabled={this.formHasErrors()}
          >
            {language.buttonText.save}
          </Button>
        </div>
        <CustomDialog
          open={isShowCancelDialog}
          handleClose={this.handleCloseCancelDialog}
          content={
            <Typography variant="subtitle1" id="modal-title">
              {language.alertMessages.confirmExitPayment}
            </Typography>
          }
          title={language.alertMessages.confirmExitPaymentTitle}
          handleAction={() => this.props.history.goBack()}
        />
      </div>
    );
  }
}

const styles = theme => ({
  content: {
    backgroundColor: theme.palette.primary.background,
    flex: 1,
    overflow: 'auto',
    width: '100%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  stepperContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 88px)',
    margin: '2rem auto 0',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formActions: {
    background: theme.palette.primary.background,
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  actionButton: {
    fontSize: 14,
    margin: '1rem',
    maxWidth: '7rem',
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
});

PaymentMethodContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array,
  pricing: PropTypes.object,
  pricingCodes: PropTypes.object,
  profile: PropTypes.object.isRequired,
  selectedPaymentMethod: PropTypes.object,

  getPaymentMethods: PropTypes.func.isRequired,
  getPricing: PropTypes.func.isRequired,
  savePaymentMethod: PropTypes.func.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  setPricingCodes: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

PaymentMethodContainer.defaultProps = {
  paymentMethods: [],
  pricing: {},
  pricingCodes: {},
  selectedPaymentMethod: null,
};

const mapStateToProps = state => {
  return {
    isLoading: state.profile.isSavingPaymentInfo || state.profile.isLoadingPaymentInfo,
    paymentMethods: state.profile.paymentMethods,
    pricing: state.visit.pricing,
    pricingCodes: state.visit.pricingCodes,
    profile: state.profile.profile,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    getPaymentMethods,
    getPricing,
    savePaymentMethod,
    setPageTitle,
    setPricingCodes,
    showToast,
  })
)(PaymentMethodContainer);
