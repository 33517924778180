import { TRACK_TYPES, TRACK_ACTIONS } from '../types/visitTrackTypes';

const muteOrUnmuteYourMedia = (room, kind, action) => {
  const publications =
    kind === TRACK_TYPES.AUDIO
      ? room.localParticipant.audioTracks
      : room.localParticipant.videoTracks;

  publications.forEach(publication => {
    if (action === TRACK_ACTIONS.MUTE) {
      publication.track.disable();
    } else {
      publication.track.enable();
    }
  });
};

const toggleAudioMute = (room, status) => {
  muteOrUnmuteYourMedia(
    room,
    TRACK_TYPES.AUDIO,
    status ? TRACK_ACTIONS.MUTE : TRACK_ACTIONS.UNMUTE
  );
};

const toggleVideoMute = (room, status) => {
  muteOrUnmuteYourMedia(
    room,
    TRACK_TYPES.VIDEO,
    status ? TRACK_ACTIONS.MUTE : TRACK_ACTIONS.UNMUTE
  );
};

export default {
  toggleAudioMute,
  toggleVideoMute,
};
