import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';

import { config as brandConfig } from '@brand';
import { MARKETING_CONTENT_TYPES } from '../../types/marketingContentTypes';

const hexRegEx = /^#[0-9A-F]{6}$/i;

const generateColorObject = (main, contrastText, defaultColor) => {
  /*
    Theme documentation for material-ui: https://material-ui.com/customization/palette/
    When providing a custom color object main is required, past that everything needs to
    be a valid hex code. If this does not pass, material ui will calculate the light,
    dark, and / or contrastText if the provide value isn't valid
  */
  const color = {};

  if (isEmpty(main) || !hexRegEx.test(main[0].color)) {
    return { main: defaultColor };
  }

  color.main = main[0].color;

  if (!isEmpty(contrastText) && hexRegEx.test(contrastText[0].color))
    color.contrastText = contrastText[0].color;

  return color;
};

const getColor = (themeColor, defaultColor) =>
  isEmpty(themeColor) || !hexRegEx.test(themeColor[0].color) ? defaultColor : themeColor[0].color;

const ThemeProvider = ({ children, marketingContent }) => {
  const primaryColor = marketingContent
    ? marketingContent[MARKETING_CONTENT_TYPES.BRAND_PRIMARY_COLOR]
    : brandConfig.theme.primary.main;

  const primaryContrastColor = marketingContent
    ? marketingContent[MARKETING_CONTENT_TYPES.BRAND_PRIMARY_CONTRAST_COLOR]
    : brandConfig.theme.primary.contrastText;

  const primary = generateColorObject(
    primaryColor,
    primaryContrastColor,
    brandConfig.theme.primary.main,
  );

  const primaryDarkColor = marketingContent
    ? marketingContent[MARKETING_CONTENT_TYPES.BRAND_PRIMARY_DARK_COLOR]
    : brandConfig.theme.primary.dark;

  const primaryDark = getColor(primaryDarkColor, brandConfig.theme.primary.dark);

  const secondaryColor = marketingContent
    ? marketingContent[MARKETING_CONTENT_TYPES.BRAND_SECONDARY_COLOR]
    : brandConfig.theme.secondary.main;

  const secondary = generateColorObject(secondaryColor, null, brandConfig.theme.secondary.main);

  const errorColor = marketingContent
    ? marketingContent[MARKETING_CONTENT_TYPES.BRAND_ERROR_COLOR]
    : brandConfig.theme.primary.error;

  const error = generateColorObject(errorColor, null, brandConfig.theme.primary.error);

  const successColor = marketingContent
    ? marketingContent[MARKETING_CONTENT_TYPES.BRAND_SUCCESS_COLOR]
    : brandConfig.theme.primary.success;

  const success = generateColorObject(successColor, null, brandConfig.theme.primary.success);

  const warningColor = marketingContent
    ? marketingContent[MARKETING_CONTENT_TYPES.BRAND_WARNING_COLOR]
    : brandConfig.theme.primary.warning;

  const warning = getColor(warningColor, brandConfig.theme.primary.warning);

  const customTheme = createMuiTheme({
    typography: {
      useNextVariants: true,
      fontFamily: 'Heebo,Cabin,"Varela Round"',
      button: {
        fontFamily: 'Varela Round',
      },
      h1: {
        fontFamily: 'Cabin',
      },
      h2: {
        fontFamily: 'Cabin',
      },
      h3: {
        fontFamily: 'Cabin',
      },
      h4: {
        fontFamily: 'Cabin',
      },
      h5: {
        fontFamily: 'Cabin',
      },
      h6: {
        fontFamily: 'Cabin',
      },
      body: {
        fontFamily: 'Heebo',
      },
      body2: {
        fontFamily: 'Heebo',
      },
    },
    palette: {
      primary: {
        ...primary,
        dark: primaryDark,
        background: '#E7ECED',
        darkGray: '#5C5C5C',
        lightGray: '#E0E0E0',
        warning,
      },
      secondary,
      error,
      success,
    },
  });

  return <MuiThemeProvider theme={customTheme}>{children}</MuiThemeProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.any.isRequired,
  marketingContent: PropTypes.object.isRequired,
};

export default ThemeProvider;
