/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles, Paper, Typography, Icon, Link } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isNil, has } from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';

import { setShowFacilitySelection } from '../../facility/facility.actions';
import Languages from '../../language/languages';

class FacilityHomeHeader extends Component {
  switchAccounts = () => {
    this.props.setShowFacilitySelection(true);
  };

  getFacilityAddress = () => {
    const { selectedFacility } = this.props;
    const { addressLine1, addressCity, addressState, addressZip } = selectedFacility;
    return `${addressLine1}, ${addressCity}, ${addressState} ${addressZip}`;
  };

  render() {
    const { profile, selectedFacility, classes, selectedLanguageKey } = this.props;
    const language = Languages[selectedLanguageKey].strings;

    const isShowDocumentSearch =
      !isNil(selectedFacility) &&
      has(selectedFacility, 'shareVisitDocuments') &&
      selectedFacility.shareVisitDocuments === true;

    return (
      <Paper className={classes.paper}>
        <div className={classes.content}>
          <div>
            <Typography className={classes.facilityName}>
              {selectedFacility.name.toUpperCase()}
            </Typography>
            <Typography className={classes.facilityAddress}>{this.getFacilityAddress()}</Typography>
          </div>
          <div className={classes.links}>
            <Link
              className={classes.link}
              component="button"
              variant="body2"
              onClick={this.switchAccounts}
            >
              {language.switchAccount}
            </Link>

            {isShowDocumentSearch && (
              <Link
                className={classes.link}
                component="button"
                variant="body2"
                onClick={() => this.props.history.push('/documents')}
              >
                {language.documentSearch}
              </Link>
            )}
          </div>
        </div>
        <div className={classes.userContainer}>
          <Icon className={classnames(['far fa-user', classes.userIcon])} />
          <Typography className={classes.user}>{language.loggedInAs}</Typography>
          <Typography className={classes.user}>
            {`${profile.firstName} ${profile.lastName}`}
          </Typography>
        </div>
      </Paper>
    );
  }
}

const styles = theme => ({
  userIcon: {
    fontSize: 26,
    color: theme.palette.primary.darkGray,
  },
  paper: {
    padding: '0.75rem 2rem',
    display: 'flex',
    borderRadius: 0,
  },
  content: {
    flex: '1',
    display: 'flex',
    margin: 'auto',
  },
  facilityName: {
    fontSize: 22,
    color: theme.palette.primary.darkGray,
  },
  facilityAddress: {
    fontSize: 14,
    fontWeight: 200,
    color: theme.palette.primary.darkGray,
  },
  links: {
    paddingLeft: '1rem',
    paddingTop: '0.25rem',
  },
  userContainer: {
    textAlign: 'center',
    marginRight: '1rem',
  },
  link: {
    color: theme.palette.primary.darkGray,
    display: 'block',
    textDecoration: 'underline',
  },
  user: {
    color: theme.palette.primary.darkGray,
    fontSize: 12,
  },
});

FacilityHomeHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  profile: PropTypes.object.isRequired,
  selectedFacility: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  setShowFacilitySelection: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedLanguageKey: state.language.selectedLanguageKey,
    profile: state.profile.profile,
    selectedFacility: state.facility.selectedFacility,
  };
};

const withRouterFacilityHomeHeader = withRouter(
  connect(mapStateToProps, {
    setShowFacilitySelection,
  })(FacilityHomeHeader)
);

export default withStyles(styles, { withTheme: true })(withRouterFacilityHomeHeader);
