import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemAvatar, Avatar, ListItemText, Icon } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const SurveyAnswerChoice = ({ classes, choice, isSelected, selectChoice, theme }) => (
  <ListItem button onClick={selectChoice}>
    <ListItemAvatar>
      <Avatar
        style={{
          backgroundColor: isSelected
            ? theme.palette.success.main
            : theme.palette.primary.lightGray,
        }}
      >
        <Icon
          className={classNames([
            isSelected ? 'fas fa-check-circle' : 'fas fa-circle',
            classes.listIcon,
          ])}
        />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary={choice} />
  </ListItem>
);

const styles = theme => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  listIcon: {
    fontSize: '20px',
    width: 'unset',
    alignSelf: 'center',
    color: theme.palette.common.white,
  },
});

SurveyAnswerChoice.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  choice: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,

  selectChoice: PropTypes.func.isRequired,
};

SurveyAnswerChoice.defaultProps = {
  choice: '',
};

export default withStyles(styles, { withTheme: true })(SurveyAnswerChoice);
