import produce from 'immer';
import {
  GET_TWILIO_VIDEO_ROOM_AUTH_REQUEST,
  GET_TWILIO_VIDEO_ROOM_AUTH_SUCCESS,
  GET_TWILIO_VIDEO_ROOM_AUTH_FAILURE,
  RESET_TWILIO_VIDEO_ROOM_AUTH,
} from './videoCall.actions';

const initialState = {
  twilioVideoAuth: null,
  isLoading: false,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_TWILIO_VIDEO_ROOM_AUTH_SUCCESS:
      draft.twilioVideoAuth = action.response;
      draft.isLoading = false;
      break;

    case GET_TWILIO_VIDEO_ROOM_AUTH_FAILURE:
      draft.isLoading = false;
      break;

    case GET_TWILIO_VIDEO_ROOM_AUTH_REQUEST:
      draft.isLoading = true;
      break;

    case RESET_TWILIO_VIDEO_ROOM_AUTH:
      draft.twilioVideoAuth = null;
      break;

    default:
      break;
  }
}, initialState);
