import produce from 'immer';
import { LOGIN_STATE, SET_AUTH, CLEAR_AUTH } from './login.actions';

const initialState = {
  user: null,
  loginState: LOGIN_STATE.NOT_LOGGED_IN,
  authSet: false,
  roles: [],
  permissions: [],
  patientId: null
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      draft.authSet = true;

      if (action.auth) {
        const roles = action.auth['http://relymd.com/roles'];
        const permissions = action.auth['http://relymd.com/permissions'];

        draft.user = action.auth;
        draft.roles = roles;
        draft.permissions = permissions;
        draft.loginState = LOGIN_STATE.LOGGED_IN;
        draft.patientId = action.auth.sub.split('|')[1];
      }

      break;

    case CLEAR_AUTH:
      draft.user = null;
      draft.roles = [];
      draft.permissions = [];
      draft.loginState = LOGIN_STATE.NOT_LOGGED_IN;
      draft.patientId = null;
      break;

    default:
      break;
  }
}, initialState);
