// map db field definitions
export const genderTypes = {
  MALE: 'male',
  FEMALE: 'female',
  ASK: 'ask'
};

export const getShortGender = gender => {
  switch (gender.toLowerCase()) {
    case genderTypes.MALE:
      return 'M';

    case genderTypes.FEMALE:
      return 'F';

    case genderTypes.ASK:
      return 'Ask';

    default:
      return 'Unknown';
  }
};

export const getLongGender = gender => {
  switch (gender) {
    case genderTypes.MALE:
      return 'Male';

    case genderTypes.FEMALE:
      return 'Female';

    case genderTypes.ASK:
      return 'Ask';

    default:
      return 'Unknown';
  }
};

export const getPatientPossessivePronoun = gender => {
  switch (gender.toLowerCase()) {
    case genderTypes.MALE:
      return 'his';
    case genderTypes.FEMALE:
      return 'hers';
    default:
      return 'theirs';
  }
};

export default { genderTypes, getShortGender, getLongGender, getPatientPossessivePronoun };
