import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';

const styles = theme => ({
  image: {
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      minHeight: '150px' // This is the height of the image when it hits this breakpoint
    }
  }
});

class ImageCarousel extends Component {
  render() {
    const { items, handleItemClick, maxHeight, classes } = this.props;

    const settings = {
      showStatus: false,
      infiniteLoop: items.length > 1,
      swipeable: items.length > 1,
      emulateTouch: true,
      interval: 5000,
      autoPlay: items.length > 1,
      onClickItem: index => {
        handleItemClick(items[index]);
      },
      showThumbs: false,
      showIndicators: items.length > 1
    };

    return (
      <Carousel {...settings}>
        {items.map((item, i) => {
          return (
            <div key={i} style={{ cursor: 'pointer' }}>
              <img
                src={item.imageUrl}
                className={classes.image}
                style={{ maxHeight: maxHeight ? maxHeight : 'unset' }}
                alt={item.altText}
              />
            </div>
          );
        })}
      </Carousel>
    );
  }
}

ImageCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  settings: PropTypes.object,
  handleItemClick: PropTypes.func,
  maxHeight: PropTypes.number
};

export default withStyles(styles)(ImageCarousel);
