import React, { Component } from 'react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';
import GooglePlacesTextValidator from '../../common/inputValidators/googlePlacesTextValidator.component';
import Languages from '../language/languages';

const styles = theme => ({
  formContainer: {},
  formControl: {
    width: '100%'
  },
  autocomplete: {
    marginTop: '1rem',
    marginBottom: '0.5rem'
  }
});

class PharmacySearch extends Component {
  handleAddressSelection = selection => {
    if (!isNil(selection)) {
      const { lat, lng } = selection.geometry.location;
      this.props.onSelection(selection.formatted_address, lat(), lng());
    }
  };

  handleAddressChange = value => {
    this.props.onChange(value);
  };

  render() {
    const { address, classes, selectedLanguageKey } = this.props;

    return (
      <GooglePlacesTextValidator
        inputClassName={classnames([classes.formControl, classes.autocomplete])}
        label={Languages[selectedLanguageKey].strings.inputLabels.pharmacySearch}
        onChange={this.handleAddressChange}
        onSelect={this.handleAddressSelection}
        validators={[]}
        value={address ? address : ''}
        type="full-place"
      />
    );
  }
}

PharmacySearch.propTypes = {
  address: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelection: PropTypes.func.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired
};

export default withStyles(styles)(PharmacySearch);
