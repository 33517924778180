import React from 'react';
import { isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { config as brandConfig } from '@brand';

const Map = ({ currentPosition, selectedPosition, mapContainerClassName, markers, ...props }) => {
  const selectMarker = marker => {
    if (!marker || !marker.Latitude || !marker.Longitude) {
      return;
    }

    props.selectMarker(marker);
  };

  const getCenter = () => {
    if (!isNil(selectedPosition)) {
      return { lat: selectedPosition.latitude, lng: selectedPosition.longitude };
    }

    if (!isNil(currentPosition) && !currentPosition.error) {
      return { lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude };
    }

    return {
      lat: brandConfig.defaultPharmacySearchLatLng[0],
      lng: brandConfig.defaultPharmacySearchLatLng[1]
    };
  };

  const getInitialStart = () => {
    return !isNil(currentPosition)
      ? {
          lat: currentPosition.coords.latitude,
          lng: currentPosition.coords.longitude
        }
      : {
          lat: brandConfig.defaultPharmacySearchLatLng[0],
          lng: brandConfig.defaultPharmacySearchLatLng[1]
        };
  };

  return (
    <GoogleMap zoom={13} center={getCenter()} mapContainerClassName={mapContainerClassName}>
      <Marker position={getInitialStart()} />
      {!isEmpty(markers) &&
        markers.map(pharm => (
          <Marker
            key={pharm.PharmacyId}
            position={{ lat: Number(pharm.Latitude), lng: Number(pharm.Longitude) }}
            onClick={() => selectMarker(pharm)}
            icon={
              pharm.selected
                ? 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                : 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
            }
          />
        ))}
    </GoogleMap>
  );
};

const styles = () => ({});

Map.propTypes = {
  markers: PropTypes.array,
  selectMarker: PropTypes.func.isRequired,
  currentPosition: PropTypes.object,
  selectedPosition: PropTypes.object
};

export default withStyles(styles)(Map);
