import jwt_decode from 'jwt-decode';
import { CALL_API } from '../../middleware/api';
import { config as brandConfig } from '@brand';
const { entranceId } = brandConfig;

export const SET_AUTH = 'SET_AUTH';
export const CLEAR_AUTH = 'CLEAR_AUTH';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

/* Define a login state to use across the app. */
export const LOGIN_STATE = {
  NOT_LOGGED_IN: 0,
  LOGGED_IN: 1
};

export const setAuth = auth => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_AUTH,
      auth: auth !== null ? jwt_decode(auth) : null
    });
  };
};

export const clearAuth = () => {
  return {
    type: CLEAR_AUTH
  };
};

export const createUser = payload => {
  payload.entranceId = entranceId;

  return {
    [CALL_API]: {
      types: [CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE],
      authenticated: false,
      endpoint: 'v1/patient/registration',
      method: 'POST',
      payload
    }
  };
};
