import { CALL_API } from '../../middleware/api';
import { cloneDeep, findIndex } from 'lodash';
import { setProfile } from '../profile/profile.actions';

export const CREATE_DEPENDENT_REQUEST = 'CREATE_DEPENDENT_REQUEST';
export const CREATE_DEPENDENT_SUCCESS = 'CREATE_DEPENDENT_SUCCESS';
export const CREATE_DEPENDENT_FAILURE = 'CREATE_DEPENDENT_FAILURE';

export const UPDATE_DEPENDENT_REQUEST = 'UPDATE_DEPENDENT_REQUEST';
export const UPDATE_DEPENDENT_SUCCESS = 'UPDATE_DEPENDENT_SUCCESS';
export const UPDATE_DEPENDENT_FAILURE = 'UPDATE_DEPENDENT_FAILURE';

export const createDependentRequest = payload => {
  return {
    [CALL_API]: {
      types: [CREATE_DEPENDENT_REQUEST, CREATE_DEPENDENT_SUCCESS, CREATE_DEPENDENT_FAILURE],
      authenticated: true,
      endpoint: 'v1/patient/profile/dependents',
      method: 'POST',
      payload
    }
  };
};

export const createDependent = payload => {
  return async (dispatch, getState) => {
    const response = await dispatch(createDependentRequest(payload));

    if (response.type === CREATE_DEPENDENT_SUCCESS) {
      const state = getState();
      const profile = cloneDeep(state.profile.profile);

      profile.dependents.push(response.response);

      dispatch(setProfile(profile));
    }

    return response;
  };
};

export const updateDependentRequest = (patientID, payload) => {
  return {
    [CALL_API]: {
      types: [UPDATE_DEPENDENT_REQUEST, UPDATE_DEPENDENT_SUCCESS, UPDATE_DEPENDENT_FAILURE],
      authenticated: true,
      endpoint: `v1/patient/profile/dependents/${patientID}`,
      method: 'PUT',
      payload
    }
  };
};

export const updateDependent = (patientID, payload) => {
  return async (dispatch, getState) => {
    const response = await dispatch(updateDependentRequest(patientID, payload));

    if (response.type === UPDATE_DEPENDENT_SUCCESS) {
      const state = getState();
      const profile = cloneDeep(state.profile.profile);

      const dependentIndex = findIndex(profile.dependents, dependent => {
        return response.response.id === dependent.id;
      });

      if (dependentIndex !== -1) {
        profile.dependents[dependentIndex] = response.response;
      }

      dispatch(setProfile(profile));
    }

    return response;
  };
};
