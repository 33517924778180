import React from 'react';
import { Typography, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import TextFieldValidator from '../../../common/inputValidators/textFieldValidator.component';
import { required } from '../../../utilities/fieldValidation';
import PhotoSet from '../../../common/photoSet/photoSet.component';
import Languages from '../../language/languages';

const VisitInfoForm = ({
  classes,
  forceValidation,
  isUploadingFiles,
  selectedLanguageKey,
  visit,
  handleAddAttachments,
  handleChange,
  handleRemoveAttachment,
  handleValidationChange,
}) => {
  const language = Languages[selectedLanguageKey].strings;

  return (
    <Paper className={classes.formContainer}>
      <TextFieldValidator
        label={language.inputLabels.reasonForVisit}
        className={classes.formControl}
        value={visit.chiefComplaint ? visit.chiefComplaint : ''}
        onChange={handleChange('chiefComplaint')}
        validators={[required]}
        margin="normal"
        variant="outlined"
        onValidationChange={handleValidationChange('chiefComplaint')}
        forceValidation={forceValidation}
        maxChars={120}
        style={{ marginBottom: '1rem' }}
      />
      <div className={classes.photoTextContainer}>
        <Typography className={classes.photoTitle}>{language.visitDetailsAddPhoto}</Typography>
        <Typography className={classes.descriptionText}>
          {language.visitDetailsPhotoDescription}
        </Typography>
      </div>
      <PhotoSet
        photos={visit.attachments}
        addPhotos={handleAddAttachments}
        removePhoto={handleRemoveAttachment}
        containerStyle={{ marginTop: '1rem' }}
        isLoading={isUploadingFiles}
      />
    </Paper>
  );
};

const styles = theme => ({
  formContainer: {
    margin: '1rem 1rem 6rem',
    padding: '1rem',
  },
  formControl: {
    width: '100%',
  },
  photoTextContainer: {
    marginTop: '1rem',
  },
  photoTitle: {
    color: theme.palette.primary.darkGray,
    fontSize: 18,
  },
  descriptionText: {
    color: theme.palette.primary.darkGray,
    fontSize: 14,
  },
});

VisitInfoForm.propTypes = {
  classes: PropTypes.object.isRequired,

  forceValidation: PropTypes.bool,
  isUploadingFiles: PropTypes.bool,
  selectedLanguageKey: PropTypes.string.isRequired,
  visit: PropTypes.object.isRequired,

  handleAddAttachments: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemoveAttachment: PropTypes.func.isRequired,
  handleValidationChange: PropTypes.func.isRequired,
};

VisitInfoForm.defaultProps = {
  forceValidation: false,
  isUploadingFiles: false,
};

export default withStyles(styles)(VisitInfoForm);
