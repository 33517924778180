import React, { Component } from 'react';
import { withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { InlineDatePicker } from 'material-ui-pickers';
import moment from 'moment';
import { isEmpty } from 'lodash';

class DateFieldValidator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      isValid: true,
      helperText: '',
      partialDate: '',
    };
  }

  componentDidMount() {
    if (this.props.forceValidation === true) {
      this.runValidators(this.props.value, true);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.forceValidation === true) {
      this.runValidators(nextProps.value, true);
    }
  }

  runValidators = (value, forceValidation) => {
    // run validators if provided and field is dirty (has been blurred)
    if (
      (this.props.validators && this.props.validators.length > 0 && this.state.isDirty) ||
      forceValidation
    ) {
      const errors = this.props.validators
        .map(validator => {
          return validator(value, this.props.label);
        })
        .filter(err => {
          return err !== false;
        });

      if (errors && errors.length > 0) {
        if (this.props.onValidationChange) this.props.onValidationChange(errors[0]);
        this.setState({
          isValid: false,
          helperText: errors[0],
        });
      } else {
        if (this.props.onValidationChange && this.state.isValid !== true)
          this.props.onValidationChange(null);
        this.setState({
          isValid: true,
          helperText: '',
        });
      }
    }
  };

  // set date value in state
  // accept format as valid if date is accepted
  handleDateAccept = date => {
    this.setState(
      {
        isDirty: true,
        partialDate: '',
      },
      () => {
        const formattedDate = date && date.format(this.props.format);
        // change date, then run validators on new date
        const changePromise = new Promise((resolve, reject) => {
          resolve(this.props.handleDateChange(formattedDate));
        });

        changePromise.then(() => this.runValidators(formattedDate || ''));
      }
    );
  };

  // do validation on each character entry
  handleDateInputChange = event => {
    const updateVal = event.target.value;
    const updateMoment = moment(updateVal);
    const isValid = updateMoment.isValid();
    let helperText = '';

    // valid date
    if (isValid) {
      if (this.props.onValidationChange) this.props.onValidationChange(null);
      this.handleDateAccept(updateMoment);
    }

    // // invalid date and have update value
    if (!isValid && updateVal !== '') {
      helperText = 'Invalid date';
      if (this.props.onValidationChange) this.props.onValidationChange('Invalid Date');
    } else if (updateVal === '') {
      this.runValidators(updateVal, true);
    }

    this.setState({ isDirty: true, partialDate: updateVal, isValid, helperText });
  };

  render() {
    const { ...rest } = this.props;
    const { isValid, helperText, isDirty, partialDate } = this.state;

    // wipe out unneeded props
    delete rest.onInputChange;
    delete rest.onChange;
    delete rest.handleDateChange;
    delete rest.onValidationChange;
    delete rest.forceValidation;

    // send partial date text via InputProps, unless empty
    if (!isEmpty(partialDate)) {
      rest.InputProps = { value: partialDate };
    }

    return (
      <InlineDatePicker
        variant="outlined"
        onChange={this.handleDateAccept}
        onInputChange={this.handleDateInputChange}
        error={!isValid && isDirty}
        helperText={helperText}
        {...rest}
      />
    );
  }
}

DateFieldValidator.propTypes = {
  format: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  forceValidation: PropTypes.bool,
  validators: PropTypes.array,
  value: PropTypes.string,

  handleDateChange: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func,
};

DateFieldValidator.defaultProps = {
  forceValidation: false,
  validators: [],
  value: '',

  onValidationChange: () => {},
};

export default withTheme()(DateFieldValidator);
