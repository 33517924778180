import { combineReducers } from 'redux';

import dependent from './modules/dependents/dependent.reducer';
import entrance from './modules/entrance/entrance.reducer';
import language from './modules/language/language.reducer';
import layout from './modules/layout/layout.reducer';
import legal from './modules/legal/legal.reducer';
import location from './modules/location/location.reducer';
import login from './modules/login/login.reducer';
import priorVisits from './modules/priorVisits/priorVisits.reducer';
import profile from './modules/profile/profile.reducer';
import survey from './modules/survey/survey.reducer';
import video from './modules/videoCall/videoCall.reducer';
import visit from './modules/visit/visit.reducer';
import facility from './modules/facility/facility.reducer';

const appReducer = combineReducers({
  dependent,
  layout,
  legal,
  location,
  login,
  priorVisits,
  profile,
  video,
  visit,
  survey,
  entrance,
  language,
  facility
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
