import React from 'react';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { MenuItem } from '@material-ui/core';
import { isEmpty } from 'lodash';
import TextFieldValidator from './textFieldValidator.component';
import { parseAddressComponents } from '../../utilities/locationUtils';

class GooglePlacesTextValidator extends React.Component {
  handleSelection = (selection, placeId) => {
    geocodeByPlaceId(placeId)
      .then(places => {
        if (!isEmpty(places) && this.props.type === 'full-place') {
          this.props.onSelect(places[0]);
        } else {
          this.props.onSelect(parseAddressComponents(places[0].address_components));
        }
      })
      .catch(e => console.log('geocoding error: ', e));
  };

  render() {
    const {
      label,
      inputClassName,
      validators,
      value,
      onChange,
      forceValidation,
      onValidationChange,
      required
    } = this.props;

    return (
      <PlacesAutocomplete
        value={value}
        onSelect={this.handleSelection}
        onChange={onChange}
        debounce={250}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div>
              <TextFieldValidator
                {...getInputProps()}
                className={inputClassName}
                label={label}
                margin="normal"
                variant="outlined"
                validators={validators}
                value={value}
                forceValidation={forceValidation}
                onValidationChange={onValidationChange}
                required={required}
              />
              <div>
                {loading && <MenuItem>Loading...</MenuItem>}
                {suggestions.map((suggestion, i) => {
                  return (
                    <MenuItem {...getSuggestionItemProps(suggestion)} key={i}>
                      {suggestion.description}
                    </MenuItem>
                  );
                })}
              </div>
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

export default GooglePlacesTextValidator;
