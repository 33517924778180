import { connect } from 'react-redux';
import { isEmpty, isNil, has, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import {
  CREATE_FACILITY_PATIENT_SUCCESS,
  UPDATE_FACILITY_PATIENT_SUCCESS,
  createFacilityPatient,
  updateFacilityPatient,
  facilityPatientSearch,
  setSelectedFacility,
} from './facility.actions';
import { getProfile, GET_PROFILE_SUCCESS } from '../profile/profile.actions';
import { DATE_INPUT_DATE_FORMAT, lessThan12YearsAgo } from '../../utilities/fieldValidation';
import { showToast } from '../layout/layout.actions';
import PatientProfileForm from '../patient/forms/patientProfileForm.component';
import Languages from '../language/languages';
import accountTypes from '../../types/accountTypes';
import CustomDialog from '../../common/customDialog/customDialog.component';
import TOAST_TYPES from '../../types/toastTypes';

class FacilityPatient extends Component {
  constructor(props) {
    super(props);

    const profile =
      !isNil(props.location.state) && has(props, 'location.state.profile')
        ? props.location.state.profile
        : null;

    this.state = {
      isSaving: false,
      formIsDirty: false,
      isShowCancelDialog: false,
      patient: profile || {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressZip: '',
        weight: '',
      },
      formErrors: {},
    };
  }

  async componentDidMount() {
    // ensure facility settings are updated in case they changed between page loads
    const result = await this.props.getProfile();
    if (result.type === GET_PROFILE_SUCCESS) {
      if (has(result.response, 'facilities') && !isEmpty(result.response.facilities)) {
        // update selectedFacility from response data
        const foundFacility = result.response.facilities.find(
          f => f.id === this.props.selectedFacility.id
        );
        if (!isNil(foundFacility) && !isEqual(foundFacility, this.props.selectedFacility)) {
          this.props.setSelectedFacility(foundFacility);
        }
      }
    }
  }

  formHasErrors = () => {
    let requiredFields = ['firstName', 'lastName', 'dob', 'gender'];
    const addressFields = ['addressLine1', 'addressCity', 'addressState', 'addressZip'];

    // don't validate address fields if not required by facility
    if (this.props.selectedFacility.skipAddress === false) {
      requiredFields = [...requiredFields, ...addressFields];
    }

    // require weight when dob is completed, and less than 12 yr old
    const hasDob = !isEmpty(this.state.patient.dob);
    const lessThan12YearsOld =
      lessThan12YearsAgo(DATE_INPUT_DATE_FORMAT)(
        moment(this.state.patient.dob).format(DATE_INPUT_DATE_FORMAT)
      ) === true;
    if (hasDob && lessThan12YearsOld) requiredFields.push('weight');

    // check required fields
    if (requiredFields.some(k => isEmpty(this.state.patient[k]))) {
      return true;
    }

    return Object.keys(this.state.formErrors).some(k => !isNil(this.state.formErrors[k]));
  };

  handleValidationChange = field => error => {
    const updateErrors = { ...this.state.formErrors };
    updateErrors[field] = error;
    this.setState({
      formErrors: updateErrors,
    });
  };

  handleFieldChange = name => update => {
    const updatePatient = { ...this.state.patient };
    const updateErrors = { ...this.state.formErrors };

    // see if update is an event, if not it is a value already
    const val = !isNil(update) && has(update.target, 'value') ? update.target.value : update;
    updatePatient[name] = val;

    if (name === 'dob') {
      // special case for when dob changes to an earlier date beyond weight requirement:
      const weightIsRequired = !lessThan12YearsAgo(DATE_INPUT_DATE_FORMAT)(val) === false;

      if (!weightIsRequired) {
        // clear validation error if weight not required
        delete updateErrors.weight;
      }
    }

    this.setState({
      patient: {
        ...updatePatient,
      },
      formErrors: { ...updateErrors },
      formIsDirty: true,
    });
  };

  handleCreateFacilityPatient = async () => {
    this.setState({
      isSaving: true,
    });

    const modifiedPatient = { ...this.state.patient };
    const { selectedFacility } = this.props;

    if (modifiedPatient.addressLine2 === '') delete modifiedPatient.addressLine2;

    if (!lessThan12YearsAgo(DATE_INPUT_DATE_FORMAT)(modifiedPatient.dob))
      delete modifiedPatient.weight;

    const result = await this.props.createFacilityPatient(selectedFacility.id, modifiedPatient);

    if (result.type === CREATE_FACILITY_PATIENT_SUCCESS) {
      this.setState(
        {
          patient: {
            ...result.response,
            accountType: accountTypes.FACILITY_PATIENT,
          },
          isSaving: false,
        },
        () => {
          this.props.history.push('/');
        }
      );
    } else {
      console.log('CREATE PATIENT ERROR');
      console.log(result);

      this.setState({
        isSaving: false,
      });

      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.createPatientFailure,
        TOAST_TYPES.ERROR,
        true,
        null,
        result.messages[0]
      );
    }
  };

  handleShowCancelDialog = () => {
    this.setState({
      isShowCancelDialog: true,
    });
  };

  handleCloseCancelDialog = () => {
    this.setState({
      isShowCancelDialog: false,
    });
  };

  handleUpdateFacilityPatient = async (/* isStartVisit */) => {
    this.setState({
      isSaving: true,
    });

    const modifiedPatient = { ...this.state.patient };
    const savePatientId = this.state.patient.id;

    if (modifiedPatient.addressLine2 === '' || modifiedPatient.addressLine2 === null)
      delete modifiedPatient.addressLine2;

    if (
      !lessThan12YearsAgo(DATE_INPUT_DATE_FORMAT)(modifiedPatient.dob) &&
      !lessThan12YearsAgo('YYYY-MM-DD')(modifiedPatient.dob)
    )
      delete modifiedPatient.weight;

    delete modifiedPatient.id;
    delete modifiedPatient.accountType;
    delete modifiedPatient.pharmacyPlaceId;
    delete modifiedPatient.populationId;
    delete modifiedPatient.medications;
    delete modifiedPatient.allergies;
    delete modifiedPatient.surgicalHistory;
    delete modifiedPatient.pharmacyDoseSpotId;
    delete modifiedPatient.created;
    delete modifiedPatient.updated;
    delete modifiedPatient.medicalHistory;
    delete modifiedPatient.doseSpotPatientId;

    const result = await this.props.updateFacilityPatient(savePatientId, modifiedPatient);

    if (result.type === UPDATE_FACILITY_PATIENT_SUCCESS) {
      const { returnTo } = this.props.location.state;

      if (!isNil(returnTo)) {
        this.props.history.replace({ pathname: returnTo, state: { profile: this.state.patient } });
      } else {
        this.props.history.goBack();
      }
    } else {
      console.log('UPDATE PATIENT ERROR');
      console.log(result);

      this.setState({
        isSaving: false,
      });

      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.updatePatientFailure,
        TOAST_TYPES.ERROR,
        true,
        null,
        result.messages[0]
      );
    }
  };

  getActionLabel() {
    const language = Languages[this.props.selectedLanguageKey].strings;
    if (this.props.isLoading || this.state.isSaving) return language.saving;
    const isEdit = has(this.props.location, 'state.isEdit')
      ? this.props.location.state.isEdit
      : false;
    return isEdit ? language.buttonText.savePatient : language.buttonText.addPatient;
  }

  render() {
    const { patient, formIsDirty, isShowCancelDialog } = this.state;
    const { classes, selectedLanguageKey, location, isLoading } = this.props;

    const isEdit = has(location, 'state.isEdit') ? location.state.isEdit : false;
    const language = Languages[selectedLanguageKey].strings;

    return (
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Typography variant="h5" className={classes.title}>
              {isEdit ? language.editPatientTitle : language.createPatientTitle}
            </Typography>

            <PatientProfileForm
              handleChange={this.handleFieldChange}
              handleValidationChange={this.handleValidationChange}
              profile={{ ...patient, accountType: accountTypes.FACILITY_PATIENT }}
              selectedLanguageKey={selectedLanguageKey}
            />
          </div>
        </div>
        <div className={classes.formActions}>
          <Button
            variant="text"
            className={classes.actionButton}
            onClick={formIsDirty ? this.handleShowCancelDialog : () => this.props.history.goBack()}
          >
            {language.buttonText.cancel}
          </Button>
          <Button
            variant="text"
            className={classes.actionButton}
            classes={{ root: this.formHasErrors() ? classes.buttonDisabled : classes.button }}
            onClick={
              isEdit
                ? () => this.handleUpdateFacilityPatient(false)
                : () => this.handleCreateFacilityPatient(false)
            }
            disabled={this.formHasErrors() | isLoading}
          >
            {this.getActionLabel()}
          </Button>
          {/* TODO: if creating new, also show start visit? */}
          {/* {!isEdit &&
            <Button
              variant="text"
              style={{
                margin: '1rem',
                paddingLeft: '1rem',
                paddingRight: '1rem'
              }}
              classes={{ root: this.formHasErrors() ? classes.buttonDisabled : classes.button }}
              onClick={() => this.handleCreateFacilityPatient(true)}
              disabled={this.formHasErrors()}
            >
              {'Start Visit'}
            </Button>
          } */}
        </div>
        <CustomDialog
          open={isShowCancelDialog}
          handleClose={this.handleCloseCancelDialog}
          content={
            <Typography variant="subtitle1" id="modal-title">
              {`${
                isEdit
                  ? language.alertMessages.confirmExitPatientEdit
                  : language.alertMessages.confirmExitPatientCreate
              }`}
            </Typography>
          }
          title={`${
            isEdit
              ? language.alertMessages.confirmExitPatientEditTitle
              : language.alertMessages.confirmExitPatientCreateTitle
          }`}
          handleAction={() => this.props.history.goBack()}
        />
      </div>
    );
  }
}

const styles = theme => ({
  contentContainer: {
    flex: 1,
    overflow: 'auto',
    width: '100%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 88px)',
    margin: '2rem auto 0',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formActions: {
    background: theme.palette.primary.background,
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightGray,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  title: {
    color: theme.palette.primary.darkGray,
    marginBottom: '1rem',
  },
  actionButton: {
    margin: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
});

FacilityPatient.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  isLoading: PropTypes.bool.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,
  updateFacilityPatient: PropTypes.func.isRequired,
  selectedFacility: PropTypes.object.isRequired,

  createFacilityPatient: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  setSelectedFacility: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoading: state.facility.isLoading,
    selectedLanguageKey: state.language.selectedLanguageKey,
    selectedFacility: state.facility.selectedFacility,
    patientSearchText: state.facility.patientSearchText,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    createFacilityPatient,
    updateFacilityPatient,
    facilityPatientSearch,
    showToast,
    getProfile,
    setSelectedFacility,
  })
)(FacilityPatient);
