import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, isNil } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { hasWebcam } from '../../utilities/hardwareUtils';
import { setPageTitle, showToast } from '../layout/layout.actions';
import DependentCards from './dependentCards.container';
import ImageCarousel from '../../common/imageCarousel/imageCarousel.component';
import Languages from '../language/languages';
import { getProfile } from '../profile/profile.actions';
import ToastTypes from '../../types/toastTypes';
import UIVars from '../../styles/ui-vars';
import { getMarketingContent } from '../../utilities/entranceUtils';
import { MARKETING_CONTENT_TYPES } from '../../types/marketingContentTypes';

class Home extends Component {
  componentWillMount() {
    this.props.setPageTitle(Languages[this.props.selectedLanguageKey].strings.pageTitles.home);

    // reload profile
    // useful after a visit to update profile information
    if (
      !isNil(this.props.history.location.state) &&
      this.props.history.location.state.isReloadProfile
    ) {
      this.props.getProfile();
    }
  }

  componentDidMount() {
    if (!hasWebcam) {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.missingHardware,
        ToastTypes.WARNING,
        false
      );
    }
  }

  handleItemClick = item => {
    if (!isEmpty(item.url)) {
      window.open(item.url, '_blank');
    }
  };

  render() {
    const { classes, marketingHomeContent } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.carouselContainer}>
          {!isNil(marketingHomeContent) && !isEmpty(marketingHomeContent) && (
            <ImageCarousel
              maxHeight={UIVars.headerGraphic.height}
              items={marketingHomeContent}
              handleItemClick={this.handleItemClick}
            />
          )}
        </div>
        <div className={classes.dependentsContainer}>
          <DependentCards />
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  content: {
    backgroundColor: theme.palette.primary.background,
    flex: 1,
  },
  carouselContainer: {
    width: '100%',
  },
  dependentsContainer: {
    padding: '2rem',
  },
});

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  marketingHomeContent: PropTypes.array,
  selectedLanguageKey: PropTypes.string.isRequired,

  getProfile: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
};

Home.defaultProps = {
  marketingHomeContent: [],
};

const mapStateToProps = state => {
  const {
    entrance: { entrance },
    language,
  } = state;

  return {
    marketingHomeContent: getMarketingContent(entrance, MARKETING_CONTENT_TYPES.DESKTOP_HOME),
    selectedLanguageKey: language.selectedLanguageKey,
  };
};

const withRouterHome = withRouter(
  connect(mapStateToProps, {
    getProfile,
    setPageTitle,
    showToast,
  })(Home)
);

export default withStyles(styles, { withTheme: true })(withRouterHome);
