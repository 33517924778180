export const browserPermissionTypes = {
  MICROPHONE: 'microphone',
  CAMERA: 'camera'
};

export const browserPermissionStatus = {
  GRANTED: 'granted',
  PROMPT: 'prompt',
  DENIED: 'denied'
};

export const getFriendlyPermission = type => {
  switch (type) {
    case browserPermissionTypes.MICROPHONE:
      return 'Microphone';

    case browserPermissionTypes.CAMERA:
      return 'Camera';

    default:
      return '';
  }
};

export default { browserPermissionTypes, browserPermissionStatus };
