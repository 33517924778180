import { BASE_URL } from '../middleware/api';
import auth from '../auth/auth';
import request from 'superagent';

export const uploadFiles = async (files, onComplete, onError, onProgress, onCancel) => {
  const jwt = auth.getAuthToken();
  const req = request
    .post(`${BASE_URL}v1/uploads`)
    .set('Authorization', `Bearer ${jwt}`)
    .set('Accept', 'application/json');

  files.forEach(file => {
    req.attach(file.name, file);
  });

  req
    .then(response => {
      if (response && response.ok === true) {
        onComplete(response.body);
      } else {
        onError({ statusCode: response.statusCode, statusText: response.statusText });
      }
    })
    .catch(err => onError(err));
};

export default { uploadFiles };
