const ALLERGIES_LABEL = 'Name';
const ALLERGIES_VALUE = 'Code';

// get display label and value either from server or from page update
// data: array of json objects formatted with key names (i.e. not NIH response)
export const mapAllergyJsonToSelectOptions = data => {
  return data.map(item => {
    const mapObj = { ...item };
    if (mapObj.isManualEntry === true) {
      // manually entered value
      // could come from server or from page edit
      if (mapObj.text && mapObj.id) {
        mapObj.label = mapObj.text;
        mapObj.value = mapObj.id;
      }
    } else {
      // provide label and value for select fields
      mapObj.label = item[ALLERGIES_LABEL];
      mapObj.value = item[ALLERGIES_VALUE];
    }
    return mapObj;
  });
};

// convert allergy data to select options
export const mapAllergyDataToSelectOptions = allergies => {
  allergies.forEach(allergy => {
    allergy.label = allergy.Name;
    allergy.value = allergy.Code;
  });
  return allergies;
};

// map json objects to list of labels
// input: data array of objects
// output: string
export const mapAllergyJsonToLabelList = data => {
  if (!data || !data.length > 0) return [];

  return data
    .map(item => {
      let label = item[ALLERGIES_LABEL];
      return label ? label : item.label ? item.label : item.text ? item.text : '';
    })
    .join(', ');
};
