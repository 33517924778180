import { CALL_API } from '../../middleware/api';
import { config as brandConfig } from '@brand';

export const GET_ENTRANCE_REQUEST = 'GET_ENTRANCE_REQUEST';
export const GET_ENTRANCE_SUCCESS = 'GET_ENTRANCE_SUCCESS';
export const GET_ENTRANCE_FAILURE = 'GET_ENTRANCE_FAILURE';

export const getEntrance = () => {
  return {
    [CALL_API]: {
      types: [GET_ENTRANCE_REQUEST, GET_ENTRANCE_SUCCESS, GET_ENTRANCE_FAILURE],
      endpoint: `v1/patient/entrances/${brandConfig.entranceId}`,
      method: 'GET',
    },
  };
};
