import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper, Grid, Typography } from '@material-ui/core';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import classNames from 'classnames';

import { nihApiTypes } from '../../types/nihApiTypes';
import { getNihObjectLabel } from '../../utilities/nihApiUtils';
import { mapAllergyJsonToLabelList } from '../../utilities/medicalAllergyUtils';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Languages from '../language/languages';
import ResponsiveBreakpoints from '../layout/responsiveBreakpoints';

// const SLIDE_HEIGHT = 365;
const SCREEN_BREAK_POINTS = {
  [ResponsiveBreakpoints.LARGE.name]: {
    height: 550,
    width: 980,
    visibleSlides: 2,
  },
  [ResponsiveBreakpoints.MEDIUM.name]: {
    height: 550,
    width: 980,
    visibleSlides: 1,
  },
  [ResponsiveBreakpoints.SMALL.name]: {
    height: 660,
    width: 980,
    visibleSlides: 1,
  },
  [ResponsiveBreakpoints.XSMALL.name]: {
    height: 400,
    width: 300,
    visibleSlides: 1,
  },
};

class WaitingRoomCarousel extends Component {
  getCarouselItems() {
    const {
      classes,
      currentVisit,
      marketingContent,
      responsiveBreakpoint,
      selectedLanguageKey,
    } = this.props;
    const language = Languages[selectedLanguageKey].strings;
    const carouselItems = [];

    if (responsiveBreakpoint.maxWidth <= ResponsiveBreakpoints.SMALL.maxWidth) {
      carouselItems.push(
        <Slide index={0} key="slide-0">
          <Paper classes={{ root: classes.paper }} elevation={3}>
            {responsiveBreakpoint.name === ResponsiveBreakpoints.SMALL.name ? (
              <Grid container>
                <Grid item sm={6} className={classes.leftVisitCard}>
                  <div className={classes.infoTitle}>
                    <Typography variant="h6" className={classes.darkGray}>
                      {language.waitingRoomMessages.whatsNext}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.paragraph}>
                      {language.waitingRoomMessages.nextStepsText}
                    </Typography>
                  </div>
                  <div className={classes.infoDetails}>
                    <Typography variant="h6" className={classes.darkGray}>
                      {language.waitingRoomMessages.reasonForVisit}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.paragraph}>
                      {currentVisit.chiefComplaint}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6}>
                  {this.renderMedicalHistory()}
                </Grid>
              </Grid>
            ) : (
              <>
                <div className={classNames(classes.reason, classes.reasonSmallScreen)}>
                  <Typography variant="h6" className={classes.darkGray}>
                    {language.waitingRoomMessages.reasonForVisit}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.paragraph}>
                    {currentVisit.chiefComplaint}
                  </Typography>
                </div>
                <div className={classes.medicalHistoryContainer}>{this.renderMedicalHistory()}</div>
                <div className={classes.nextSteps}>
                  <Typography variant="h6" className={classes.darkGray}>
                    {language.waitingRoomMessages.whatsNext}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.paragraph}>
                    {language.waitingRoomMessages.nextStepsText}
                  </Typography>
                </div>
              </>
            )}
          </Paper>
        </Slide>,
      );
    }

    // PUSH MARKETING CONTENT SLIDES
    // index offset by 1, since there may already be an index 0 (see above)
    marketingContent.forEach((item, i) => {
      carouselItems.push(
        <Slide
          index={i + 1}
          key={`slide-${i + 1}`}
          onMouseUp={item.url ? () => window.open(item.url, '_blank') : null}
        >
          <Paper
            classes={{ root: classes.slidePaper }}
            style={{ cursor: item.url ? 'pointer' : 'default' }}
            elevation={3}
          >
            <img style={{ display: 'block' }} src={item.imageUrl} alt={item.altText} />
          </Paper>
        </Slide>,
      );
    });

    return carouselItems;
  }

  renderMedicalHistory() {
    const {
      medicalHistory,
      theme,
      classes,
      selectedLanguageKey,
      responsiveBreakpoint,
      phiVisible,
    } = this.props;
    const language = Languages[selectedLanguageKey].strings;
    const medicalContainerStyle = { display: 'flex', flexDirection: 'column' };

    // if phi is not visible, do not render med history
    if (!phiVisible) return null;

    if (responsiveBreakpoint.maxWidth > ResponsiveBreakpoints.SMALL.maxWidth) {
      medicalContainerStyle.margin = theme.spacing.unit * 2;
    }

    const medications = medicalHistory.medications || [];
    const allergies = medicalHistory.allergies || [];
    const surgicalHistory = medicalHistory.surgicalHistory || [];

    return (
      <div style={medicalContainerStyle}>
        <Typography variant="h6" className={classes.darkGray}>
          {language.waitingRoomMessages.medicalInfoTitle}
        </Typography>
        <ul className={classes.unorderedList}>
          {this.renderMedicalHistoryItem(language.waitingRoomMessages.reviewedMedical)}
          {this.renderMedicalHistoryItem(
            medications.length === 0
              ? language.waitingRoomMessages.noMedications
              : `${language.waitingRoomMessages.reviewedMedications} ${medications.length} ${language.waitingRoomMessages.medsAtHome}`,
          )}
          {this.renderMedicalHistoryItem(
            allergies.length === 0
              ? language.waitingRoomMessages.noAllergies
              : `${language.waitingRoomMessages.haveAllergies} ${mapAllergyJsonToLabelList(
                  allergies,
                )}`,
          )}
          {this.renderMedicalHistoryItem(
            surgicalHistory.length === 0
              ? language.waitingRoomMessages.noSurgeries
              : `${language.waitingRoomMessages.haveSurgeries} ${this.getMedicalHistoryList(
                  nihApiTypes.NIH_PROCEDURES,
                  surgicalHistory,
                )}`,
          )}
        </ul>
      </div>
    );
  }

  renderMedicalHistoryItem(text) {
    const { classes } = this.props;

    return (
      <li className={classes.listItem}>
        <Typography className={classes.darkGray} variant="subtitle1">
          {text}
        </Typography>
      </li>
    );
  }

  getMedicalHistoryList = (type, list) => {
    let toReturn = '';
    list &&
      list.forEach((item, i) => {
        if (item.isManualEntry === true) {
          toReturn += `${item.text}`;
        } else {
          toReturn += `${getNihObjectLabel(type, item)}`;
        }
        if (i !== list.length - 1) {
          toReturn += ', ';
        }
      });
    return toReturn;
  };

  render() {
    const { classes, selectedLanguageKey, responsiveBreakpoint } = this.props;
    const language = Languages[selectedLanguageKey].strings;
    const slides = this.getCarouselItems();
    const size = SCREEN_BREAK_POINTS[responsiveBreakpoint.name];

    if (slides.length === 1) {
      size.visibleSlides = 1;
    }

    return (
      <>
        {responsiveBreakpoint.maxWidth > ResponsiveBreakpoints.SMALL.maxWidth && (
          <div className={classes.detailsContainer}>
            <Grid container className={classes.detailsGrid}>
              <Grid item sm={6}>
                <div className={classes.nextStepsTitle}>
                  <Typography variant="h6" className={classes.darkGray}>
                    {language.waitingRoomMessages.whatsNext}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.paragraph}>
                    {language.waitingRoomMessages.nextStepsText}
                  </Typography>
                </div>
                <div className={classes.reason}>
                  <Typography variant="h6" className={classes.darkGray}>
                    {language.waitingRoomMessages.reasonForVisit}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.paragraph}>
                    {this.props.currentVisit.chiefComplaint}
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={6}>
                {this.renderMedicalHistory()}
              </Grid>
            </Grid>
          </div>
        )}
        <CarouselProvider
          className={this.props.classes.carouselContainer}
          totalSlides={slides.length}
          naturalSlideHeight={size.height}
          naturalSlideWidth={size.width}
          visibleSlides={size.visibleSlides}
          isPlaying
        >
          <Slider className={this.props.classes.sliderContainer}>{slides}</Slider>
          <DotGroup className={this.props.classes.dot} />
        </CarouselProvider>
      </>
    );
  }
}

const styles = (theme) => ({
  paper: {
    borderRadius: 0,
    margin: 8,
    // minHeight: SLIDE_HEIGHT,
    maxWidth: '768px',
    padding: '1rem',
  },
  slidePaper: {
    borderRadius: 0,
    margin: 8,
    // minHeight: SLIDE_HEIGHT,
    maxWidth: '768px',
    display: 'flex',
    alignItems: 'center',
  },
  dotStyle: {
    fontSize: '2rem',
    margin: theme.spacing.unit,
    color: theme.palette.primary.background,
  },
  unorderedList: {
    color: theme.palette.primary.darkGray,
    listStyleType: 'disc',
    margin: '1rem 0 0 1rem;',
    paddingInlineStart: '1rem',
  },
  listItem: {
    fontSize: '1.5rem',
  },
  sliderContainer: {
    margin: '0 auto',
    '@media (min-width: 1600px)': {
      width: 1600,
    },
    '@media (min-width: 600px) and (max-width: 1599.99px)': {
      width: 800,
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: 600,
    },
    [theme.breakpoints.down('xs')]: {
      width: 475,
    },
  },
  dot: {
    display: 'flex',
    justifyContent: 'center',
    '& > .carousel__dot': {
      backgroundColor: theme.palette.primary.background,
      height: '1rem',
      borderRadius: '1rem',
      margin: '.5rem',
    },
    '& > .carousel__dot--selected': {
      backgroundColor: theme.palette.primary.darkGray,
    },
  },
  detailsContainer: {
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  detailsGrid: {
    maxWidth: 1200,
    padding: '1rem',
  },
  reason: {
    marginLeft: theme.spacing.unit * 2,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  reasonSmallScreen: {
    borderBottom: `solid 2px ${theme.palette.primary.darkGray}`,
  },
  paragraph: {
    color: theme.palette.primary.darkGray,
    padding: theme.spacing.unit * 2,
  },
  carouselContainer: {
    marginTop: '1rem',
    marginBottom: '5rem',
  },
  leftVisitCard: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing.unit,
  },
  infoTitle: {
    borderBottom: `solid 2px ${theme.palette.primary.darkGray}`,
  },
  darkGray: {
    color: theme.palette.primary.darkGray,
  },
  infoDetails: {
    marginTop: theme.spacing.unit * 2,
  },
  medicalHistoryContainer: {
    borderBottom: `solid 2px ${theme.palette.primary.darkGray}`,
    padding: `${theme.spacing.unit * 2}px 0`,
  },
  nextSteps: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
  nextStepsTitle: {
    margin: theme.spacing.unit * 2,
  },
});

WaitingRoomCarousel.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  currentVisit: PropTypes.object.isRequired,
  marketingContent: PropTypes.array,
  phiVisible: PropTypes.bool.isRequired,
  responsiveBreakpoint: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,
};

WaitingRoomCarousel.defaultProps = {
  marketingContent: [],
};

export default withStyles(styles, { withTheme: true })(WaitingRoomCarousel);
