import React from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const LoadingOverlay = ({ classes, initialColor }) => (
  <div className={classes.screenOverlay}>
    <div className={classes.progressIndicator}>
      <CircularProgress size={100} style={initialColor ? { color: initialColor } : {}} />
    </div>
  </div>
);

const styles = theme => ({
  screenOverlay: {
    backgroundColor: 'rgba(255,255,255,0.75)',
    color: theme.palette.primary.main,
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 100,
  },
  progressIndicator: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
});

LoadingOverlay.propTypes = {
  classes: PropTypes.object.isRequired,

  initialColor: PropTypes.string,
};

LoadingOverlay.defaultProps = {
  initialColor: null,
};

export default withStyles(styles)(LoadingOverlay);
