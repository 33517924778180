import React from 'react';
import { Card, CardActions, CardContent, Button, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import Languages from '../../language/languages';

const InfoReviewCard = ({ classes, handleEdit, children, heading, selectedLanguageKey }) => (
  <Card className={classes.card}>
    <CardContent classes={{ root: classes.contentRoot }}>
      <Typography className={classes.heading} variant="h6">
        {heading}
      </Typography>
      <div className={classes.childrenContainer}>{children}</div>
    </CardContent>
    <CardActions style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
      <Button
        variant="text"
        style={{
          fontSize: 14,
          paddingLeft: 18,
          paddingRight: 18,
          marginLeft: 'auto',
        }}
        onClick={handleEdit}
      >
        {Languages[selectedLanguageKey].strings.buttonText.edit}
      </Button>
    </CardActions>
  </Card>
);

const styles = theme => ({
  card: {
    // width: 350,
    margin: '2rem auto',
    display: 'flex',
    flexDirection: 'column',
  },
  contentRoot: {
    flex: 1,
    padding: '1rem 1rem 0px',
  },
  title: {
    fontSize: 23,
    marginLeft: '1rem',
  },
  childrenContainer: {
    marginLeft: '1rem',
  },
  heading: {
    color: theme.palette.primary.darkGray,
    marginBottom: '0.5rem',
  },
});

InfoReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,

  children: PropTypes.any,
  heading: PropTypes.string.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  handleEdit: PropTypes.func.isRequired,
};

InfoReviewCard.defaultProps = {
  children: null,
};

export default withStyles(styles)(InfoReviewCard);
