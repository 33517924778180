// @ts-check
// / <reference path="../../../typings/brand.d.ts" />

import { config as defaultConfig } from './default';

/** @type BrandConfig */
export const config = {
  ...defaultConfig,
  entranceId: 'ent_wmed',
  auth0: {
    ...defaultConfig.auth0,
    domain: 'wakemed-patient.auth0.com',
    clientId: 'U83BwMj6RnwPsJ7XNOLTIkvFVNVNZwyV',
    logoutRedirectUrl: 'https://virtualurgentcare.wakemed.org/login',
  },
  serverUrl: 'https://api.relymd.app/',
  stripeKey: 'pk_live_fxrWJE5Myy7ZtMT8XhmyXxR700y1pT3Cen',
  serverOutageUrl: 'https://static.relymd.app/status.json',
};
