import { CALL_API } from '../../middleware/api';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const SET_PROFILE = 'SET_PROFILE';

export const GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE';

export const GET_MEDICAL_HISTORY_REQUEST = 'GET_MEDICAL_HISTORY_REQUEST';
export const GET_MEDICAL_HISTORY_SUCCESS = 'GET_MEDICAL_HISTORY_SUCCESS';
export const GET_MEDICAL_HISTORY_FAILURE = 'GET_MEDICAL_HISTORY_FAILURE';

export const UPDATE_MEDICAL_HISTORY_REQUEST = 'UPDATE_MEDICAL_HISTORY_REQUEST';
export const UPDATE_MEDICAL_HISTORY_SUCCESS = 'UPDATE_MEDICAL_HISTORY_SUCCESS';
export const UPDATE_MEDICAL_HISTORY_FAILURE = 'UPDATE_MEDICAL_HISTORY_FAILURE';

export const SAVE_PAYMENT_METHOD_REQUEST = 'SAVE_PAYMENT_METHOD_REQUEST';
export const SAVE_PAYMENT_METHOD_SUCCESS = 'SAVE_PAYMENT_METHOD_SUCCESS';
export const SAVE_PAYMENT_METHOD_FAILURE = 'SAVE_PAYMENT_METHOD_FAILURE';

export const UPDATE_DEPENDENT_MEDICAL_HISTORY_REQUEST = 'UPDATE_DEPENDENT_MEDICAL_HISTORY_REQUEST';
export const UPDATE_DEPENDENT_MEDICAL_HISTORY_SUCCESS = 'UPDATE_DEPENDENT_MEDICAL_HISTORY_SUCCESS';
export const UPDATE_DEPENDENT_MEDICAL_HISTORY_FAILURE = 'UPDATE_DEPENDENT_MEDICAL_HISTORY_FAILURE';

export const GET_DEPENDENT_MEDICAL_HISTORY_REQUEST = 'GET_DEPENDENT_MEDICAL_HISTORY_REQUEST';
export const GET_DEPENDENT_MEDICAL_HISTORY_SUCCESS = 'GET_DEPENDENT_MEDICAL_HISTORY_SUCCESS';
export const GET_DEPENDENT_MEDICAL_HISTORY_FAILURE = 'GET_DEPENDENT_MEDICAL_HISTORY_FAILURE';

export const GET_INSURANCE_INFO_REQUEST = 'GET_INSURANCE_INFO_REQUEST';
export const GET_INSURANCE_INFO_SUCCESS = 'GET_INSURANCE_INFO_SUCCESS';
export const GET_INSURANCE_INFO_FAILURE = 'GET_INSURANCE_INFO_FAILURE';

export const SAVE_INSURANCE_INFO_REQUEST = 'SAVE_INSURANCE_INFO_REQUEST';
export const SAVE_INSURANCE_INFO_SUCCESS = 'SAVE_INSURANCE_INFO_SUCCESS';
export const SAVE_INSURANCE_INFO_FAILURE = 'SAVE_INSURANCE_INFO_FAILURE';

export const getProfile = () => {
  return {
    [CALL_API]: {
      types: [GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE],
      authenticated: true,
      endpoint: 'v1/patient/profile',
      method: 'GET',
    },
  };
};

export const updateProfile = payload => {
  return {
    [CALL_API]: {
      types: [UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE],
      authenticated: true,
      endpoint: 'v1/patient/profile',
      method: 'PUT',
      payload,
    },
  };
};

export const setProfile = profile => {
  return {
    type: SET_PROFILE,
    response: profile,
  };
};

export const getPaymentMethods = () => {
  return {
    [CALL_API]: {
      types: [
        GET_PAYMENT_METHODS_REQUEST,
        GET_PAYMENT_METHODS_SUCCESS,
        GET_PAYMENT_METHODS_FAILURE,
      ],
      authenticated: true,
      endpoint: 'v1/patient/profile/payment',
      method: 'GET',
    },
  };
};

export const savePaymentMethod = payload => {
  return {
    [CALL_API]: {
      types: [
        SAVE_PAYMENT_METHOD_REQUEST,
        SAVE_PAYMENT_METHOD_SUCCESS,
        SAVE_PAYMENT_METHOD_FAILURE,
      ],
      authenticated: true,
      endpoint: 'v1/patient/profile/payment',
      method: 'PUT',
      payload,
    },
  };
};

export const getInsuranceInfo = patientId => {
  return {
    [CALL_API]: {
      types: [GET_INSURANCE_INFO_REQUEST, GET_INSURANCE_INFO_SUCCESS, GET_INSURANCE_INFO_FAILURE],
      authenticated: true,
      endpoint: `v1/patient/patients/${patientId}/insurance`,
      method: 'GET',
    },
  };
};

export const saveInsuranceInfo = (patientId, payload) => {
  return {
    [CALL_API]: {
      types: [
        SAVE_INSURANCE_INFO_REQUEST,
        SAVE_INSURANCE_INFO_SUCCESS,
        SAVE_INSURANCE_INFO_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/patient/patients/${patientId}/insurance`,
      method: 'PUT',
      payload,
    },
  };
};

export const getMedicalHistory = () => {
  return {
    [CALL_API]: {
      types: [
        GET_MEDICAL_HISTORY_REQUEST,
        GET_MEDICAL_HISTORY_SUCCESS,
        GET_MEDICAL_HISTORY_FAILURE,
      ],
      authenticated: true,
      endpoint: 'v1/patient/profile/medicalHistory',
      method: 'GET',
    },
  };
};

export const getDependentMedicalHistory = patientId => {
  return {
    [CALL_API]: {
      types: [
        GET_DEPENDENT_MEDICAL_HISTORY_REQUEST,
        GET_DEPENDENT_MEDICAL_HISTORY_SUCCESS,
        GET_DEPENDENT_MEDICAL_HISTORY_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/patient/profile/dependents/${patientId}/medicalHistory`,
      method: 'GET',
    },
  };
};

export const updateMedicalHistory = payload => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_MEDICAL_HISTORY_REQUEST,
        UPDATE_MEDICAL_HISTORY_SUCCESS,
        UPDATE_MEDICAL_HISTORY_FAILURE,
      ],
      authenticated: true,
      endpoint: 'v1/patient/profile/medicalHistory',
      method: 'PUT',
      payload,
    },
  };
};

export const updateDependentMedicalHistory = (patientId, payload) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_DEPENDENT_MEDICAL_HISTORY_REQUEST,
        UPDATE_DEPENDENT_MEDICAL_HISTORY_SUCCESS,
        UPDATE_DEPENDENT_MEDICAL_HISTORY_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/patient/profile/dependents/${patientId}/medicalHistory`,
      method: 'PUT',
      payload,
    },
  };
};
