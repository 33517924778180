import { CALL_API } from '../../middleware/api';

export const GET_PRICING_REQUEST = 'GET_PRICING_REQUEST';
export const GET_PRICING_SUCCESS = 'GET_PRICING_SUCCESS';
export const GET_PRICING_FAILURE = 'GET_PRICING_FAILURE';

export const SET_PRICING_CODES = 'SET_PRICING_CODES';

export const CLEAR_PRICING_CODES = 'CLEAR_PRICING_CODES';

export const START_VISIT_REQUEST = 'START_VISIT_REQUEST';
export const START_VISIT_SUCCESS = 'START_VISIT_SUCCESS';
export const START_VISIT_FAILURE = 'START_VISIT_FAILURE';

export const UPLOAD_ATTACHMENT_REQUEST = 'UPLOAD_ATTACHMENT_REQUEST';
export const UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS';
export const UPLOAD_ATTACHMENT_FAILURE = 'UPLOAD_ATTACHMENT_FAILURE';

export const GET_CURRENT_VISIT_REQUEST = 'GET_CURRENT_VISIT_REQUEST';
export const GET_CURRENT_VISIT_SUCCESS = 'GET_CURRENT_VISIT_SUCCESS';
export const GET_CURRENT_VISIT_FAILURE = 'GET_CURRENT_VISIT_FAILURE';

export const SET_CURRENT_VISIT = 'SET_CURRENT_VISIT';

export const GET_CURRENT_VISIT_PROVIDER_REQUEST = 'GET_CURRENT_VISIT_PROVIDER_REQUEST';
export const GET_CURRENT_VISIT_PROVIDER_SUCCESS = 'GET_CURRENT_VISIT_PROVIDER_SUCCESS';
export const GET_CURRENT_VISIT_PROVIDER_FAILURE = 'GET_CURRENT_VISIT_PROVIDER_FAILURE';

// used to request NIH proxy data
export const GET_API_DATA_REQUEST = 'GET_API_DATA_REQUEST';
export const GET_API_DATA_SUCCESS = 'GET_API_DATA_SUCCESS';
export const GET_API_DATA_FAILURE = 'GET_API_DATA_FAILURE';

export const SEARCH_ALLERGIES_REQUEST = 'SEARCH_ALLERGIES_REQUEST';
export const SEARCH_ALLERGIES_SUCCESS = 'SEARCH_ALLERGIES_SUCCESS';
export const SEARCH_ALLERGIES_FAILURE = 'SEARCH_ALLERGIES_FAILURE';

export const RESET_VISIT_STATE = 'RESET_VISIT_STATE';
export const SET_VISIT_STATUS = 'SET_VISIT_STATUS';

export const MARK_CURRENT_VISIT_READY_REQUEST = 'MARK_CURRENT_VISIT_READY_REQUEST';
export const MARK_CURRENT_VISIT_READY_SUCCESS = 'MARK_CURRENT_VISIT_READY_SUCCESS';
export const MARK_CURRENT_VISIT_READY_FAILURE = 'MARK_CURRENT_VISIT_READY_FAILURE';

export const CANCEL_CURRENT_VISIT_REQUEST = 'CANCEL_CURRENT_VISIT_REQUEST';
export const CANCEL_CURRENT_VISIT_SUCCESS = 'CANCEL_CURRENT_VISIT_SUCCESS';
export const CANCEL_CURRENT_VISIT_FAILURE = 'CANCEL_CURRENT_VISIT_FAILURE';

export const GET_PREVIOUS_VISITS_REQUEST = 'GET_PREVIOUS_VISITS_REQUEST';
export const GET_PREVIOUS_VISITS_SUCCESS = 'GET_PREVIOUS_VISITS_SUCCESS';
export const GET_PREVIOUS_VISITS_FAILURE = 'GET_PREVIOUS_VISITS_FAILURE';

export const GET_PHARMACIES_REQUEST = 'GET_PHARMACIES_REQUEST';
export const GET_PHARMACIES_SUCCESS = 'GET_PHARMACIES_SUCCESS';
export const GET_PHARMACIES_FAILURE = 'GET_PHARMACIES_FAILURE';

export const RESET_PHARMACY_STATE = 'RESET_PHARMACY_STATE';

export const SAVE_PHARMACY_REQUEST = 'SAVE_PHARMACY_REQUEST';
export const SAVE_PHARMACY_SUCCESS = 'SAVE_PHARMACY_SUCCESS';
export const SAVE_PHARMACY_FAILURE = 'SAVE_PHARMACY_FAILURE';

export const GET_PHARMACY_REQUEST = 'GET_PHARMACY_REQUEST';
export const GET_PHARMACY_SUCCESS = 'GET_PHARMACY_SUCCESS';
export const GET_PHARMACY_FAILURE = 'GET_PHARMACY_FAILURE';

export const CLEAR_PHARMACY = 'CLEAR_PHARMACY';

export const getPricing = (coupon, group, identifier) => {
  let url = 'v1/patient/pricing?';
  if (coupon) {
    url += `coupon=${coupon}&`;
  }
  if (group) {
    url += `group=${group}&`;
  }
  if (identifier) {
    url += `identifier=${identifier}`;
  }

  return {
    [CALL_API]: {
      types: [GET_PRICING_REQUEST, GET_PRICING_SUCCESS, GET_PRICING_FAILURE],
      authenticated: true,
      endpoint: url,
      method: 'GET',
    },
  };
};

export const setPricingCodes = (coupon, group, identifier) => {
  return dispatch =>
    dispatch({
      type: SET_PRICING_CODES,
      response: { coupon, group, identifier },
    });
};

export const startVisit = payload => {
  return {
    [CALL_API]: {
      types: [START_VISIT_REQUEST, START_VISIT_SUCCESS, START_VISIT_FAILURE],
      authenticated: true,
      endpoint: 'v1/patient/visit',
      method: 'POST',
      payload,
    },
  };
};

export const uploadAttachment = payload => {
  return {
    [CALL_API]: {
      types: [UPLOAD_ATTACHMENT_REQUEST, UPLOAD_ATTACHMENT_SUCCESS, UPLOAD_ATTACHMENT_FAILURE],
      authenticated: true,
      endpoint: 'v1/uploads',
      method: 'POST',
      payload,
    },
  };
};

export const getCurrentVisit = () => {
  return {
    [CALL_API]: {
      types: [GET_CURRENT_VISIT_REQUEST, GET_CURRENT_VISIT_SUCCESS, GET_CURRENT_VISIT_FAILURE],
      authenticated: true,
      endpoint: 'v1/patient/currentVisit',
      method: 'GET',
    },
  };
};

export const setCurrentVisit = currentVisit => {
  return {
    type: SET_CURRENT_VISIT,
    payload: currentVisit,
  };
};

export const getPreviousVisits = patientId => {
  return {
    [CALL_API]: {
      types: [
        GET_PREVIOUS_VISITS_REQUEST,
        GET_PREVIOUS_VISITS_SUCCESS,
        GET_PREVIOUS_VISITS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/patient/patients/${patientId}/visits`,
      method: 'GET',
    },
  };
};

export const markCurrentVisitReady = () => {
  return {
    [CALL_API]: {
      types: [
        MARK_CURRENT_VISIT_READY_REQUEST,
        MARK_CURRENT_VISIT_READY_SUCCESS,
        MARK_CURRENT_VISIT_READY_FAILURE,
      ],
      authenticated: true,
      endpoint: 'v1/patient/currentVisit/ready',
      method: 'POST',
    },
  };
};

export const fetchData = endpoint => {
  return {
    [CALL_API]: {
      types: [GET_API_DATA_REQUEST, GET_API_DATA_SUCCESS, GET_API_DATA_FAILURE],
      authenticated: true,
      endpoint,
      method: 'GET',
    },
  };
};

export const searchAllergies = searchTerm => {
  return {
    [CALL_API]: {
      types: [SEARCH_ALLERGIES_REQUEST, SEARCH_ALLERGIES_SUCCESS, SEARCH_ALLERGIES_FAILURE],
      authenticated: true,
      endpoint: `v1/allergies/search?q=${searchTerm}`,
      method: 'GET',
    },
  };
};

export const cancelVisit = () => {
  return {
    [CALL_API]: {
      types: [
        CANCEL_CURRENT_VISIT_REQUEST,
        CANCEL_CURRENT_VISIT_SUCCESS,
        CANCEL_CURRENT_VISIT_FAILURE,
      ],
      authenticated: true,
      endpoint: 'v1/patient/currentVisit/cancel',
      method: 'POST',
    },
  };
};

export const getCurrentVisitProvider = providerId => {
  return {
    [CALL_API]: {
      types: [
        GET_CURRENT_VISIT_PROVIDER_REQUEST,
        GET_CURRENT_VISIT_PROVIDER_SUCCESS,
        GET_CURRENT_VISIT_PROVIDER_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/patient/provider/${providerId}`,
      method: 'GET',
    },
  };
};

export const resetCurrentVisit = () => {
  return {
    type: RESET_VISIT_STATE,
    payload: {},
  };
};

export const getPharmacies = (lat, lng) => {
  return {
    [CALL_API]: {
      types: [GET_PHARMACIES_REQUEST, GET_PHARMACIES_SUCCESS, GET_PHARMACIES_FAILURE],
      authenticated: true,
      endpoint: `v1/patient/pharmacies?lat=${lat}&lng=${lng}`,
      method: 'GET',
    },
  };
};

export const resetPharmacies = () => {
  return {
    type: RESET_PHARMACY_STATE,
    payload: [],
  };
};

export const clearPricingCodes = () => {
  return {
    type: CLEAR_PRICING_CODES,
  };
};

export const savePharmacy = (patientId, doseSpotId) => {
  return {
    [CALL_API]: {
      types: [SAVE_PHARMACY_REQUEST, SAVE_PHARMACY_SUCCESS, SAVE_PHARMACY_FAILURE],
      authenticated: true,
      endpoint: `v1/patient/patients/${patientId}/pharmacy`,
      method: 'POST',
      payload: {
        doseSpotId,
      },
    },
  };
};

export const getPharmacyDetails = patientId => {
  return {
    [CALL_API]: {
      types: [GET_PHARMACY_REQUEST, GET_PHARMACY_SUCCESS, GET_PHARMACY_FAILURE],
      authenticated: true,
      endpoint: `v2/patient/patients/${patientId}/pharmacy`,
      method: 'GET',
    },
  };
};

export const clearPharmacy = () => {
  return {
    type: CLEAR_PHARMACY,
    payload: {},
  };
};
