import React from 'react';
import { withStyles } from '@material-ui/core';
import PaymentMethodForm from '../../patient/forms/patientPaymentMethodForm.component';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';

const styles = theme => ({
  container: {
    margin: '1rem',
    paddingBottom: '6rem'
  }
});

const VisitPaymentInfoForm = ({
  cardBrand,
  cardNumber,
  classes,
  couponCode,
  groupCode,
  groupIdentifier,
  handleChange,
  handleSetToken,
  pricing,
  selectedPaymentMethod
}) => (
  <div className={classes.container}>
    <Elements>
      <PaymentMethodForm
        cardBrand={cardBrand}
        cardNumber={cardNumber}
        couponCode={couponCode}
        groupCode={groupCode}
        groupIdentifier={groupIdentifier}
        handleSetToken={handleSetToken}
        onFieldChange={handleChange}
        pricing={pricing}
        selectedPaymentMethod={selectedPaymentMethod}
      />
    </Elements>
  </div>
);

VisitPaymentInfoForm.propTypes = {
  cardBrand: PropTypes.string,
  cardNumber: PropTypes.string,
  classes: PropTypes.object.isRequired,
  couponCode: PropTypes.string,
  groupCode: PropTypes.string,
  groupIdentifier: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSetToken: PropTypes.func.isRequired,
  pricing: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(VisitPaymentInfoForm);
