import React, { Component } from 'react';
import { withTheme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

class MaskedTextValidator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      isValid: true,
      helperText: ''
    };
  }

  componentDidMount() {
    if (this.props.forceValidation === true) {
      this.runValidators(this.props.value, true);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.runValidators(nextProps.value, true);
    }
  }

  handleChange = evt => {
    let updateValue = evt.target.value;

    // wipe postfix from updateValue
    let index = updateValue.indexOf(this.props.maskPostfix);
    if (index > -1) {
      updateValue = updateValue.substring(0, index).trim();
    }

    if (this.props.value !== '' && updateValue !== '') {
      this.setState(
        {
          isDirty: true
        },
        () => this.runValidators(updateValue)
      );
    }

    this.props.onChange(updateValue);
  };

  runValidators = (value, forceValidation) => {
    // run validators if provided and field is dirty (has been blurred)
    if ((this.props.validators && this.props.validators.length > 0 && this.state.isDirty) || forceValidation) {
      const errors = this.props.validators
        .map(validator => {
          return validator(value, this.props.label);
        })
        .filter(err => {
          return err !== false;
        });

      if (errors && errors.length > 0) {
        if (this.props.onValidationChange) this.props.onValidationChange(errors[0]);
        this.setState({
          isValid: false,
          isDirty: true,
          helperText: errors[0]
        });
      } else {
        if (this.props.onValidationChange && this.state.isValid !== true) this.props.onValidationChange(null);
        this.setState({
          isValid: true,
          helperText: ''
        });
      }
    }
  };

  render() {
    let { label, value, onChange, mask, maskPostfix, ...rest } = this.props;
    const { isValid, helperText, isDirty } = this.state;

    // use local onchange
    delete rest.onChange;
    delete rest.onValidationChange;
    delete rest.forceValidation;

    mask = mask + (maskPostfix ? maskPostfix : '');

    return (
      <InputMask mask={mask} maskChar={' '} value={value} onChange={this.handleChange}>
        {inputProps => <TextField label={label} error={!isValid && isDirty} helperText={helperText} id={label} {...rest} />}
      </InputMask>
    );
  }
}

MaskedTextValidator.propTypes = {
  label: PropTypes.string.isRequired,
  validators: PropTypes.array,
  forceValidation: PropTypes.bool,
  onValidationChange: PropTypes.func
};

export default withTheme()(MaskedTextValidator);
