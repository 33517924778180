import 'core-js/stable';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';

import './index.css';
import { Router } from 'react-router-dom';
import { render } from 'react-dom';
import React from 'react';
import Root from './root.container';
import getStore from './store/store';
import * as serviceWorker from './serviceWorker';
import { initializeFirebase } from './fcm';
import history from './history';
import { initializeGA } from './utilities/googleAnalytics';
import { config as brandConfig } from '@brand';

const store = getStore();

const basename = process.env.PUBLIC_URL || '/';

function startApp() {
  document.title = brandConfig.appName;
  initializeGA(process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID);
  initializeFirebase(store);
  render(
    <Router basename={basename} history={history}>
      <Root store={store} />
    </Router>,
    document.getElementById('root')
  );
}

startApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
