import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouteOnEnter } from '../../../utilities/withRouteOnEnter.component';

import HomePage from '../../home/home.container';
import FacilityHomePage from '../../home/facility/facilityHome.container';
import PatientProfilePage from '../../patient/patientProfile.container';
import VisitPage from '../../visit/visit.container';
import MedicalHistoryPage from '../../patient/patientMedicalHistory.container';
import DependentCreatePage from '../../dependents/dependentCreate.container';
import PaymentMethodPage from '../../patient/paymentMethod.container';
import WaitingRoomPage from '../../waitingRoom/waitingRoom.container';
import VideoCallPage from '../../videoCall/videoCall.container';
import PriorVisitsPage from '../../priorVisits/priorVisits.container';
import PriorVisitPage from '../../priorVisits/priorVisit.container';
import PharmacyPage from '../../patient/pharmacySelection.container';
import SurveyPage from '../../survey/survey.container';
import FacilityPatientPage from '../../facility/facilityPatient.container';
import FacilityDocumentSearchPage from '../../facility/facilityDocumentSearch.container';
import InsurancePage from '../../patient/insuranceInfo.container';

import { LOGIN_STATE } from '../../login/login.actions';

class Routes extends Component {
  checkAuth = () => {
    if (this.props.loginState === LOGIN_STATE.LOGGED_IN) {
      // NOTE: Right here you can read from the user's profile to determine if they have what they need to proceed
      if (this.props.location.pathname === '/login') {
        this.props.history.replace('/');
      }
    } else {
      console.log('NOT LOGGED IN!');
      if (this.props.location.pathname !== '/login') {
        this.props.history.replace('/login');
      }
    }
  };

  render() {
    const { selectedFacility } = this.props;

    return (
      <Switch>
        {/* USE FACILITATED HOME COMPONENT IF FACILITY SELECTED */}
        {isNil(selectedFacility) ? (
          <Route exact path="/" component={withRouteOnEnter(this.checkAuth)(HomePage)} />
        ) : (
          <Route exact path="/" component={withRouteOnEnter(this.checkAuth)(FacilityHomePage)} />
        )}

        <Route
          exact
          path="/medicalhistory"
          component={withRouteOnEnter(this.checkAuth)(MedicalHistoryPage)}
        />
        <Route
          exact
          path="/medicalHistory/:patientId"
          component={withRouteOnEnter(this.checkAuth)(MedicalHistoryPage)}
        />
        <Route
          exact
          path="/payment"
          component={withRouteOnEnter(this.checkAuth)(PaymentMethodPage)}
        />
        <Route
          exact
          path="/patient/create"
          component={withRouteOnEnter(this.checkAuth)(DependentCreatePage)}
        />
        <Route
          exact
          path="/facilitypatient/create"
          component={withRouteOnEnter(this.checkAuth)(FacilityPatientPage)}
        />
        <Route
          exact
          path="/patient/:patientId"
          component={withRouteOnEnter(this.checkAuth)(PatientProfilePage)}
        />
        <Route
          exact
          path="/facilitypatient/:patientId"
          component={withRouteOnEnter(this.checkAuth)(FacilityPatientPage)}
        />
        <Route
          exact
          path="/patient/:patientId/visit"
          component={withRouteOnEnter(this.checkAuth)(VisitPage)}
        />
        <Route
          exact
          path="/visit/:visitId"
          component={withRouteOnEnter(this.checkAuth)(VideoCallPage)}
        />
        <Route
          exact
          path="/waitingRoom"
          component={withRouteOnEnter(this.checkAuth)(WaitingRoomPage)}
        />
        <Route
          exact
          path="/insurance/:patientId"
          component={withRouteOnEnter(this.checkAuth)(InsurancePage)}
        />
        <Route
          exact
          path="/patient/:patientId/priorVisits"
          component={withRouteOnEnter(this.checkAuth)(PriorVisitsPage)}
        />
        <Route
          exact
          path="/patient/:patientId/priorVisits/:visitId"
          component={withRouteOnEnter(this.checkAuth)(PriorVisitPage)}
        />
        <Route exact path="/pharmacy" component={withRouteOnEnter(this.checkAuth)(PharmacyPage)} />
        <Route
          exact
          path="/visit/:visitId/survey"
          component={withRouteOnEnter(this.checkAuth)(SurveyPage)}
        />
        <Route
          exact
          path="/documents"
          render={props => <FacilityDocumentSearchPage {...props} />}
        />
        <Redirect from="*" to="/" />
      </Switch>
    );
  }
}

Routes.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  loginState: PropTypes.number.isRequired,
  selectedFacility: PropTypes.object,
};

Routes.defaultProps = {
  selectedFacility: null,
};

const mapStateToProps = state => {
  return {
    selectedFacility: state.facility.selectedFacility,
  };
};

export default withRouter(connect(mapStateToProps, {})(Routes));
