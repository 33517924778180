import produce from 'immer';
import {
  HIDE_TOAST_MESSAGE,
  SET_DIRTY_FORM,
  SET_PAGE_TITLE,
  SET_RESPONSIVE_BREAKPOINT,
  SET_TOAST_POSITION,
  SHOW_TOAST_MESSAGE,
  TOGGLE_DRAWER_MENU,
  SET_LAYOUT_INITALIZING,
  SET_LAYOUT_BEFORE_UNLOAD,
  CLEAR_LAYOUT_BEFORE_UNLOAD,
  GET_MESSAGE_BANNER_SUCCESS,
} from './layout.actions';
import responsiveBreakpoints from './responsiveBreakpoints';
import toastTypes from '../../types/toastTypes';

const initialState = {
  pageTitle: 'Home',
  responsiveBreakpoint: responsiveBreakpoints.LARGE,
  isShowToast: false,
  toastMessage: '',
  variant: toastTypes.INFO,
  toastAction: null,
  detailMessage: '',
  toastPosition: '0',
  isDrawerMenuOpen: false,
  hasDirtyForm: false,
  isInitializing: true, // default to loading until loaded
  handleUnload: null,
  messageBanner: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_TITLE:
      draft.pageTitle = action.payload;
      // draft.breadcrumb = action.breadcrumb;
      // draft.breadcrumbTitle = action.breadcrumbTitle;
      break;

    case SET_RESPONSIVE_BREAKPOINT:
      draft.responsiveBreakpoint = action.payload;
      break;

    case SHOW_TOAST_MESSAGE:
      draft.isShowToast = true;
      draft.toastMessage = action.message;
      draft.variant = action.variant;
      draft.toastAction = action.toastAction;
      draft.detailMessage = action.detailMessage;
      break;

    case HIDE_TOAST_MESSAGE:
      draft.isShowToast = false;
      break;

    case SET_TOAST_POSITION:
      draft.toastPosition = action.position;
      break;

    case TOGGLE_DRAWER_MENU:
      draft.isDrawerMenuOpen = !draft.isDrawerMenuOpen;
      break;

    case SET_DIRTY_FORM:
      draft.hasDirtyForm = action.payload.flag;
      break;

    case SET_LAYOUT_INITALIZING:
      draft.isInitializing = action.payload;
      break;

    case SET_LAYOUT_BEFORE_UNLOAD:
      window.addEventListener('beforeunload', action.payload);
      draft.handleUnload = action.payload;
      break;

    case CLEAR_LAYOUT_BEFORE_UNLOAD:
      window.removeEventListener('beforeunload', draft.handleUnload);
      draft.handleUnload = null;
      break;

    case GET_MESSAGE_BANNER_SUCCESS:
      draft.messageBanner = action.payload;
      break;

    default:
      break;
  }
}, initialState);
