import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { has, isEmpty, isNil } from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import InfoReviewCard from './infoReviewCard.component';
import { mapJsonToLabelList } from '../../../utilities/nihApiUtils';
import { nihApiTypes } from '../../../types/nihApiTypes';
import { mapAllergyJsonToLabelList } from '../../../utilities/medicalAllergyUtils';
import Languages from '../../language/languages';
import { getFormattedPrice } from '../../../utilities/dataUtils';

class VisitInfoReviewForm extends Component {
  handleEditProfile = () => {
    const isFacilitatedVisit = !isNil(this.props.selectedFacility);
    if (isFacilitatedVisit) {
      this.props.history.push({
        pathname: `/facilitypatient/${this.props.profile.id}`,
        state: {
          profile: this.props.profile,
          isEdit: true,
          returnTo: `/patient/${this.props.profile.id}/visit`,
        },
      });
    } else {
      this.props.history.push({
        pathname: `/patient/${this.props.profile.id}`,
      });
    }
  };

  handleEditMedicalHistory = (accountType) => () => {
    const { profile } = this.props;
    let pathname = '/medicalhistory';

    // medical history will treat as personal account without an id passed
    if (has(profile, 'id')) {
      pathname += `/${profile.id}`;
    }

    this.props.history.push({
      pathname,
      state: {
        accountType,
      },
    });
  };

  handleEditPharmacy = () => {
    this.props.history.push({
      pathname: '/pharmacy',
      state: {
        profile: this.props.profile,
      },
    });
  };

  handleEditPayment = () => {
    this.props.history.push({
      pathname: '/payment',
      state: {
        patientId: this.props.profile.id,
      },
    });
  };

  handleEditInsurance = () => {
    const { profile } = this.props;
    const pathname = `/insurance/${profile.id}`;

    this.props.history.push(pathname);
  };

  getMedicalHistoryList = (array) => {
    if (array && array.length > 0) {
      return array.map((item) => item.label).join(', ');
    }

    return Languages[this.props.selectedLanguageKey].strings.none;
  };

  render() {
    const {
      profile,
      medicalHistory,
      classes,
      paymentMethods,
      selectedPharmacy,
      selectedLanguageKey,
      pricing,
      selectedFacility,
      insuranceInfo,
      isLoadingInsuranceInfo,
    } = this.props;

    const medicationsList = mapJsonToLabelList(nihApiTypes.NIH_RXTERMS, medicalHistory.medications);
    const conditionsList = mapJsonToLabelList(
      nihApiTypes.NIH_CONDITIONS,
      medicalHistory.medicalHistory,
    );
    const surgeryList = mapJsonToLabelList(
      nihApiTypes.NIH_PROCEDURES,
      medicalHistory.surgicalHistory,
    );
    const allergyList = mapAllergyJsonToLabelList(medicalHistory.allergies);

    const language = Languages[selectedLanguageKey].strings;
    const isZeroPriceVisit = has(pricing, 'price.price') && pricing.price.price === 0;
    const isFacilitatedVisit = !isNil(selectedFacility);

    // show or hide insurance info based on entrance config
    const isShowInsurance =
      has(this.props.entrance, 'clientConfig.collectInsurance') &&
      this.props.entrance.clientConfig.collectInsurance === true;

    const hasInsuranceInfo =
      !isEmpty(insuranceInfo) &&
      (!isEmpty(insuranceInfo.carrierName) ||
        !isEmpty(insuranceInfo.policyNumber) ||
        !isEmpty(insuranceInfo.cardImageUploadId) ||
        !isEmpty(insuranceInfo.relationshipToInsured));

    // can be configured per facility
    let isShowAddress = true;
    if (
      isFacilitatedVisit &&
      has(selectedFacility, 'skipAddress') &&
      selectedFacility.skipAddress === true
    ) {
      isShowAddress = false;
    }

    // can be configured per facility
    let isShowMedicalHistory = true;
    if (
      isFacilitatedVisit &&
      has(selectedFacility, 'phiVisible') &&
      selectedFacility.phiVisible !== true
    ) {
      isShowMedicalHistory = false;
    }

    let isShowPharmacyInfo = true;
    if (
      isFacilitatedVisit &&
      has(selectedFacility, 'skipPharmacy') &&
      selectedFacility.skipPharmacy === true
    ) {
      isShowPharmacyInfo = false;
    }

    return (
      <div className={classes.formContainer}>
        {/* PROFILE */}
        <InfoReviewCard
          selectedLanguageKey={selectedLanguageKey}
          handleEdit={this.handleEditProfile}
          heading={language.sumCardDemographics}
        >
          <Typography className={classes.text} style={{ fontSize: 16 }}>
            {`${profile.firstName} ${profile.lastName}`}
          </Typography>
          <Typography className={classes.text}>{moment(profile.dob).format('M/D/YYYY')}</Typography>
          {isShowAddress && (
            <>
              <Typography className={classes.text}>{profile.addressLine1}</Typography>
              {profile.addressLine2 && (
                <Typography className={classes.text}>{profile.addressLine2}</Typography>
              )}
              <Typography className={classes.text}>
                {`${profile.addressCity}, ${profile.addressState}  ${profile.addressZip}`}
              </Typography>
            </>
          )}
        </InfoReviewCard>

        {/* MEDICAL HISTORY */}
        {isShowMedicalHistory && (
          <InfoReviewCard
            handleEdit={this.handleEditMedicalHistory(profile.accountType)}
            heading={language.medicalHistoryTitle}
            selectedLanguageKey={selectedLanguageKey}
          >
            <div className={classes.medicalHistoryItem}>
              <Typography className={classes.text}>
                {language.sumCardMedical.medications}
              </Typography>
              <Typography className={classes.text}>
                {isEmpty(medicationsList) ? language.none : medicationsList}
              </Typography>
            </div>

            <div className={classes.medicalHistoryItem}>
              <Typography className={classes.text}>{language.sumCardMedical.conditions}</Typography>
              <Typography className={classes.text}>
                {isEmpty(conditionsList) ? language.none : conditionsList}
              </Typography>
            </div>

            <div className={classes.medicalHistoryItem}>
              <Typography className={classes.text}>{language.sumCardMedical.allergies}</Typography>
              <Typography className={classes.text}>
                {isEmpty(allergyList) ? language.none : allergyList}
              </Typography>
            </div>

            <div className={classes.medicalHistoryItem}>
              <Typography className={classes.text}>{language.sumCardMedical.surgeries}</Typography>
              <Typography className={classes.text}>
                {isEmpty(surgeryList) ? language.none : surgeryList}
              </Typography>
            </div>
          </InfoReviewCard>
        )}

        {/* PHARMACY */}
        {isShowPharmacyInfo && (
          <InfoReviewCard
            selectedLanguageKey={selectedLanguageKey}
            handleEdit={this.handleEditPharmacy}
            heading={language.sumCardPharmacy}
          >
            {isEmpty(selectedPharmacy) ? (
              <div>
                <Typography className={classes.errorText}>{language.noPharmacySelected}</Typography>
              </div>
            ) : (
              <div>
                <Typography className={classes.text}>{selectedPharmacy.StoreName}</Typography>
                <Typography className={classes.text}>
                  {`${selectedPharmacy.City}, ${selectedPharmacy.State} ${selectedPharmacy.ZipCode}`}
                </Typography>
              </div>
            )}
          </InfoReviewCard>
        )}

        {/* PAYMENT */}
        {!isFacilitatedVisit && (
          <InfoReviewCard
            selectedLanguageKey={selectedLanguageKey}
            handleEdit={this.handleEditPayment}
            heading={language.sumCardPayment}
          >
            <div>
              {has(pricing, 'price.price') && (
                <div className={classes.price}>{`$${getFormattedPrice(pricing.price.price)}`}</div>
              )}

              {paymentMethods && paymentMethods.length > 0 && !isZeroPriceVisit && (
                <Typography className={classes.text}>
                  {`${paymentMethods[0].brand} ****${paymentMethods[0].last4}`}
                </Typography>
              )}

              {!paymentMethods ||
                (paymentMethods.length <= 0 && !isZeroPriceVisit && (
                  <Typography className={classes.errorText}>{language.noPaymentMethods}</Typography>
                ))}

              {isZeroPriceVisit && (
                <Typography className={classes.text}>{language.noPaymentMethodRequired}</Typography>
              )}
            </div>
          </InfoReviewCard>
        )}

        {/* INSURANCE INFO */}
        {!isFacilitatedVisit && isShowInsurance && (
          <InfoReviewCard
            selectedLanguageKey={selectedLanguageKey}
            heading={language.sumCardInsurance}
            handleEdit={this.handleEditInsurance}
          >
            {isLoadingInsuranceInfo ? (
              <div style={styles.textBlockWrapper}>
                <Typography className={classes.text}>{language.loading}</Typography>
              </div>
            ) : (
              <div>
                {!isEmpty(insuranceInfo.carrierName) && (
                  <Typography className={classes.text}>
                    {`${language.carrier}: ${insuranceInfo.carrierName}`}
                  </Typography>
                )}
                {!isEmpty(insuranceInfo.subscriberId) && (
                  <Typography className={classes.text}>
                    {`${language.subscriberId}: ${insuranceInfo.subscriberId}`}
                  </Typography>
                )}
                {!isEmpty(insuranceInfo.policyNumber) && (
                  <Typography className={classes.text}>
                    {`${language.policyNumber}: ${insuranceInfo.policyNumber}`}
                  </Typography>
                )}
                {!isEmpty(insuranceInfo.cardImageUploadId) && (
                  <Typography className={classes.text}>
                    {`${language.insuranceCardImageProvided}`}
                  </Typography>
                )}
                {!hasInsuranceInfo && (
                  <Typography className={classes.text}>{`${language.noInsurance}`}</Typography>
                )}
              </div>
            )}
          </InfoReviewCard>
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  text: {
    fontSize: 14,
    color: theme.palette.primary.darkGray,
  },
  errorText: {
    fontSize: 14,
    color: theme.palette.error.main,
  },
  formContainer: {
    marginLeft: '1rem',
    marginRight: '1rem',
    paddingBottom: '6rem',
  },
  medicalHistoryItem: {
    marginBottom: '0.5rem',
  },
  price: {
    color: theme.palette.primary.darkGray,
    fontSize: '1.3rem',
    marginBottom: '1rem',
  },
});

VisitInfoReviewForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  entrance: PropTypes.object.isRequired,
  insuranceInfo: PropTypes.object,
  isLoadingInsuranceInfo: PropTypes.bool.isRequired,
  medicalHistory: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  pricing: PropTypes.object,
  profile: PropTypes.object.isRequired,
  selectedFacility: PropTypes.object,
  selectedLanguageKey: PropTypes.string.isRequired,
  selectedPharmacy: PropTypes.object,
};

VisitInfoReviewForm.defaultProps = {
  insuranceInfo: {},
  pricing: {},
  selectedFacility: {},
  selectedPharmacy: {},
};

export default compose(withRouter, withStyles(styles))(VisitInfoReviewForm);
