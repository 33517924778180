import produce from 'immer';
import {
  CLEAR_PATIENTS,
  CLEAR_SELECTED_FACILITY,
  FACILITY_PATIENTS_SEARCH_FAILURE,
  FACILITY_PATIENTS_SEARCH_REQUEST,
  FACILITY_PATIENTS_SEARCH_SUCCESS,
  SET_SELECTED_FACILITY,
  SET_FACILITY_PATIENT_SEARCH_TEXT,
  SET_SHOW_FACILITY_SELECTION,
  FACILITY_PATIENT_DOC_REQUEST,
  FACILITY_PATIENT_DOC_SUCCESS,
  FACILITY_PATIENT_DOC_FAILURE,
  CREATE_FACILITY_PATIENT_REQUEST,
  CREATE_FACILITY_PATIENT_SUCCESS,
  CREATE_FACILITY_PATIENT_FAILURE,
  UPDATE_FACILITY_PATIENT_REQUEST,
  UPDATE_FACILITY_PATIENT_SUCCESS,
  UPDATE_FACILITY_PATIENT_FAILURE
} from './facility.actions';

const initialState = {
  patientSearchText: '',
  patientSearchResults: [],
  isLoading: false,
  selectedFacility: null,
  isShowSelectFacility: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_FACILITY_PATIENT_SEARCH_TEXT: {
      draft.patientSearchText = action.payload;
      draft.isLoading = true; // mark as isLoading until search completes
      break;
    }

    case FACILITY_PATIENTS_SEARCH_SUCCESS: {
      draft.patientSearchResults = action.response.data;
      draft.isLoading = false;
      break;
    }

    case FACILITY_PATIENTS_SEARCH_FAILURE:
      draft.isLoading = false;
      break;

    case FACILITY_PATIENTS_SEARCH_REQUEST:
      draft.isLoading = true;
      break;

    case SET_SHOW_FACILITY_SELECTION: {
      draft.isShowSelectFacility = action.payload;
      break;
    }

    case SET_SELECTED_FACILITY:
      draft.selectedFacility = action.payload;
      draft.patientSearchResults = [];
      draft.patientSearchText = '';
      break;

    case CLEAR_SELECTED_FACILITY:
      draft.selectedFacility = null;
      draft.patientSearchText = '';
      draft.patientSearchResults = [];
      break;

    case CLEAR_PATIENTS:
      draft.patientSearchResults = [];
      break;

    case FACILITY_PATIENT_DOC_REQUEST:
      draft.isLoading = true;
      break;

    case FACILITY_PATIENT_DOC_SUCCESS:
      draft.isLoading = false;
      break;

    case FACILITY_PATIENT_DOC_FAILURE:
      draft.isLoading = false;
      break;

    case CREATE_FACILITY_PATIENT_REQUEST:
      draft.isLoading = true;
      break;

    case CREATE_FACILITY_PATIENT_SUCCESS:
      draft.isLoading = false;
      break;

    case CREATE_FACILITY_PATIENT_FAILURE:
      draft.isLoading = false;
      break;

    case UPDATE_FACILITY_PATIENT_REQUEST:
      draft.isLoading = true;
      break;

    case UPDATE_FACILITY_PATIENT_SUCCESS:
      draft.isLoading = false;
      break;

    case UPDATE_FACILITY_PATIENT_FAILURE:
      draft.isLoading = false;
      break;

    default:
      break;
  }
}, initialState);
