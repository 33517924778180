import { isEmpty, isNil } from 'lodash';
import moment from 'moment';

// convert weight string i.e. "123.00" to "123"
export const getFormattedWeight = weightString => {
  if (!isEmpty(weightString)) {
    const index = weightString.indexOf('.');
    if (index > -1) {
      return weightString.substring(0, index);
    }
  }

  return weightString === 0 ? '' : weightString;
};

// return formatted price
// optionally provide numDecimals
export const getFormattedPrice = (price, numDecimals) => {
  if (!isNil(numDecimals)) {
    return `${(price / 100).toFixed(numDecimals)}`;
  }
  const remainder = price % 100;
  if (remainder > 0) {
    return `${(price / 100).toFixed(2)}`;
  }
  return `${(price / 100).toFixed(0)}`;
};

// input: start ms, end ms
// returns whether now is in that timerange
// if an end time is not provided, only check start time
export const isInTimeRange = (start, end = null) => {
  const nowMs = moment()
    .utc()
    .valueOf();

  if (isNil(end)) {
    const startMs = moment(start).valueOf();
    return nowMs >= startMs;
  }
  const startMs = moment(start).valueOf();
  const endMs = moment(end).valueOf();
  return nowMs >= startMs && nowMs <= endMs;
};

export default { getFormattedWeight, getFormattedPrice, isInTimeRange };
