import { CALL_API } from '../../middleware/api';

export const FACILITY_PATIENTS_SEARCH_REQUEST = 'FACILITY_PATIENTS_SEARCH_REQUEST';
export const FACILITY_PATIENTS_SEARCH_SUCCESS = 'FACILITY_PATIENTS_SEARCH_SUCCESS';
export const FACILITY_PATIENTS_SEARCH_FAILURE = 'FACILITY_PATIENTS_SEARCH_FAILURE';

export const UPDATE_FACILITY_PATIENT_REQUEST = 'UPDATE_FACILITY_PATIENT_REQUEST';
export const UPDATE_FACILITY_PATIENT_SUCCESS = 'UPDATE_FACILITY_PATIENT_SUCCESS';
export const UPDATE_FACILITY_PATIENT_FAILURE = 'UPDATE_FACILITY_PATIENT_FAILURE';

export const CREATE_FACILITY_PATIENT_REQUEST = 'CREATE_FACILITY_PATIENT_REQUEST';
export const CREATE_FACILITY_PATIENT_SUCCESS = 'CREATE_FACILITY_PATIENT_SUCCESS';
export const CREATE_FACILITY_PATIENT_FAILURE = 'CREATE_FACILITY_PATIENT_FAILURE';

export const GET_FAC_PATIENT_MEDICAL_HISTORY_REQUEST = 'GET_FAC_PATIENT_MEDICAL_HISTORY_REQUEST';
export const GET_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS = 'GET_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS';
export const GET_FAC_PATIENT_MEDICAL_HISTORY_FAILURE = 'GET_FAC_PATIENT_MEDICAL_HISTORY_FAILURE';

export const UPDATE_FAC_PATIENT_MEDICAL_HISTORY_REQUEST =
  'UPDATE_FAC_PATIENT_MEDICAL_HISTORY_REQUEST';
export const UPDATE_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS =
  'UPDATE_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS';
export const UPDATE_FAC_PATIENT_MEDICAL_HISTORY_FAILURE =
  'UPDATE_FAC_PATIENT_MEDICAL_HISTORY_FAILURE';

export const SET_SELECTED_FACILITY = 'SET_SELECTED_FACILITY';
export const CLEAR_SELECTED_FACILITY = 'CLEAR_SELECTED_FACILITY';

export const CLEAR_PATIENTS = 'CLEAR_PATIENTS';

export const SET_FACILITY_PATIENT_SEARCH_TEXT = 'SET_FACILITY_PATIENT_SEARCH_TEXT';

export const SET_SHOW_FACILITY_SELECTION = 'SET_SHOW_FACILITY_SELECTION';

export const FACILITY_PATIENT_DOC_REQUEST = 'FACILITY_PATIENT_DOC_REQUEST';
export const FACILITY_PATIENT_DOC_SUCCESS = 'FACILITY_PATIENT_DOC_SUCCESS';
export const FACILITY_PATIENT_DOC_FAILURE = 'FACILITY_PATIENT_DOC_FAILURE';

export const facilityPatientSearch = (facilityId, searchString) => {
  return {
    [CALL_API]: {
      types: [
        FACILITY_PATIENTS_SEARCH_REQUEST,
        FACILITY_PATIENTS_SEARCH_SUCCESS,
        FACILITY_PATIENTS_SEARCH_FAILURE
      ],
      authenticated: true,
      endpoint: `v1/patient/facilities/${facilityId}/search?q=${searchString}`,
      method: 'GET',
      actionMetadata: {
        searchString
      }
    }
  };
};

export const setSelectedFacility = payload => {
  return {
    type: SET_SELECTED_FACILITY,
    payload
  };
};

export const clearSelectedFacility = () => {
  return {
    type: CLEAR_SELECTED_FACILITY
  };
};

export const clearPatients = () => {
  return {
    type: CLEAR_PATIENTS
  };
};

export const createFacilityPatient = (facilityId, payload) => {
  return {
    [CALL_API]: {
      types: [
        CREATE_FACILITY_PATIENT_REQUEST,
        CREATE_FACILITY_PATIENT_SUCCESS,
        CREATE_FACILITY_PATIENT_FAILURE
      ],
      authenticated: true,
      endpoint: `v1/patient/facilities/${facilityId}/patients`,
      method: 'POST',
      payload
    }
  };
};

export const updateFacilityPatient = (patientId, payload) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_FACILITY_PATIENT_REQUEST,
        UPDATE_FACILITY_PATIENT_SUCCESS,
        UPDATE_FACILITY_PATIENT_FAILURE
      ],
      authenticated: true,
      endpoint: `v1/patient/patients/${patientId}`,
      method: 'PUT',
      payload
    }
  };
};

export const setFacilityPatientSearchText = text => {
  return {
    type: SET_FACILITY_PATIENT_SEARCH_TEXT,
    payload: text
  };
};

export const getFacilityPatientMedicalHistory = patientId => {
  return {
    [CALL_API]: {
      types: [
        GET_FAC_PATIENT_MEDICAL_HISTORY_REQUEST,
        GET_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS,
        GET_FAC_PATIENT_MEDICAL_HISTORY_FAILURE
      ],
      authenticated: true,
      endpoint: `v1/patient/patients/${patientId}/medicalHistory`,
      method: 'GET'
    }
  };
};

export const updateFacilityPatientMedicalHistory = (patientId, payload) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_FAC_PATIENT_MEDICAL_HISTORY_REQUEST,
        UPDATE_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS,
        UPDATE_FAC_PATIENT_MEDICAL_HISTORY_FAILURE
      ],
      authenticated: true,
      endpoint: `v1/patient/patients/${patientId}/medicalHistory`,
      method: 'PUT',
      payload
    }
  };
};

export const setShowFacilitySelection = payload => {
  return {
    type: SET_SHOW_FACILITY_SELECTION,
    payload
  };
};

export const facilityDocumentSearch = payload => {
  return {
    [CALL_API]: {
      types: [
        FACILITY_PATIENT_DOC_REQUEST,
        FACILITY_PATIENT_DOC_SUCCESS,
        FACILITY_PATIENT_DOC_FAILURE
      ],
      authenticated: false, // UNAUTHENTICATED
      endpoint: `v1/patient/shareddocuments`,
      method: 'POST',
      payload
    }
  };
};
