import React, { Component } from 'react';
import { withTheme } from '@material-ui/core/styles';
import { TextField, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isNil, isEmpty } from 'lodash';

class TextFieldValidator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      isValid: true,
      helperText: '',
    };
  }

  componentDidMount() {
    if (this.props.forceValidation === true) {
      this.runValidators(this.props.value, true);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.forceValidation === true) {
      this.runValidators(nextProps.value, true);
    }
  }

  handleChange = (evt) => {
    const updateValue = evt.target.value;

    // if maxChars is provided, check max chars before allowing update
    if (!isNil(this.props.maxChars)) {
      if (updateValue.toString().length > this.props.maxChars) {
        return;
      }
    }

    this.setState(
      {
        isDirty: true,
      },
      () => this.runValidators(updateValue, true),
    );
    this.props.onChange(evt);
  };

  runValidators = (value, forceValidation) => {
    // run validators if provided and field is dirty (has been blurred)
    if (
      (this.props.validators && this.props.validators.length > 0 && this.state.isDirty) ||
      forceValidation
    ) {
      const errors = this.props.validators
        .map((validator) => {
          return validator(value, this.props.label);
        })
        .filter((err) => {
          return err !== false;
        });

      if (errors && errors.length > 0) {
        if (this.props.onValidationChange) this.props.onValidationChange(errors[0]);
        this.setState({
          isValid: false,
          helperText: errors[0],
        });
      } else {
        if (this.props.onValidationChange && this.state.isValid !== true)
          this.props.onValidationChange(null);
        this.setState({
          isValid: true,
          helperText: '',
        });
      }
    }
  };

  render() {
    const { label, forceValidation, maxChars, containerStyles, ...rest } = this.props;
    const { isValid, helperText, isDirty } = this.state;

    // use local onchange
    delete rest.onChange;
    delete rest.onValidationChange;
    delete rest.forceValidation;

    return (
      <div style={{ position: 'relative', ...containerStyles }}>
        <TextField
          label={label}
          error={(!isValid && isDirty) || (!isValid && forceValidation)}
          helperText={helperText}
          id={label}
          onChange={this.handleChange}
          {...rest}
        />
        {isEmpty(helperText) && isValid && !isNil(maxChars) && (
          <FormHelperText style={{ position: 'absolute', right: 0, bottom: '-0.5rem' }}>
            {`${rest.value.toString().length}/${maxChars}`}
          </FormHelperText>
        )}
      </div>
    );
  }
}

TextFieldValidator.propTypes = {
  label: PropTypes.string.isRequired,
  validators: PropTypes.array,
  forceValidation: PropTypes.bool,
  onValidationChange: PropTypes.func,
  containerStyles: PropTypes.object,
};

export default withTheme()(TextFieldValidator);
