import PropTypes from 'prop-types';
import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { isNil, isEmpty } from 'lodash';
import classNames from 'classnames';

const IMAGE_HEIGHT = 200;
const IMAGE_WIDTH = 200;

const ProviderInfo = ({ classes, firstName, lastName, bio, university, photoUrl, displayName }) => (
  <div className={classes.infoContainer}>
    {!isNil(photoUrl) && !isEmpty(photoUrl) ? (
      <img
        src={photoUrl}
        alt=""
        style={{ height: IMAGE_HEIGHT, width: IMAGE_WIDTH, objectFit: 'cover' }}
      />
    ) : (
      <i className={classNames('fas fa-user-md fa-10x', classes.userIcon)} />
    )}

    {!isNil(displayName) && !isEmpty(displayName) ? (
      <Typography variant="h6" className={classes.infoText}>{`${displayName}`}</Typography>
    ) : (
      <Typography variant="h6" className={classes.infoText}>
        {`${firstName} ${lastName}`}
      </Typography>
    )}

    <Typography variant="subtitle1" className={classes.infoText}>
      {university}
    </Typography>

    <Typography variant="subtitle1" className={classes.infoText}>
      {bio}
    </Typography>
  </div>
);

const styles = theme => ({
  infoContainer: {
    textAlign: 'center',
  },
  infoText: {
    color: theme.palette.primary.darkGray,
    marginTop: '1rem',
  },
  userIcon: {
    color: theme.palette.primary.darkGray,
  },
});

ProviderInfo.propTypes = {
  classes: PropTypes.object.isRequired,

  bio: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  photoUrl: PropTypes.string,
  university: PropTypes.string.isRequired,
};

ProviderInfo.defaultProps = {
  displayName: '',
  photoUrl: '',
};

export default withStyles(styles)(ProviderInfo);
