import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, has } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Button, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ReactRouterPropsTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import InsuranceForm from '../visit/forms/visitInsuranceForm.component';
import Languages from '../language/languages';
import {
  saveInsuranceInfo,
  SAVE_INSURANCE_INFO_SUCCESS,
  getInsuranceInfo,
} from '../profile/profile.actions';
import { showToast } from '../layout/layout.actions';
import { uploadFiles } from '../../utilities/upload';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { relationshipTypes } from '../../types/insuranceRelationships';
import {
  createInsurancePayload,
  formatInsuranceInfo,
  MAX_INSURANCE_ATTACHMENTS,
} from '../../utilities/insuranceUtils';
import TOAST_TYPES from '../../types/toastTypes';

class InsuranceInfoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      insuranceInfo: {},
      formErrors: {},
      isUploadingFiles: false,
    };
  }

  componentDidMount() {
    this.props.getInsuranceInfo(this.props.match.params.patientId);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      insuranceInfo: formatInsuranceInfo(nextProps.insuranceInfo, this.props.profile),
    });
  }

  handleChange = field => update => {
    // see if update is an event, if not it is a value already
    if (update && has(update.target, 'value')) {
      update.persist();
    }

    this.setState(prevState => ({
      insuranceInfo: {
        ...prevState.insuranceInfo,
        [field]: update && has(update.target, 'value') ? update.target.value : update,
      },
    }));
  };

  handleValidationChange = field => error => {
    if (error === null) {
      this.setState(prevState => {
        delete prevState.formErrors[field];

        return {
          formErrors: prevState.formErrors,
        };
      });

      return;
    }

    this.setState(prevState => ({
      formErrors: {
        ...prevState.formErrors,
        [field]: error,
      },
    }));
  };

  handleAddInsuranceAttachment = attachments => {
    const numOfAttachments = this.state.insuranceInfo.attachments.length + attachments.length;

    if (numOfAttachments > MAX_INSURANCE_ATTACHMENTS) {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.insuranceAttachmentLimit
      );
      return;
    }

    this.setState({
      isUploadingFiles: true,
    });

    uploadFiles(
      attachments,
      response => {
        this.setState(prevState => ({
          isUploadingFiles: false,
          insuranceInfo: {
            ...prevState.insuranceInfo,
            attachments: [].concat(prevState.insuranceInfo.attachments, response.data),
          },
        }));
      },
      err => {
        console.log(`error during image upload: ${err}`);
        this.setState({ isUploadingFiles: false });
        this.props.showToast(
          Languages[this.props.selectedLanguageKey].strings.showToastMessages.uploadImageFailure
        );
      },
      pct => console.log('upload progress: ', pct),
      () => console.log('upload cancelled')
    );
  };

  handleRemoveInsuranceAttachment = index => {
    const attachments = [].concat(this.state.insuranceInfo.attachments);
    attachments.splice(index, 1);

    this.setState(prevState => ({
      insuranceInfo: {
        ...prevState.insuranceInfo,
        attachments,
      },
    }));
  };

  saveInsurance = async () => {
    const payload = createInsurancePayload(this.state.insuranceInfo);
    const result = await this.props.saveInsuranceInfo(this.props.match.params.patientId, payload);

    if (result.type === SAVE_INSURANCE_INFO_SUCCESS) {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.updateInsuranceSuccess
      );
      this.props.history.goBack();
    } else {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.updateInsuranceFailure,
        TOAST_TYPES.ERROR,
        true,
        null,
        result.messages[0]
      );
    }
  };

  render() {
    const { classes, selectedLanguageKey, isLoadingInsuranceInfo } = this.props;
    const { formIsDirty, insuranceInfo, formErrors } = this.state;
    const language = Languages[selectedLanguageKey].strings;

    if (isLoadingInsuranceInfo) return <LoadingOverlay />;

    const hasFormErrors =
      insuranceInfo &&
      insuranceInfo.relationshipToInsured !== relationshipTypes.RELATIONSHIP_SELF.value &&
      !isEmpty(formErrors);

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.stepperContent}>
            <Typography className={classes.title} variant="h5">
              Edit Insurance
            </Typography>
            <InsuranceForm
              insuranceInfo={insuranceInfo || {}}
              isUploadingFiles={this.state.isUploadingFiles}
              selectedLanguageKey={selectedLanguageKey}
              handleAddAttachment={this.handleAddInsuranceAttachment}
              handleRemoveAttachment={this.handleRemoveInsuranceAttachment}
              handleValidationChange={this.handleValidationChange}
              onFieldChange={this.handleChange}
            />
          </div>
        </div>
        <div className={classes.formActions}>
          <Button
            className={classes.actionButton}
            variant="text"
            onClick={formIsDirty ? this.handleShowCancelDialog : () => this.props.history.goBack()}
          >
            {language.buttonText.cancel}
          </Button>
          <Button
            className={classes.actionButton}
            disabled={hasFormErrors}
            classes={{ root: hasFormErrors ? classes.buttonDisabled : classes.button }}
            variant="text"
            onClick={this.saveInsurance}
          >
            {language.buttonText.saveInsurance}
          </Button>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  content: {
    background: theme.palette.primary.background,
    flex: 1,
    overflow: 'auto',
    width: '100%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  actionButton: {
    margin: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  title: {
    color: theme.palette.primary.darkGray,
    marginBottom: '0.5rem',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  stepperContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 88px)',
    margin: '2rem auto 0',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formActions: {
    background: theme.palette.primary.background,
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightGray,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
});

InsuranceInfoContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropsTypes.history.isRequired,
  match: ReactRouterPropsTypes.match.isRequired,

  isLoadingInsuranceInfo: PropTypes.bool.isRequired,
  insuranceInfo: PropTypes.object,
  profile: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  getInsuranceInfo: PropTypes.func.isRequired,
  saveInsuranceInfo: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

InsuranceInfoContainer.defaultProps = {
  insuranceInfo: {},
};

const mapStateToProps = (state, props) => {
  const {
    profile: { profile, insuranceInfo, isLoadingInsuranceInfo },
  } = state;

  const { patientId } = props.match.params;
  let patient = profile;

  if (!isEmpty(profile) && profile.id !== patientId) {
    patient = profile.dependents.find(dependent => dependent.id === patientId);
  }

  return {
    isLoadingInsuranceInfo,
    insuranceInfo,
    profile: patient,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    getInsuranceInfo,
    saveInsuranceInfo,
    showToast,
  })
)(InsuranceInfoContainer);
