import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, InputAdornment } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import TextFieldValidator from '../../common/inputValidators/textFieldValidator.component';
import { required, validEmail } from '../../utilities/fieldValidation';
import auth from '../../auth/auth';
import { showToast } from '../layout/layout.actions';
import CustomDialog from '../../common/customDialog/customDialog.component';
import Languages from '../language/languages';

class ForgotPasswordModal extends Component {
  state = {
    email: '',
    formErrors: [],
  };

  handleEmailChange = field => event => {
    this.setState({ [field]: event.target.value });
  };

  handleValidationChange = field => error => {
    const updateErrors = { ...this.state.formErrors };

    if (!isEqual(updateErrors[field], error)) {
      updateErrors[field] = error;
      this.setState({
        formErrors: updateErrors,
      });
    }
  };

  handleForgotPassword = () => {
    auth.forgotPassword(
      this.state.email,
      () => {
        console.log('Password Reset Email Sent!');
        this.props.handleClose();
        this.props.showToast(
          Languages[this.props.selectedLanguageKey].strings.showToastMessages.forgotPasswordSuccess
        );
      },
      err => {
        console.log('Password Reset Email Failed!', err);
        this.props.handleClose();
        this.props.showToast(
          Languages[this.props.selectedLanguageKey].strings.showToastMessages.forgotPasswordFailure
        );
      }
    );
  };

  formHasErrors = () => {
    return Object.keys(this.state.formErrors).some(key => !!this.state.formErrors[key]);
  };

  render() {
    const { classes, handleClose, open } = this.props;
    const { email } = this.state;

    return (
      <CustomDialog
        open={open}
        handleClose={handleClose}
        content={
          <TextFieldValidator
            label={Languages[this.props.selectedLanguageKey].strings.inputLabels.email}
            className={classes.textField}
            value={email}
            onChange={this.handleEmailChange('email')}
            validators={[required, validEmail]}
            margin="normal"
            variant="outlined"
            onValidationChange={this.handleValidationChange('firstName')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email className={classes.adornment} />
                </InputAdornment>
              ),
            }}
          />
        }
        title={Languages[this.props.selectedLanguageKey].strings.alertMessageTitles.forgotPassword}
        handleAction={this.handleForgotPassword}
        isActionDisabled={this.state.email === '' || this.formHasErrors()}
      />
    );
  }
}

const styles = ({ spacing, palette, shadows }) => ({
  paper: {
    position: 'absolute',
    width: spacing.unit * 50,
    backgroundColor: palette.background.paper,
    boxShadow: shadows[5],
    padding: spacing.unit * 2,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    alignSelf: 'flex-end',
    color: palette.primary.darkGray,
  },
  textField: {
    width: '100%',
    margin: '1rem auto 0.5rem auto',
    color: palette.primary.darkGray,
  },
  buttonDisabled: {
    backgroundColor: palette.primary.lightGray,
    padding: '1rem 2rem',
    margin: '2rem auto 1rem auto',
  },
  loginButton: {
    color: palette.common.white,
    padding: '1rem 2rem',
    margin: '2rem auto 1rem auto',
    borderRadius: 10,
    backgroundColor: palette.primary.main,
    '&:hover': {
      color: palette.common.white,
      backgroundColor: palette.primary.darkGray,
    },
    '&:disabled': {
      color: palette.primary.darkGray,
      backgroundColor: palette.primary.lighGray,
    },
  },
  adornment: {
    color: palette.primary.darkGray,
  },
});

ForgotPasswordModal.propTypes = {
  classes: PropTypes.object.isRequired,

  open: PropTypes.bool.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  handleClose: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    showToast,
  })
)(ForgotPasswordModal);
