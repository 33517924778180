import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { isNil, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import MedicalHistoryCard from '../medicalHistoryCard.component';
import ReactSelectField from '../../../common/reactSelectField/reactSelectField.component';
import { nihApiTypes } from '../../../types/nihApiTypes';
import {
  mapJsonArrayToSelectOptions,
  getNihApiPath,
  mapNihDataToSelectOptions,
} from '../../../utilities/nihApiUtils';
import { fetchData, GET_API_DATA_SUCCESS } from '../../../common/api/api.actions';
import MedicalHistoryRadioButtons from '../medicalHistoryRadioButtons.component';
import {
  mapAllergyDataToSelectOptions,
  mapAllergyJsonToSelectOptions,
} from '../../../utilities/medicalAllergyUtils';
import { SEARCH_ALLERGIES_SUCCESS, searchAllergies } from '../../visit/visit.actions';
import Languages from '../../language/languages';

class MedicalHistoryForm extends Component {
  handleFetchData = type => async query => {
    if (type === 'TYPE_ALLERGIES') {
      const response = await this.props.searchAllergies(query);
      if (response.type === SEARCH_ALLERGIES_SUCCESS) {
        return mapAllergyDataToSelectOptions(response.response);
      }
    } else {
      const response = await this.props.fetchData(getNihApiPath(type, query));
      if (response.type === GET_API_DATA_SUCCESS) {
        return mapNihDataToSelectOptions(type, response.response);
      }
    }
  };

  render() {
    const {
      medicalHistory,
      medicalHistoryResponses,
      handleMedicalHistoryResponse,
      handleSelectFieldChange,
      classes,
      selectedLanguageKey,
    } = this.props;
    const language = Languages[selectedLanguageKey].strings;

    // radio buttons require string value 'true' or 'false'
    const medicationsResponse = !isNil(medicalHistoryResponses.medications)
      ? medicalHistoryResponses.medications.toString()
      : null;
    const conditionsResponse = !isNil(medicalHistoryResponses.medicalHistory)
      ? medicalHistoryResponses.medicalHistory.toString()
      : null;
    const allergiesResponse = !isNil(medicalHistoryResponses.allergies)
      ? medicalHistoryResponses.allergies.toString()
      : null;
    const surgeriesResponse = !isNil(medicalHistoryResponses.surgicalHistory)
      ? medicalHistoryResponses.surgicalHistory.toString()
      : null;

    return (
      <div className={classes.historyContainer}>
        <MedicalHistoryCard
          heading={language.medicalHistoryCards.medications.label}
          subHeading={language.medicalHistoryCards.medications.subText}
          radioButtons={
            <MedicalHistoryRadioButtons
              handleChange={handleMedicalHistoryResponse('medications')}
              selectedLanguageKey={selectedLanguageKey}
              value={medicationsResponse}
            />
          }
        >
          {medicationsResponse === 'true' && (
            <div>
              <Typography className={classes.cardHeading}>
                {language.medicalHistoryCards.medications.inputLabelText}
              </Typography>
              <ReactSelectField
                selectedValues={
                  medicalHistory.medications
                    ? mapJsonArrayToSelectOptions(
                        nihApiTypes.NIH_RXTERMS,
                        medicalHistory.medications
                      )
                    : []
                }
                handleChange={handleSelectFieldChange('medications')}
                hasError={medicationsResponse && isEmpty(medicalHistory.medications)}
                errorText={language.medicalHistoryErrors.medications}
                isMulti
                isReadOnly={false}
                handleFetchData={this.handleFetchData(nihApiTypes.NIH_RXTERMS)}
              />
            </div>
          )}
        </MedicalHistoryCard>
        <MedicalHistoryCard
          heading={language.medicalHistoryCards.conditions.label}
          subHeading={language.medicalHistoryCards.conditions.subText}
          radioButtons={
            <MedicalHistoryRadioButtons
              handleChange={handleMedicalHistoryResponse('medicalHistory')}
              selectedLanguageKey={selectedLanguageKey}
              value={conditionsResponse}
            />
          }
        >
          {conditionsResponse === 'true' && (
            <div>
              <Typography className={classes.cardHeading}>
                {language.medicalHistoryCards.conditions.inputLabelText}
              </Typography>
              <ReactSelectField
                selectedValues={
                  medicalHistory.medicalHistory
                    ? mapJsonArrayToSelectOptions(
                        nihApiTypes.NIH_CONDITIONS,
                        medicalHistory.medicalHistory
                      )
                    : []
                }
                handleChange={handleSelectFieldChange('medicalHistory')}
                isMulti
                isReadOnly={false}
                handleFetchData={this.handleFetchData(nihApiTypes.NIH_CONDITIONS)}
                hasError={conditionsResponse && isEmpty(medicalHistory.medicalHistory)}
                errorText={language.medicalHistoryErrors.conditions}
              />
            </div>
          )}
        </MedicalHistoryCard>
        <MedicalHistoryCard
          heading={language.medicalHistoryCards.allergies.label}
          subHeading={language.medicalHistoryCards.allergies.subText}
          radioButtons={
            <MedicalHistoryRadioButtons
              handleChange={handleMedicalHistoryResponse('allergies')}
              selectedLanguageKey={selectedLanguageKey}
              value={allergiesResponse}
            />
          }
        >
          {allergiesResponse === 'true' && (
            <div>
              <Typography className={classes.cardHeading}>
                {language.medicalHistoryCards.allergies.inputLabelText}
              </Typography>
              <ReactSelectField
                selectedValues={
                  medicalHistory.allergies
                    ? mapAllergyJsonToSelectOptions(medicalHistory.allergies)
                    : []
                }
                handleChange={handleSelectFieldChange('allergies')}
                isMulti
                isReadOnly={false}
                handleFetchData={this.handleFetchData('TYPE_ALLERGIES')}
                hasError={allergiesResponse && isEmpty(medicalHistory.allergies)}
                errorText={language.medicalHistoryErrors.allergies}
              />
            </div>
          )}
        </MedicalHistoryCard>
        <MedicalHistoryCard
          heading={language.medicalHistoryCards.surgical.label}
          subHeading={language.medicalHistoryCards.surgical.subText}
          radioButtons={
            <MedicalHistoryRadioButtons
              handleChange={handleMedicalHistoryResponse('surgicalHistory')}
              selectedLanguageKey={selectedLanguageKey}
              value={surgeriesResponse}
            />
          }
        >
          {surgeriesResponse === 'true' && (
            <div>
              <Typography className={classes.cardHeading}>
                {language.medicalHistoryCards.surgical.inputLabelText}
              </Typography>
              <ReactSelectField
                selectedValues={
                  medicalHistory.surgicalHistory
                    ? mapJsonArrayToSelectOptions(
                        nihApiTypes.NIH_PROCEDURES,
                        medicalHistory.surgicalHistory
                      )
                    : []
                }
                handleChange={handleSelectFieldChange('surgicalHistory')}
                isMulti
                isReadOnly={false}
                handleFetchData={this.handleFetchData(nihApiTypes.NIH_PROCEDURES)}
                hasError={surgeriesResponse && isEmpty(medicalHistory.surgicalHistory)}
                errorText={language.medicalHistoryErrors.surgeries}
              />
            </div>
          )}
        </MedicalHistoryCard>
      </div>
    );
  }
}

const styles = theme => ({
  historyContainer: {
    margin: '1rem',
    paddingBottom: '6rem',
  },
  cardHeading: {
    color: theme.palette.primary.darkGray,
  },
});

MedicalHistoryForm.propTypes = {
  classes: PropTypes.object.isRequired,

  medicalHistory: PropTypes.object.isRequired,
  medicalHistoryResponses: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  fetchData: PropTypes.func.isRequired,
  handleMedicalHistoryResponse: PropTypes.func.isRequired,
  handleSelectFieldChange: PropTypes.func.isRequired,
  searchAllergies: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    fetchData,
    searchAllergies,
  })
)(MedicalHistoryForm);
