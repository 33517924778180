const XSMALL = { name: 'XSMALL', maxWidth: 600 };
const SMALL = { name: 'SMALL', maxWidth: 960 };
const MEDIUM = { name: 'MEDIUM', maxWidth: 1280 };
const LARGE = { name: 'LARGE', maxWidth: 1600 }; // this changes the default of material-ui from 1920
const XLARGE = { name: 'XLARGE' };

export default {
  XSMALL,
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE
};
