import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { isEmpty, has, isNil } from 'lodash';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { setDirtyForm, showToast } from '../layout/layout.actions';
import PatientProfileForm from '../patient/forms/patientProfileForm.component';
import CustomDialog from '../../common/customDialog/customDialog.component';
import { lessThan12YearsAgo, DATE_INPUT_DATE_FORMAT } from '../../utilities/fieldValidation';
import { createDependent, CREATE_DEPENDENT_SUCCESS } from './dependent.actions';
import toastTypes from '../../types/toastTypes';
import accountTypes from '../../types/accountTypes';
import Languages from '../language/languages';

class DependentCreateContainer extends Component {
  constructor(props) {
    super(props);

    const profile =
      !isNil(props.location.state) && has(props, 'location.state.profile')
        ? props.location.state.profile
        : null;

    this.state = {
      dependent: {
        firstName: '',
        lastName: '',
        dob: '',
        weight: '',
        addressLine1: profile ? profile.addressLine1 : '',
        addressLine2: profile ? profile.addressLine2 : '',
        addressCity: profile ? profile.addressCity : '',
        addressState: profile ? profile.addressState : '',
        addressZip: profile ? profile.addressZip : '',
        gender: '',
        accountType: accountTypes.CHILD,
      },
      isShowCancelDialog: false,
      formIsDirty: false,
      formErrors: [],
    };
  }

  handleChange = name => update => {
    const updateDependent = { ...this.state.dependent };

    // see if update is an event, if not it is a value already
    updateDependent[name] =
      !isNil(update) && has(update.target, 'value') ? update.target.value : update;

    this.setState({
      dependent: updateDependent,
      formIsDirty: true,
    });

    this.props.setDirtyForm(true);
  };

  handleValidationChange = field => error => {
    const updateErrors = { ...this.state.formErrors };
    updateErrors[field] = error;
    this.setState({
      formErrors: updateErrors,
    });
  };

  handleShowCancelDialog = () => {
    this.setState({
      isShowCancelDialog: true,
    });
  };

  handleCloseCancelDialog = () => {
    this.setState({
      isShowCancelDialog: false,
    });
  };

  createDependentProfile = async () => {
    const dependent = { ...this.state.dependent };

    if (dependent.addressLine2 === '' || dependent.addressLine2 === null)
      delete dependent.addressLine2;
    if (!lessThan12YearsAgo(DATE_INPUT_DATE_FORMAT)(dependent.dob)) delete dependent.weight;

    delete dependent.accountType;

    const response = await this.props.createDependent(dependent);

    if (response.type === CREATE_DEPENDENT_SUCCESS) {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.createDependentSuccess
      );
      this.props.history.goBack();
    } else {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.createDependentFailure,
        toastTypes.ERROR,
        true,
        null,
        response.messages[0]
      );
    }
  };

  formHasErrors = () => {
    const requiredFields = [
      'firstName',
      'lastName',
      'dob',
      'gender',
      'addressLine1',
      'addressCity',
      'addressState',
      'addressZip',
    ];

    if (lessThan12YearsAgo(DATE_INPUT_DATE_FORMAT)(this.state.dependent.dob))
      requiredFields.push('weight');

    const isMissingRequiredField = requiredFields.some(key => isEmpty(this.state.dependent[key]));
    const hasValidationError = Object.keys(this.state.formErrors).some(
      key => !!this.state.formErrors[key]
    );
    return isMissingRequiredField || hasValidationError;
  };

  render() {
    const { classes, selectedLanguageKey } = this.props;
    const { dependent, isShowCancelDialog, formIsDirty } = this.state;
    const language = Languages[selectedLanguageKey].strings;

    return (
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Typography variant="h5" className={classes.title}>
              {language.createDependentTitle}
            </Typography>

            <PatientProfileForm
              handleChange={this.handleChange}
              handleValidationChange={this.handleValidationChange}
              profile={dependent}
              selectedLanguageKey={selectedLanguageKey}
            />
          </div>
        </div>
        <div className={classes.formActions}>
          <Button
            variant="text"
            className={classes.actionButton}
            onClick={formIsDirty ? this.handleShowCancelDialog : () => this.props.history.goBack()}
          >
            {language.buttonText.cancel}
          </Button>
          <Button
            variant="text"
            className={classes.actionButton}
            classes={{ root: this.formHasErrors() ? classes.buttonDisabled : classes.button }}
            onClick={this.createDependentProfile}
            disabled={this.formHasErrors()}
          >
            {language.buttonText.addChild}
          </Button>
        </div>
        <CustomDialog
          open={isShowCancelDialog}
          handleClose={this.handleCloseCancelDialog}
          content={
            <Typography variant="subtitle1" id="modal-title">
              {language.alertMessages.cancelAddingDependent}
            </Typography>
          }
          title={language.alertMessageTitles.cancelAddingDependent}
          handleAction={() => this.props.history.goBack()}
        />
      </div>
    );
  }
}

const styles = theme => ({
  contentContainer: {
    flex: 1,
    overflow: 'auto',
    width: '100%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 88px)',
    margin: '2rem auto 0',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formActions: {
    background: theme.palette.primary.background,
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightGray,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  title: {
    color: theme.palette.primary.darkGray,
    marginBottom: '0.5rem',
  },
  actionButton: {
    margin: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
});

DependentCreateContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  selectedLanguageKey: PropTypes.string.isRequired,

  showToast: PropTypes.func.isRequired,
  createDependent: PropTypes.func.isRequired,
  setDirtyForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    setDirtyForm,
    createDependent,
    showToast,
  })
)(DependentCreateContainer);
