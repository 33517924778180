import { GET_ENTRANCE_FAILURE, GET_ENTRANCE_REQUEST, GET_ENTRANCE_SUCCESS } from './entrance.actions';
import produce from 'immer';

const initialState = {
  entrance: null,
  isLoading: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_ENTRANCE_SUCCESS:
      draft.entrance = action.response;
      draft.isLoading = false;
      break;

    case GET_ENTRANCE_FAILURE:
      draft.isLoading = false;
      break;

    case GET_ENTRANCE_REQUEST:
      draft.isLoading = true;
      break;

    default:
      break;
  }
}, initialState);
