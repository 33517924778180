import {
  GET_SURVEY_QUESTIONS_FAILURE,
  GET_SURVEY_QUESTIONS_REQUEST,
  GET_SURVEY_QUESTIONS_SUCCESS,
  SEND_SURVEY_ANSWERS_FAILURE,
  SEND_SURVEY_ANSWERS_REQUEST,
  SEND_SURVEY_ANSWERS_SUCCESS
} from './survey.actions';
import produce from 'immer';

const initialState = {
  survey: null,
  isLoading: false,
  isSending: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_SURVEY_QUESTIONS_SUCCESS:
      draft.survey = action.response;
      draft.isLoading = false;
      break;

    case GET_SURVEY_QUESTIONS_FAILURE:
      draft.isLoading = false;
      break;

    case GET_SURVEY_QUESTIONS_REQUEST:
      draft.isLoading = true;
      break;

    case SEND_SURVEY_ANSWERS_SUCCESS:
      draft.isSending = false;
      break;

    case SEND_SURVEY_ANSWERS_FAILURE:
      draft.isSending = false;
      break;

    case SEND_SURVEY_ANSWERS_REQUEST:
      draft.isSending = true;
      break;

    default:
      break;
  }
}, initialState);
