/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles, TextField, Icon, Button, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';

import {
  clearPatients,
  facilityPatientSearch,
  setFacilityPatientSearchText,
} from '../../facility/facility.actions';
import Languages from '../../language/languages';

const SEARCH_DEBOUNCE_MS = 250;
const SEARCH_BORDER_RADIUS = 10;
const MIN_PATIENT_SEARCH_CHARS = 3;

class FacilityPatientSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: props.patientSearchText ? props.patientSearchText : '',
    };
  }

  componentDidMount() {
    // update search on returning home
    if (!isEmpty(this.state.searchText)) {
      this.props.facilityPatientSearch(this.props.selectedFacility.id, this.state.searchText);
    }
  }

  doSearch = searchText => {
    const { selectedFacility } = this.props;

    // save search text in redux
    this.setState({
      searchText,
    });

    clearTimeout(this.searchTimer);

    if (!isEmpty(searchText) && searchText.trim().length >= MIN_PATIENT_SEARCH_CHARS) {
      this.searchTimer = setTimeout(() => {
        this.props.setFacilityPatientSearchText(searchText);
        this.props.facilityPatientSearch(selectedFacility.id, searchText);
      }, SEARCH_DEBOUNCE_MS);
    } else {
      this.props.setFacilityPatientSearchText('');
      this.props.clearPatients();
    }
  };

  addPatient = (/* evt */) => {
    this.props.history.push('/facilitypatient/create');
  };

  render() {
    const {
      isLoadingFacility,
      classes,
      patientSearchResults,
      patientSearchText,
      selectedLanguageKey,
    } = this.props;
    const { searchText } = this.state;

    const language = Languages[selectedLanguageKey].strings;

    const isNoPatientsFound =
      patientSearchText.length >= MIN_PATIENT_SEARCH_CHARS &&
      patientSearchResults.length <= 0 &&
      !isLoadingFacility;

    return (
      <div className={classes.searchContainer}>
        <TextField
          className={classes.searchBox}
          placeholder={language.searchForPatient}
          InputProps={{
            endAdornment: <Icon className={classNames(['fas fa-search', classes.searchIcon])} />,
            classes: { notchedOutline: classes.notchedOutline },
          }}
          value={searchText}
          variant="outlined"
          onChange={evt => this.doSearch(evt.target.value)}
        />
        {isNoPatientsFound && (
          <div classNames={classes.emptyContainer}>
            <div style={{ margin: 'auto' }}>
              <Typography align="center" className={classes.emptyText}>
                No patients found. Click below to add a patient.
              </Typography>
              <Button
                className={classes.addButton}
                color="primary"
                variant="raised"
                onClick={this.addPatient}
              >
                {language.buttonText.addAPatient}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  searchBox: {
    width: '50%',
    color: theme.palette.primary.darkGray,
    backgroundColor: theme.palette.common.white,
    borderRadius: SEARCH_BORDER_RADIUS,
    height: 'fit-content',
  },
  notchedOutline: {
    borderRadius: SEARCH_BORDER_RADIUS,
  },
  searchIcon: {
    color: theme.palette.primary.darkGray,
  },
  searchContainer: {
    display: 'flex',
    flex: 1,
  },
  emptyContainer: {
    display: 'flex',
    flex: 1,
  },
  emptyText: {
    color: theme.palette.primary.darkGray,
    fontSize: '1.1rem',
    marginBottom: '0.5rem',
  },
  addButton: {
    display: 'balock',
    margin: 'auto',
    width: 220,
  },
});

FacilityPatientSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  isLoadingFacility: PropTypes.bool.isRequired,
  patientSearchResults: PropTypes.array,
  patientSearchText: PropTypes.string,
  selectedFacility: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  clearPatients: PropTypes.func.isRequired,
  facilityPatientSearch: PropTypes.func.isRequired,
  setFacilityPatientSearchText: PropTypes.func.isRequired,
};

FacilityPatientSearch.defaultProps = {
  patientSearchResults: [],
  patientSearchText: '',
};

const mapStateToProps = state => {
  return {
    isLoadingFacility: state.facility.isLoading,
    patientSearchResults: state.facility.patientSearchResults,
    patientSearchText: state.facility.patientSearchText,
    selectedFacility: state.facility.selectedFacility,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    setFacilityPatientSearchText,
    clearPatients,
    facilityPatientSearch,
  })
)(FacilityPatientSearch);
