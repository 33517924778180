import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles, Paper, Button, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, has } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import DependentCard from './dependentCard.component';
import accountTypes from '../../types/accountTypes';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import Languages from '../language/languages';
import { logEvent } from '../../utilities/googleAnalytics';
import { hasWebcam } from '../../utilities/hardwareUtils';
import { clearPricingCodes } from '../visit/visit.actions';
import { config as brandConfig } from '@brand';

const { entranceId } = brandConfig;

const styles = () => ({
  paper: {
    width: 350,
    height: 150,
    margin: '1rem auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  actionButton: {
    fontSize: 14,
    paddingLeft: 12,
    paddingRight: 12
  },
  icon: {
    marginRight: '.25rem'
  }
});

class DependentCards extends Component {
  handleDependentEdit = profile => {
    logEvent('edit_patient', {
      patientId: profile.id,
      entryId: brandConfig.entranceId,
      accountId: this.props.profile.id
    });
    this.props.history.push({
      pathname: `/patient/${profile.id}`,
      state: {
        profile
      }
    });
  };

  handleDependentCreate = () => {
    this.props.history.push({
      pathname: '/patient/create',
      state: {
        profile: this.props.profile
      }
    });
  };

  handleStartVisit = profile => {
    logEvent('see_a_doctor', {
      patientId: profile.id,
      entryId: entranceId,
      accountId: this.props.profile.id
    });

    // RMD-1678, 1679, clear pricing codes when starting a visit
    this.props.clearPricingCodes();

    this.props.history.push({
      pathname: `/patient/${profile.id}/visit`
    });
  };

  handlePreviousVisits = profile => {
    console.log('VIEWING PREVIOUS VISITS FOR', profile.id);
    logEvent('previous_visit', {
      patientId: profile.id,
      entryId: entranceId,
      accountId: this.props.profile.id
    });
    this.props.history.push({
      pathname: `/patient/${profile.id}/priorVisits`
    });
  };

  render() {
    const { profile, classes, isLoading, entrance } = this.props;

    if (isLoading) return <LoadingOverlay />;

    // map own profile into first item
    // dependents after
    let listItems = [];

    if (!isEmpty(profile)) {
      listItems = [
        {
          ...profile,
          accountType: accountTypes.ACCOUNT_HOLDER
        }
      ];
    }

    if (!isEmpty(profile) && !isEmpty(profile.dependents)) {
      listItems = listItems.concat(
        profile.dependents.map(dep => {
          return {
            ...dep,
            accountType: accountTypes.CHILD
          };
        })
      );
    }

    const isFacilitatedVisitsOnly =
      has(entrance, 'clientConfig.facilitatedVisitsOnly') &&
      entrance.clientConfig.facilitatedVisitsOnly === true;

    return (
      <Grid container>
        {listItems.length > 0 &&
          listItems.map((dependent, i) => {
            return (
              <Grid key={i} item xs={12} md={6} lg={4}>
                <DependentCard
                  data={dependent}
                  handleEdit={this.handleDependentEdit}
                  handleStartVisit={this.handleStartVisit}
                  handlePreviousVisits={this.handlePreviousVisits}
                  isStartVisitDisabled={!hasWebcam}
                  isHideActions={isFacilitatedVisitsOnly}
                />
              </Grid>
            );
          })}

        {!isFacilitatedVisitsOnly && (
          <Grid item xs={12} md={6} lg={4}>
            <Paper className={classes.paper}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.actionButton}
                onClick={() => this.handleDependentCreate()}
              >
                <AddIcon classes={{ root: classes.icon }} />
                {Languages[this.props.selectedLanguageKey].strings.buttonText.addAChild}
              </Button>
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }
}

DependentCards.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,
  entrance: PropTypes.object,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    isLoading: state.profile.isLoadingProfile,
    profile: state.profile.profile,
    selectedLanguageKey: state.language.selectedLanguageKey,
    entrance: state.entrance.entrance
  };
};

const withRouterDependentCards = withRouter(
  connect(mapStateToProps, {
    clearPricingCodes
  })(DependentCards)
);

export default withStyles(styles, { withTheme: true })(withRouterDependentCards);
