import { CALL_API } from '../../middleware/api';

export const GET_API_DATA_REQUEST = 'GET_API_DATA_REQUEST';
export const GET_API_DATA_SUCCESS = 'GET_API_DATA_SUCCESS';
export const GET_API_DATA_FAILURE = 'GET_API_DATA_FAILURE';

// simple api action to fetch data using api middleware
// used for /proxy routes for example
export const fetchData = endpoint => {
  return {
    [CALL_API]: {
      types: [GET_API_DATA_REQUEST, GET_API_DATA_SUCCESS, GET_API_DATA_FAILURE],
      authenticated: true,
      endpoint,
      method: 'GET'
    }
  };
};
