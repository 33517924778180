import { find } from 'lodash';
import moment from 'moment';
import Languages from '../modules/language/languages';

export const DATE_FORMAT = 'MM/DD/YYYY';

const getPriorVisitHeading = (selectedLanguageKey, patientId, profile, visitId, priorVisits) => {
  let toReturn = Languages[selectedLanguageKey].strings.pageTitles.priorVisit;

  const visit = find(priorVisits, { id: visitId });

  let patient = null;
  if (patientId === profile.id) {
    patient = { ...profile };
  } else {
    patient = find(profile.dependents, { id: patientId });
  }

  if (visit && patient) {
    toReturn = `${patient.firstName} ${patient.lastName} - ${visit.chiefComplaint} (${moment(
      visit.created
    ).format(DATE_FORMAT)})`;
  }

  return toReturn;
};

export default { getPriorVisitHeading };
