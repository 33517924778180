import React, { useCallback } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import LoadingOverlay from '../loadingOverlay/loadingOverlay.component';

const MAX_FILE_UPLOAD_SIZE = 10000000;

const PhotoSet = ({
  classes,
  containerStyle,
  photos,
  isLoading,
  removePhoto,
  addPhotos: handleAddPhotos,
  disabled,
}) => {
  const handleFileDrop = useCallback(
    (accepted /* rejectedFile */) => {
      handleAddPhotos(accepted);
    },
    [handleAddPhotos]
  );

  if (isLoading) return <LoadingOverlay />;

  return (
    <div className={classes.photoContainer} style={containerStyle}>
      <Grid container spacing={16} className={classes.photoGrid}>
        {photos.map((photo, i) => {
          return (
            <Grid key={photo.id} item className={classes.photoItem}>
              <IconButton onClick={() => removePhoto(i)} className={classes.removeButton}>
                <Close className={classes.closeIcon} />
              </IconButton>
              <img className={classes.image} src={photo.thumbnailUrl} alt="" />
            </Grid>
          );
        })}
      </Grid>
      {!disabled && (
        <Dropzone
          disabled={disabled}
          accept="image/*"
          maxSize={MAX_FILE_UPLOAD_SIZE}
          onDrop={handleFileDrop}
          className="no-select"
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={{ outline: 'none' }}>
              <input {...getInputProps()} />
              <div className={classes.dropzone}>
                Drop image files here, or click to select image files to upload.
              </div>
            </div>
          )}
        </Dropzone>
      )}
    </div>
  );
};

PhotoSet.propTypes = {
  classes: PropTypes.object.isRequired,

  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  photos: PropTypes.array.isRequired,

  addPhotos: PropTypes.func.isRequired,
  removePhoto: PropTypes.func.isRequired,
};

PhotoSet.defaultProps = {
  containerStyle: {},
  disabled: false,
};

const styles = theme => ({
  image: {
    objectFit: 'contain',
    maxHeight: 175,
  },
  removeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: 4,
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  dropzone: {
    margin: '0.5rem',
    borderColor: theme.palette.primary.darkGray,
    borderStyle: 'dashed',
    borderRadius: '0.5rem',
    borderWidth: 2,
    cursor: 'pointer',
    padding: '1rem',
    color: theme.palette.primary.darkGray,
  },
  closeIcon: {
    height: 20,
    width: 20,
  },
  photoContainer: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  photoItem: {
    marginTop: '1rem',
    position: 'relative',
  },
  photoGrid: {
    marginBottom: '1rem',
  },
});

export default withStyles(styles, { withTheme: true })(PhotoSet);
