import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, withStyles } from '@material-ui/core';
import Languages from '../language/languages';

const VideoStatus = ({
  classes,
  hasNoAnswer,
  hasParticipants,
  hasRetry,
  isConnected,
  isConnectedMobile,
  isConnecting,
  isVoiceCall,
  selectedLanguageKey,
  onRetry,
}) => {
  const language = Languages[selectedLanguageKey].strings;

  const isWaiting = isConnected && !hasParticipants;
  const hasFailedTwice = !isConnecting && !isConnected && hasRetry;

  if (hasNoAnswer) {
    return (
      <Typography align="center" variant="h6" className={classes.statusText}>
        {language.visitVoiceNoAnswer}
      </Typography>
    );
  }

  if (isVoiceCall) {
    return (
      <Typography align="center" variant="h6" className={classes.statusText}>
        {language.visitIsVoice}
      </Typography>
    );
  }

  if (isConnectedMobile) {
    return (
      <Typography align="center" variant="h6" className={classes.statusText}>
        {language.visitOnDevice}
      </Typography>
    );
  }

  if (isWaiting)
    return (
      <Typography align="center" variant="h6" className={classes.statusText}>
        {language.visitCallWaiting}
      </Typography>
    );

  if (hasFailedTwice)
    return (
      <div className={classes.failedContainer}>
        <Typography align="center" className={classes.statusText} variant="h6">
          {language.visitVideoFailed}
        </Typography>
        <Button color="primary" variant="outlined" onClick={onRetry}>
          {language.buttonText.retry}
        </Button>
      </div>
    );

  return (
    !isConnected && (
      <Typography align="center" className={classes.statusText} variant="h6">
        You are not currently in a call.
      </Typography>
    )
  );
};

const styles = theme => ({
  statusText: {
    margin: 'auto',
  },
  failedContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  retryButton: {
    marginTop: theme.spacing.unit * 2,
    maxWidth: '10rem',
  },
});

VideoStatus.propTypes = {
  classes: PropTypes.object.isRequired,

  hasNoAnswer: PropTypes.bool.isRequired,
  hasParticipants: PropTypes.bool.isRequired,
  hasRetry: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isConnectedMobile: PropTypes.bool.isRequired,
  isConnecting: PropTypes.bool.isRequired,
  isVoiceCall: PropTypes.bool.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  onRetry: PropTypes.func.isRequired,
};

export default withStyles(styles)(VideoStatus);
