/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';

const styles = theme => ({
  card: {
    height: '7rem',
    margin: '1rem auto',
    display: 'flex',
    flexDirection: 'column'
  },
  contentRoot: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflowX: 'hidden'
  },
  title: {
    fontSize: '1.25rem',
    cursor: 'pointer',
    textOverflow: 'ellipsis'
  }
});

class PriorVisitListItem extends Component {
  render() {
    const { classes, priorVisit, viewPriorVisitAction } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent classes={{ root: classes.contentRoot }}>
          <Typography
            className={classes.title}
            color="textSecondary"
            onClick={() => viewPriorVisitAction(priorVisit)}
            noWrap
          >
            {priorVisit.chiefComplaint}
          </Typography>
          <Typography variant="body1">{moment(priorVisit.created).format('MM/DD/YYYY')}</Typography>
        </CardContent>
      </Card>
    );
  }
}

PriorVisitListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  priorVisit: PropTypes.object.isRequired,
  viewPriorVisitAction: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(PriorVisitListItem);
