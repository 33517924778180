import produce from 'immer';
import {
  CANCEL_CURRENT_VISIT_FAILURE,
  CANCEL_CURRENT_VISIT_REQUEST,
  CANCEL_CURRENT_VISIT_SUCCESS,
  GET_CURRENT_VISIT_FAILURE,
  GET_CURRENT_VISIT_PROVIDER_FAILURE,
  GET_CURRENT_VISIT_PROVIDER_REQUEST,
  GET_CURRENT_VISIT_PROVIDER_SUCCESS,
  GET_CURRENT_VISIT_REQUEST,
  GET_CURRENT_VISIT_SUCCESS,
  GET_PHARMACIES_FAILURE,
  GET_PHARMACIES_REQUEST,
  GET_PHARMACIES_SUCCESS,
  GET_PHARMACY_FAILURE,
  GET_PHARMACY_REQUEST,
  GET_PHARMACY_SUCCESS,
  GET_PREVIOUS_VISITS_FAILURE,
  GET_PREVIOUS_VISITS_REQUEST,
  GET_PREVIOUS_VISITS_SUCCESS,
  GET_PRICING_FAILURE,
  GET_PRICING_REQUEST,
  GET_PRICING_SUCCESS,
  MARK_CURRENT_VISIT_READY_FAILURE,
  MARK_CURRENT_VISIT_READY_REQUEST,
  MARK_CURRENT_VISIT_READY_SUCCESS,
  RESET_PHARMACY_STATE,
  RESET_VISIT_STATE,
  SAVE_PHARMACY_FAILURE,
  SAVE_PHARMACY_REQUEST,
  SAVE_PHARMACY_SUCCESS,
  SET_PRICING_CODES,
  CLEAR_PRICING_CODES,
  START_VISIT_FAILURE,
  START_VISIT_REQUEST,
  START_VISIT_SUCCESS,
  CLEAR_PHARMACY,
  SET_CURRENT_VISIT,
} from './visit.actions';

const initialState = {
  pricing: null,
  isLoading: false,
  isLoadingPricing: false,
  isLoadingCurrentVisit: false,
  currentVisit: null,
  provider: null,
  isLoadingPharmacies: false,
  isLoadingProvider: false,
  isSavingMedicalHistory: false,
  isStartingVisit: false,
  pharmacies: [],
  pharmacy: null,
  pricingCodes: {
    coupon: null,
    group: null,
    identifier: null,
  },
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_PRICING_SUCCESS:
      console.log('GET PRICING SUCCESS', action.response);
      draft.pricing = action.response;
      draft.isLoadingPricing = false;
      break;

    case GET_PRICING_FAILURE:
      console.log('GET PRICING FAILURE', action.messages);
      draft.isLoadingPricing = false;
      break;

    case GET_PRICING_REQUEST:
      draft.isLoadingPricing = true;
      break;

    case SET_PRICING_CODES:
      draft.pricingCodes = { ...action.response };
      break;

    case CLEAR_PRICING_CODES:
      draft.pricingCodes = {
        coupon: null,
        group: null,
        identifier: null,
      };
      break;

    case START_VISIT_SUCCESS:
      draft.isStartingVisit = false;
      break;

    case START_VISIT_FAILURE:
      draft.isStartingVisit = false;
      break;

    case START_VISIT_REQUEST:
      draft.isStartingVisit = true;
      break;

    case CANCEL_CURRENT_VISIT_SUCCESS:
      draft.currentVisit = null;
      draft.isLoading = false;
      break;

    case CANCEL_CURRENT_VISIT_FAILURE:
      draft.isLoading = false;
      break;

    case CANCEL_CURRENT_VISIT_REQUEST:
      draft.isLoading = true;
      break;

    case GET_CURRENT_VISIT_SUCCESS: {
      draft.currentVisit = action.response;
      draft.isLoadingCurrentVisit = false;
      break;
    }

    case GET_CURRENT_VISIT_FAILURE:
      draft.currentVisit = null;
      draft.isLoadingCurrentVisit = false;
      break;

    case GET_CURRENT_VISIT_REQUEST:
      draft.isLoadingCurrentVisit = true;
      break;

    case SET_CURRENT_VISIT:
      draft.currentVisit = action.payload;
      break;

    case GET_CURRENT_VISIT_PROVIDER_SUCCESS: {
      draft.provider = action.response;
      draft.isLoadingProvider = false;
      break;
    }

    case GET_CURRENT_VISIT_PROVIDER_FAILURE:
      draft.isLoadingProvider = false;
      break;

    case GET_CURRENT_VISIT_PROVIDER_REQUEST:
      draft.isLoadingProvider = true;
      break;

    case RESET_VISIT_STATE:
      draft.currentVisit = null;
      draft.provider = null;
      break;

    case MARK_CURRENT_VISIT_READY_REQUEST:
      draft.isLoading = true;
      break;

    case MARK_CURRENT_VISIT_READY_SUCCESS:
      draft.isLoading = false;
      break;

    case MARK_CURRENT_VISIT_READY_FAILURE:
      draft.isLoading = false;
      break;

    case GET_PREVIOUS_VISITS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_PREVIOUS_VISITS_SUCCESS:
      draft.isLoading = false;
      break;

    case GET_PREVIOUS_VISITS_FAILURE:
      draft.isLoading = false;
      break;

    case GET_PHARMACIES_REQUEST:
      draft.pharmacies = [];
      draft.isLoadingPharmacies = true;
      break;

    case GET_PHARMACIES_SUCCESS:
      draft.isLoadingPharmacies = false;
      draft.pharmacies = action.response.data;
      break;

    case GET_PHARMACIES_FAILURE:
      draft.isLoadingPharmacies = false;
      break;

    case RESET_PHARMACY_STATE:
      draft.pharmacies = [];
      break;

    case SAVE_PHARMACY_REQUEST:
      draft.isLoadingPharmacies = true;
      break;

    case SAVE_PHARMACY_SUCCESS:
      draft.isLoadingPharmacies = false;
      break;

    case SAVE_PHARMACY_FAILURE:
      draft.isLoadingPharmacies = false;
      break;

    case GET_PHARMACY_REQUEST:
      draft.isLoadingPharmacies = true;
      break;

    case GET_PHARMACY_SUCCESS:
      draft.isLoadingPharmacies = false;
      draft.pharmacy = action.response;
      break;

    case GET_PHARMACY_FAILURE:
      draft.isLoadingPharmacies = false;
      break;

    case CLEAR_PHARMACY:
      draft.pharmacy = null;
      break;

    default:
      break;
  }
}, initialState);
