import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Icon,
  Grid,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { has } from 'lodash';
import { compose } from 'recompose';
import { getPriorVisits, GET_PRIOR_VISITS_SUCCESS } from '../../priorVisits/priorVisits.actions';
import { DATE_INPUT_DATE_FORMAT } from '../../../utilities/fieldValidation';
import Languages from '../../language/languages';

class FacilityPatientCard extends Component {
  state = {
    isInitialVisit: false,
  };

  async componentDidMount() {
    const result = await this.props.getPriorVisits(this.props.data.id);

    if (result.type === GET_PRIOR_VISITS_SUCCESS && result.response.data.length === 0) {
      this.setState({ isInitialVisit: true });
    }
  }

  render() {
    const {
      classes,
      data,
      handleEdit,
      handleStartVisit,
      selectedLanguageKey,
      isStartVisitDisabled,
      selectedFacility,
      isLoading,
      handlePreviousVisits,
    } = this.props;

    const formatDob = moment(data.dob).format(DATE_INPUT_DATE_FORMAT);
    const { buttonText } = Languages[selectedLanguageKey].strings;

    let fullName = `${data.firstName} ${data.lastName}`;
    fullName = fullName.length > 18 ? `${fullName.substring(0, 18)}...` : fullName;

    const isShowPreviousVisits =
      has(selectedFacility, 'phiVisible') && selectedFacility.phiVisible === true;

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.name} onClick={() => handleEdit(data)}>
            <Typography className={classes.title} color="textSecondary">
              {fullName}
            </Typography>
            <Tooltip
              title={
                <Typography variant="body2" className={classes.tooltipTitle}>
                  Edit Information
                </Typography>
              }
            >
              <Icon className={classnames(['fas fa-pencil-alt', classes.editIcon])} />
            </Tooltip>
          </div>
          <Typography className={classes.subText} color="textSecondary">
            {`${formatDob}`}
          </Typography>
        </CardContent>
        <CardActions>
          <Grid container spacing={8}>
            {isShowPreviousVisits && (
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handlePreviousVisits(data)}
                  disabled={this.state.isInitialVisit || isLoading}
                  classes={{ root: classes.actionButtons }}
                >
                  {isLoading ? buttonText.loading : buttonText.previousVisits}
                </Button>
              </Grid>
            )}

            <Grid item xs={isShowPreviousVisits ? 6 : 12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleStartVisit(data)}
                classes={{ root: classes.actionButtons }}
                disabled={isStartVisitDisabled}
              >
                {buttonText.seeProvider}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

const styles = theme => ({
  card: {
    width: 350,
    height: 150,
    margin: '1rem auto',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 23,
    marginLeft: '1rem',
  },
  subText: {
    fontSize: 18,
    marginLeft: '1rem',
  },
  editIcon: {
    fontSize: '14px',
    alignSelf: 'center',
    marginLeft: '1rem',
    color: theme.palette.primary.darkGray,
  },
  actionButtons: {
    width: '100%',
  },
  name: {
    cursor: 'pointer',
    display: 'flex',
  },
  tooltipTitle: {
    color: theme.palette.common.white,
  },
});

FacilityPatientCard.propTypes = {
  classes: PropTypes.object.isRequired,

  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isStartVisitDisabled: PropTypes.bool.isRequired,
  selectedFacility: PropTypes.object,
  selectedLanguageKey: PropTypes.string.isRequired,

  getPriorVisits: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handlePreviousVisits: PropTypes.func.isRequired,
  handleStartVisit: PropTypes.func.isRequired,
};

FacilityPatientCard.defaultProps = {
  selectedFacility: {},
};

const mapStateToProps = state => {
  return {
    isLoading: state.priorVisits.isLoadingPriorVisits,
    selectedLanguageKey: state.language.selectedLanguageKey,
    selectedFacility: state.facility.selectedFacility,
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    getPriorVisits,
  })
)(FacilityPatientCard);
