import DetectRTC from 'detectrtc';

let hasWebcam = false;
let hasMicrophone = false;

DetectRTC.load(() => {
  hasWebcam = DetectRTC.hasWebcam;
  hasMicrophone = DetectRTC.hasMicrophone;
});

export { hasWebcam, hasMicrophone };
