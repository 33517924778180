import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Link,
} from '@material-ui/core';
import { Email, Lock, Visibility } from '@material-ui/icons';
import { isNil, isEmpty, has } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';

import { compose } from 'recompose';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { createUser, CREATE_USER_SUCCESS } from './login.actions';
import DateFieldValidator from '../../common/inputValidators/dateFieldValidator.component';
import TextFieldValidator from '../../common/inputValidators/textFieldValidator.component';
import {
  required,
  greaterThan18YearsAgo,
  validEmail,
  validPassword,
  charLimit,
  validCharacters,
  dateIsInThePast,
  DATE_INPUT_DATE_FORMAT,
  DATE_FIELD_MASK,
  TEXT_INPUT_CHAR_LIMIT,
} from '../../utilities/fieldValidation';
import { showToast } from '../layout/layout.actions';
import toastTypes from '../../types/toastTypes';
import CustomDialog from '../../common/customDialog/customDialog.component';
import Languages from '../language/languages';
import { logEvent } from '../../utilities/googleAnalytics';

import { config as brandConfig } from '@brand';

const INPUT_LABEL_OFFSET_LEFT = 32;

class CreateAccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dob: '',
      email: '',
      error: null,
      firstName: '',
      focusedField: null,
      lastName: '',
      password: '',
      formErrors: {},
      isShowTerms: false,
      isShowPrivacy: false,
    };

    this.handleCreateUser = this.handleCreateUser.bind(this);
  }

  handleValidationChange = (field) => (error) => {
    const updateErrors = { ...this.state.formErrors };

    if (isNil(error)) {
      delete updateErrors[field];
    } else {
      updateErrors[field] = error;
    }
    this.setState({
      formErrors: updateErrors,
    });
  };

  handleChange = (field) => (update) => {
    const updateState = { ...this.state };
    updateState[field] =
      update !== null && has(update.target, 'value') ? update.target.value : update;

    // clear error state on edit
    updateState.error = null;
    this.setState(updateState);
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  formHasErrors = () => {
    const requiredFields = ['firstName', 'lastName', 'dob', 'email', 'password'];

    const isMissingRequiredField = requiredFields.some((key) => isEmpty(this.state[key]));
    const hasValidationError = Object.keys(this.state.formErrors).some(
      (key) => !!this.state.formErrors[key],
    );
    return isMissingRequiredField || hasValidationError;
  };

  async handleCreateUser(evt) {
    evt.preventDefault();
    const { email, password, lastName, firstName, dob } = this.state;

    const result = await this.props.createUser({
      email,
      password,
      firstName,
      lastName,
      dob,
    });

    if (result.type === CREATE_USER_SUCCESS) {
      this.props.showToast('Your account has been created.');
      this.props.handleToggleCreateForm();
      logEvent('create_account');
    } else {
      this.props.showToast(
        'There was a problem creating your profile. Please check your connection and try again.',
        toastTypes.ERROR,
        true,
        null,
        result.messages[0],
      );
    }
  }

  render() {
    const { classes, handleToggleCreateForm, selectedLanguageKey } = this.props;
    const {
      focusedField,
      email,
      password,
      showPassword,
      error,
      firstName,
      lastName,
      dob,
    } = this.state;
    const language = Languages[selectedLanguageKey].strings;

    return (
      <div className={classes.content}>
        <form className={classes.loginForm} onSubmit={this.handleCreateUser}>
          <div className={classes.container}>
            <TextFieldValidator
              label={language.inputLabels.email}
              className={classes.textField}
              value={email}
              onChange={this.handleChange('email')}
              validators={[required, validEmail]}
              margin="normal"
              variant="outlined"
              type="email"
              autoComplete="email"
              onValidationChange={this.handleValidationChange('email')}
              containerStyles={{
                width: '100%',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email className={classes.darkGray} />
                  </InputAdornment>
                ),
              }}
              onFocus={() => this.setState({ focusedField: 'email' })}
              onBlur={() => this.setState({ focusedField: null })}
              InputLabelProps={{
                shrink: focusedField === 'email' || !isEmpty(email),
                style: {
                  marginLeft:
                    focusedField === 'email' || !isEmpty(email) ? 0 : INPUT_LABEL_OFFSET_LEFT,
                },
              }}
            />
            <TextFieldValidator
              label={language.inputLabels.password}
              className={classes.textField}
              value={password}
              onChange={this.handleChange('password')}
              validators={[required, validPassword]}
              margin="normal"
              variant="outlined"
              onValidationChange={this.handleValidationChange('password')}
              containerStyles={{
                width: '100%',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock className={classes.darkGray} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      <Visibility />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onFocus={() => this.setState({ focusedField: 'password' })}
              onBlur={() => this.setState({ focusedField: null })}
              InputLabelProps={{
                shrink: focusedField === 'password' || !isEmpty(password),
                style: {
                  marginLeft:
                    focusedField === 'password' || !isEmpty(password) ? 0 : INPUT_LABEL_OFFSET_LEFT,
                },
              }}
              type={showPassword ? 'text' : 'password'}
            />
          </div>

          <div className={classNames(classes.nameContainer, classes.container)}>
            <div className={classes.firstNameContainer}>
              <TextFieldValidator
                containerStyles={{
                  width: '100%',
                }}
                label={language.inputLabels.firstName}
                className={classes.nameField}
                value={firstName || ''}
                onChange={this.handleChange('firstName')}
                validators={[required, charLimit(TEXT_INPUT_CHAR_LIMIT), validCharacters]}
                margin="normal"
                variant="outlined"
                onValidationChange={this.handleValidationChange('firstName')}
                onFocus={() => this.setState({ focusedField: 'firstName' })}
                onBlur={() => this.setState({ focusedField: null })}
              />
            </div>
            <div className={classes.lastNameContainer}>
              <TextFieldValidator
                containerStyles={{
                  width: '100%',
                }}
                label={language.inputLabels.lastName}
                className={classes.nameField}
                value={lastName || ''}
                onChange={this.handleChange('lastName')}
                validators={[required, charLimit(TEXT_INPUT_CHAR_LIMIT), validCharacters]}
                margin="normal"
                variant="outlined"
                onValidationChange={this.handleValidationChange('lastName')}
                onFocus={() => this.setState({ focusedField: 'lastName' })}
                onBlur={() => this.setState({ focusedField: null })}
              />
            </div>
          </div>
          <DateFieldValidator
            className={classes.dateField}
            keyboard
            clearable
            maxDate={moment().format(DATE_INPUT_DATE_FORMAT)}
            value={dob ? moment(dob, DATE_INPUT_DATE_FORMAT).format(DATE_INPUT_DATE_FORMAT) : ''}
            format={DATE_INPUT_DATE_FORMAT}
            mask={DATE_FIELD_MASK}
            validators={[
              required,
              dateIsInThePast(DATE_INPUT_DATE_FORMAT),
              greaterThan18YearsAgo(DATE_INPUT_DATE_FORMAT),
            ]}
            handleDateChange={this.handleChange('dob')}
            label={language.inputLabels.dateOfBirth}
            onValidationChange={this.handleValidationChange('dob')}
            openTo="year"
          />

          <Button
            className={classes.loginButton}
            disabled={this.formHasErrors()}
            onClick={this.handleLogin}
            type="submit"
            variant="outlined"
          >
            {language.buttonText.createAccount}
          </Button>

          {!isNil(error) && (
            <Typography className={classes.errorText}>{language.incorrectLogin}</Typography>
          )}
        </form>

        <Typography className={classes.terms}>
          {`${language.createAccountFinePrint.clickingLogin} `}
          <Link
            className={classes.darkGray}
            component="button"
            variant="body2"
            onClick={() => this.setState({ isShowTerms: true })}
          >
            {`${language.createAccountFinePrint.termsOfUse}`}
          </Link>
          {` ${language.createAccountFinePrint.and} `}
          <Link
            className={classes.darkGray}
            component="button"
            variant="body2"
            onClick={() => this.setState({ isShowPrivacy: true })}
          >
            {`${language.createAccountFinePrint.privacyPolicy}.`}
          </Link>
        </Typography>
        <CustomDialog
          fullScreen
          open={this.state.isShowTerms}
          handleClose={() => this.setState({ isShowTerms: false })}
          cancelText="Close"
          cancelOnly
          title={language.createAccountFinePrint.termsOfUse}
          content={
            <iframe
              title={language.createAccountFinePrint.termsOfUse}
              width="100%"
              height="100%"
              frameBorder={0}
              src={brandConfig.termsUrl}
            />
          }
        />
        <CustomDialog
          fullScreen
          open={this.state.isShowPrivacy}
          handleClose={() => this.setState({ isShowPrivacy: false })}
          cancelText="Close"
          cancelOnly
          title={language.createAccountFinePrint.privacyPolicy}
          content={
            <iframe
              title={language.createAccountFinePrint.privacyPolicy}
              width="100%"
              height="100%"
              frameBorder={0}
              src={brandConfig.privacyPolicyUrl}
            />
          }
        />
        <div className={classes.actionsContainer}>
          <Typography className={classes.accountAlready}>{language.accountAlready}</Typography>
          <Link
            className={classes.login}
            component="button"
            variant="body2"
            onClick={() => handleToggleCreateForm()}
          >
            {language.login}
          </Link>
        </div>
      </div>
    );
  }
}

const formContainerMinWidth = 280;
const formContainerWidth = '65%';

const styles = (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '2rem',
    alignItems: 'center',
  },
  container: {
    minWidth: formContainerMinWidth,
    width: formContainerWidth,
    margin: '0 auto',
  },
  textField: {
    width: '100%',
    margin: '1rem auto 0.5rem auto',
    color: theme.palette.primary.darkGray,
  },
  nameField: {
    width: '100%',
    margin: '1rem 0 0.5rem 0',
    color: theme.palette.primary.darkGray,
  },
  dateField: {
    minWidth: formContainerMinWidth,
    width: formContainerWidth,
    alignSelf: 'center',
    margin: '1rem 0 0.5rem 0',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  firstNameContainer: {
    flex: 1,
    paddingRight: '.5rem',
  },
  lastNameContainer: {
    flex: 1,
    paddingLeft: '.5rem',
  },
  loginButton: {
    color: theme.palette.common.white,
    width: '50%',
    margin: '1rem auto',
    padding: '.6rem 1rem',
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    '&:disabled': {
      color: theme.palette.primary.darkGray,
      backgroundColor: theme.palette.primary.lightGray,
    },
  },
  darkGray: {
    color: theme.palette.primary.darkGray,
  },
  errorText: {
    color: theme.palette.error.main,
    margin: '1rem auto',
  },
  terms: {
    color: theme.palette.primary.darkgGray,
    margin: '1rem auto',
  },
  actionsContainer: {
    display: 'flex',
    margin: '2rem auto',
  },
  accountAlready: {
    color: theme.palette.primary.darkGray,
    marginRight: '0.5rem',
  },
  login: {
    color: theme.palette.primary.darkGray,
    textDecoration: 'underline',
  },
});

CreateAccountForm.propTypes = {
  classes: PropTypes.object.isRequired,

  selectedLanguageKey: PropTypes.string.isRequired,

  createUser: PropTypes.func.isRequired,
  handleToggleCreateForm: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    createUser,
    showToast,
  }),
)(CreateAccountForm);
