import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import SurveyAnswerChoice from './surveyAnswerChoice.component';

const styles = theme => ({
  card: {
    // width: 320,
    // height: 100,
    margin: '1rem auto',
    display: 'flex',
    flexDirection: 'column'
  },
  contentRoot: {
    flex: 1
  },
  title: {
    fontSize: 23
  }
});

class SurveyQuestion extends Component {
  render() {
    const { classes, question, index, selectedAnswerIndex, answerQuestion } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent classes={{ root: classes.contentRoot }}>
          <Typography className={classes.title} color="textSecondary">
            {`${index + 1}. ${question.text}`}
          </Typography>

          {question.choices.map((choice, i) => {
            return (
              <SurveyAnswerChoice
                key={i}
                index={i}
                choice={choice}
                isSelected={i === selectedAnswerIndex}
                selectChoice={() => answerQuestion(index, i)}
              />
            );
          })}
        </CardContent>
      </Card>
    );
  }
}

SurveyQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  question: PropTypes.object,
  index: PropTypes.number,
  selectedAnswerIndex: PropTypes.number,
  answerQuestion: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(SurveyQuestion);
