import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  withStyles,
  Grid,
  Card,
  CardContent,
  Button,
  CardActions,
  Typography,
  Icon,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import { config as brandConfig } from '@brand';

import FacilityPatientCard from './facilityPatientCard.component';
import { logEvent } from '../../../utilities/googleAnalytics';
import { hasWebcam } from '../../../utilities/hardwareUtils';
import Languages from '../../language/languages';

const { entranceId } = brandConfig;

class FacilityPatientCards extends Component {
  handleEditPatient = profile => {
    logEvent('edit_patient', {
      patientId: profile.id,
      entryId: entranceId,
      accountId: this.props.profile.id,
    });

    this.props.history.push({
      pathname: `/facilitypatient/${profile.id}`,
      state: {
        profile,
        isEdit: true,
      },
    });
  };

  handleStartVisit = profile => {
    logEvent('see_a_doctor', {
      patientId: profile.id,
      entryId: entranceId,
      accountId: this.props.profile.id,
    });

    this.props.history.push({
      pathname: `/patient/${profile.id}/visit`,
      state: {
        profile,
      },
    });
  };

  handlePreviousVisits = patient => {
    console.log('VIEWING PREVIOUS VISITS FOR', patient.id);
    logEvent('previous_visit', {
      patientId: patient.id,
      entryId: entranceId,
      accountId: this.props.profile.id,
    });
    this.props.history.push({
      pathname: `/patient/${patient.id}/priorVisits`,
    });
  };

  handleAddPatient = () => {
    this.props.history.push('/facilitypatient/create');
  };

  render() {
    const { patientSearchResults, classes, selectedLanguageKey } = this.props;

    const { strings } = Languages[selectedLanguageKey];
    const isShowAddPatientCard = !isEmpty(patientSearchResults);

    return (
      <Grid container>
        {patientSearchResults.map((patient, i) => {
          return (
            <Grid key={i} item xs={12} md={6} lg={4}>
              <FacilityPatientCard
                data={patient}
                handleEdit={this.handleEditPatient}
                handleStartVisit={this.handleStartVisit}
                handlePreviousVisits={this.handlePreviousVisits}
                isStartVisitDisabled={!hasWebcam}
              />
            </Grid>
          );
        })}

        {isShowAddPatientCard && (
          <Grid item xs={12} md={6} lg={4}>
            <Card className={classes.card}>
              <CardContent>
                <div
                  style={{ display: 'flex', cursor: 'pointer' }}
                  onClick={() => this.handleAddPatient()}
                >
                  <Typography className={classes.title} color="textSecondary">
                    {strings.addANewPatient}
                  </Typography>
                  <Icon className={classNames(['fas fa-plus', classes.addIcon])} />
                </div>
                <Typography className={classes.subText} color="textSecondary">
                  {strings.addANewPatientSubText}
                </Typography>
              </CardContent>
              <CardActions>
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleAddPatient()}
                      classes={{ root: classes.actionButtons }}
                    >
                      {strings.buttonText.addPatient}
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        )}
      </Grid>
    );
  }
}

const styles = theme => ({
  actionButton: {
    fontSize: 14,
    paddingLeft: 12,
    paddingRight: 12,
  },
  icon: {
    marginRight: '.25rem',
  },
  addIcon: {
    fontSize: '14px',
    alignSelf: 'center',
    marginLeft: '1rem',
    color: theme.palette.primary.darkGray,
  },
  card: {
    width: 350,
    height: 150,
    margin: '1rem auto',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 23,
    marginLeft: '1rem',
  },
  subText: {
    fontSize: 18,
    marginLeft: '1rem',
  },
  actionButtons: {
    width: '100%',
  },
});

FacilityPatientCards.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  patientSearchResults: PropTypes.array,
  profile: PropTypes.object,
  selectedLanguageKey: PropTypes.string.isRequired,
};

FacilityPatientCards.defaultProps = {
  patientSearchResults: [],
  profile: {},
};

const mapStateToProps = state => {
  return {
    profile: state.profile.profile,
    patientSearchResults: state.facility.patientSearchResults,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

const withRouterFacilityPatientCards = withRouter(
  connect(mapStateToProps, {})(FacilityPatientCards)
);

export default withStyles(styles, { withTheme: true })(withRouterFacilityPatientCards);
