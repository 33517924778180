import firebase from 'firebase';

import { has } from 'lodash';
import { CALL_API } from './middleware/api';
import { showToast } from './modules/layout/layout.actions';
import { getCurrentVisit, resetCurrentVisit, setCurrentVisit } from './modules/visit/visit.actions';
import visitStateHandler, { VISIT_STATES } from './utilities/visitStateHandler';

require('firebase/firestore');

const FCM_REGISTER_REQUEST = 'FCM_REGISTER_REQUEST';
const FCM_REGISTER_SUCCESS = 'FCM_REGISTER_SUCCESS';
const FCM_REGISTER_FAILURE = 'FCM_REGISTER_FAILURE';

const FCM_DEREGISTER_REQUEST = 'FCM_DEREGISTER_REQUEST';
const FCM_DEREGISTER_SUCCESS = 'FCM_DEREGISTER_SUCCESS';
const FCM_DEREGISTER_FAILURE = 'FCM_DEREGISTER_FAILURE';

const GET_FIREBASE_AUTH_TOKEN_REQUEST = 'GET_FIREBASE_AUTH_TOKEN_REQUEST';
const GET_FIREBASE_AUTH_TOKEN_SUCCESS = 'GET_FIREBASE_AUTH_TOKEN_SUCCESS';
const GET_FIREBASE_AUTH_TOKEN_FAILURE = 'GET_FIREBASE_AUTH_TOKEN_FAILURE';

export const VISIT_STATE_UPDATE = 'VISIT_STATE_UPDATE';
export const CALL_STATUS = 'CALL_STATUS';

const FCM_TOKEN_LS_KEY = 'fcmToken';

let store;

export const initializeFirebase = s => {
  store = s;

  firebase.initializeApp({
    messagingSenderId: '180827047577',
    apiKey: 'AIzaSyA2_2eSBU4I1ZOIxveMll4mAKPVYoyiqTU',
    authDomain: 'bison-notify.firebaseapp.com',
    databaseURL: 'https://bison-notify.firebaseio.com',
    projectId: 'bison-notify',
  });

  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();

    // Callback fired if Instance ID token is updated.
    messaging.onTokenRefresh(function() {
      messaging
        .getToken()
        .then(function(refreshedToken) {
          // currentToken = refreshedToken;
          console.log('Token refreshed.');
          // save token and send to app server
          sendTokenToServer(refreshedToken);
        })
        .catch(function(err) {
          console.log('Unable to retrieve refreshed token ', err);
        });
    });

    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.setBackgroundMessageHandler` handler.
    messaging.onMessage(async function(payload) {
      console.log('Message received. ', payload);

      // If this is a display notification the payload will have a notification attribute
      if (payload.notification) {
        store.dispatch(
          showToast(`${payload.notification.title}: ${payload.notification.body}`, 'notification')
        );
      }
    });
  }
};

export const fcmRegister = token => {
  return {
    [CALL_API]: {
      types: [FCM_REGISTER_REQUEST, FCM_REGISTER_SUCCESS, FCM_REGISTER_FAILURE],
      authenticated: true,
      endpoint: 'v1/fcm/register',
      method: 'POST',
      payload: {
        token,
      },
    },
  };
};

export const fcmDeregister = token => {
  return {
    [CALL_API]: {
      types: [FCM_DEREGISTER_REQUEST, FCM_DEREGISTER_SUCCESS, FCM_DEREGISTER_FAILURE],
      authenticated: true,
      endpoint: 'v1/fcm/deregister',
      method: 'POST',
      payload: {
        token,
      },
    },
  };
};

const getFirebaseAuthToken = () => {
  return {
    [CALL_API]: {
      types: [
        GET_FIREBASE_AUTH_TOKEN_REQUEST,
        GET_FIREBASE_AUTH_TOKEN_SUCCESS,
        GET_FIREBASE_AUTH_TOKEN_FAILURE,
      ],
      authenticated: true,
      endpoint: 'v1/fcm/token',
      method: 'POST',
    },
  };
};

const setFcmToken = token => {
  window.localStorage.setItem(FCM_TOKEN_LS_KEY, token);
};

const getFcmToken = () => {
  window.localStorage.getItem(FCM_TOKEN_LS_KEY);
};

const clearFcmToken = () => {
  window.localStorage.removeItem(FCM_TOKEN_LS_KEY);
};

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
const sendTokenToServer = async token => {
  console.log('Sending token to server...');
  const response = await store.dispatch(fcmRegister(token));
  if (response.type === FCM_REGISTER_SUCCESS) {
    setFcmToken(token);
  } else {
    console.log('Registering for notifications failed');
  }
};

export const requestNotificationPermission = async () => {
  try {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();

      await messaging.requestPermission();
      const token = await messaging.getToken();
      console.log('obtained fcm token: ', token);
      sendTokenToServer(token);
      return token;
    }
  } catch (error) {
    console.error(error);
  }
};

export const initFirestore = async () => {
  try {
    const result = await store.dispatch(getFirebaseAuthToken());
    if (result.type === GET_FIREBASE_AUTH_TOKEN_SUCCESS) {
      if (has(result.response, 'token')) {
        await firebase.auth().signInWithCustomToken(result.response.token);
        const user = firebase.auth().currentUser;

        // delete old messages since last login
        const db = firebase.firestore();
        const snapshot = await db
          .collection('notifications')
          .where('uid', '==', user.uid)
          .get();

        snapshot.forEach(function(doc) {
          console.log('Deleting', doc.data());
          doc.ref.delete();
        });

        db.collection('notifications')
          .where('uid', '==', user.uid)
          .onSnapshot(async snapshot => {
            const additions = [];
            snapshot.docChanges().forEach(change => {
              if (change.type === 'added') {
                additions.push(change.doc);
              }
            });
            for (const not of additions) {
              const data = not.data();
              // Do something with it
              handleFirestoreMessage(data);
              try {
                await not.ref.delete();
              } catch (err) {
                // possibly deleted from another tab/browser
              }
            }
          });
      }
    }
  } catch (e) {
    console.log('error during init firestore:', e);
  }
};

const handleFirestoreMessage = async data => {
  // If this is a data notification the payload will have a data attribute
  switch (data.type) {
    case VISIT_STATE_UPDATE:
      if (data.newState === VISIT_STATES.COMPLETED) {
        let currentVisitId = null;
        try {
          currentVisitId = store.getState().visit.currentVisit.id;
        } catch (e) {
          console.log('ERROR GETTING CURRENT VISIT ID');
        }
        console.log('CURRENT VISIT ID?', currentVisitId);
        store.dispatch(resetCurrentVisit());
        visitStateHandler.handleVisitStateChange(data.newState, data.oldState, currentVisitId);
      } else if (data.newState === VISIT_STATES.CANCELED) {
        store.dispatch(resetCurrentVisit());
        visitStateHandler.handleVisitStateChange(data.newState, data.oldState);
      } else {
        const response = await store.dispatch(getCurrentVisit());
        visitStateHandler.handleVisitStateChange(
          data.newState,
          data.oldState,
          response.response.id
        );
      }
      break;

    case CALL_STATUS: {
      const { response } = await store.dispatch(getCurrentVisit());
      store.dispatch(setCurrentVisit({ ...response, voiceState: data.status }));
      break;
    }

    default:
  }
};

export const clearNotificationRegistration = async () => {
  console.log('Deregistering for notifications...');
  const token = getFcmToken();
  if (token) {
    const response = await store.dispatch(fcmDeregister(token));
    if (response.type === FCM_DEREGISTER_SUCCESS) {
      clearFcmToken();
    } else {
      console.log('Deregistering for notifications failed');
    }
  }
};
