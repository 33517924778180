import { SET_LANGUAGE_KEY } from './language.actions';
import produce from 'immer';

const initialState = {
  selectedLanguageKey: 'en_us'
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE_KEY:
      draft.selectedLanguageKey = action.response;
      break;

    default:
      break;
  }
}, initialState);
