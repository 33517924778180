import React, { PureComponent } from 'react';
import { Redirect, Route, Switch, matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import { isNil, isEmpty, has } from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';

import { LOGIN_STATE, clearAuth, setAuth } from '../login/login.actions';
import Header, { HEADER_HEIGHT } from './header.container';
import LoginPage from '../login/login.container';
import ResizeHandler from './resizeHandler.component';
import Toast from './toast.container';
import DocumentTitleFormatter from '../../utilities/documentTitleFormatter';
import MapRouteTitle from '../../utilities/mapRouteTitle';
import facilityUtil from '../../utilities/facilityUtil';
import auth, {
  INACTIVITY_TIMEOUT_MS,
  INACTIVITY_TIMEOUT_POLLING_INTERVAL_MS,
} from '../../auth/auth';
import { setLayoutInitializing, clearLayoutBeforeUnload, getMessageBanner } from './layout.actions';
import { getEntrance } from '../entrance/entrance.actions';
import Routes from './routes/routes.component';
import { getProfile, GET_PROFILE_SUCCESS } from '../profile/profile.actions';
import {
  setShowFacilitySelection,
  setSelectedFacility,
  clearSelectedFacility,
} from '../facility/facility.actions';
import { getCurrentVisit, GET_CURRENT_VISIT_SUCCESS } from '../visit/visit.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { requestNotificationPermission, initFirestore } from '../../fcm';
import { VISIT_STATES } from '../../utilities/visitStateHandler';
import { isInTimeRange } from '../../utilities/dataUtils';
import MessageBanner from './messageBanner.component';
import FacilitySelectionModal from '../facility/facilitySelectionModal.container';
import FacilityDocumentSearchPage from '../facility/facilityDocumentSearch.container';
import LayoutUtils from '../../utilities/layoutUtils';
import { MARKETING_CONTENT_TYPES } from '../../types/marketingContentTypes';
import { isSupportedBrowser } from '../../utilities/browserUtils';
import { getMarketingContent } from '../../utilities/entranceUtils';
import UnsupportedBrowser from './unsupportedBrowser.component';
import ThemeProvider from './themeProvider.component';

import { config as brandConfig } from '@brand';

const { entranceId } = brandConfig;

class MainContainer extends PureComponent {
  constructor(props) {
    super(props);
    this._lastActivityTime = moment().valueOf();
    this._debounceActivityTimeout = null;
  }

  async componentWillMount() {
    const rawIdToken = localStorage.getItem('id_token');

    await this.props.getEntrance(entranceId);
    await this.props.setAuth(rawIdToken);
    await this.props.getMessageBanner();
  }

  componentWillReceiveProps(nextProps) {
    /* 
      Init layout when transitioning from not logged in to logged in
    */
    if (
      this.props.loginState === LOGIN_STATE.NOT_LOGGED_IN &&
      nextProps.loginState === LOGIN_STATE.LOGGED_IN
    ) {
      this.initLayout();
      this.initActivityTimer();
    } else if (this.props.isLayoutInitializing === true) {
      this.props.setLayoutInitializing(false);
    }
  }

  componentWillUnmount() {
    if (this._activityCheckInterval) clearInterval(this._activityCheckInterval);
    if (this._debounceActivityTimeout) clearTimeout(this._debounceActivityTimeout);
  }

  // kick off polling interval to check time of last user activity
  initActivityTimer = () => {
    if (this._activityCheckInterval) clearInterval(this._activityCheckInterval);

    this._activityCheckInterval = setInterval(async () => {
      const diff = moment().diff(this._lastActivityTime);
      if (
        diff > INACTIVITY_TIMEOUT_MS &&
        !LayoutUtils.checkIsActiveVisitPage(this.props.location.pathname)
      ) {
        if (this._activityCheckInterval) clearInterval(this._activityCheckInterval);
        await this.props.clearAuth();
        this.props.clearSelectedFacility();
        window.localStorage.removeItem('FACILITY_ID');
        auth.logout();
      }
    }, INACTIVITY_TIMEOUT_POLLING_INTERVAL_MS);
  };

  // debounce user activity for performance
  trackUserActivity = evt => {
    clearTimeout(this._debounceActivityTimeout);
    this._debounceActivityTimeout = setTimeout(() => {
      this._lastActivityTime = moment().valueOf();
    }, 250);
  };

  // fetch required layout data
  initLayout = async () => {
    // Setup FCM Notifications
    requestNotificationPermission();

    // Setup firestore
    initFirestore();

    // get patient profile information
    const profileResult = await this.props.getProfile();
    const selectedFacilityId = facilityUtil.getFacilityId();

    if (profileResult.type === GET_PROFILE_SUCCESS) {
      const profile = profileResult.response;
      // has profile facilities and already has one selected (returning to app after timeout, for example)
      if (
        has(profile, 'facilities') &&
        !isEmpty(profile.facilities) &&
        !isNil(selectedFacilityId)
      ) {
        // try to find facility in profile
        // if present, save in redux
        const foundFacility = profile.facilities.find(f => f.id === selectedFacilityId);
        if (!isNil(foundFacility)) {
          this.props.setSelectedFacility(foundFacility);
        }

        // if facility not found in profile, continue to load personal account
      }
    }

    // set up current visit, if available
    // setSelectedFacility based on visit if we have a current visit
    const visitResult = await this.props.getCurrentVisit();
    if (visitResult.type === GET_CURRENT_VISIT_SUCCESS) {
      const visit = visitResult.response;

      switch (visit.state) {
        case VISIT_STATES.NEW:
        case VISIT_STATES.SELECTED: {
          if (has(visit, 'facility') && !isNil(visit.facility)) {
            // validate that the user has the facility in their profile
            // if so, load the facility on the visit as the selected facility
            if (profileResult.type === GET_PROFILE_SUCCESS) {
              const profile = profileResult.response;
              if (has(profile, 'facilities') && !isEmpty(profile.facilities)) {
                if (profile.facilities.findIndex(f => f.id === visit.facility.id) > -1) {
                  this.props.setShowFacilitySelection(false);
                  await facilityUtil.setFacilityId(visit.facility.id);
                  await this.props.setSelectedFacility(visit.facility);
                }
              }
            }
          }
          this.props.history.replace('/waitingRoom');
          break;
        }

        case VISIT_STATES.READY:
        case VISIT_STATES.STARTED:
          if (has(visit, 'facility') && !isNil(visit.facility)) {
            // validate that the user has the facility in their profile
            // if so, load the facility on the visit as the selected facility
            if (profileResult.type === GET_PROFILE_SUCCESS) {
              const profile = profileResult.response;
              if (has(profile, 'facilities') && !isEmpty(profile.facilities)) {
                if (profile.facilities.findIndex(f => f.id === visit.facility.id) > -1) {
                  this.props.setShowFacilitySelection(false);
                  await facilityUtil.setFacilityId(visit.facility.id);
                  await this.props.setSelectedFacility(visit.facility);
                }
              }
            }
          }
          if (this.props.location.pathname.indexOf('visit/') === -1) {
            this.props.history.replace({ pathname: `/visit/${visitResult.response.id}` });
          }
          break;

        default:
          break;
      }
    } else if (
      this.props.history.location.pathname.indexOf('visit/') !== -1 ||
      this.props.history.location.pathname.indexOf('waitingRoom') !== -1
    ) {
      this.props.history.replace('/');
    }

    // init complete
    this.props.setLayoutInitializing(false);
  };

  shouldShowBackButton = () => {
    const { pathname } = this.props.location;
    const nonTerminalRoutes = ['/', '/users', '/logout', '/visit', '/waitingRoom'];

    let isShowBackButton = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const route of nonTerminalRoutes) {
      // Need to check for non-terminal routes any route that starts with visit (waitingRoom or videoCall)
      if (
        matchPath(pathname, {
          path: route,
          exact: true,
          strict: false,
        }) ||
        (route === '/visit' &&
          matchPath(pathname, {
            path: route,
            exact: false,
            strict: false,
          }))
      ) {
        isShowBackButton = false;
      }
    }

    // RMD-1678 back button during visit was causing inconsistent behavior
    // disable back button during visit creation
    if (pathname.indexOf('/visit') > -1) {
      isShowBackButton = false;
    }

    return isShowBackButton;
  };

  // only show message banner on certain routes
  // only show in timeframe if provided
  shouldShowMessageBanner = () => {
    const { messageBanner } = this.props;
    if (!isNil(messageBanner)) {
      let showMessageBanner = this.checkRoute(['/']);

      if (!isNil(messageBanner.startTime) && showMessageBanner === true) {
        showMessageBanner = isInTimeRange(messageBanner.startTime, messageBanner.endTime);
      }

      return showMessageBanner;
    }
    return false;
  };

  checkRoute(messageRoutes) {
    const { pathname } = this.props.location;

    let showMessageBanner = false;

    messageRoutes.forEach(route => {
      if (
        matchPath(pathname, {
          path: route,
          exact: true,
          strict: false,
        })
      ) {
        showMessageBanner = true;
      }
    });

    return showMessageBanner;
  }

  render() {
    const {
      classes,
      authSet,
      entrance,
      isLayoutInitializing,
      loginState,
      messageBanner,
    } = this.props;

    /*
      When either initializing or auth has not been set or entrance data is still empty,
      we want to have a loading overlay
    */
    if (isLayoutInitializing || !authSet || isNil(entrance)) {
      return <LoadingOverlay initialColor={brandConfig.theme.primary.main} />;
    }

    const marketingBannerText = getMarketingContent(
      entrance.entrance,
      MARKETING_CONTENT_TYPES.HOMEPAGE_BANNER
    );
    const shouldShowMessageBanner = this.shouldShowMessageBanner();

    return (
      <ThemeProvider marketingContent={entrance.entrance?.marketingContent}>
        {!isSupportedBrowser && <UnsupportedBrowser />}
        {isSupportedBrowser && (
          <div
            className={classes.contentWrapper}
            onKeyDown={this.trackUserActivity}
            onMouseMove={this.trackUserActivity}
          >
            {loginState === LOGIN_STATE.LOGGED_IN ? (
              <DocumentTitle title={DocumentTitleFormatter(MapRouteTitle(this.props.location))}>
                <>
                  <ResizeHandler />
                  <Header shouldShowBackButton={this.shouldShowBackButton()} />
                  <main className={classes.content}>
                    {shouldShowMessageBanner && (
                      <MessageBanner message={messageBanner.text} type={messageBanner.state} />
                    )}
                    {!isEmpty(marketingBannerText) && this.checkRoute(['/']) && (
                      <div style={shouldShowMessageBanner ? { marginTop: '.5rem' } : {}}>
                        <MessageBanner
                          message={marketingBannerText[0].bannerText}
                          type={marketingBannerText[0].bannerColor}
                        />
                      </div>
                    )}
                    <Routes loginState={loginState} />
                  </main>
                </>
              </DocumentTitle>
            ) : (
              <Switch>
                <Route exact path="/login" render={props => <LoginPage {...props} />} />
                <Route
                  exact
                  path="/documents"
                  render={props => <FacilityDocumentSearchPage {...props} />}
                />
                <Redirect from="*" to="/login" />
              </Switch>
            )}
            <Toast />
            <FacilitySelectionModal
              handleClose={() => this.props.setShowFacilitySelection(false)}
            />
          </div>
        )}
      </ThemeProvider>
    );
  }
}

const styles = theme => ({
  contentWrapper: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '100vh',
    position: 'relative',
    zIndex: 1,
  },
  content: {
    backgroundColor: theme.palette.primary.background,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: HEADER_HEIGHT,
    overflow: 'auto',
  },
});

MainContainer.contextTypes = {
  router: PropTypes.object,
};

MainContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  authSet: PropTypes.bool.isRequired,
  children: PropTypes.node,
  entrance: PropTypes.object,
  isLayoutInitializing: PropTypes.bool.isRequired,
  loginState: PropTypes.number.isRequired,
  messageBanner: PropTypes.object,

  getCurrentVisit: PropTypes.func.isRequired,
  getEntrance: PropTypes.func.isRequired,
  getMessageBanner: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired,
  setLayoutInitializing: PropTypes.func.isRequired,
  setSelectedFacility: PropTypes.func.isRequired,
  setShowFacilitySelection: PropTypes.func.isRequired,
};

MainContainer.defaultProps = {
  children: null,
  entrance: null,
  messageBanner: {},
};

const mapStateToProps = state => {
  const { layout, login, entrance } = state;

  return {
    authSet: login.authSet,
    entrance,
    isLayoutInitializing: layout.isInitializing,
    loginState: login.loginState,
    messageBanner: layout.messageBanner,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    clearAuth,
    clearLayoutBeforeUnload,
    clearSelectedFacility,
    getCurrentVisit,
    getEntrance,
    getMessageBanner,
    getProfile,
    setAuth,
    setLayoutInitializing,
    setSelectedFacility,
    setShowFacilitySelection,
  })
)(MainContainer);
