import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, IconButton, withStyles } from '@material-ui/core';
import { MicOff, Mic, VideocamOff, Videocam } from '@material-ui/icons';
import VisitUtils from '../../utilities/visitUtils';

const VideoCallControls = ({ classes, room }) => {
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);

  const toggleAudioMute = useCallback(() => {
    VisitUtils.toggleAudioMute(room, !isAudioMuted);
    setIsAudioMuted(!isAudioMuted);
  }, [isAudioMuted, room, setIsAudioMuted]);

  const toggleVideoMute = useCallback(() => {
    VisitUtils.toggleVideoMute(room, !isVideoMuted);
    setIsVideoMuted(!isVideoMuted);
  }, [isVideoMuted, room, setIsVideoMuted]);

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          classes={{ root: classes.actionButton }}
          key="mute"
          aria-label="Mute"
          color="inherit"
          onClick={toggleAudioMute}
        >
          {isAudioMuted ? <MicOff /> : <Mic />}
        </IconButton>
        <IconButton
          classes={{ root: classes.actionButton }}
          key="muteVideo"
          aria-label="Mute Video"
          color="inherit"
          onClick={toggleVideoMute}
        >
          {isVideoMuted ? <VideocamOff /> : <Videocam />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

const styles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  toolbar: {
    justifyContent: 'center',
  },

  actionButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.darkGray,
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.darkGray,
      color: theme.palette.common.white,
    },
  },
});

VideoCallControls.propTypes = {
  classes: PropTypes.object.isRequired,

  room: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoCallControls);
