/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Email, Lock, Visibility } from '@material-ui/icons';
import { has, isEmpty, isNil } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { config as brandConfig } from '@brand';

import auth from '../../auth/auth';
import { setAuth } from './login.actions';
import CustomDialog from '../../common/customDialog/customDialog.component';
import Languages from '../language/languages';
import { logEvent } from '../../utilities/googleAnalytics';
import { GET_PROFILE_SUCCESS, getProfile } from '../profile/profile.actions';
import facilityUtil from '../../utilities/facilityUtil';
import { setShowFacilitySelection } from '../facility/facility.actions';
import { GET_CURRENT_VISIT_SUCCESS, getCurrentVisit } from '../visit/visit.actions';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: null,
      password: '',
      isLoading: false,
      isShowTerms: false,
      isShowPrivacy: false,
    };
  }

  handleLogin = (evt) => {
    evt.preventDefault();
    const { email, password } = this.state;

    this.setState({
      isLoading: true,
    });

    auth.login(
      email,
      password,
      async (auth) => {
        await this.props.setAuth(auth);
        const selectedFacilityId = facilityUtil.getFacilityId();

        // check profile for facilities and show prompt if needed
        const profileResult = await this.props.getProfile();

        // check for current visit before showing facility selection
        let hasCurrentVisit = false;
        const visitResult = await this.props.getCurrentVisit();
        if (visitResult.type === GET_CURRENT_VISIT_SUCCESS) {
          hasCurrentVisit = has(visitResult, 'response.id');
        }

        if (profileResult.type === GET_PROFILE_SUCCESS) {
          const profile = profileResult.response;
          if (
            has(profile, 'facilities') &&
            !isEmpty(profile.facilities) &&
            isNil(selectedFacilityId) &&
            !hasCurrentVisit
          ) {
            // show facility selection if facilities are available in profile and none are selected.
            // do not show if there is a selected visit
            this.props.setShowFacilitySelection(true);
          }
        }

        logEvent('login');
      },
      () => {
        this.setState({
          error: Languages[this.props.selectedLanguageKey].strings.showToastMessages.loginFailure,
          isLoading: false,
        });
      },
    );
  };

  handleChange = (field) => (event) => {
    const updateState = { ...this.state };
    updateState[field] = event.target.value;

    // clear error state on edit
    updateState.error = null;

    this.setState(updateState);
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    const {
      classes,
      handleToggleCreateForm,
      handleToggleForgotPassword,
      selectedLanguageKey,
    } = this.props;
    const { email, password, showPassword, error, isLoading } = this.state;
    const language = Languages[selectedLanguageKey].strings;

    return (
      <div className={classes.content}>
        <form className={classes.loginForm} onSubmit={this.handleLogin}>
          <TextField
            id="outlined-email-input"
            label={language.inputLabels.email}
            className={classes.textField}
            type="email"
            name="email"
            autoComplete="email"
            margin="normal"
            variant="outlined"
            value={email}
            onChange={this.handleChange('email')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email className={classes.darkGray} />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            autoComplete="current-password"
            className={classes.textField}
            id="outlined-password-input"
            label={language.inputLabels.password}
            margin="normal"
            onChange={this.handleChange('password')}
            type={showPassword ? 'text' : 'password'}
            value={password}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock className={classes.darkGray} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                  >
                    <Visibility className={classes.darkGray} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.loginActionsContainer}>
            <div className={classes.forgotPasswordContainer}>
              {/* FORGOT PASSWORD */}
              <Link
                className={classes.forgotPassword}
                component="button"
                type="button"
                variant="body2"
                onClick={() => handleToggleForgotPassword()}
              >
                {language.forgotPassword}
              </Link>
            </div>
            <div className={classes.loginBtnContainer}>
              <Button
                className={classes.loginButton}
                disabled={isEmpty(password) || isEmpty(email) || isLoading}
                onClick={this.handleLogin}
                type="submit"
                variant="outlined"
              >
                {!isLoading ? language.buttonText.login : language.buttonText.loading}
              </Button>
            </div>
          </div>

          {!isNil(error) && (
            <Typography className={classes.errorText}>{language.incorrectLogin}</Typography>
          )}
        </form>

        {/* FINE PRINT */}
        <div className={classes.finePrintContainer}>
          <Typography className={classes.finePrint}>
            {`${language.createAccountFinePrint.clickingLogin} `}
            <Link
              className={classes.darkGray}
              component="button"
              variant="body2"
              onClick={() => this.setState({ isShowTerms: true })}
            >
              {`${language.createAccountFinePrint.termsOfUse}`}
            </Link>
            {` ${language.createAccountFinePrint.and} `}
            <Link
              className={classes.darkGray}
              component="button"
              variant="body2"
              onClick={() => this.setState({ isShowPrivacy: true })}
            >
              {`${language.createAccountFinePrint.privacyPolicy}.`}
            </Link>
            {language.loginDisclaimer && (
              <>
                <br />
                <span
                  className={classes.loginDisclaimer}
                  dangerouslySetInnerHTML={{ __html: language.loginDisclaimer }}
                />
              </>
            )}
          </Typography>
        </div>

        {/* CREATE ACCOUNT */}
        <div className={classes.createAccountContainer}>
          <Typography className={classes.createAccount}>
            {language.loginDontHaveAnAccount}
          </Typography>
          <Link
            className={classes.textLink}
            component="button"
            variant="body2"
            onClick={() => handleToggleCreateForm()}
          >
            {language.loginCreateAnAccount}
          </Link>
        </div>

        {/* FACILITATOR DOCUMENT SEARCH */}
        <div className={classes.docSearch}>
          <Link
            className={classes.textLink}
            component="button"
            variant="body2"
            onClick={() => this.props.history.push('/documents')}
          >
            {language.documentSearch}
          </Link>
        </div>
        <CustomDialog
          fullScreen
          open={this.state.isShowTerms}
          handleClose={() => this.setState({ isShowTerms: false })}
          cancelText="Close"
          cancelOnly
          title={language.createAccountFinePrint.termsOfUse}
          content={
            <iframe
              title={language.createAccountFinePrint.termsOfUse}
              width="100%"
              height="100%"
              frameBorder={0}
              src={brandConfig.termsUrl}
            />
          }
        />
        <CustomDialog
          fullScreen
          open={this.state.isShowPrivacy}
          handleClose={() => this.setState({ isShowPrivacy: false })}
          cancelText="Close"
          cancelOnly
          title={language.createAccountFinePrint.privacyPolicy}
          content={
            <iframe
              title={language.createAccountFinePrint.privacyPolicy}
              width="100%"
              height="100%"
              frameBorder={0}
              src={brandConfig.privacyPolicyUrl}
            />
          }
        />
      </div>
    );
  }
}

const loginContainerMinWidth = 280;
const loginContainerWidth = '65%';

const styles = (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '2rem',
  },
  textField: {
    minWidth: loginContainerMinWidth,
    width: loginContainerWidth,
    margin: '1rem auto 0.5rem auto',
    color: theme.palette.primary.darkGray,
  },
  loginActionsContainer: {
    margin: '.8rem auto .5rem',
    minWidth: loginContainerMinWidth,
    width: loginContainerWidth,
    display: 'flex',
    alignItems: 'center',
  },
  forgotPasswordContainer: {
    flex: 1,
    textAlign: 'left',
  },
  loginBtnContainer: {
    flex: '1',
    textAlign: 'right',
    marginLeft: '1.5rem',
  },
  loginButton: {
    color: theme.palette.common.white,
    width: 175,
    padding: '.6rem 1rem',
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    '&:disabled': {
      color: theme.palette.primary.darkGray,
      backgroundColor: theme.palette.primary.lightGray,
    },
  },
  forgotPassword: {
    color: theme.palette.primary.darkGray,
    display: 'inline-block',
    textDecoration: 'underline',
  },
  darkGray: {
    color: theme.palette.primary.darkGray,
  },
  errorText: {
    color: theme.palette.error.main,
    margin: '1rem auto',
  },
  finePrintContainer: {
    minWidth: loginContainerMinWidth,
    width: loginContainerWidth,
    margin: '.3rem auto 0',
    textAlign: 'center',
    fontSize: '.8rem',
  },
  loginDisclaimer: {
    fontStyle: 'italic',
    margin: '.3rem 0 0',
    display: 'block',
  },
  finePrint: {
    color: theme.palette.primary.darkGray,
    margin: '1rem auto',
  },
  createAccount: {
    color: theme.palette.primary.darkGray,
    marginRight: '0.5rem',
  },
  textLink: {
    color: theme.palette.primary.darkGray,
    textDecoration: 'underline',
  },
  docSearch: {
    display: 'flex',
    margin: 'auto',
  },
  createAccountContainer: {
    display: 'flex',
    margin: '2rem auto',
  },
});

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  getCurrentVisit: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  handleToggleCreateForm: PropTypes.func.isRequired,
  handleToggleForgotPassword: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired,
  setShowFacilitySelection: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setAuth,
    getProfile,
    setShowFacilitySelection,
    getCurrentVisit,
  }),
)(LoginForm);
