import { cancelVisit, getCurrentVisitProvider } from '../visit/visit.actions';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import ProviderInfo from './providerInfo.component';
import React, { Component } from 'react';
import CustomDialog from '../../common/customDialog/customDialog.component';
import { withStyles, CircularProgress } from '@material-ui/core';
import Languages from '../language/languages';

class ProviderDialog extends Component {
  async componentWillReceiveProps(nextProps) {
    if (!this.props.open && nextProps.open) await this.props.getCurrentVisitProvider(this.props.providerId);
  }

  render() {
    const { provider, isStartingVisit, open, onVisitStart, onClose, selectedLanguageKey } = this.props;

    return (
      <CustomDialog
        open={open}
        handleClose={onClose}
        content={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {!isNil(provider) ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ProviderInfo
                  photoUrl={provider.photoUrl}
                  bio={provider.bio}
                  firstName={provider.firstName}
                  lastName={provider.lastName}
                  university={provider.university}
                  displayName={provider.displayName}
                />
              </div>
            ) : (
              <CircularProgress style={{ margin: '2rem 0' }} size={50} />
            )}
          </div>
        }
        title="We're ready to see you now"
        handleAction={onVisitStart}
        actionText={
          isStartingVisit
            ? Languages[selectedLanguageKey].strings.buttonText.starting
            : Languages[selectedLanguageKey].strings.buttonText.startVisit
        }
      />
    );
  }
}

const styles = theme => ({});

ProviderDialog.propTypes = {
  getCurrentVisitProvider: PropTypes.func.isRequired,
  isStartingVisit: PropTypes.bool.isRequired,
  onVisitStart: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  provider: PropTypes.object,
  providerId: PropTypes.string,
  selectedLanguageKey: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    isStartingVisit: state.visit.isStartingVisit,
    provider: state.visit.provider,
    selectedLanguageKey: state.language.selectedLanguageKey
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    {
      cancelVisit,
      getCurrentVisitProvider
    }
  )(ProviderDialog)
);
