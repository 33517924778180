import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Typography, Grid, Button, withStyles, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { isNil } from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';

import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import {
  getSurveyQuestions,
  sendSurveyAnswers,
  SEND_SURVEY_ANSWERS_SUCCESS,
} from './survey.actions';
import SurveyQuestion from './surveyQuestion.component';
import { setPageTitle, showToast } from '../layout/layout.actions';
import TOAST_TYPES from '../../types/toastTypes';
import Languages from '../language/languages';
import { logEvent } from '../../utilities/googleAnalytics';

import { config as brandConfig } from '@brand';

const { entranceId } = brandConfig;

/*
[ { text: 'How would you rate your experience with the doctor?',
    choices: [ 'Very Poor', 'Poor', 'Neutral', 'Good', 'Excellent' ] },
  { text: 'How would you rate your experience using this app?',
    choices: [ 'Very Poor', 'Poor', 'Neutral', 'Good', 'Excellent' ] },
  { text: 'How likely is it that you would use this service again?',
    choices: [ 'Very Unlikely', 'Unlikely', 'Unsure', 'Likely', 'Very Likely' ] },
  { text: 'If you had not been online to see a Provider today, what would have been your next choice in seeking medical care?',
    choices: 
     [ 'Gone to the emergency department',
       'Gone to an urgent care facility',
       'Waited for an appointment with my doctor',
       'Would not have sought medical care',
       'None of the above' ] } ]
*/

class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [],
    };
  }

  async componentWillMount() {
    this.props.setPageTitle(Languages[this.props.selectedLanguageKey].strings.pageTitles.survey);

    const { visitId } = this.props.match.params;
    await this.props.getSurveyQuestions(visitId);

    const answers = [];
    if (!isNil(this.props.survey)) {
      this.props.survey.forEach(() => {
        answers.push(-1);
      });
    }

    this.setState({ answers });
  }

  handleAnswerQuestion = (index, answerIndex) => {
    const answers = [...this.state.answers];

    answers[index] = answerIndex;
    this.setState({ answers });
  };

  handleSubmit = async () => {
    // alert(`ANSWERS: ${JSON.stringify(this.state.answers)}`);
    const { visitId } = this.props.match.params;
    const response = await this.props.sendSurveyAnswers(visitId, this.state.answers);

    if (response.type === SEND_SURVEY_ANSWERS_SUCCESS) {
      logEvent('survey', {
        patientId: this.props.profile.id,
        entryId: entranceId,
        accountId: this.props.profile.id,
      });
      this.props.history.replace('/', { isReloadProfile: true });
    } else {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.saveSurveyFailure,
        TOAST_TYPES.ERROR,
        true,
        null,
        response.messages[0]
      );
    }
  };

  render() {
    const { classes, isLoading, survey, isSending, selectedLanguageKey } = this.props;
    const language = Languages[selectedLanguageKey].strings;

    if (isLoading) return <LoadingOverlay />;

    return (
      <div className={classes.container}>
        <Grid container justify="center">
          <Grid item xs={12} sm={8}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={8}>
                <Typography className={classes.surveyTitle} variant="h5">
                  {language.saveSurveyInfo}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  onClick={() => this.props.history.replace('/', { isReloadProfile: true })}
                  className={classes.removeButton}
                >
                  <Close style={{ height: 25, width: 25 }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          {survey &&
            survey.map((question, i) => {
              return (
                <Grid item xs={12} sm={8} key={i}>
                  <SurveyQuestion
                    question={question}
                    index={i}
                    selectedAnswerIndex={this.state.answers[i]}
                    answerQuestion={this.handleAnswerQuestion}
                  />
                </Grid>
              );
            })}
          <Grid item xs={12} sm={8}>
            <Button
              className={classes.submitButton}
              disabled={isSending}
              fullWidth
              variant="text"
              onClick={this.handleSubmit}
            >
              {isSending ? language.buttonText.submitting : language.buttonText.submitSurvey}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.primary.background,
    flex: 1,
    padding: '1rem',
    paddingBottom: '3rem',
    margin: '0 auto',
  },
  removeButton: {
    float: 'right',
  },
  surveyTitle: {
    color: theme.palette.primary.darkGray,
    marginBottom: '0.5rem',
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    paddingLeft: 18,
    paddingRight: 18,
    marginLeft: 'auto',
  },
});

Survey.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  isLoading: PropTypes.bool,
  isSending: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,
  survey: PropTypes.array,

  getSurveyQuestions: PropTypes.func.isRequired,
  sendSurveyAnswers: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

Survey.defaultProps = {
  isLoading: false,
  isSending: false,
  survey: null,
};

const mapStateToProps = state => {
  return {
    isLoading: state.survey.isLoading,
    isSending: state.survey.isSending,
    profile: state.profile.profile,
    selectedLanguageKey: state.language.selectedLanguageKey,
    survey: state.survey.survey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    getSurveyQuestions,
    sendSurveyAnswers,
    setPageTitle,
    showToast,
  })
)(Survey);
