/* eslint-disable import/prefer-default-export */
export const MARKETING_CONTENT_TYPES = {
  BRAND_ERROR_COLOR: 'BRAND_ERROR_COLOR',
  BRAND_PRIMARY_COLOR: 'BRAND_PRIMARY_COLOR',
  BRAND_PRIMARY_CONTRAST_COLOR: 'BRAND_PRIMARY_CONTRAST_COLOR',
  BRAND_PRIMARY_DARK_COLOR: 'BRAND_PRIMARY_DARK_COLOR',
  BRAND_SECONDARY_COLOR: 'BRAND_SECONDARY_COLOR',
  BRAND_SUCCESS_COLOR: 'BRAND_SUCCESS_COLOR',
  BRAND_WARNING_COLOR: 'BRAND_WARNING_COLOR',
  DESKTOP_HOME: 'DESKTOP_HOME',
  DESKTOP_WAITING_ROOM: 'DESKTOP_WAITING_ROOM',
  DESKTOP_VISIT_LEGAL: 'DESKTOP_VISIT_LEGAL',
  DESKTOP_LOGIN: 'DESKTOP_LOGIN',
  DESKTOP_WHITE_LOGO: 'DESKTOP_WHITE_LOGO',
  DESKTOP_GREY_LOGO: 'DESKTOP_GREY_LOGO',
  MOBILE_WAITING_ROOM: 'MOBILE_WAITING_ROOM',
  LOGIN_BANNER: 'LOGIN_BANNER',
  HOMEPAGE_BANNER: 'HOMEPAGE_BANNER',
};
