/* eslint-disable class-methods-use-this */
import { isNil } from 'lodash';
import { clearSelectedFacility } from '../modules/facility/facility.actions';
import getStore from '../store/store';

const store = getStore();

export const FACILITY_ID = 'FACILITY_ID';

class FacilityUtil {
  setFacilityId(id) {
    if (!isNil(id)) {
      localStorage.setItem(FACILITY_ID, id);
    } else {
      // if facility is null or does not contain required fields, clear facility from ls
      this.clearFacilityId();
    }
  }

  clearFacilityId() {
    localStorage.removeItem(FACILITY_ID);
    store.dispatch(clearSelectedFacility());
  }

  getFacilityId() {
    const id = localStorage.getItem(FACILITY_ID);
    return !isNil(id) ? id : null;
  }
}

export default new FacilityUtil();
