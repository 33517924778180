import React, { Component } from 'react';
import { withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import ReactDOM from 'react-dom';

class SelectInputValidator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      isValid: true,
      helperText: '',
      labelWidth: 0,
    };
  }

  componentDidMount() {
    if (this.props.forceValidation === true) {
      this.runValidators(this.props.value, true);
    }

    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.forceValidation === true) {
      this.runValidators(nextProps.value, true);
    }
  }

  handleChange = event => {
    const updateValue = event.target.value;

    this.setState(
      {
        isDirty: true,
      },
      () => this.runValidators(updateValue, true)
    );

    this.props.onChange(updateValue);
  };

  runValidators = (value, forceValidation) => {
    // run validators if provided and field is dirty (has been blurred)
    if (
      (this.props.validators && this.props.validators.length > 0 && this.state.isDirty) ||
      forceValidation
    ) {
      const errors = this.props.validators
        .map(validator => {
          return validator(value, this.props.label);
        })
        .filter(err => {
          return err !== false;
        });

      if (errors && errors.length > 0) {
        if (this.props.onValidationChange) this.props.onValidationChange(errors[0]);
        this.setState({
          isValid: false,
          isDirty: true,
          helperText: errors[0],
        });
      } else {
        if (this.props.onValidationChange && this.state.isValid !== true)
          this.props.onValidationChange(null);
        this.setState({
          isValid: true,
          helperText: '',
        });
      }
    }
  };

  render() {
    const { label, value, onChange, options, ...rest } = this.props;
    const { isValid, helperText, isDirty, labelWidth } = this.state;

    delete rest.onValidationChange;

    return (
      <FormControl variant="outlined" {...rest}>
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor="outlined-state-simple"
        >
          {label}
        </InputLabel>
        <Select
          value={value}
          onChange={this.handleChange}
          input={
            <OutlinedInput
              error={isDirty && !isValid}
              labelWidth={labelWidth}
              name="state"
              id="outlined-state-simple"
            />
          }
          labelWidth={labelWidth}
        >
          <MenuItem disabled value="" />
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && helperText !== '' && (
          <InputAdornment style={{ paddingTop: 12, paddingLeft: 12, marginBottom: 12 }}>
            {helperText}
          </InputAdornment>
        )}
      </FormControl>
    );
  }
}

SelectInputValidator.propTypes = {
  label: PropTypes.string.isRequired,
  forceValidation: PropTypes.bool,
  options: PropTypes.array.isRequired,
  validators: PropTypes.array,
  value: PropTypes.any,

  onChange: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
};

SelectInputValidator.defaultProps = {
  forceValidation: false,
  validators: [],
  value: '',
};

export default withTheme()(SelectInputValidator);
