/* eslint-disable import/prefer-default-export */
import { has, isNil } from 'lodash';

export const getMarketingContent = (entrance, contentKey) => {
  if (
    has(entrance, 'marketingContent') &&
    entrance.marketingContent &&
    !isNil(entrance.marketingContent[contentKey])
  ) {
    return entrance.marketingContent[contentKey];
  }

  return [];
};
