import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TwilioVideo from 'twilio-video';
import { isEmpty, isNil } from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import classNames from 'classnames';

import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { setPageTitle as setPageTitleAction } from '../layout/layout.actions';
import {
  getTwilioVideoRoomAuth as getTwilioVideoRoomAuthAction,
  resetTwilioVideoRoomAuth as resetTwilioVideoRoomAuthAction,
} from './videoCall.actions';
import Languages from '../language/languages';
import {
  getCurrentVisit as getCurrentVisitAction,
  GET_CURRENT_VISIT_FAILURE,
} from '../visit/visit.actions';
import visitStateHandler, { CALL_STATES, VISIT_STATES } from '../../utilities/visitStateHandler';
import VideoStatus from './videoStatus.component';
import VideoCallControls from './videoCallControls.component';
import { TRACK_TYPES } from '../../types/visitTrackTypes';

const LOCAL_MEDIA_HEIGHT = 500;
const LOCAL_MEDIA_RATIO = 4 / 3;

const MEDIA_IDS = {
  LOCAL_AUDIO: 'local-audio',
  LOCAL_MEDIA: 'local-media',
  LOCAL_VIDEO: 'local-video',
  PARTICIPANT_VIDEO: 'participant-video',
  PARTICIPANT_MEDIA: 'participant-media',
  MEDIA_CONTAINER: 'media-container',
};

class VideoCallContainer extends Component {
  state = {
    hasRetry: false,
    hasParticipants: false,
    isConnected: false,
    isConnectedMobile: false,
    isConnecting: false,
    prevCallState: null,
  };

  async componentDidMount() {
    const { match, selectedLanguageKey, getTwilioVideoRoomAuth, setPageTitle } = this.props;

    setPageTitle(Languages[selectedLanguageKey].strings.pageTitles.patientVisit);
    getTwilioVideoRoomAuth(match.params.visitId);

    window.addEventListener('focus', this.handleGetCurrentVisit);
  }

  componentDidUpdate(/* prevProps */) {
    const { currentVisit, twilioVideoAuth } = this.props;
    const { isConnected, isConnecting } = this.state;

    if (
      !isNil(currentVisit) &&
      currentVisit.voiceState &&
      this.localVideoTrack &&
      this.localAudioTrack
    ) {
      this.handleDisconnect();

      const localMedia = document.getElementById(MEDIA_IDS.LOCAL_MEDIA);
      if (!isNil(localMedia)) localMedia.parentNode.removeChild(localMedia);

      const participantMedia = document.getElementById(MEDIA_IDS.PARTICIPANT_MEDIA);
      if (!isNil(participantMedia)) participantMedia.parentNode.removeChild(participantMedia);
    }

    if (isNil(currentVisit) || isNil(twilioVideoAuth) || currentVisit.isVoiceCall) return;

    if (!isConnected && !isConnecting) this.connectToRoom();
  }

  componentWillUnmount() {
    if (this.state.isConnected) {
      this.handleDisconnect();
    }

    this.props.resetTwilioVideoRoomAuth();

    window.removeEventListener('focus', this.handleGetCurrentVisit);
  }

  handleGetCurrentVisit = async () => {
    const { prevCallState } = this.state;
    const { currentVisit, history, match, getCurrentVisit } = this.props;
    const { type } = await getCurrentVisit();

    // 1. If transitioning from STARTED or READY to empty visit is equivalent to a COMPLETED status
    // 2. If response is not STARTED, READY, or COMPLETED, let the state handler take care of it
    // 3. If response is undefined, this means the visit was completed and is a catch
    if (
      (prevCallState === VISIT_STATES.STARTED || prevCallState === VISIT_STATES.READY) &&
      isEmpty(currentVisit)
    ) {
      visitStateHandler.handleVisitStateChange(VISIT_STATES.COMPLETED, null, match.params.visitId);
    } else if (
      !isEmpty(currentVisit) &&
      currentVisit.state !== VISIT_STATES.STARTED &&
      currentVisit.state !== VISIT_STATES.READY
    ) {
      visitStateHandler.handleVisitStateChange(
        currentVisit.state,
        prevCallState,
        match.params.visitId
      );
    } else if (type === GET_CURRENT_VISIT_FAILURE) {
      history.replace('/');
    }

    this.setState({
      prevCallState: currentVisit ? currentVisit.state : null,
    });
  };

  handleDisconnect = () => {
    try {
      console.log('disconnecting room');
      try {
        this.localVideoTrack.stop();
        this.localVideoTrack.detach();
        this.localAudioTrack.stop();
        this.localAudioTrack.detach();

        this.room.localParticipant.unpublishTrack(this.localVideoTrack);
        this.room.localParticipant.unpublishTrack(this.localAudioTrack);

        this.room.disconnect();

        // TODO: need to remove the video and audio elements from the localMediaContainer, and possibly remove the participant ones when they disconnect
        // or reconnect
      } catch (e) {
        console.log('ERROR DISCONNECTING', e);
      }
    } catch (e) {
      console.log('e!', e);
    }
  };

  connectToRoom = () => {
    const {
      classes,
      match: {
        params: { visitId },
      },
      getCurrentVisit,
      twilioVideoAuth,
    } = this.props;

    this.setState({ isConnecting: true });

    TwilioVideo.connect(twilioVideoAuth.token, {
      name: visitId,
      tracks: [],
    }).then(
      room => {
        this.room = room;
        this.setState({ isConnected: true, isConnecting: false });
        // console.log(`Successfully joined a Room: ${room}`, room);

        const participantConnected = participant => {
          console.log('Participant "%s" connected', participant.identity);

          const container = document.getElementById(MEDIA_IDS.PARTICIPANT_MEDIA);
          const div = document.createElement('div');
          div.setAttribute('class', classes.participantVideo);
          div.id = participant.sid;

          participant.on('trackSubscribed', track => trackSubscribed(div, track));
          participant.on('trackUnsubscribed', trackUnsubscribed);

          participant.tracks.forEach(publication => {
            if (publication.isSubscribed) {
              trackSubscribed.bind(this, div, publication.track);
            }
          });

          if (container) {
            container.prepend(div);
          }

          this.setState({ hasParticipants: true });
        };

        const participantDisconnected = participant => {
          console.log('participant disconnected', participant);
          console.log('Participant "%s" disconnected', participant.identity);

          const participantElem = document.getElementById(participant.sid);
          if (participantElem) {
            participantElem.remove();
            this.setState({ isConnectedMobile: true });
          }

          this.setState({ hasParticipants: false }, getCurrentVisit);
        };

        const trackSubscribed = (div, track) => {
          div.appendChild(track.attach());
          const elements = div.getElementsByTagName(TRACK_TYPES.VIDEO);

          if (elements.length > 0) {
            elements[0].id = MEDIA_IDS.PARTICIPANT_VIDEO;
          }
        };

        const trackUnsubscribed = track => {
          track.detach().forEach(element => element.remove());
        };

        room.localParticipant.tracks.forEach(track => {
          room.localParticipant.unpublishTrack(track.track);
        });

        TwilioVideo.createLocalVideoTrack().then(track => {
          const localMediaContainer = document.getElementById(MEDIA_IDS.LOCAL_MEDIA);
          if (localMediaContainer) {
            localMediaContainer.appendChild(track.attach());

            const elements = localMediaContainer.getElementsByTagName(TRACK_TYPES.VIDEO);
            if (elements.length > 0) {
              elements[0].id = MEDIA_IDS.LOCAL_VIDEO;
              elements[0].width = LOCAL_MEDIA_HEIGHT * LOCAL_MEDIA_RATIO;
              elements[0].height = LOCAL_MEDIA_HEIGHT;
            }

            this.localVideoTrack = track;
            room.localParticipant.publishTrack(track);
          }
        });

        TwilioVideo.createLocalAudioTrack().then(track => {
          const localMediaContainer = document.getElementById(MEDIA_IDS.LOCAL_MEDIA);
          if (localMediaContainer) {
            localMediaContainer.appendChild(track.attach());

            const elements = localMediaContainer.getElementsByTagName(TRACK_TYPES.AUDIO);
            if (elements.length > 0) {
              elements[0].id = MEDIA_IDS.LOCAL_AUDIO;
            }

            this.localAudioTrack = track;
            room.localParticipant.publishTrack(track);
          }
        });

        room.participants.forEach(participantConnected);
        room.on('participantConnected', participantConnected);

        room.on('participantDisconnected', participantDisconnected);
        room.once('disconnected', (/* error */) =>
          room.participants.forEach(participantDisconnected));
      },
      error => {
        console.error(`Unable to connect to Room: ${error.message}`);

        if (!this.state.hasRetry) {
          this.setState({ hasRetry: true }, this.connectToRoom);
          return;
        }

        this.setState({ isConnecting: false });
      }
    );
  };

  handleRetry = () => this.setState({ isConnecting: true }, this.connectToRoom);

  render() {
    const { classes, currentVisit, selectedLanguageKey } = this.props;
    const { hasParticipants, hasRetry, isConnected, isConnectedMobile, isConnecting } = this.state;

    const isVoiceCall = !isNil(currentVisit) && currentVisit.isVoiceCall;

    if ((isConnecting && !isVoiceCall) || isNil(currentVisit)) return <LoadingOverlay />;

    return (
      <div className={classes.videoContainer}>
        <div id={MEDIA_IDS.MEDIA_CONTAINER} className={classes.mediaContainer}>
          <div
            id={MEDIA_IDS.PARTICIPANT_MEDIA}
            className={classNames(classes.participantMedia, {
              [classes.mediaFlex]: !isVoiceCall && hasParticipants,
            })}
          />
          <div id={MEDIA_IDS.LOCAL_MEDIA} className={classes.localMedia} />
          <VideoStatus
            hasNoAnswer={currentVisit.voiceState === CALL_STATES.NO_ANSWER}
            hasParticipants={hasParticipants}
            hasRetry={hasRetry}
            isConnected={isConnected}
            isConnectedMobile={isConnectedMobile}
            isConnecting={isConnecting}
            isVoiceCall={isVoiceCall}
            selectedLanguageKey={selectedLanguageKey}
            onRetry={this.handleRetry}
          />
        </div>
        {isConnected && !isVoiceCall && <VideoCallControls room={this.room} />}
      </div>
    );
  }
}

const styles = theme => ({
  mediaContainer: {
    display: 'flex',
    height: '100%',
    maxHeight: 'calc(100vh - 112px)',
  },
  localMedia: {
    position: 'absolute',
    top: '8rem',
    '& > video': {
      height: '50%',
      objectFit: 'contain',
      width: '50%',
    },
  },
  participantContainer: {
    display: 'flex',
    flex: 1,
  },
  participantMedia: {
    fontSize: 0,
    position: 'relative',
  },
  participantVideo: {
    height: '100%',
    '& > video': {
      height: '100%',
      maxWidth: '100vw',
      objectFit: 'contain',
      width: '100%',
    },
  },
  mediaFlex: {
    flex: 1,
  },
  videoContainer: {
    backgroundColor: theme.palette.primary.background,
    flex: 1,
  },
});

VideoCallContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  currentVisit: PropTypes.object,
  selectedLanguageKey: PropTypes.string.isRequired,
  twilioVideoAuth: PropTypes.object,

  getCurrentVisit: PropTypes.func.isRequired,
  getTwilioVideoRoomAuth: PropTypes.func.isRequired,
  resetTwilioVideoRoomAuth: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
};

VideoCallContainer.defaultProps = {
  currentVisit: {},
  twilioVideoAuth: null,
};

const mapStateToProps = state => {
  return {
    currentVisit: state.visit.currentVisit,
    selectedLanguageKey: state.language.selectedLanguageKey,
    twilioVideoAuth: state.video.twilioVideoAuth,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    getCurrentVisit: getCurrentVisitAction,
    getTwilioVideoRoomAuth: getTwilioVideoRoomAuthAction,
    resetTwilioVideoRoomAuth: resetTwilioVideoRoomAuthAction,
    setPageTitle: setPageTitleAction,
  })
)(VideoCallContainer);
