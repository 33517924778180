import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { has, isNil } from 'lodash';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { setDirtyForm, showToast } from '../layout/layout.actions';
import CustomDialog from '../../common/customDialog/customDialog.component';
import {
  getMedicalHistory,
  GET_MEDICAL_HISTORY_SUCCESS,
  updateMedicalHistory,
  updateDependentMedicalHistory,
  getDependentMedicalHistory,
  GET_DEPENDENT_MEDICAL_HISTORY_SUCCESS,
  UPDATE_DEPENDENT_MEDICAL_HISTORY_SUCCESS,
  UPDATE_MEDICAL_HISTORY_SUCCESS,
} from '../profile/profile.actions';
import {
  updateFacilityPatientMedicalHistory,
  UPDATE_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS,
} from '../facility/facility.actions';

import toastTypes from '../../types/toastTypes';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import MedicalHistoryForm from './forms/patientMedicalHistoryForm.component';
import Languages from '../language/languages';
import { logEvent } from '../../utilities/googleAnalytics';
import accountTypes from '../../types/accountTypes';

import { config as brandConfig } from '@brand';

const { entranceId } = brandConfig;

class PatientMedicalHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowCancelDialog: false,
      medicalHistory: {},
      medicalHistoryResponses: {},
      isInitializing: true,
    };
  }

  async componentDidMount() {
    // if patientId is provided, treat as a dependent
    // account holder route needs no patientId and does not require it
    if (has(this.props, 'match.params.patientId')) {
      const { patientId } = this.props.match.params;
      const response = await this.props.getDependentMedicalHistory(patientId);
      if (response.type === GET_DEPENDENT_MEDICAL_HISTORY_SUCCESS) {
        this.setState({
          medicalHistory: response.response,
          medicalHistoryResponses: this.getInitialResponses(response.response),
          isInitializing: false,
        });
      }
    } else {
      // account holder GET
      const response = await this.props.getMedicalHistory();
      if (response.type === GET_MEDICAL_HISTORY_SUCCESS) {
        this.setState({
          medicalHistory: response.response,
          medicalHistoryResponses: this.getInitialResponses(response.response),
          isInitializing: false,
        });
      }
    }
  }

  // responses can be null, true or false
  getInitialResponses = medicalHistory => {
    const medicalHistoryResponses = {
      medications:
        medicalHistory.medications === null ? null : medicalHistory.medications.length > 0,
      medicalHistory:
        medicalHistory.medicalHistory === null ? null : medicalHistory.medicalHistory.length > 0,
      allergies: medicalHistory.allergies === null ? null : medicalHistory.allergies.length > 0,
      surgicalHistory:
        medicalHistory.surgicalHistory === null ? null : medicalHistory.surgicalHistory.length > 0,
    };
    return medicalHistoryResponses;
  };

  handleMedicalHistoryResponse = key => value => {
    const updateResponses = { ...this.state.medicalHistoryResponses };
    updateResponses[key] = value;

    let eventKey = '';
    switch (key) {
      case 'medications':
        value ? (eventKey = 'med_yes') : (eventKey = 'med_no');
        break;

      case 'medicalHistory':
        value ? (eventKey = 'medConditions_yes') : (eventKey = 'medConditions_no');
        break;

      case 'allergies':
        value ? (eventKey = 'allergies_yes') : (eventKey = 'allergies_no');
        break;

      case 'surgicalHistory':
        value ? (eventKey = 'surgeries_yes') : (eventKey = 'surgeries_no');
        break;

      default:
        break;
    }

    logEvent(eventKey, {
      patientId: has(this.props, 'match.params.patientId')
        ? this.props.match.params.patientId
        : this.props.profile.id,
      entryId: entranceId,
      accountId: this.props.profile.id,
    });

    this.setState({
      medicalHistoryResponses: updateResponses,
    });
    this.props.setDirtyForm();
  };

  handleMedicalSelectFieldChange = key => value => {
    const updateMedicalHistory = { ...this.state.medicalHistory };
    updateMedicalHistory[key] = value;
    this.setState({
      medicalHistory: updateMedicalHistory,
    });
    this.props.setDirtyForm();
  };

  // filter out former data that is being cleared by a 'no' response
  // only allow med history entries with a yes response
  getFilteredMedicalHistory = () => {
    const { medicalHistory, medicalHistoryResponses } = this.state;

    return {
      medications: medicalHistoryResponses.medications === false ? [] : medicalHistory.medications,
      allergies: medicalHistoryResponses.allergies === false ? [] : medicalHistory.allergies,
      medicalHistory:
        medicalHistoryResponses.medicalHistory === false ? [] : medicalHistory.medicalHistory,
      surgicalHistory:
        medicalHistoryResponses.surgicalHistory === false ? [] : medicalHistory.surgicalHistory,
    };
  };

  handleSaveMedicalHistory = async () => {
    const language = Languages[this.props.selectedLanguageKey].strings;
    const filteredMedicalHistory = this.getFilteredMedicalHistory();
    if (
      has(this.props, 'match.params.patientId') &&
      this.props.location.state.accountType === accountTypes.FACILITY_PATIENT
    ) {
      // FAC PATIENT
      const response = await this.props.updateFacilityPatientMedicalHistory(
        this.props.match.params.patientId,
        filteredMedicalHistory
      );
      if (response.type === UPDATE_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS) {
        this.props.showToast(
          language.showToastMessages.updateFacilitatedPatientMedicalHistorySuccess
        );
        this.props.history.goBack();
      } else {
        this.props.showToast(
          language.showToastMessages.updateFacilitatedPatientMedicalHistoryFailure,
          toastTypes.ERROR,
          true,
          null,
          response.messages[0]
        );
      }
    } else if (
      has(this.props, 'match.params.patientId') &&
      this.props.location.state.accountType === accountTypes.CHILD
    ) {
      // DEPENDENT
      const response = await this.props.updateDependentMedicalHistory(
        this.props.match.params.patientId,
        filteredMedicalHistory
      );
      if (response.type === UPDATE_DEPENDENT_MEDICAL_HISTORY_SUCCESS) {
        this.props.showToast(language.showToastMessages.updateDependentMedicalHistorySuccess);
        this.props.history.goBack();
      } else {
        this.props.showToast(
          language.showToastMessages.updateDependentMedicalHistoryFailure,
          toastTypes.ERROR
        );
      }
    } else {
      // PRIMARY ACCOUNT HOLDER
      const response = await this.props.updateMedicalHistory(filteredMedicalHistory);
      if (response.type === UPDATE_MEDICAL_HISTORY_SUCCESS) {
        this.props.showToast(language.showToastMessages.updateMedicalHistorySuccess);
        this.props.history.goBack();
      } else {
        this.props.showToast(
          language.showToastMessages.updateMedicalHistoryFailure,
          toastTypes.ERROR,
          true,
          null,
          response.messages[0]
        );
      }
    }
  };

  // form is invalid if any responses are null
  // or if a response is yes and the corresponding history key is empty
  formHasErrors = () => {
    const historyKeys = Object.keys(this.state.medicalHistory);
    const responseKeys = ['allergies', 'medicalHistory', 'surgicalHistory', 'medications'];

    const isMissingHistoryItems = historyKeys.some(key => {
      if (this.state.medicalHistoryResponses[key] === true) {
        return isNil(this.state.medicalHistory[key]) || this.state.medicalHistory[key].length <= 0;
      }
      return false;
    });

    const isMissingResponse = responseKeys.some(key => {
      const value = this.state.medicalHistoryResponses[key];
      return value !== true && value !== false;
    });

    return isMissingHistoryItems || isMissingResponse;
  };

  render() {
    const { classes, isLoading, selectedLanguageKey } = this.props;
    const {
      medicalHistory,
      formIsDirty,
      isShowCancelDialog,
      medicalHistoryResponses,
      isInitializing,
    } = this.state;
    const language = Languages[selectedLanguageKey].strings;

    if (isLoading || isInitializing) return <LoadingOverlay />;

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.stepperContent}>
            <Typography className={classes.heading} variant="h5">
              {language.editMedicalHistoryTitle}
            </Typography>

            <MedicalHistoryForm
              medicalHistory={medicalHistory}
              medicalHistoryResponses={medicalHistoryResponses}
              handleSelectFieldChange={this.handleMedicalSelectFieldChange}
              handleValidationChange={this.handleValidationChange}
              handleMedicalHistoryResponse={this.handleMedicalHistoryResponse}
            />
          </div>
        </div>
        <div className={classes.formActionsContainer}>
          <div className={classes.formActions}>
            <Button
              variant="outlined"
              className={classes.actionButton}
              onClick={
                formIsDirty ? this.handleShowCancelDialog : () => this.props.history.goBack()
              }
            >
              {language.buttonText.cancel}
            </Button>
            <Button
              className={classes.actionButton}
              color="primary"
              disabled={this.formHasErrors()}
              onClick={this.handleSaveMedicalHistory}
              variant="contained"
            >
              {language.buttonText.save}
            </Button>
          </div>
        </div>
        <CustomDialog
          open={isShowCancelDialog}
          handleClose={this.handleCloseCancelDialog}
          content={
            <Typography variant="subtitle1" id="modal-title">
              {language.alertMessages.confirmExitMedical}
            </Typography>
          }
          title={language.alertMessages.confirmExitMedicalTitle}
          handleAction={() => this.props.history.goBack()}
        />
      </div>
    );
  }
}

const styles = theme => ({
  content: {
    backgroundColor: theme.palette.primary.background,
    flex: 1,
    overflow: 'auto',
    width: '100%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  stepperContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 88px)',
    margin: '2rem auto 0',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formActionsContainer: {
    background: theme.palette.primary.background,
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  formActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightGray,
  },
  actionButton: {
    margin: '1rem 0',
    maxWidth: '7rem',
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
  heading: {
    color: theme.palette.primary.darkGray,
    margin: '0 1rem',
  },
});

PatientMedicalHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  isLoading: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  getDependentMedicalHistory: PropTypes.func.isRequired,
  getMedicalHistory: PropTypes.func.isRequired,
  setDirtyForm: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  updateDependentMedicalHistory: PropTypes.func.isRequired,
  updateFacilityPatientMedicalHistory: PropTypes.func.isRequired,
  updateMedicalHistory: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoading: state.profile.isLoading,
    profile: state.profile.profile,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    getDependentMedicalHistory,
    getMedicalHistory,
    setDirtyForm,
    showToast,
    updateDependentMedicalHistory,
    updateFacilityPatientMedicalHistory,
    updateMedicalHistory,
  })
)(PatientMedicalHistory);
