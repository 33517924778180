import { GET_CURRENT_POSITION, GET_CURRENT_POSITION_FAILURE } from './location.actions';
import produce from 'immer';

const initialState = {
  currentPosition: null,
  error: null
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_POSITION:
      draft.currentPosition = action.location;
      draft.error = null;
      break;

    case GET_CURRENT_POSITION_FAILURE:
      draft.error = action;
      break;

    default:
      break;
  }
}, initialState);
