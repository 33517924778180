import { CALL_API } from '../../middleware/api';

export const GET_PRIOR_VISITS_REQUEST = 'GET_PRIOR_VISITS_REQUEST';
export const GET_PRIOR_VISITS_SUCCESS = 'GET_PRIOR_VISITS_SUCCESS';
export const GET_PRIOR_VISITS_FAILURE = 'GET_PRIOR_VISITS_FAILURE';

export const GET_PRIOR_VISIT_PRESCRIPTIONS_REQUEST = 'GET_PRIOR_VISIT_PRESCRIPTIONS_REQUEST';
export const GET_PRIOR_VISIT_PRESCRIPTIONS_SUCCESS = 'GET_PRIOR_VISIT_PRESCRIPTIONS_SUCCESS';
export const GET_PRIOR_VISIT_PRESCRIPTIONS_FAILURE = 'GET_PRIOR_VISIT_PRESCRIPTIONS_FAILURE';

export const GET_PRIOR_VISIT_NOTES_REQUEST = 'GET_PRIOR_VISIT_NOTES_REQUEST';
export const GET_PRIOR_VISIT_NOTES_SUCCESS = 'GET_PRIOR_VISIT_NOTES_SUCCESS';
export const GET_PRIOR_VISIT_NOTES_FAILURE = 'GET_PRIOR_VISIT_NOTES_FAILURE';

export const CLEAR_PRIOR_VISITS = 'CLEAR_PRIOR_VISITS';

export const SET_PRIOR_VISIT = 'SET_PRIOR_VISIT';
export const CLEAR_PRIOR_VISIT = 'CLEAR_PRIOR_VISIT';

export const GET_VISIT_NOTE_PDF_REQUEST = 'GET_VISIT_NOTE_PDF_REQUEST';
export const GET_VISIT_NOTE_PDF_SUCCESS = 'GET_VISIT_NOTE_PDF_SUCCESS';
export const GET_VISIT_NOTE_PDF_FAILURE = 'GET_VISIT_NOTE_PDF_FAILURE';

export const getPriorVisits = patientId => {
  return {
    [CALL_API]: {
      types: [GET_PRIOR_VISITS_REQUEST, GET_PRIOR_VISITS_SUCCESS, GET_PRIOR_VISITS_FAILURE],
      authenticated: true,
      endpoint: `v1/patient/patients/${patientId}/visits`,
      method: 'GET',
    },
  };
};

export const getPriorVisitPrescriptions = visitId => {
  return {
    [CALL_API]: {
      types: [
        GET_PRIOR_VISIT_PRESCRIPTIONS_REQUEST,
        GET_PRIOR_VISIT_PRESCRIPTIONS_SUCCESS,
        GET_PRIOR_VISIT_PRESCRIPTIONS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/patient/visits/${visitId}/prescriptions`,
      method: 'GET',
    },
  };
};

export const getPriorVisitNotes = visitId => {
  return {
    [CALL_API]: {
      types: [
        GET_PRIOR_VISIT_NOTES_REQUEST,
        GET_PRIOR_VISIT_NOTES_SUCCESS,
        GET_PRIOR_VISIT_NOTES_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/patient/visits/${visitId}/notes`,
      method: 'GET',
    },
  };
};

export const clearPriorVisits = () => {
  return {
    type: CLEAR_PRIOR_VISITS,
  };
};

export const setPriorVisit = visit => {
  return {
    type: SET_PRIOR_VISIT,
    response: visit,
  };
};

export const clearPriorVisit = () => {
  return {
    type: CLEAR_PRIOR_VISIT,
  };
};

export const getVisitNotePdf = (visitId, noteId) => {
  return {
    [CALL_API]: {
      types: [GET_VISIT_NOTE_PDF_REQUEST, GET_VISIT_NOTE_PDF_SUCCESS, GET_VISIT_NOTE_PDF_FAILURE],
      authenticated: true,
      endpoint: `v1/patient/visits/${visitId}/notes/${noteId}/pdf`,
      method: 'GET',
    },
  };
};
