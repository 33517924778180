import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles, Typography, Button } from '@material-ui/core';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';

import { getCurrentPosition } from '../location/location.actions';
import {
  resetPharmacies,
  savePharmacy,
  getPharmacyDetails,
  SAVE_PHARMACY_SUCCESS,
} from '../visit/visit.actions';
import PharmacyForm from './forms/pharmacyForm.component';
import Languages from '../language/languages';
import { logEvent } from '../../utilities/googleAnalytics';

import { config as brandConfig } from '@brand';

const { entranceId } = brandConfig;

class PharmacySelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPharmacy: null,
    };
  }

  async componentDidMount() {
    await this.props.getCurrentPosition();
  }

  componentWillUnmount() {
    this.props.resetPharmacies();
  }

  handleSelectPharmacy = (pharmacy = null) => {
    logEvent('phar_search', {
      patientId: this.props.profile.id,
      entryId: entranceId,
      accountId: this.props.profile.id,
    });
    this.setState({ selectedPharmacy: pharmacy });
  };

  // save pharmacy on location state profile, if provided.
  // otherwise default to save on account users' profile
  handleSavePharmacy = async () => {
    let { profile } = this.props;

    if (this.props.location.state && this.props.location.state.profile) {
      profile = this.props.location.state.profile;
    }

    const result = await this.props.savePharmacy(
      profile.id,
      this.state.selectedPharmacy.PharmacyId.toString()
    );

    if (result.type === SAVE_PHARMACY_SUCCESS) {
      await this.props.getPharmacyDetails(profile.id);

      this.props.history.replace({
        pathname: `/patient/${this.props.location.state.profile.id}/visit`,
        state: {
          profile: {
            ...this.props.location.state.profile,
            pharmacyDoseSpotId: this.state.selectedPharmacy.PharmacyId,
          },
        },
      });
    }
  };

  render() {
    const { isLoading, classes, selectedLanguageKey } = this.props;
    const language = Languages[selectedLanguageKey].strings;

    return (
      <div className={classes.container}>
        <div className={classes.scrollableContainer}>
          <div className={classes.pharmacyContainer}>
            <Typography className={classes.heading} variant="h5">
              {language.editPharmacyTitle}
            </Typography>
            <PharmacyForm
              onSelectPharmacy={this.handleSelectPharmacy}
              selectedPharmacy={this.state.selectedPharmacy}
            />
            <div className={classes.footer}>
              {!isNil(this.state.selectedPharmacy) && (
                <Typography variant="subtitle1" color="primary">
                  {`${language.pharmacySelected} ${this.state.selectedPharmacy.StoreName}`}
                </Typography>
              )}
              <div className={classes.actionsContainer}>
                <Button
                  onClick={() => this.props.history.goBack()}
                  className={classes.actionButton}
                >
                  {language.buttonText.cancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleSavePharmacy()}
                  disabled={isLoading || isNil(this.state.selectedPharmacy)}
                  className={classes.actionButton}
                >
                  {isLoading ? language.buttonText.loading : language.buttonText.save}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  pharmacyContainer: {
    margin: '1rem auto 0',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 1rem 1rem',
  },
  footer: {
    backgroundColor: theme.palette.primary.background,
    borderRadius: 0,
    bottom: 0,
    paddingTop: '1rem',
    position: 'absolute',
    textAlign: 'center',
    width: 800,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  container: {
    backgroundColor: theme.palette.primary.background,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  scrollableContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 56px)',
    overflowY: 'scroll',
  },
  actionButton: {
    fontSize: 14,
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
  heading: {
    color: theme.palette.primary.darkGray,
    margin: '0.5rem',
  },
});

PharmacySelection.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  isLoading: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  getCurrentPosition: PropTypes.func.isRequired,
  getPharmacyDetails: PropTypes.func.isRequired,
  resetPharmacies: PropTypes.func.isRequired,
  savePharmacy: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoading: state.visit.isLoadingPharmacies,
    profile: state.profile.profile,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getCurrentPosition,
    resetPharmacies,
    savePharmacy,
    getPharmacyDetails,
  })
)(PharmacySelection);
