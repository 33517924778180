/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Icon,
  withStyles,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { compose } from 'recompose';

import greyLogo from '@brand/images/login/logo-grey.png';

import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import {
  getPriorVisitNotes,
  getPriorVisitPrescriptions,
  getPriorVisits,
  getVisitNotePdf,
  GET_VISIT_NOTE_PDF_SUCCESS,
} from './priorVisits.actions';
import { setPageTitle, showToast } from '../layout/layout.actions';
import CustomDialog from '../../common/customDialog/customDialog.component';
import PrescriptionInfo from './prescriptionInfo.component';
import Languages from '../language/languages';
import { getMarketingContent } from '../../utilities/entranceUtils';
import { MARKETING_CONTENT_TYPES } from '../../types/marketingContentTypes';
import { getProfile } from '../profile/profile.actions';
import PriorVisitUtils, { DATE_FORMAT } from '../../utilities/priorVisitUtils';
import TOAST_TYPES from '../../types/toastTypes';

class PriorVisit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isShowNoteDialog: false,
      selectedNote: null,
      selectedNotePdfUrl: null,
      isShowPrescriptionDialog: false,
      selectedPrescription: null,
    };
  }

  async componentWillMount() {
    this.props.setPageTitle(
      Languages[this.props.selectedLanguageKey].strings.pageTitles.priorVisit
    );

    const { visitId, patientId } = this.props.match.params;
    const { priorVisits, profile } = this.props;

    if (priorVisits.length === 0) {
      await this.props.getPriorVisits(patientId);
    }

    if (isEmpty(profile)) {
      await this.props.getProfile();
    }

    await Promise.all([
      this.props.getPriorVisitNotes(visitId),
      this.props.getPriorVisitPrescriptions(visitId),
    ]);
    this.setState({ isLoading: false });
  }

  getInPageTitle() {
    const { selectedLanguageKey, profile, priorVisits, match } = this.props;
    const { visitId, patientId } = match.params;

    return PriorVisitUtils.getPriorVisitHeading(
      selectedLanguageKey,
      patientId,
      profile,
      visitId,
      priorVisits
    );
  }

  handleShowNote = note => {
    this.setState({ isShowNoteDialog: true, selectedNote: note });
  };

  handleCloseNoteDialog = () => {
    this.setState({ isShowNoteDialog: false, selectedNote: null, selectedNotePdfUrl: null });
  };

  handleShowPrescription = prescription => {
    this.setState({ isShowPrescriptionDialog: true, selectedPrescription: prescription });
  };

  handleClosePrescriptionDialog = () => {
    this.setState({ isShowPrescriptionDialog: false, selectedPrescription: null });
  };

  handleShowNotePrintView = () => {
    const { selectedNote } = this.state;
    const { classes, marketingGreyLogo } = this.props;

    if (selectedNote && selectedNote.html) {
      // create a document in a new tab
      const newTab = window.open(undefined, '_blank');
      newTab.document.title = 'Visit Note';

      // create container to use later with ReactDOM render
      const container = newTab.document.createElement('div');

      // add font to header
      const head = newTab.document.getElementsByTagName('head')[0];
      const link = newTab.document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://fonts.googleapis.com/css?family=Heebo:300,400,500';
      head.appendChild(link);

      // create jsx element with html of note
      const printViewJsx = (
        <div className={classes.printView}>
          <img
            src={!isEmpty(marketingGreyLogo) ? marketingGreyLogo[0].imageUrl : greyLogo}
            alt=""
          />
          <p>{this.getInPageTitle()}</p>
          <div dangerouslySetInnerHTML={{ __html: selectedNote.html }} />
        </div>
      );

      // render element to container
      newTab.document.body.append(ReactDOM.render(printViewJsx, container));
    }
  };

  handleGetPdf = async () => {
    if (this.state.selectedNotePdfUrl) {
      window.open(this.state.selectedNotePdfUrl, '_blank');
    } else {
      const { visitId } = this.props.match.params;
      const response = await this.props.getVisitNotePdf(visitId, this.state.selectedNote.id);
      if (response.type === GET_VISIT_NOTE_PDF_SUCCESS) {
        window.open(response.response.pdfUrl, '_blank');
        this.setState({ selectedNotePdfUrl: response.response.pdfUrl });
      } else {
        this.handleCloseNoteDialog();
        this.props.showToast(
          Languages[this.props.selectedLanguageKey].strings.showToastMessages.pdfDownloadFailure,
          TOAST_TYPES.ERROR,
          true,
          null,
          response.messages[0]
        );
      }
    }
  };

  render() {
    const { classes, priorVisit, selectedLanguageKey, isDownloadingPdf } = this.props;
    const language = Languages[selectedLanguageKey].strings;

    if (this.state.isLoading) return <LoadingOverlay />;

    return (
      <div className={classes.container}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12}>
            <Typography align="center" variant="h5" className={classes.pageTitle}>
              {this.getInPageTitle()}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent classes={{ root: classes.contentRoot }}>
                <Typography variant="h6">
                  {`${language.prescriptions} (${
                    priorVisit.prescriptions ? priorVisit.prescriptions.length : 0
                  })`}
                </Typography>

                <List dense={false}>
                  {priorVisit.prescriptions &&
                    priorVisit.prescriptions.map((prescription, i) => {
                      return (
                        <ListItem
                          button
                          onClick={() => this.handleShowPrescription(prescription)}
                          key={i}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <Icon
                                className={classnames([
                                  'fas fa-prescription-bottle',
                                  classes.listIcon,
                                ])}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={prescription.DisplayName}
                            secondary={
                              prescription.WrittenDate
                                ? moment(prescription.WrittenDate).format(DATE_FORMAT)
                                : null
                            }
                          />
                        </ListItem>
                      );
                    })}
                </List>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent classes={{ root: classes.contentRoot }}>
                <Typography variant="h6">
                  {`${language.notes} (${priorVisit.notes ? priorVisit.notes.length : 0})`}
                </Typography>

                <List>
                  {priorVisit.notes &&
                    priorVisit.notes.map(note => {
                      return (
                        <ListItem button onClick={() => this.handleShowNote(note)} key={note.id}>
                          <ListItemAvatar>
                            <Avatar>
                              <Icon
                                className={classnames([
                                  'fas fa-file-medical-alt',
                                  classes.listIcon,
                                ])}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={note.type}
                            secondary={
                              note.signedDate ? moment(note.signedDate).format(DATE_FORMAT) : null
                            }
                          />
                        </ListItem>
                      );
                    })}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {this.state.selectedNote && (
          <CustomDialog
            open={this.state.isShowNoteDialog}
            handleClose={this.handleCloseNoteDialog}
            content={
              <>
                <Typography variant="body1" className={classes.pageTitle}>
                  {this.getInPageTitle()}
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: this.state.selectedNote.html }} />
              </>
            }
            title={`${this.state.selectedNote.type} - ${moment(
              this.state.selectedNote.signedDate
            ).format(DATE_FORMAT)}`}
            cancelOnly
            cancelText={language.buttonText.close}
            maxWidth="md"
            customAction={this.handleShowNotePrintView}
            customActionText="Print View"
            pdfAction={this.handleGetPdf}
            isDownloadingPdf={isDownloadingPdf}
          />
        )}
        {this.state.selectedPrescription && (
          <CustomDialog
            open={this.state.isShowPrescriptionDialog}
            handleClose={this.handleClosePrescriptionDialog}
            content={
              <PrescriptionInfo
                selectedLanguageKey={selectedLanguageKey}
                prescription={this.state.selectedPrescription}
              />
            }
            title={language.prescription}
            cancelOnly
            cancelText={language.buttonText.close}
            maxWidth="sm"
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.primary.background,
    flex: 1,
  },
  gridContainer: {
    margin: '0 auto',
    maxWidth: '768px',
    padding: '1rem',
    paddingBottom: '3rem',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem',
  },
  contentRoot: {
    flex: 1,
  },
  listIcon: {
    alignSelf: 'center',
    color: theme.palette.common.white,
    fontSize: '20px',
    width: 'unset',
  },
  pageTitle: {
    color: theme.palette.primary.darkGray,
    marginBottom: '0.5rem',
  },
  printView: {
    fontFamily: 'Heebo',
    margin: 'auto',
    maxWidth: 800,
    padding: '2rem',
  },
});

PriorVisit.propTypes = {
  classes: PropTypes.object.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  marketingGreyLogo: PropTypes.array,
  priorVisit: PropTypes.object.isRequired,
  priorVisits: PropTypes.array.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  isDownloadingPdf: PropTypes.bool.isRequired,

  getPriorVisitNotes: PropTypes.func.isRequired,
  getPriorVisits: PropTypes.func.isRequired,
  getPriorVisitPrescriptions: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  getVisitNotePdf: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

PriorVisit.defaultProps = {
  marketingGreyLogo: [],
};

const mapStateToProps = state => {
  return {
    marketingGreyLogo: getMarketingContent(
      state.entrance.entrance,
      MARKETING_CONTENT_TYPES.DESKTOP_GREY_LOGO
    ),
    priorVisits: state.priorVisits.priorVisits,
    priorVisit: state.priorVisits.priorVisit,
    selectedLanguageKey: state.language.selectedLanguageKey,
    profile: state.profile.profile,
    isDownloadingPdf: state.priorVisits.isDownloadingPdf,
  };
};

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    getPriorVisitNotes,
    getPriorVisitPrescriptions,
    getPriorVisits,
    setPageTitle,
    getProfile,
    getVisitNotePdf,
    showToast,
  })
)(PriorVisit);
