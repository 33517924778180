import { Grid, Typography, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import { isNil, isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import loginHeader from '@brand/images/login/loginHeader.jpg';
import { compose } from 'recompose';
import { config as brandConfig } from '@brand';

import { isInTimeRange } from '../../utilities/dataUtils';
import { setPageTitle } from '../layout/layout.actions';
import { setAuth } from './login.actions';
import ForgotPasswordModal from './forgotPasswordModal.component';
import LoginForm from './loginForm.component';
import CreateAccountForm from './createAccountForm.container';
import Languages from '../language/languages';
import MessageBanner from '../layout/messageBanner.component';
import UIVars from '../../styles/ui-vars';
import { getEntrance } from '../entrance/entrance.actions';
import { getMarketingContent } from '../../utilities/entranceUtils';
import { MARKETING_CONTENT_TYPES } from '../../types/marketingContentTypes';

const { entranceId } = brandConfig;

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forgotPasswordOpen: false,
      createAccountSelected: false,
    };
  }

  componentWillMount() {
    this.props.setPageTitle(Languages[this.props.selectedLanguageKey].strings.pageTitles.login);
    this.props.getEntrance(entranceId);
  }

  handleToggleForgotPassword = () => {
    this.setState(prevState => ({
      forgotPasswordOpen: !prevState.forgotPasswordOpen,
    }));
  };

  handleToggleCreateForm = () => {
    this.setState(prevState => ({
      createAccountSelected: !prevState.createAccountSelected,
    }));
  };

  shouldShowMessageBanner = () => {
    const { messageBanner } = this.props;
    if (!isNil(messageBanner)) {
      if (!isNil(messageBanner.startTime)) {
        return isInTimeRange(messageBanner.startTime, messageBanner.endTime);
      }
      // no timeframe provided
      return true;
    }
    return false;
  };

  render() {
    const {
      classes,
      marketingLoginHeader,
      marketingBannerText,
      messageBanner,
      selectedLanguageKey,
    } = this.props;
    const { forgotPasswordOpen, createAccountSelected } = this.state;

    const language = Languages[selectedLanguageKey].strings;

    return (
      <div className={classes.content}>
        <img
          className={classes.image}
          src={!isEmpty(marketingLoginHeader) ? marketingLoginHeader[0].imageUrl : loginHeader}
          alt={`Welcome to ${brandConfig.appName}`}
        />
        <div className={classes.messageBanner}>
          {this.shouldShowMessageBanner() && (
            <MessageBanner message={messageBanner.text} type={messageBanner.state} />
          )}
        </div>
        <div className={classes.loginBanner}>
          {!isEmpty(marketingBannerText) && (
            <MessageBanner
              message={marketingBannerText[0].bannerText}
              type={marketingBannerText[0].bannerColor}
            />
          )}
        </div>
        <Grid container className={classes.loginGrid}>
          <Grid item xs={12} md={7} className={classes.leftPanel}>
            <Typography variant="h5" className={classes.headerText}>
              {language.loginHeader}
            </Typography>
            <div className={classes.listItems}>
              <Typography variant="h5" className={classes.darkGray}>
                {language.loginListTitle}
              </Typography>
              <ol className={classes.orderedList}>
                {language.loginList.itemOne && (
                  <li
                    className={classes.listItem}
                    dangerouslySetInnerHTML={{ __html: language.loginList.itemOne }}
                  />
                )}
                {language.loginList.itemTwo && (
                  <li
                    className={classes.listItem}
                    dangerouslySetInnerHTML={{ __html: language.loginList.itemTwo }}
                  />
                )}
                {language.loginList.itemThree && (
                  <li
                    className={classes.listItem}
                    dangerouslySetInnerHTML={{ __html: language.loginList.itemThree }}
                  />
                )}
                {language.loginList.itemFour && (
                  <li
                    className={classes.listItem}
                    dangerouslySetInnerHTML={{ __html: language.loginList.itemFour }}
                  />
                )}
                {language.loginList.itemFive && (
                  <li
                    className={classes.listItem}
                    dangerouslySetInnerHTML={{ __html: language.loginList.itemFive }}
                  />
                )}
                {language.loginList.itemSix && (
                  <li
                    className={classes.listItem}
                    dangerouslySetInnerHTML={{ __html: language.loginList.itemSix }}
                  />
                )}
              </ol>

              {language.technicalSupportInfo && (
                <>
                  {/* This can be refined in the future for variable amount of contact information */}
                  <Typography variant="body2" className={classes.darkGray}>
                    {`${language.technicalSupportInfo.preface} `}
                    <Link href={`mailto: ${brandConfig.supportEmail}`}>
                      {`${brandConfig.supportEmail} `}
                    </Link>
                    {`${language.technicalSupportInfo.joinOr} ${language.technicalSupportInfo.phonePrompt} `}
                    <Link href={`tel: ${brandConfig.supportPhone}`}>
                      {brandConfig.supportPhone}
                    </Link>
                  </Typography>
                </>
              )}
            </div>
          </Grid>

          <Grid item xs={12} md={5} className={classes.rightPanel}>
            {createAccountSelected ? (
              <CreateAccountForm handleToggleCreateForm={this.handleToggleCreateForm} />
            ) : (
              <LoginForm
                handleToggleForgotPassword={this.handleToggleForgotPassword}
                handleToggleCreateForm={this.handleToggleCreateForm}
              />
            )}
          </Grid>
        </Grid>
        <ForgotPasswordModal
          open={forgotPasswordOpen}
          handleClose={this.handleToggleForgotPassword}
        />
      </div>
    );
  }
}

const styles = theme => ({
  content: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.background,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  leftPanel: {
    padding: '1rem',
  },
  rightPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
  },
  orderedList: {
    color: theme.palette.primary.darkGray,
    fontSize: '1.1rem',
    paddingInlineStart: '1rem',
  },
  listItem: {
    marginBottom: '1.5rem',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      minHeight: 90, // This is the height of the image when it hits this breakpoint
    },
    '@media (max-width:499px)': {
      // below 500px is used on mobile devices: see RMD-1770
      // need to allow shrinkage to accommodate small screens
      minHeight: 'unset',
    },
    maxHeight: UIVars.headerGraphic.height,
  },
  messageBanner: {
    width: '100%',
  },
  loginBanner: {
    marginTop: '.5rem',
    width: '100%',
  },
  headerText: {
    marginBottom: '1rem',
    marginLeft: '1rem',
    marginTop: '2rem',
    color: theme.palette.primary.darkGray,
  },
  listItems: {
    marginLeft: '2rem',
    marginTop: '3rem',
  },
  darkGray: {
    color: theme.palette.primary.darkGray,
  },
  loginGrid: {
    maxWidth: '1920px',
  },
});

LoginContainer.propTypes = {
  classes: PropTypes.object.isRequired,

  marketingBannerText: PropTypes.array,
  marketingLoginHeader: PropTypes.array,
  messageBanner: PropTypes.object,
  selectedLanguageKey: PropTypes.string.isRequired,

  getEntrance: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
};

LoginContainer.defaultProps = {
  marketingBannerText: [],
  marketingLoginHeader: [],
  messageBanner: {},
};

const mapStateToProps = state => {
  const { layout, language, entrance } = state;

  return {
    marketingBannerText: getMarketingContent(
      entrance.entrance,
      MARKETING_CONTENT_TYPES.LOGIN_BANNER
    ),
    marketingLoginHeader: getMarketingContent(
      entrance.entrance,
      MARKETING_CONTENT_TYPES.DESKTOP_LOGIN
    ),
    messageBanner: layout.messageBanner,
    selectedLanguageKey: language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    getEntrance,
    setAuth,
    setPageTitle,
  })
)(LoginContainer);
