import { GET_LEGAL_TERMS_FAILURE, GET_LEGAL_TERMS_REQUEST, GET_LEGAL_TERMS_SUCCESS } from './legal.actions';
import produce from 'immer';

const initialState = {
  legalTerms: null,
  isLoading: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_LEGAL_TERMS_SUCCESS:
      console.log('GET LEGAL TERMS SUCCESS', action.response);
      draft.legalTerms = action.response.data;
      draft.isLoading = false;
      break;

    case GET_LEGAL_TERMS_FAILURE:
      console.log('GET LEGAL TERMS FAILURE', action.messages);
      draft.isLoading = false;
      break;

    case GET_LEGAL_TERMS_REQUEST:
      draft.isLoading = true;
      break;

    default:
      break;
  }
}, initialState);
