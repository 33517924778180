import React, { Component } from 'react';
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import greyLogo from '@brand/images/login/logo-grey.png';
import { setPageTitle } from './layout.actions';
import { isMobile, browser } from '../../utilities/browserUtils';
import { config as brandConfig } from '@brand';
import { getMarketingContent } from '../../utilities/entranceUtils';
import { MARKETING_CONTENT_TYPES } from '../../types/marketingContentTypes';

const IOS_APP_URL = brandConfig.iosAppUrl || 'https://apps.apple.com/us/app/relymd/id962442972';
const ANDROID_APP_URL = brandConfig.androidAppUrl || 'https://play.google.com/store/apps/details?id=com.virtumedix.relymd&hl=en';

const MAX_WIDTH = '10rem';

class UnsupportedBrowser extends Component {
  componentWillMount() {
    this.props.setPageTitle('Unsupported Browser');
  }

  getMessage = () => {
    return isMobile
      ? 'It looks like you are using a mobile device. Please use our mobile app.'
      : 'It looks like you are using an unsupported browser.';
  };

  goToAppStore = () => {
    window.location.href = browser.os === 'iOS' ? IOS_APP_URL : ANDROID_APP_URL;
  };

  render() {
    const { classes, marketingGreyLogo } = this.props;

    return (
      <div className={classes.formWrapper}>
        <div className={classes.cardContainer}>
          <Card className={classes.card}>
            <CardContent className={classNames(classes.formContent)}>
              <img
                alt={`${brandConfig.appName} logo`}
                className={classNames(classes.logo)}
                src={!isEmpty(marketingGreyLogo) ? marketingGreyLogo[0].imageUrl : greyLogo}
              />
              <Typography className={classes.message}>{this.getMessage()}</Typography>
              {!isMobile && (
                <Typography className={classes.brandMessage}>
                  {`In order to provide video visits, ${brandConfig.appName} requires a compatible web browser. Unfortunately, Internet Explorer and Safari don’t support this technology. We recommend Google Chrome or Firefox. Or, try our iPhone or Android Apps.`}
                </Typography>
              )}
              {isMobile && (
                <Button
                  className={classes.appStoreButton}
                  color="primary"
                  fullWidth
                  onClick={this.goToAppStore}
                  type="submit"
                  variant="contained"
                >
                  Go To App Store
                </Button>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  formWrapper: {
    backgroundColor: theme.palette.primary.background,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100vh',
  },
  formContent: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logo: {
    margin: theme.spacing.unit,
    marginBottom: '2rem',
    maxWidth: MAX_WIDTH,
  },
  cardContainer: {
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '75vw',
  },
  card: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '80vh',
    justifyContent: 'center',
    marginTop: '1rem',
    width: '50vw',
  },
  message: {
    color: theme.palette.primary.darkGray,
    fontSize: '1.3rem',
    textAlign: 'center',
  },
  brandMessage: {
    color: theme.palette.primary.darkGray,
    fontSize: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    marginTop: '2rem',
    maxWidth: 400,
    textAlign: 'center',
  },
  appStoreButton: {
    margin: '1.5rem',
    width: 250,
  },
});

UnsupportedBrowser.propTypes = {
  classes: PropTypes.object.isRequired,

  marketingGreyLogo: PropTypes.array,

  setPageTitle: PropTypes.func.isRequired,
};

UnsupportedBrowser.defaultProps = {
  marketingGreyLogo: [],
};

const mapStateToProps = state => {
  const { entrance } = state;

  return {
    marketingGreyLogo: getMarketingContent(
      entrance.entrance,
      MARKETING_CONTENT_TYPES.DESKTOP_GREY_LOGO
    ),
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    setPageTitle,
  })
)(UnsupportedBrowser);
