import { matchPath, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PowerSettingsIcon from '@material-ui/icons/PowerSettingsNew';
import routeLinks from './routes/routeLinks';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { LOGIN_STATE } from '../login/login.actions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Home from '@material-ui/icons/Home';

export const DRAWER_WIDTH = 240;

const styles = theme => ({
  activeRoute: {
    color: theme.palette.primary.main
  },
  drawerContents: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: DRAWER_WIDTH
  },
  drawerFooter: {
    marginBottom: '2rem'
  },
  userEmailWrapper: {
    padding: '1rem',
    textAlign: 'center'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: 0
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  listItemIcon: {
    width: '2.5rem'
  },
  listItemSelected: {
    color: theme.palette.primary.main
  }
});

class DrawerMenu extends Component {
  constructor(props) {
    super(props);
    this.isActiveRoute = this.isActiveRoute.bind(this);
    this.getIcon = this.getIcon.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  isActiveRoute(route) {
    let { pathname } = this.props.location;

    // highlight active route for all paths under a top level path
    pathname = '/' + pathname.split('/')[1];

    //explicitly return true/false because matchpath doesn't return a boolean
    return matchPath(pathname, {
      path: route,
      exact: true,
      strict: false
    })
      ? true
      : false;
  }

  getIcon(routeLink) {
    const activeColor = this.props.theme.palette.primary.main;
    const isActiveRoute = this.isActiveRoute(routeLink.route);
    const iconStyle = { width: 'unset', margin: 'auto', color: isActiveRoute ? activeColor : 'unset' };

    switch (routeLink.icon.toUpperCase()) {
      case 'HOME':
        return <Home style={iconStyle} />;

      default:
        return <Home />;
    }
  }

  handleLogout() {
    if (window.confirm('Are you sure you want to logout?')) {
      this.props.handleLogout();
    }
  }

  render() {
    let { classes, theme, isDrawerMenuOpen, user, loginState, toggleDrawerMenu } = this.props;

    return (
      <Drawer
        variant="persistent"
        classes={{
          paper: classNames(classes.drawerPaper, !isDrawerMenuOpen && classes.drawerPaperClose)
        }}
        open={isDrawerMenuOpen}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={toggleDrawerMenu}>{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
        </div>

        <div className={classes.drawerContents}>
          <List>
            {loginState === LOGIN_STATE.LOGGED_IN &&
              routeLinks.map((routeLink, i) => {
                if (routeLink.name.toLowerCase() === 'logout') {
                  return null;
                }

                return (
                  <ListItem button onClick={() => this.props.viewPage(routeLink.route)} key={i}>
                    {!isEmpty(routeLink.icon) && <ListItemIcon className={classes.listItemIcon}>{this.getIcon(routeLink)}</ListItemIcon>}
                    <ListItemText
                      primary={
                        <Typography variant="h6" className={this.isActiveRoute(routeLink.route) ? classes.activeRoute : ''}>
                          {routeLink.name}
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
          </List>

          <div className={classes.drawerFooter}>
            {isDrawerMenuOpen && (
              <div className={classes.userEmailWrapper}>
                <Typography variant="body1" color="secondary">
                  {!isEmpty(user) && user.email}
                </Typography>
              </div>
            )}
            <ListItem button onClick={this.handleLogout}>
              <ListItemIcon className={classes.listItemIcon}>
                <PowerSettingsIcon style={{ margin: 'auto' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Logout</Typography>} />
            </ListItem>
          </div>
        </div>
      </Drawer>
    );
  }
}

DrawerMenu.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isDrawerMenuOpen: PropTypes.bool,
  user: PropTypes.object,
  viewPage: PropTypes.func.isRequired,
  loginState: PropTypes.number,
  handleLogout: PropTypes.func.isRequired
};

const withRouterDrawerMenu = withRouter(DrawerMenu);

export default withStyles(styles, { withTheme: true })(withRouterDrawerMenu);
