import React from 'react';
import { Card, CardContent, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const MedicalHistoryCard = ({ classes, children, heading, subHeading, radioButtons }) => (
  <Card className={classes.card}>
    <CardContent classes={{ root: classes.contentRoot }}>
      <Typography className={classes.heading} variant="h6">
        {heading}
      </Typography>
      <div className={classes.formContainer}>
        <div className={classes.formHeaderWrapper}>
          <Typography className={classes.formHeader}>{subHeading}</Typography>
        </div>
        <div>{radioButtons}</div>
      </div>
      <div>{children}</div>
    </CardContent>
  </Card>
);

const styles = theme => ({
  card: {
    width: '100%',
    margin: '2rem auto',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'unset', // allows select menu to expand beyond card
  },
  contentRoot: {
    flex: 1,
    padding: '1rem 1rem 0px',
  },
  title: {
    fontSize: 23,
    marginLeft: '1rem',
  },
  heading: {
    color: theme.palette.primary.darkGray,
    marginBottom: '0.5rem',
  },
  formContainer: {
    display: 'flex',
  },
  formHeaderWrapper: {
    flex: 1,
    paddingRight: '1rem',
  },
  formHeader: {
    color: theme.palette.primary.darkGray,
    marginBottom: '0.5rem',
    fontSize: 14,
  },
});

MedicalHistoryCard.propTypes = {
  classes: PropTypes.object.isRequired,

  children: PropTypes.any,
  heading: PropTypes.string.isRequired,
  radioButtons: PropTypes.object.isRequired,
  subHeading: PropTypes.string.isRequired,
};

MedicalHistoryCard.defaultProps = {
  children: null,
};

export default withStyles(styles)(MedicalHistoryCard);
