export const relationshipTypes = {
  RELATIONSHIP_SELF: { value: 'self', label: 'Self' },
  RELATIONSHIP_CHILD: { value: 'child', label: 'Child' },
  RELATIONSHIP_SPOUSE: { value: 'spouse', label: 'Spouse' },
  RELATIONSHIP_OTHER: { value: 'other', label: 'Other' }
};

export const getRelationshipOptions = () => {
  return Object.keys(relationshipTypes).map(key => {
    const { label, value } = relationshipTypes[key];
    return {
      label,
      value
    };
  });
};

export default { relationshipTypes, getRelationshipOptions };
