/* eslint-disable class-methods-use-this */
import auth0 from 'auth0-js';
import { isNil } from 'lodash';
import moment from 'moment';
import { config as brandConfig } from '@brand';

let auth0Config = brandConfig.auth0;

export const INACTIVITY_TIMEOUT_MS = 15 * 60 * 1000;
// export const INACTIVITY_TIMEOUT_MS = 5000; // debug 5s
export const INACTIVITY_TIMEOUT_POLLING_INTERVAL_MS = 1000;

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      clientID: auth0Config.clientId,
      domain: auth0Config.domain,
      scope: 'openid profile'
    });
  }

  login(username, password, onSuccess, onError) {
    this.auth0.client.login(
      {
        username,
        password,
        realm: auth0Config.realm // realm provided as param here instead of constructor
      },
      (err, result) => {
        if (!isNil(err)) {
          // error
          onError();
        } else if (!isNil(result)) {
          // success
          this.setSession(result, onSuccess, onError);
        } else {
          onError();
        }
      }
    );
  }

  forgotPassword(email, onSuccess, onError) {
    this.auth0.changePassword(
      {
        email,
        connection: auth0Config.realm
      },
      (err, result) => {
        if (!isNil(err)) {
          // error
          onError(err);
        } else if (!isNil(result)) {
          // success
          onSuccess();
        } else {
          onError(err);
        }
      }
    );
  }

  setSession(authResult, onSuccess, onError) {
    try {
      window.localStorage.setItem('access_token', authResult.accessToken);
      window.localStorage.setItem('id_token', authResult.idToken);
      window.localStorage.setItem('expiresAt', moment() + authResult.expiresIn);
      onSuccess(authResult.idToken);
    } catch (e) {
      onError({ err: e });
    }
  }

  getAuthToken() {
    return window.localStorage.getItem('id_token');
  }

  async clearSession(isTimeout = false) {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('id_token');
    window.localStorage.removeItem('expiresAt');
  }

  logout() {
    this.clearSession();
    this.auth0.logout({ returnTo: auth0Config.logoutRedirectUrl });
  }
}

export default new Auth();
