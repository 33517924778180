import React, { Component } from 'react';
import {
  withStyles,
  List,
  ListItem,
  Typography,
  Paper,
  Checkbox,
  Button,
  Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNil, find } from 'lodash';
import LoadingOverlay from '../../../common/loadingOverlay/loadingOverlay.component';
import { getLegalTerms } from '../../legal/legal.actions';
import CustomDialog from '../../../common/customDialog/customDialog.component';
import Languages from '../../language/languages';

const styles = theme => ({
  formContainer: {
    margin: '1rem',
    padding: '1rem'
  },
  button: {
    '&:hover': {
      background: 'none',
      textDecoration: 'underline'
    },
    padding: 0
  },
  listItemRoot: {
    justifyContent: 'space-between'
  },
  listItemTextRoot: {
    alignItems: 'center',
    display: 'flex'
  }
});

class VisitLegalTermsForm extends Component {
  state = {
    termOpen: false,
    selectedTerm: null
  };

  async componentDidMount() {
    this.props.getLegalTerms();
  }

  handleToggleLegalTerm = term => {
    this.props.toggleLegalAccepted(term);
  };

  showTerms = termId => {
    this.setState({
      termOpen: true,
      selectedTerm: find(this.props.legalTerms, { id: termId })
    });
  };

  handleDialogAction = () => {
    if (this.props.selectedLegalTerms.indexOf(this.state.selectedTerm.id) === -1) {
      this.handleToggleLegalTerm(this.state.selectedTerm);
    }
    this.setState({ termOpen: false, selectedTerm: null });
  };

  handleDialogClose = () => {
    if (this.props.selectedLegalTerms.indexOf(this.state.selectedTerm.id) !== -1) {
      this.handleToggleLegalTerm(this.state.selectedTerm);
    }
    this.setState({ termOpen: false, selectedTerm: null });
  };

  renderDialogContent() {
    return <Typography>{this.state.selectedTerm.text}</Typography>;
  }

  render() {
    const {
      classes,
      legalTerms,
      isLoading,
      selectedLegalTerms,
      theme,
      selectedLanguageKey
    } = this.props;
    const language = Languages[selectedLanguageKey].strings;

    if (isLoading) return <LoadingOverlay />;

    return (
      <Paper className={classes.formContainer}>
        <Typography variant="h4" style={{ fontSize: '1.25rem', textAlign: 'center' }}>
          {language.legalTermsInfo}
        </Typography>
        {!isNil(legalTerms) ? (
          <List>
            {legalTerms.map(term => (
              <ListItem key={term.id} classes={{ root: classes.listItemRoot }}>
                <Grid container>
                  <Grid item xs={2}>
                    <Checkbox
                      style={{ color: theme.palette.primary.main }}
                      checked={selectedLegalTerms.includes(term.id)}
                      onChange={() => this.handleToggleLegalTerm(term)}
                    />
                  </Grid>
                  <Grid item xs={10} classes={{ item: classes.listItemTextRoot }}>
                    <Typography variant="subtitle1" color="textPrimary">
                      {`${language.legalTermsAgree} `}
                      <Button
                        size="small"
                        className={classes.button}
                        onClick={() => this.showTerms(term.id)}
                      >
                        <Typography
                          component="span"
                          color="textPrimary"
                          style={{ textDecoration: 'underline' }}
                        >
                          {term.name}
                        </Typography>
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h6">{language.legalTermsEmpty}</Typography>
            </div>
          )}
        {!isNil(this.state.selectedTerm) && (
          <CustomDialog
            fullScreen
            open={this.state.termOpen}
            handleClose={this.handleDialogClose}
            actionText={language.buttonText.agree}
            cancelText={language.buttonText.disagree}
            title={this.state.selectedTerm.name}
            content={this.renderDialogContent()}
            handleAction={this.handleDialogAction}
          />
        )}
      </Paper>
    );
  }
}

VisitLegalTermsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  legalTerms: PropTypes.array,
  selectedLanguageKey: PropTypes.string.isRequired,
  selectedLegalTerms: PropTypes.array.isRequired,
  toggleLegalAccepted: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    isLoading: state.legal.isLoading,
    legalTerms: state.legal.legalTerms,
    selectedLanguageKey: state.language.selectedLanguageKey
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, {
    getLegalTerms
  })(VisitLegalTermsForm)
);
