/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Button,
  Link,
  Paper,
  Card,
  CardContent,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  Icon,
  ListItemText,
} from '@material-ui/core';
import { isNil, has, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { compose } from 'recompose';
import ReactRouterPropTypes from 'react-router-prop-types';

import CustomDialog from '../../common/customDialog/customDialog.component';
import TextFieldValidator from '../../common/inputValidators/textFieldValidator.component';
import DateFieldValidator from '../../common/inputValidators/dateFieldValidator.component';
import Languages from '../language/languages';
import {
  required,
  validCharacters,
  dateIsInThePast,
  DATE_INPUT_DATE_FORMAT,
  DATE_FIELD_MASK,
} from '../../utilities/fieldValidation';
import { facilityDocumentSearch, FACILITY_PATIENT_DOC_SUCCESS } from './facility.actions';
import { config as brandConfig } from '@brand';

const DATE_FORMAT = 'MM/DD/YYYY';

const initialState = {
  firstName: '',
  lastName: '',
  dob: '',
  password: '',
  formErrors: {},
  isShowTerms: false,
  isShowPrivacy: false,
  noteResults: [],
  selectedNote: null,
  isShowNoteDialog: false,
  error: null,
};

class FacilityDocumentSearch extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleDocumentLogin = this.handleDocumentLogin.bind(this);
  }

  async handleDocumentLogin(evt) {
    evt.preventDefault();

    this.setState({
      error: null,
    });

    const payload = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      password: this.state.password,
      dob: this.state.dob,
    };

    const result = await this.props.facilityDocumentSearch(payload);
    if (result.type === FACILITY_PATIENT_DOC_SUCCESS) {
      this.setState({
        noteResults: result.response.data,
      });
    } else {
      const language = Languages[this.props.selectedLanguageKey].strings;
      this.setState({
        error: language.patientDocumentError,
      });
    }
  }

  handleValidationChange = field => error => {
    const updateErrors = { ...this.state.formErrors };

    if (isNil(error)) {
      delete updateErrors[field];
    } else {
      updateErrors[field] = error;
    }
    this.setState({
      formErrors: updateErrors,
    });
  };

  // update can be event or value
  handleChange = field => update => {
    const updateState = { ...this.state };
    updateState[field] =
      update !== null && has(update.target, 'value') ? update.target.value : update;

    // clear error state on edit
    updateState.error = null;
    this.setState(updateState);
  };

  // force password to always be lowercase
  handlePasswordChange = field => evt => {
    const value = evt.target.value.toLowerCase();
    this.setState({
      [field]: value,
    });
  };

  handleShowNote = note => {
    this.setState({ isShowNoteDialog: true, selectedNote: note });
  };

  handleCloseNoteDialog = () => {
    this.setState({ isShowNoteDialog: false, selectedNote: null });
  };

  // error if required fields are missing
  // or if errors contains an entry
  formHasErrors = () => {
    const requiredFields = ['firstName', 'lastName', 'dob', 'password'];
    return (
      requiredFields.some(f => isEmpty(this.state[f])) ||
      !isEmpty(Object.entries(this.state.formErrors))
    );
  };

  handleCloseDocuments = () => {
    this.setState(initialState);
  };

  render() {
    const { classes, selectedLanguageKey, isLoading } = this.props;
    const {
      firstName,
      lastName,
      dob,
      password,
      error,
      noteResults,
      selectedNote,
      isShowNoteDialog,
    } = this.state;

    const language = Languages[selectedLanguageKey].strings;

    // return note (document) results if available
    // or return form to retrieve results
    if (!isEmpty(noteResults)) {
      return (
        <div className={classes.content}>
          <Card className={classes.noteResultsCard}>
            <CardContent>
              <Typography variant="h6">{`${language.notes} (${noteResults.length})`}</Typography>
              <List>
                {noteResults.map((note, i) => {
                  return (
                    <ListItem button onClick={() => this.handleShowNote(note)} key={i}>
                      <ListItemAvatar>
                        <Avatar>
                          <Icon
                            className={classNames(['fas fa-file-medical-alt', classes.listIcon])}
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={note.type}
                        secondary={
                          note.signedDate ? moment(note.signedDate).format(DATE_FORMAT) : null
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
            <Button
              className={classNames(classes.button, classes.doneButton)}
              onClick={this.handleCloseDocuments}
              autoFocus
              variant="outlined"
            >
              Done
            </Button>
          </Card>

          {selectedNote && (
            <CustomDialog
              open={isShowNoteDialog}
              handleClose={this.handleCloseNoteDialog}
              content={<div dangerouslySetInnerHTML={{ __html: selectedNote.html }} />}
              title={`${selectedNote.type} - ${moment(selectedNote.signedDate).format(
                DATE_FORMAT
              )}`}
              cancelOnly
              cancelText={language.buttonText.close}
              maxWidth="md"
            />
          )}
        </div>
      );
    }

    return (
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <Typography variant="h5" className={classes.paperHeading}>
            {language.patientDocumentSearchTitle}
          </Typography>
          <Typography className={classes.paperSubText}>
            {language.patientDocumentSearchSubText}
          </Typography>
          <form className={classes.loginForm} onSubmit={this.handleDocumentLogin}>
            <TextFieldValidator
              label={language.inputLabels.firstName}
              className={classes.textField}
              value={firstName}
              onChange={this.handleChange('firstName')}
              validators={[required, validCharacters]}
              margin="normal"
              variant="outlined"
              onValidationChange={this.handleValidationChange('firstName')}
            />
            <TextFieldValidator
              label={language.inputLabels.lastName}
              className={classes.textField}
              value={lastName}
              onChange={this.handleChange('lastName')}
              validators={[required, validCharacters]}
              margin="normal"
              variant="outlined"
              onValidationChange={this.handleValidationChange('lastName')}
            />
            <DateFieldValidator
              className={classes.dateField}
              keyboard
              clearable
              maxDate={moment().format(DATE_INPUT_DATE_FORMAT)}
              value={dob ? moment(dob, DATE_INPUT_DATE_FORMAT).format(DATE_INPUT_DATE_FORMAT) : ''}
              format={DATE_INPUT_DATE_FORMAT}
              mask={DATE_FIELD_MASK}
              validators={[required, dateIsInThePast(DATE_INPUT_DATE_FORMAT)]}
              handleDateChange={this.handleChange('dob')}
              label={language.inputLabels.dateOfBirth}
              onValidationChange={this.handleValidationChange('dob')}
              openTo="year"
            />
            <TextFieldValidator
              label={language.inputLabels.secretWord}
              className={classes.textField}
              value={password}
              onChange={this.handlePasswordChange('password')}
              validators={[required, validCharacters]}
              margin="normal"
              variant="outlined"
              onValidationChange={this.handleValidationChange('password')}
            />
            <div className={classes.actionsContainer}>
              {/* SEARCH */}
              <Button
                className={classNames(classes.button, classes.submitButton)}
                disabled={this.formHasErrors() || isLoading}
                onClick={this.handleDocumentLogin}
                type="submit"
                variant="outlined"
              >
                {!isLoading ? language.buttonText.documentLogin : language.buttonText.loading}
              </Button>

              {/* CANCEL */}
              <Button
                className={classes.button}
                disabled={isLoading}
                onClick={() => this.props.history.push('/')}
                variant="outlined"
              >
                {language.buttonText.cancel}
              </Button>
            </div>

            {!isNil(error) && <Typography className={classes.error}>{error}</Typography>}
          </form>

          <Typography className={classes.termText}>
            {`${language.patientDocumentFinePrint.clickingSearch} `}
            <Link
              className={classes.gray}
              component="button"
              variant="body2"
              onClick={() => this.setState({ isShowTerms: true })}
            >
              {`${language.patientDocumentFinePrint.termsOfUse}`}
            </Link>
            {` ${language.patientDocumentFinePrint.and} `}
            <Link
              className={classes.gray}
              component="button"
              variant="body2"
              onClick={() => this.setState({ isShowPrivacy: true })}
            >
              {`${language.patientDocumentFinePrint.privacyPolicy}.`}
            </Link>
          </Typography>
        </Paper>
        <CustomDialog
          fullScreen
          open={this.state.isShowTerms}
          handleClose={() => this.setState({ isShowTerms: false })}
          cancelText="Close"
          cancelOnly
          title={language.patientDocumentFinePrint.termsOfUse}
          content={
            <iframe
              title={language.patientDocumentFinePrint.termsOfUse}
              width="100%"
              height="100%"
              frameBorder={0}
              src={brandConfig.termsUrl}
            />
          }
        />
        <CustomDialog
          fullScreen
          open={this.state.isShowPrivacy}
          handleClose={() => this.setState({ isShowPrivacy: false })}
          cancelText="Close"
          cancelOnly
          title={language.patientDocumentFinePrint.privacyPolicy}
          content={
            <iframe
              title={language.patientDocumentFinePrint.privacyPolicy}
              width="100%"
              height="100%"
              frameBorder={0}
              src={brandConfig.privacyPolicyUrl}
            />
          }
        />
      </div>
    );
  }
}

const styles = ({ palette, shadows }) => ({
  noteResultsCard: {
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: shadows[8],
    minWidth: 300,
  },
  listIcon: {
    fontSize: '20px',
    width: 'unset',
    alignSelf: 'center',
    color: palette.common.white,
  },
  content: {
    backgroundColor: palette.primary.background,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: '3rem',
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '1rem',
  },
  textField: {
    width: '100%',
    margin: '1rem auto 0.5rem auto',
    color: palette.primary.darkGray,
  },
  button: {
    color: palette.common.white,
    width: 100,
    // margin: '2rem auto 1rem auto',
    borderRadius: 10,
    backgroundColor: palette.primary.darkGray,
    '&:hover': {
      color: palette.common.white,
      backgroundColor: palette.primary.darkGray,
    },
    '&:disabled': {
      color: palette.primary.darkGray,
      backgroundColor: palette.primary.lightGray,
    },
  },
  paper: {
    padding: '2rem',
    boxShadow: shadows[8],
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1rem',
  },
  paperHeading: {
    color: palette.primary.darkGray,
    marginBottom: '0.5rem',
  },
  paperSubText: {
    color: palette.primary.darkGray,
    fontSize: '1rem',
    fontWeight: 200,
  },
  actionsContainer: {
    alignSelf: 'center',
    display: 'flex',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  doneButton: {
    margin: '1rem auto',
  },
  dateField: {
    marginTop: '1rem',
  },
  submitButton: {
    marginRight: '1rem',
  },
  error: {
    color: palette.error.main,
    margin: '1rem auto',
  },
  termText: {
    color: palette.primary.darkGray,
    margin: '1rem auto',
  },
  gray: {
    color: palette.primary.darkGray,
  },
});

FacilityDocumentSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  isLoading: PropTypes.bool.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  facilityDocumentSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoading: state.facility.isLoading,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    facilityDocumentSearch,
  })
)(FacilityDocumentSearch);
