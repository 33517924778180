import { CALL_API } from '../../middleware/api';

export const GET_SURVEY_QUESTIONS_REQUEST = 'GET_SURVEY_QUESTIONS_REQUEST';
export const GET_SURVEY_QUESTIONS_SUCCESS = 'GET_SURVEY_QUESTIONS_SUCCESS';
export const GET_SURVEY_QUESTIONS_FAILURE = 'GET_SURVEY_QUESTIONS_FAILURE';

export const SEND_SURVEY_ANSWERS_REQUEST = 'SEND_SURVEY_ANSWERS_REQUEST';
export const SEND_SURVEY_ANSWERS_SUCCESS = 'SEND_SURVEY_ANSWERS_SUCCESS';
export const SEND_SURVEY_ANSWERS_FAILURE = 'SEND_SURVEY_ANSWERS_FAILURE';

export const getSurveyQuestions = visitId => {
  return {
    [CALL_API]: {
      types: [GET_SURVEY_QUESTIONS_REQUEST, GET_SURVEY_QUESTIONS_SUCCESS, GET_SURVEY_QUESTIONS_FAILURE],
      authenticated: true,
      endpoint: `v1/patient/visits/${visitId}/survey`,
      method: 'GET'
    }
  };
};

export const sendSurveyAnswers = (visitId, answers) => {
  return {
    [CALL_API]: {
      types: [SEND_SURVEY_ANSWERS_REQUEST, SEND_SURVEY_ANSWERS_SUCCESS, SEND_SURVEY_ANSWERS_FAILURE],
      authenticated: true,
      endpoint: `v1/patient/visits/${visitId}/surveyanswer`,
      method: 'POST',
      payload: {
        answers
      }
    }
  };
};
