import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import ReactRouterPropTypes from 'react-router-prop-types';
import { find } from 'lodash';
import { compose } from 'recompose';

import { getPriorVisits, clearPriorVisits, setPriorVisit } from './priorVisits.actions';
import { setPageTitle } from '../layout/layout.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import PriorVisitListItem from './priorVisitListItem.component';
import Languages from '../language/languages';

class PriorVisits extends Component {
  async componentWillMount() {
    this.props.setPageTitle(
      Languages[this.props.selectedLanguageKey].strings.pageTitles.priorVisits
    );

    const { patientId } = this.props.match.params;
    const { priorVisits } = this.props;

    if (priorVisits.length > 0 && priorVisits[0].patientId !== patientId) {
      this.props.clearPriorVisits();
    }

    await this.props.getPriorVisits(patientId);
  }

  getInPageTitle() {
    const { profile, match, selectedLanguageKey } = this.props;
    const { patientId } = match.params;

    let toReturn = Languages[selectedLanguageKey].strings.pageTitles.priorVisits;

    if (profile.id === patientId) {
      toReturn += ` - ${profile.firstName} ${profile.lastName}`;
    } else if (profile.dependents && profile.dependents.length > 0) {
      const dependent = find(profile.dependents, { id: patientId });
      if (dependent) {
        toReturn += ` - ${dependent.firstName} ${dependent.lastName}`;
      }
    }

    return toReturn;
  }

  handleVisitSelection = priorVisit => {
    this.props.setPriorVisit(priorVisit);

    const { patientId } = this.props.match.params;
    this.props.history.push({
      pathname: `/patient/${patientId}/priorVisits/${priorVisit.id}`,
    });
  };

  render() {
    const { classes, isLoading, priorVisits } = this.props;

    if (isLoading && priorVisits.length === 0) return <LoadingOverlay />;

    return (
      <div className={classes.container}>
        <Grid container justify="center">
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography className={classes.pageTitle} variant="h5">
              {this.getInPageTitle()}
            </Typography>
          </Grid>
          {priorVisits.length > 0 &&
            priorVisits.map(priorVisit => {
              return (
                <Grid
                  key={priorVisit.id}
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  style={{ padding: '0 1rem' }}
                  zeroMinWidth
                >
                  <PriorVisitListItem
                    priorVisit={priorVisit}
                    viewPriorVisitAction={this.handleVisitSelection}
                  />
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.primary.background,
    flex: 1,
    padding: '1rem',
    paddingBottom: '3rem',
  },
  pageTitle: {
    color: theme.palette.primary.darkGray,
    marginBottom: '0.5rem',
  },
});

PriorVisits.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  isLoading: PropTypes.bool,
  priorVisits: PropTypes.array,
  profile: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,

  clearPriorVisits: PropTypes.func.isRequired,
  getPriorVisits: PropTypes.func.isRequired,
  setPriorVisit: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
};

PriorVisits.defaultProps = {
  isLoading: false,
  priorVisits: [],
};

const mapStateToProps = state => {
  return {
    isLoading: state.priorVisits.isLoadingPriorVisits,
    priorVisits: state.priorVisits.priorVisits,
    profile: state.profile.profile,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    clearPriorVisits,
    getPriorVisits,
    setPageTitle,
    setPriorVisit,
  })
)(PriorVisits);
