/*
Upon logging in to the app, or reloading the app, a visit object may be acquired.
Upon receipt of a data notification, the visit's state may have changed
Upon action by the client, the visit's state may have changed


States:
NEW - visit record created patient in the waiting room
SELECTED - provider has selected a patient off the board and is reviewing their chart while waiting on the patient
READY - the patient has indicated they are ready to start the video
STARTED - provider has started the video
CANCELED - provider or patient has canceled the visit
COMPLETED - video has ended


1. NEW - the state can become new if the provider has selected the visit initially, and then cancels that action.
    The waiting room screen should handle updating the verbiage and actions accordingly.
2. SELECTED - the state can become selected if the provider has selected the visit from the provider app board. 
    This should result in showing a modal for the patient to view the visit information before proceeding
3. READY - the state can become ready if the patient indicates they are ready to join a selected visit
    The visit should result in the twilio video room being loaded and the video call connecting
4. STARTED - the state can become started if the provider has acknowledged the visit that the patient has said they are ready for
5. CANCELED - the state can become canceled if the patient (or provider?) select to cancel
    This should close any visit related modals
6. COMPLETED - the state can become completed if the provider has marked the visit as done
    This should close any visit related modals

*/
import history from '../history';

export const VISIT_STATES = {
  NEW: 'new',
  SELECTED: 'selected',
  READY: 'ready',
  STARTED: 'started',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

export const CALL_STATES = {
  COMPLETED: 'completed',
  INITIATED: 'initiated',
  IN_PROGRESS: 'in-progress',
  NO_ANSWER: 'no-answer',
  RINGING: 'ringing',
};

const handleVisitStateChange = (newState, curState, visitId) => {
  console.log('HANDLING VISIT STATE CHANGE: ', newState);
  switch (newState) {
    case VISIT_STATES.NEW:
      console.log('NEW STATE', newState, 'CUR STATE', curState);
      if (curState !== VISIT_STATES.SELECTED) {
        setTimeout(() => history.push('/waitingRoom'));
      }

      break;

    case VISIT_STATES.SELECTED:
      setTimeout(() => history.push('/waitingRoom'));
      break;

    case VISIT_STATES.READY:
      setTimeout(() => history.push(`/visit/${visitId}`));
      break;

    case VISIT_STATES.STARTED:
      break;

    case VISIT_STATES.CANCELED:
      setTimeout(() => history.replace('/', { isReloadProfile: true }));
      break;

    case VISIT_STATES.COMPLETED:
      setTimeout(() => history.push(`/visit/${visitId}/survey`));
      break;

    default:
      break;
  }
};

export default {
  handleVisitStateChange,
};
