import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Card } from '@material-ui/core';
import classnames from 'classnames';
import alertTypes from '../../types/alertTypes';

const MessageBanner = ({ classes, message, type }) => {
  const getTypeStyles = () => {
    switch (type) {
      case alertTypes.CRITICAL:
        return classes.critical;

      case alertTypes.NORMAL:
        return classes.normal;

      default:
        return classes.alert;
    }
  };

  return (
    <Card className={classnames([classes.bannerContainer, getTypeStyles()])}>
      <Typography color="inherit" variant="h6">
        {message}
      </Typography>
    </Card>
  );
};

const styles = theme => ({
  bannerContainer: {
    borderRadius: 0,
    color: theme.palette.common.white,
    padding: theme.spacing.unit * 2,
  },
  alert: {
    backgroundColor: theme.palette.primary.warning,
  },
  critical: {
    backgroundColor: theme.palette.error.main,
  },
  normal: {
    backgroundColor: theme.palette.primary.main,
  },
});

MessageBanner.propTypes = {
  classes: PropTypes.object.isRequired,

  message: PropTypes.string.isRequired,
  type: PropTypes.string,
};

MessageBanner.defaultProps = {
  type: alertTypes.ALERT,
};

export default withStyles(styles)(MessageBanner);
