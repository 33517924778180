import brandNLS from '@brand/language/EN_US';
import { config as brandConfig } from '@brand';

export default {
  accountAlready: 'Have an account already?',
  accountFacilities:
    'Your account is associated with one or more facilities. Would you like to log in to a facility?',
  addCard: 'Add a Card',
  addANewPatient: 'Add a New Patient',
  addANewPatientSubText: 'Click below to add a new patient',
  alertMessages: {
    backButtonConfirm: 'You have unsaved changes. Clicking OK will not save these changes.',
    cancelAddingDependent:
      'Are you sure you want to cancel adding a dependent? Any unsaved changes will be lost.',
    cancelVisit: 'Cancel Visit',
    confirmCancelVisit: 'Are you sure you want to cancel the visit?',
    confirmExitMedical:
      'Are you sure you want to exit medical history update? Any unsaved changes will be lost.',
    confirmExitMedicalTitle: 'Cancel History Edit',
    confirmExitPatientEditTitle: 'Cancel Editing Patient',
    confirmExitPatientCreateTitle: 'Cancel Creating Patient',
    confirmExitPatientEdit:
      'Are you sure you want to exit editing a patient? Any unsaved changes will be lost.',
    confirmExitPatientCreate:
      'Are you sure you want to exit creating a patient? Any unsaved changes will be lost.',
    confirmExitPayment:
      'Are you sure you want to exit payment information? Any unsaved changes will be lost.',
    confirmExitPaymentTitle: 'Cancel Payment Edit',
    confirmExitProfileTitle: 'Cancel Profile Edit',
    confirmExitProfileUpdate:
      'Are you sure you want to exit profile update? Any unsaved changes will be lost.',
    confirmLogout: 'Are you sure you want to logout?',
  },
  alertMessageTitles: {
    cancelAddingDependent: 'Cancel Adding a Child',
    forgotPassword: 'Forgot Password',
    selectFacility: 'Select Facility',
  },
  buttonText: {
    addAChild: 'add a child',
    addChild: 'add child',
    addAPatient: 'add a patient',
    addPatient: 'add patient',
    agree: 'agree',
    back: 'back',
    cancelling: 'cancelling',
    cancelVisit: 'cancel visit',
    disagree: 'disagree',
    documentLogin: 'Search',
    dontCancel: "don't cancel",
    edit: 'edit',
    enterCreditCard: 'enter credit card',
    cancel: 'cancel',
    close: 'close',
    createAccount: 'create account',
    loading: 'loading...',
    login: 'login',
    logout: 'logout',
    previousVisits: 'previous visits',
    save: 'save',
    saveInsurance: 'save insurance',
    savePatient: 'save patient',
    saveProfile: 'save profile',
    seeProvider: 'see a provider',
    select: 'select',
    startVisit: 'start visit',
    start: 'start',
    ready: "i'm ready",
    retry: 'retry',
    starting: 'starting',
    submitSurvey: 'submit answers',
    submitting: 'submitting answers...',
    switchUser: 'Switch user',
    waiting: 'waiting',
  },
  carrier: 'Carrier',
  policyNumber: 'Policy/Group Number',
  noInsurance: 'No Insurance Information Provided',
  createAccountFinePrint: {
    and: 'and',
    clickingLogin: 'By clicking login, you agree to the',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
  },
  closed: 'CLOSED',
  createDependentTitle: 'Create Dependent',
  createPatientTitle: 'Create Patient',
  currentLocation: 'Current Location',
  currentPhoneNumber: 'Current Phone Number',
  editMedicalHistoryTitle: 'Edit Medical History',
  editPatientTitle: 'Edit Patient',
  editPharmacyTitle: 'Edit Pharmacy',
  enterPaymentMethod: 'Please enter a payment method',
  emptyPharmacies: 'There are no nearby pharmacies',
  howPaymentMethod: 'How would you like to pay?',
  genderRequired: 'Gender is required',
  forgotPassword: 'Forgot Password?',
  incorrectLogin: 'Incorrect Email or Password',
  inputLabels: {
    addressLineOne: 'Address Line 1',
    addressLineTwo: 'Address Line 2',
    city: 'City',
    couponCode: 'Coupon Code',
    creditCardNumber: 'Credit Card Number',
    dateOfBirth: 'Date of Birth',
    email: 'Email',
    employerCode: 'Employer Code',
    firstName: 'First Name',
    gender: 'Gender',
    lastName: 'Last Name',
    mobileNumber: 'Mobile Number',
    password: 'Password',
    pharmacySearch: 'Search for Pharmacies Near',
    reasonForVisit: 'Reason for visit',
    relationshipToInsured: 'Relationship to Insured',
    secretWord: 'Secret Word',
    state: 'State',
    weight: 'Weight (lbs.)',
    zipCode: 'Zip Code',
    carrierName: 'Carrier Name',
    policyNumber: 'Policy/Group Number',
    subscriberAddressLineOne: 'Subscriber Address Line 1',
    subscriberAddressLineTwo: 'Subscriber Address Line 2',
    subscriberCity: 'Subscriber City',
    subscriberDateOfBirth: 'Subscriber Date of Birth',
    subscriberGender: 'Subscriber Gender',
    subscriberId: 'Member/Subscriber ID',
    subscriberState: 'Subscriber State',
    subscriberName: 'Subscriber Name',
    subscriberZipCode: 'Subscriber Zip Code',
  },
  insuranceAddPhoto: 'Insurance Card',
  insuranceCardImageProvided: 'Insurance card image provided',
  insuranceDescription:
    'Your visit may be eligible for a refund. Please enter your insurance information and upload a picture of your card, including Medicare and Medicaid, before starting a new visit.',
  insurancePhotoDescription: 'Please provide photos of the front and back of all insurance cards',
  legalTermsAgree: 'I have read and agreed to the',
  legalTermsInfo:
    'Please review the following documents, use the checkbox to indicate your agreement',
  legalTermsEmpty: 'No Legal Terms',
  loading: 'Loading...',
  locationAndPhone: 'Current Location and Phone Number',
  locationInfo: 'This information will be used in the event of an emergency.',
  loggedInAs: 'Logged in as',
  login: 'Login',
  loginCreateAnAccount: 'Create one now.',
  loginDontHaveAnAccount: "Don't have an account?",
  documentSearch: 'Document Search',
  loginHeader: `${brandConfig.appName} gives you quick access to local, highly-trained medical providers for unscheduled healthcare needs whether you're at home or on the go`,
  loginListTitle: `How does ${brandConfig.appName} work?`,
  loginList: {
    itemOne: `If it's your first time using ${brandConfig.appName}, you'll need to create an account - this will only take a few minutes.`,
    itemTwo: 'Provide a bit of your medical history and the reason for your visit.',
    itemThree:
      'Once complete, the provider will review your medical info. The average wait time is less than 10 minutes.',
    itemFour: "When the provider is ready, you'll be notified that it's time to start your visit.",
    itemFive: 'Start feeling better, fast!',
  },
  logout: 'Logout',
  medicalHistoryCards: {
    allergies: {
      subText: 'Do you have any allergies to foods or medications?',
      inputLabelText: 'Please enter your allergies',
      label: 'Allergies',
    },
    conditions: {
      subText:
        'Do you have any medical conditions, such as high blood pressure, diabetes, or depression?',
      inputLabelText: 'Please enter your medical conditions',
      label: 'Medical Conditions',
    },
    medications: {
      subText: 'Do you take any medications (including over the counter medications)?',
      inputLabelText: 'Please enter your medications',
      label: 'Medications',
    },
    surgical: {
      subText: 'Have you had any surgeries or medical procedures?',
      inputLabelText: 'Please enter your surgeries',
      label: 'Surgical History',
    },
  },
  medicalHistoryErrors: {
    allergies: 'Allergies are required',
    conditions: 'Medical Condiditons are required',
    medications: 'Medications are required',
    surgeries: 'Surgeries are required',
  },
  medicalHistoryTitle: 'Medical History',
  no: 'No',
  none: 'None',
  noPaymentMethodRequired: 'No Payment is Required',
  noPaymentMethods: 'No Payment Methods Available',
  noPharmacySelected: 'No Pharmacy Selected',
  notes: 'Notes',
  open: 'OPEN',
  open24: 'OPEN 24 HOURS',
  pageTitles: {
    dependentProfile: 'Dependent Profile',
    facilityDocumentSearch: 'Patient Document Search',
    home: 'Home',
    login: 'Login',
    priorVisits: 'Prior Visits',
    priorVisit: 'Prior Visit',
    patientVisit: 'Patient Visit',
    paymentMethod: 'Payment Method',
    startVisit: 'Start a Visit',
    survey: 'Survey',
    waitingRoom: 'Waiting Room',
    yourProfile: 'Your Profile',
  },
  patientDocumentFinePrint: {
    and: 'and',
    clickingSearch: 'By clicking Search, you agree to the',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
  },
  patientDocumentError: 'No Documents Found',
  patientDocumentSearchTitle: 'Patient Documents',
  patientDocumentSearchSubText: 'Enter patient information to search for documents.',
  paymentFormTitle: 'The cost of your visit will be:',
  paymentFormInfo: 'You will not be charged until after your visit.',
  paymentOptionsHeading: 'How would you like to pay?',
  paymentFormDescription: '',
  permission: 'Permission',
  permissionsBlocked:
    "Permissions are blocked. Please adjust your browser's permission settings to continue.",
  permissionsInfo: 'These permissions are required to start your visit.',
  permissionsGranted: 'Permissions Granted',
  personalAccount: 'Personal Account',
  personalAccountLogin: 'Would you like to login with your personal account?',
  pharmacySearch: 'Search for Pharmacies',
  pharmacySelected: 'You have selected:',
  prescription: 'Prescription',
  prescriptions: 'Prescriptions',
  prescriptionInfo: {
    genericName: 'Generic Name:',
    dateInactive: 'Date Inactive:',
    quantity: 'Quantity:',
    daysSupply: 'Days Supply:',
    dispenseUnit: 'Dispense Unit:',
    refills: 'Refills:',
    otc: 'OTC:',
    directions: 'Directions:',
    comment: 'Comment:',
    pharmacyNotes: 'Pharmacy Notes:',
    pharmacy: 'Pharmacy:',
    address: 'Address:',
  },
  request: 'Request',
  saving: 'Saving',
  saveSurveyInfo: 'Please complete this short survey about your visit',
  searchForPatient: "Enter a patient's name to get started",
  showToastMessages: {
    cancelVisitFailure:
      'Your visit has not been cancelled. Please check your connection and try again.',
    createDependentFailure:
      'Could not create dependent. Please check your connection and try again.',
    createDependentSuccess: 'Dependent created successfully.',
    createPatientFailure: 'Could not create patient. Please check your connection and try again.',
    forgotPasswordFailure: 'Forgot Password Email Failed.',
    forgotPasswordSuccess: 'Forgot Password Email Successfully Sent.',
    invalidMedicalHistory:
      'You must respond to each medical history question before moving forward',
    loginFailure: 'Wrong email or password.',
    missingHardware:
      'See a Provider is disabled. To start a visit it is required that you have a camera.',
    pdfDownloadFailure: 'There was an error downloading the pdf.',
    saveSurveyFailure:
      "If you've completed this survey elsewhere please close the survey to get back to the home screen.",
    startVisitFailure:
      'Your visit has not been started. Please check your connection and try again.',
    uploadImageFailure: 'There was a problem uploading the image. Please Try Again.',
    updateDependentFailure:
      'Could not update dependent profile. Please check your connection and try again.',
    updateDependentSuccess: 'Dependent updated successfully.',
    updateDependentMedicalHistoryFailure:
      "There was a problem updating the dependent's medical history. Please check your connection and try again.",
    updateDependentMedicalHistorySuccess: 'Dependent medical history has been updated.',
    updateFacilitatedPatientMedicalHistorySuccess: 'Patient medical history has been updated.',
    updateFacilitatedPatientMedicalHistoryFailure:
      "There was a problem updating the patient's medical history. Please check your connection and try again.",
    updateFacilitatedPatientFailure:
      'There was a problem updating patient information. Please check your connection and try again',
    updateInsuranceSuccess: 'Successfully updated insurance information.',
    updateInsuranceFailure:
      'There was a problem updating your insurance information. Please check your connection and try again.',
    insuranceAttachmentLimit: 'You can only upload 4 attachments, please try again.',
    updateMedicalHistoryFailure:
      'There was a problem updating your medical history. Please check your connection and try again.',
    updateMedicalHistorySuccess: 'Your medical history has been updated.',
    updatePatientFailure: 'There was a problem saving patient information.',
    updatePaymentFailure: 'There was a problem saving your payment information.',
    updateProfileFailure: 'Could not update profile. Please check your connection and try again.',
    updateProfileSuccess: 'Your profile has been updated.',
  },
  subscriber: 'Subscriber',
  subscriberId: 'Subcriber / Member ID',
  sumCardDemographics: 'Demographics',
  sumCardMedical: {
    medications: 'Medications',
    conditions: 'Medical Conditions',
    allergies: 'Allergies',
    surgeries: 'Surgeries',
  },
  sumCardPharmacy: 'Pharmacy',
  sumCardPayment: 'Payment Method',
  sumCardInsurance: 'Insurance',
  switchAccount: 'Switch Account',
  switchAccounts: 'Switch Accounts',
  visitIsVoice:
    'This visit has been converted to a voice call. Please wait for the provider to call.',
  visitVoiceNoAnswer:
    'Your provider called and you did not answer. Please wait for the provider to try again.',
  visitOnDevice: 'You are currently in a visit on another device',
  visitCallFailed: 'You are not currently in a call',
  visitCallWaiting: 'Waiting on the provider to connect...',
  visitConfirmInfoHeading: 'Please confirm your information',
  visitConfirmInfoLabel: 'Confirm Info',
  visitDetailsAddPhoto: 'Add a photo',
  visitDetailsPhotoDescription: 'The is useful for cuts and rashes.',
  visitInsuranceHeading: 'Insurance Information',
  visitMedicalHistoryLabel: 'Med History',
  visitPatientInfoHeading: 'Profile Information',
  visitPatientInfoLabel: 'Patient Info',
  visitPatientInfoSubHeading: 'This information will be saved to make your next visit even easier',
  visitPaymentInfoHeading: 'Payment Information',
  visitPaymentInfoLabel: 'Payment Info',
  visitPermissionsLabel: 'Permissions',
  visitPharmacyHeading: 'Pharmacy Selection',
  visitPharmacyLabel: 'Pharmacy',
  visitStatusMessages: {
    default: "You're currently visiting with the provider",
    isVoiceCall: 'This visit was converted to a voice call, your provider will call you shortly',
    new: 'Your provider will be with you shortly.',
    selected: 'The provider is ready to see you now.',
  },
  visitTermsHeading: 'Visit Terms',
  visitTermsLabel: 'Visit Terms',
  visitTodaysVisitHeading:
    'Please briefly describe the reason for your visit today (i.e. cold symptoms, sore throat, etc).',
  visitTodaysVisitLabel: "Today's Visit",
  visitVideoFailed:
    'Connection to the video call has failed, please check your connection and try again.',
  waitingRoomMessages: {
    haveAllergies: 'You have the following allergies:',
    haveSurgeries: 'You have the following prior surgeries:',
    medicalInfoTitle: 'Your Medical Info',
    medsAtHome: 'medications that you are taking at home',
    nextStepsText:
      "The provider will review your medical info and you'll be notified when you are ready to be seen.",
    noAllergies: 'You have no known allergies',
    noMedications: "You aren't currently taking any medicaiton",
    noSurgeries: 'You have no prior surgeries',
    reasonForVisit: 'Reason for Visit',
    reviewedMedical: 'We have reviewed your medical history',
    reviewedMedications: 'We have reviewed the',
    whatsNext: "What's Next?",
  },
  yes: 'Yes',
  ...brandNLS,
};
