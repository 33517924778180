/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Icon,
  withStyles,
} from '@material-ui/core';
import { isNil } from 'lodash';

const CustomDialog = ({
  actionButtonProps,
  actionText,
  cancelOnly,
  cancelText,
  classes,
  content,
  customAction,
  customActionText,
  disableActions,
  fullScreen,
  fullWidth,
  handleAction,
  handleClose,
  isActionDisabled,
  isDownloadingPdf,
  isShowCloseIcon,
  maxWidth,
  open,
  pdfAction,
  title,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    disableBackdropClick
    disableEscapeKeyDown
    fullScreen={fullScreen}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
  >
    <DialogTitle>
      <Typography className={classes.title}>{title}</Typography>
      {isShowCloseIcon && !isNil(handleClose) && (
        <IconButton
          key="close"
          color="inherit"
          onClick={handleClose}
          className={classes.iconButton}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
    <DialogContent>{content}</DialogContent>
    {!disableActions && (
      <DialogActions style={{ margin: fullScreen ? '1rem 3rem 1rem 0' : '1rem' }}>
        {pdfAction && (
          <Button
            onClick={pdfAction}
            className="button"
            variant="outlined"
            {...actionButtonProps}
            disabled={isDownloadingPdf}
          >
            <Icon className="fas fa-file-pdf" style={{ marginRight: '0.25rem' }} />
            {isDownloadingPdf ? 'Downloading PDF...' : 'View PDF'}
          </Button>
        )}
        {customAction && (
          <Button
            onClick={customAction}
            className="button"
            variant="outlined"
            {...actionButtonProps}
          >
            {customActionText || 'OK'}
          </Button>
        )}
        {!cancelOnly && (
          <Button
            onClick={handleAction}
            autoFocus
            className="button"
            variant="outlined"
            disabled={isActionDisabled}
            {...actionButtonProps}
          >
            {actionText || 'OK'}
          </Button>
        )}
        <Button onClick={handleClose} className="button" variant="outlined">
          {cancelText || 'CANCEL'}
        </Button>
      </DialogActions>
    )}
  </Dialog>
);

const styles = theme => ({
  title: {
    color: theme.palette.primary.darkGray,
    fontSize: '1.3rem',
  },
  iconButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

CustomDialog.propTypes = {
  classes: PropTypes.object.isRequired,

  actionButtonProps: PropTypes.object,
  actionText: PropTypes.string,
  cancelOnly: PropTypes.bool,
  cancelText: PropTypes.string,
  content: PropTypes.element,
  customActionText: PropTypes.string,
  disableActions: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isActionDisabled: PropTypes.bool,
  isDownloadingPdf: PropTypes.bool,
  isShowCloseIcon: PropTypes.bool,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,

  customAction: PropTypes.func,
  pdfAction: PropTypes.func,
  handleAction: PropTypes.func,
  handleClose: PropTypes.func,
};

CustomDialog.defaultProps = {
  actionButtonProps: {},
  actionText: '',
  cancelOnly: false,
  cancelText: '',
  content: null,
  customActionText: '',
  disableActions: false,
  fullScreen: false,
  fullWidth: true,
  isActionDisabled: false,
  isDownloadingPdf: false,
  isShowCloseIcon: false,
  maxWidth: 'xs',

  customAction: null,
  handleAction: () => {},
  handleClose: () => {},
  pdfAction: null,
};

export default withStyles(styles)(CustomDialog);
