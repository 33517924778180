import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Typography, withStyles } from '@material-ui/core';
import { isEmpty, has } from 'lodash';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import { compose } from 'recompose';
import { setPageTitle, setDirtyForm, showToast } from '../layout/layout.actions';
import PatientProfileForm from './forms/patientProfileForm.component';
import accountTypes from '../../types/accountTypes';
import CustomDialog from '../../common/customDialog/customDialog.component';
import { lessThan12YearsAgo } from '../../utilities/fieldValidation';
import { updateDependent, UPDATE_DEPENDENT_SUCCESS } from '../dependents/dependent.actions';
import {
  updateProfile,
  UPDATE_PROFILE_SUCCESS,
  GET_PROFILE_SUCCESS,
  getProfile,
} from '../profile/profile.actions';
import toastTypes from '../../types/toastTypes';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import Languages from '../language/languages';
import { logEvent } from '../../utilities/googleAnalytics';

import { config as brandConfig } from '@brand';

const { entranceId } = brandConfig;

const initialProfile = {
  firstName: '',
  lastName: '',
  dob: '',
  weight: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: '',
  addressZip: '',
  gender: '',
};

class PatientProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: has(props, 'location.state.profile') ? props.location.state.profile : initialProfile,
      isShowCancelDialog: false,
      formIsDirty: false,
      formErrors: [],
      isInitializing: false,
    };
  }

  async componentDidMount() {
    if (!has(this.props, 'location.state.profile')) {
      this.setState({
        isInitializing: true,
      });

      const result = await this.props.getProfile();
      if (result.type === GET_PROFILE_SUCCESS) {
        // check main profile then dependents for profile
        if (result.response.id === this.props.match.params.patientId) {
          const patientProfile = result.response;
          patientProfile.accountType = accountTypes.ACCOUNT_HOLDER;
          this.setState({
            profile: patientProfile,
            isInitializing: false,
          });
          this.props.setPageTitle(
            Languages[this.props.selectedLanguageKey].strings.pageTitles.yourProfile
          );
        } else {
          const foundProfile = result.response.dependents.find(
            dep => dep.id === this.props.match.params.patientId
          );
          if (foundProfile) {
            foundProfile.accountType = accountTypes.CHILD;
            if (!isEmpty(foundProfile)) {
              this.setState({
                profile: foundProfile,
                isInitializing: false,
              });
              this.props.setPageTitle(
                Languages[this.props.selectedLanguageKey].strings.pageTitles.dependentProfile
              );
            }
          } else {
            this.props.history.replace('/');
          }
        }
      }
    }
  }

  handleChange = name => update => {
    const updateProfile = { ...this.state.profile };

    // see if update is an event, if not it is a value already
    updateProfile[name] = has(update.target, 'value') ? update.target.value : update;

    this.setState({
      profile: updateProfile,
      formIsDirty: true,
    });

    this.props.setDirtyForm(true);
  };

  handleValidationChange = field => error => {
    const updateErrors = { ...this.state.formErrors };
    updateErrors[field] = error;
    this.setState({
      formErrors: updateErrors,
    });
  };

  handleShowCancelDialog = () => {
    this.setState({
      isShowCancelDialog: true,
    });
  };

  handleCloseCancelDialog = () => {
    this.setState({
      isShowCancelDialog: false,
    });
  };

  handleSaveProfile = () => {
    const { profile } = this.state;
    if (profile.accountType === accountTypes.ACCOUNT_HOLDER) {
      this.saveAccountHolderProfile();
    } else {
      this.saveDependentProfile();
    }
  };

  // update profile and show a toast message if successful
  saveAccountHolderProfile = async () => {
    const updateProfile = { ...this.state.profile };
    const deleteFields = [
      'accountType',
      'dependents',
      'email',
      'id',
      'incomplete',
      'weight',
      'currentVisit',
      'pharmacyPlaceId',
      'pharmacyDoseSpotId',
      'facilities',
      'pricingData',
    ];

    // delete unneeded fields
    deleteFields.forEach(field => delete updateProfile[field]);

    // disallow null/undefined/blank properties
    for (const key in updateProfile) {
      if (
        updateProfile[key] === null ||
        updateProfile[key] === undefined ||
        isEmpty(updateProfile[key])
      ) {
        delete updateProfile[key];
      }
    }

    const response = await this.props.updateProfile(updateProfile);

    if (response.type === UPDATE_PROFILE_SUCCESS) {
      logEvent('profile_edit', {
        patientId: updateProfile.id,
        entranceId,
        accountId: updateProfile.id,
      });
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.updateProfileSuccess
      );
      this.props.history.goBack();
    } else {
      showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.updateProfileFailure,
        toastTypes.ERROR,
        true,
        null,
        response.messages[0]
      );
    }
  };

  saveDependentProfile = async () => {
    const dependent = { ...this.state.profile };
    const dependentId = dependent.id;

    if (dependent.addressLine2 === '' || dependent.addressLine2 === null)
      delete dependent.addressLine2;

    if (!lessThan12YearsAgo('YYYY-MM-DD')(dependent.dob)) delete dependent.weight;

    delete dependent.id;
    delete dependent.accountType;
    delete dependent.pharmacyPlaceId;
    delete dependent.pharmacyDoseSpotId;
    delete dependent.incomplete;

    const response = await this.props.updateDependent(dependentId, dependent);

    if (response.type === UPDATE_DEPENDENT_SUCCESS) {
      logEvent('profile_edit', {
        patientId: updateProfile.id,
        entranceId,
        accountId: this.props.profile.id,
      });
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.updateDependentSuccess
      );
      this.props.history.goBack();
    } else {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.updateDependentFailure,
        toastTypes.ERROR,
        true,
        null,
        response.messages[0]
      );
    }
  };

  formHasErrors = () => {
    const requiredFields = [
      'firstName',
      'lastName',
      'dob',
      'gender',
      'addressLine1',
      'addressCity',
      'addressState',
      'addressZip',
    ];

    const isMissingRequiredField = requiredFields.some(key => isEmpty(this.state.profile[key]));
    const hasValidationError = Object.keys(this.state.formErrors).some(
      key => !!this.state.formErrors[key]
    );
    return isMissingRequiredField || hasValidationError;
  };

  render() {
    const { classes, selectedLanguageKey } = this.props;
    const { profile, isShowCancelDialog, formIsDirty, isInitializing } = this.state;
    const language = Languages[selectedLanguageKey].strings;

    if (isInitializing) return <LoadingOverlay />;

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.stepperContent}>
            <Typography className={classes.heading} variant="h5">
              {`Edit ${
                profile.accountType === accountTypes.ACCOUNT_HOLDER ? 'Your' : 'Dependent'
              } Profile`}
            </Typography>

            <PatientProfileForm
              selectedLanguageKey={selectedLanguageKey}
              profile={profile}
              handleChange={this.handleChange}
              handleValidationChange={this.handleValidationChange}
            />
          </div>
        </div>
        <div className={classes.formActions}>
          <Button
            className={classes.actionButton}
            variant="text"
            onClick={formIsDirty ? this.handleShowCancelDialog : () => this.props.history.goBack()}
          >
            {language.buttonText.cancel}
          </Button>
          <Button
            className={classes.actionButton}
            classes={{ root: this.formHasErrors() ? classes.buttonDisabled : classes.button }}
            disabled={this.formHasErrors()}
            variant="text"
            onClick={this.handleSaveProfile}
          >
            {language.buttonText.saveProfile}
          </Button>
        </div>
        <CustomDialog
          open={isShowCancelDialog}
          handleClose={this.handleCloseCancelDialog}
          content={
            <Typography variant="subtitle1" id="modal-title">
              {language.alertMessages.confirmExitProfileUpdate}
            </Typography>
          }
          title={language.alertMessages.confirmExitProfileTitle}
          handleAction={() => this.props.history.goBack()}
        />
      </div>
    );
  }
}

const styles = theme => ({
  content: {
    backgroundColor: theme.palette.primary.background,
    flex: 1,
    overflow: 'auto',
    width: '100%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  stepperContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 88px)',
    margin: '2rem auto 0',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formActions: {
    background: theme.palette.primary.background,
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightGray,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  heading: {
    color: theme.palette.primary.darkGray,
    marginBottom: '0.5rem',
  },
  actionButton: {
    margin: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
});

PatientProfileContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  profile: PropTypes.object,
  selectedLanguageKey: PropTypes.string.isRequired,

  getProfile: PropTypes.func.isRequired,
  setDirtyForm: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  updateDependent: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
};

PatientProfileContainer.defaultProps = {
  profile: {},
};

const mapStateToProps = state => {
  return {
    selectedLanguageKey: state.language.selectedLanguageKey,
    profile: state.profile.profile,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    getProfile,
    setDirtyForm,
    setPageTitle,
    showToast,
    updateDependent,
    updateProfile,
  })
)(PatientProfileContainer);
