import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { StripeProvider } from 'react-stripe-elements';
import MainLayout from './modules/layout/main.layout';
import { config as brandConfig } from '@brand';

const Root = ({ store }) => (
  <Provider store={store}>
    <StripeProvider apiKey={brandConfig.stripeKey} stripe={null}>
      <DragDropContextProvider backend={HTML5Backend}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div style={{ display: 'flex' }}>
            <Route path="/" component={MainLayout} />
          </div>
        </MuiPickersUtilsProvider>
      </DragDropContextProvider>
    </StripeProvider>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};
export default Root;
