import React, { Component, Fragment } from 'react';
import { cloneDeep, isNil } from 'lodash';
import {
  withStyles,
  List,
  ListItem,
  Divider,
  ListItemText,
  Typography,
  Button,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getPharmacies, resetPharmacies } from '../../visit/visit.actions';
import PharmacySearch from '../pharmacySearch.component';
import Map from '../../../common/map/map.component';
import Languages from '../../language/languages';

import { config as brandConfig } from '@brand';

// Dosespot specialty value to indicate open 24 hours
const OPEN_24_SPECIALTY_TYPE = 'TwentyFourHourPharmacy';

class PharmacyForm extends Component {
  state = {
    selectedAddress: null,
    selectedPosition: null,
    hasInitialized: false,
  };

  componentDidMount() {
    if (!this.state.hasInitialized) {
      if (!isNil(this.props.currentPosition)) {
        this.props.getPharmacies(
          this.props.currentPosition.coords.latitude,
          this.props.currentPosition.coords.longitude
        );

        this.setState({ hasInitialized: true });
      } else {
        this.props.getPharmacies(
          brandConfig.defaultPharmacySearchLatLng[0],
          brandConfig.defaultPharmacySearchLatLng[1]
        );

        this.setState({ hasInitialized: true });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.hasInitialized) {
      if (!isNil(nextProps.currentPosition)) {
        this.props.getPharmacies(
          nextProps.currentPosition.coords.latitude,
          nextProps.currentPosition.coords.longitude
        );

        this.setState({ hasInitialized: true });
      } else if (!isNil(nextProps.locationError)) {
        this.props.getPharmacies(
          brandConfig.defaultPharmacySearchLatLng[0],
          brandConfig.defaultPharmacySearchLatLng[1]
        );

        this.setState({ hasInitialized: true });
      }
    } else if (isNil(this.props.currentPosition) && !isNil(nextProps.currentPosition)) {
      this.props.getPharmacies(
        nextProps.currentPosition.coords.latitude,
        nextProps.currentPosition.coords.longitude
      );

      this.setState({ hasInitialized: true });
    }
  }

  handleSelectLocation = location => {
    this.setState({
      selectedPosition: {
        latitude: location.Latitude,
        longitude: location.Longitude,
      },
    });
    this.props.onSelectPharmacy(location);
  };

  handleAddressSelection = async (selectedAddress, lat, lng) => {
    await this.props.getPharmacies(lat, lng);

    this.setState({
      selectedPosition: {
        latitude: lat,
        longitude: lng,
      },
      selectedAddress,
    });
    this.props.onSelectPharmacy();
  };

  handleAddressChange = field => value => {
    this.setState({ [field]: value });
    this.props.onSelectPharmacy();
  };

  getEmptyText() {
    const { pharmacies, isLoadingPharmacies, currentPosition, selectedLanguageKey } = this.props;
    const language = Languages[selectedLanguageKey].strings;

    if (isNil(pharmacies) && !isLoadingPharmacies) {
      return isNil(currentPosition) ? (
        <Typography variant="subtitle1">{language.pharmacySearch}</Typography>
      ) : (
        <Typography variant="subtitle1">{language.emptyPharmacies}</Typography>
      );
    }

    return null;
  }

  render() {
    const {
      classes,
      currentPosition,
      isLoadingPharmacies,
      pharmacies,
      selectedLanguageKey,
      selectedPharmacy,
    } = this.props;
    const { selectedPosition, selectedAddress } = this.state;

    let mappedPharmacies = [];

    if (!isNil(pharmacies)) {
      mappedPharmacies = cloneDeep(pharmacies);
      mappedPharmacies = mappedPharmacies.map(pharm => {
        // pharm.latlng = {};
        // pharm.latlng.latitude = pharm.geometry.location.lat;
        // pharm.latlng.longitude = pharm.geometry.location.lng;
        if (!isNil(selectedPharmacy) && selectedPharmacy.PharmacyId === pharm.PharmacyId) {
          pharm.selected = true;
        }
        return pharm;
      });
    }

    return (
      <>
        <Paper
          className={classes.mapContainer}
          style={{
            margin: '1rem',
          }}
        >
          <Map
            currentPosition={currentPosition}
            mapContainerClassName={classes.mapContainer}
            markers={mappedPharmacies}
            selectMarker={this.handleSelectLocation}
            selectedPosition={selectedPosition}
          />
        </Paper>
        <Paper className={classes.searchContainer}>
          <div className={classes.searchWrapper}>
            <PharmacySearch
              onSelection={this.handleAddressSelection}
              address={!isNil(selectedAddress) ? selectedAddress : ''}
              onChange={this.handleAddressChange('selectedAddress')}
              selectedLanguageKey={selectedLanguageKey}
            />
          </div>
          {(!this.state.hasInitialized || isLoadingPharmacies) && (
            <div className={classes.progressWrapper}>
              <CircularProgress size={60} className={classes.progress} />
            </div>
          )}
          {!isNil(pharmacies) && !isLoadingPharmacies && (
            <List classes={{ root: classes.listRoot }}>
              {mappedPharmacies.map(pharm => (
                <Fragment key={pharm.PharmacyId}>
                  <ListItem
                    alignItems="flex-start"
                    className={classNames({ [classes.listItemSelected]: pharm.selected })}
                  >
                    <ListItemText
                      primary={pharm.name}
                      secondary={
                        <>
                          <Typography component="span" variant="subtitle1">
                            {pharm.StoreName}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {pharm.Address1}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {`${pharm.City}, ${pharm.State} ${pharm.ZipCode}`}
                          </Typography>
                          {pharm.PharmacySpecialties &&
                            pharm.PharmacySpecialties.indexOf(OPEN_24_SPECIALTY_TYPE) > -1 && (
                              <Typography
                                className={classes.openAll}
                                component="span"
                                variant="body2"
                              >
                                {Languages[selectedLanguageKey].strings.open24}
                              </Typography>
                            )}
                        </>
                      }
                    />
                    <Button
                      variant="text"
                      className={classes.selectButton}
                      onClick={() => this.handleSelectLocation(pharm)}
                    >
                      {Languages[selectedLanguageKey].strings.buttonText.select}
                    </Button>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            </List>
          )}
          {this.getEmptyText()}
        </Paper>
      </>
    );
  }
}

const styles = theme => ({
  formContainer: {
    overflowY: 'scroll',
    marginRight: -15,
    marginBottom: 100,
  },
  mapContainer: {
    height: 400,
    marginBottom: '1rem',
  },
  selectButton: {
    alignSelf: 'center',
  },
  listItemRoot: {
    backgroundColor: theme.palette.common.white,
  },
  searchContainer: {
    margin: '1rem',
    marginBottom: '100px',
  },
  searchWrapper: {
    margin: '0 1rem',
  },
  progressWrapper: {
    display: 'flex',
  },
  progress: {
    margin: '5rem auto',
  },
  listItemSelected: {
    backgroundColor: theme.palette.primary.lightGray,
  },
  openAll: {
    color: theme.palette.success.main,
  },
});

PharmacyForm.propTypes = {
  classes: PropTypes.object.isRequired,

  currentPosition: PropTypes.object,
  isLoadingPharmacies: PropTypes.bool.isRequired,
  locationError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pharmacies: PropTypes.array,
  selectedLanguageKey: PropTypes.string.isRequired,
  selectedPharmacy: PropTypes.object,

  onSelectPharmacy: PropTypes.func.isRequired,
  getPharmacies: PropTypes.func.isRequired,
};

PharmacyForm.defaultProps = {
  currentPosition: null,
  locationError: null,
  pharmacies: null,
  selectedPharmacy: null,
};

const mapStateToProps = state => {
  return {
    currentPosition: state.location.currentPosition,
    isLoadingPharmacies: state.visit.isLoadingPharmacies,
    locationError: state.location.error,
    pharmacies: state.visit.pharmacies,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getPharmacies,
    resetPharmacies,
  })
)(PharmacyForm);
