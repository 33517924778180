/* eslint-disable react/no-deprecated */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, TextField, Button, Paper, withStyles } from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { isNil, has } from 'lodash';
import CustomDialog from '../../../common/customDialog/customDialog.component';
import StripeTextField from '../../../common/stripeTextField/stripeTextField.component';
import { getFormattedPrice } from '../../../utilities/dataUtils';
import Languages from '../../language/languages';

class PaymentMethodForm extends Component {
  state = {
    paymentInputOpen: false,
    complete: false,
    identifierName: '',
  };

  componentWillReceiveProps(nextProps) {
    if (has(nextProps, 'pricing.price.identifierName')) {
      this.setState({
        identifierName: nextProps.pricing.price.identifierName,
      });
    }
  }

  handleClosePayment = () => this.setState({ paymentInputOpen: false });

  handleOpenPayment = () => this.setState({ paymentInputOpen: true });

  handleCreditCardChange = (complete) => {
    this.setState({ complete });
  };

  handleEnterCreditCard = async () => {
    const token = await this.props.stripe.createToken({ name: 'Name' }).catch(() => {
      // the user cancelled
      console.log('User may have canceled?');
    });

    if (!isNil(token)) {
      console.log('TOKEN!', token);
      this.props.handleSetToken(token.token);
      this.handleClosePayment();
    }
  };

  render() {
    const {
      classes,
      cardBrand,
      cardNumber,
      couponCode,
      groupCode,
      groupIdentifier,
      isLoadingPricing,
      onFieldChange,
      pricing,
      selectedLanguageKey,
    } = this.props;

    const { identifierName } = this.state;

    const language = Languages[selectedLanguageKey].strings;

    const isZeroPriceVisit = has(pricing, 'price.price') && pricing.price.price === 0;

    return (
      <Paper className={classes.paper}>
        <div className={classes.paymentDetails}>
          <Typography variant="h5" className={classes.darkGray}>
            {language.paymentFormTitle}
          </Typography>
          <div style={{ margin: '.5rem 0' }}>
            {isLoadingPricing ? (
              <Typography variant="h5" className={classes.darkGray}>
                {language.loading}
              </Typography>
            ) : !isNil(pricing) &&
              !isNil(pricing.price) &&
              !isNil(pricing.price.price) &&
              pricing.basePrice !== pricing.price.price ? (
              <div className={classes.priceContainer}>
                <Typography className={classes.darkGray} variant="h3">
                  {`$${getFormattedPrice(pricing.price.price)}`}
                </Typography>
                <Typography className={classes.price} variant="h3">
                  {`$${getFormattedPrice(pricing.basePrice)}`}
                </Typography>
              </div>
            ) : (
              <Typography className={classes.grayCenter} variant="h3">
                {`$${getFormattedPrice(pricing.basePrice)}`}
              </Typography>
            )}
          </div>
          <Typography variant="subtitle1" className={classes.grayCenter}>
            {language.paymentFormInfo}
          </Typography>
          {!isNil(pricing) && !isNil(pricing.price) && !isNil(pricing.price.message) && (
            <Typography variant="body2" className={classes.error}>
              {pricing.price.message}
            </Typography>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <TextField
            label={language.inputLabels.couponCode}
            margin="normal"
            onChange={onFieldChange('couponCode')}
            style={{ marginRight: '1rem', flex: 1 }}
            value={couponCode}
            variant="outlined"
          />
          <TextField
            label={language.inputLabels.employerCode}
            margin="normal"
            onChange={onFieldChange('groupCode')}
            style={{ flex: 1 }}
            value={groupCode}
            variant="outlined"
          />
        </div>
        {((!isNil(pricing) && !isNil(pricing.price) && !isNil(pricing.price.fieldNeeded)) ||
          (!isNil(groupIdentifier) && groupIdentifier !== '')) && (
          <TextField
            fullWidth
            label={
              pricing.price.identifierName
                ? pricing.price.identifierName
                : identifierName || 'Group Identifier'
            }
            margin="normal"
            onChange={onFieldChange('groupIdentifier')}
            value={groupIdentifier}
            variant="outlined"
          />
        )}

        {language.paymentFormDescription && (
          <Typography
            className={classes.paymentDescription}
            dangerouslySetInnerHTML={{ __html: language.paymentFormDescription }}
          />
        )}

        <div className={classes.cardContainer}>
          {!isZeroPriceVisit && (
            <Typography className={classes.cost}>
              {`${
                !cardBrand || !cardNumber ? language.enterPaymentMethod : language.howPaymentMethod
              }`}
            </Typography>
          )}
          {cardBrand && cardNumber && !isZeroPriceVisit && (
            <div className={classes.cardInfo}>
              <CheckCircleOutline className={classes.checkCircle} />
              <Typography className={classes.cardSum}>
                {`${cardBrand} ****${cardNumber}`}
              </Typography>
            </div>
          )}
          {isZeroPriceVisit && (
            <div className={classes.zeroVisit}>
              <CheckCircleOutline className={classes.checkCircle} />
              <Typography className={classes.cardSum}>
                {language.noPaymentMethodRequired}
              </Typography>
            </div>
          )}
          {!isZeroPriceVisit && (
            <Button
              className={classes.enterAction}
              variant="outlined"
              onClick={this.handleOpenPayment}
            >
              {language.buttonText.enterCreditCard}
            </Button>
          )}
          <CustomDialog
            title={language.addCard}
            open={this.state.paymentInputOpen}
            handleClose={this.handleClosePayment}
            content={
              <StripeTextField
                label={language.inputLabels.creditCardNumber}
                component={CardElement}
                onChange={(complete) => this.handleCreditCardChange(complete)}
              />
            }
            actionText={language.buttonText.save}
            handleAction={this.handleEnterCreditCard}
            isActionDisabled={!this.state.complete}
          />
        </div>
      </Paper>
    );
  }
}

const styles = (theme) => ({
  paper: {
    padding: '1rem',
    margin: '1rem',
    marginBottom: '6rem',
  },
  paymentDetails: {
    marginBottom: '1rem',
    textAlign: 'center',
  },
  darkGray: {
    color: theme.palette.primary.darkGray,
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  price: {
    color: theme.palette.primary.lightGray,
    marginLeft: theme.spacing.unit * 2,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  grayCenter: {
    color: theme.palette.primary.darkGray,
    textAlign: 'center',
  },
  error: {
    color: theme.palette.error.main,
  },
  cost: {
    color: theme.palette.primary.darkGray,
    fontSize: 20,
    textAlign: 'center',
  },
  cardInfo: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5rem',
  },
  checkCircle: {
    color: theme.palette.primary.darkGray,
    marginRight: '1rem',
  },
  cardSum: {
    color: theme.palette.primary.darkGray,
    fontSize: 18,
    textAlign: 'center',
  },
  zeroVisit: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5rem',
  },
  enterAction: {
    color: theme.palette.primary.darkGray,
    display: 'block',
    margin: 'auto',
    marginTop: '1rem',
  },
  cardContainer: {
    border: '1px solid black',
    marginTop: '2rem',
    padding: '1rem',
  },
  paymentDescription: {
    textAlign: 'center',
    color: theme.palette.primary.darkGray,
    maxWidth: '60%',
    margin: '1rem auto 0',
  },
});

PaymentMethodForm.propTypes = {
  classes: PropTypes.object.isRequired,

  cardBrand: PropTypes.string,
  cardNumber: PropTypes.string,
  couponCode: PropTypes.string,
  groupCode: PropTypes.string,
  groupIdentifier: PropTypes.string,
  isLoadingPricing: PropTypes.bool.isRequired,
  pricing: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,
  stripe: PropTypes.object,

  handleSetToken: PropTypes.func,
  onFieldChange: PropTypes.func.isRequired,
};

PaymentMethodForm.defaultProps = {
  cardBrand: null,
  cardNumber: null,
  couponCode: null,
  groupCode: null,
  groupIdentifier: null,
  stripe: {},
  handleSetToken: () => {},
};

const mapStateToProps = (state) => {
  return {
    isLoadingPricing: state.visit.isLoadingPricing,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {}),
  injectStripe,
)(PaymentMethodForm);
