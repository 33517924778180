import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Checkbox,
  Button,
  ListItemSecondaryAction,
  withStyles,
} from '@material-ui/core';
import Geocoder from 'react-geocode';
import { connect } from 'react-redux';
import { isNil, has } from 'lodash';
import { compose } from 'recompose';

import TextFieldValidator from '../../../common/inputValidators/textFieldValidator.component';
import {
  required,
  charLimit,
  validCharacters,
  validPhoneNumber,
  validZip,
  TEXT_INPUT_CHAR_LIMIT,
} from '../../../utilities/fieldValidation';
import GooglePlacesTextValidator from '../../../common/inputValidators/googlePlacesTextValidator.component';
import {
  getFriendlyPermission,
  browserPermissionStatus,
  browserPermissionTypes,
} from '../../../types/browserPermissionTypes';
import MaskedTextValidator from '../../../common/inputValidators/maskedTextValidator.component';
import { getUSStateOptions } from '../../../utilities/provinceUtils';
import SelectInputValidator from '../../../common/inputValidators/selectInputValidator.component';
import { getCurrentPosition } from '../../location/location.actions';
import Languages from '../../language/languages';

import { config as brandConfig } from '@brand';

const truncateText = (value, limit = TEXT_INPUT_CHAR_LIMIT) => {
  return value.substring(0, limit - 1);
};

class VisitPermissionsForm extends Component {
  // initialize permissions
  async componentDidMount() {
    const { permissions } = this.props;

    permissions.forEach(async (p) => {
      const result = await this.getPermissionStatus(p.type);
      this.props.onPermissionChange({
        type: p.type,
        status: result,
      });
    });

    function findAddressComponentByType(components, typeName, key) {
      let toReturn = '';

      components.forEach((component) => {
        if (component.types.indexOf(typeName) > -1) {
          if (key) {
            toReturn = component[key];
          } else {
            toReturn = component.long_name;
          }
        }
      });

      return toReturn;
    }

    Geocoder.setApiKey(brandConfig.googlePlacesApiKey);
    const locationResult = await this.props.getCurrentPosition();

    if (!isNil(locationResult)) {
      const { coords } = locationResult;
      Geocoder.fromLatLng(coords.latitude, coords.longitude)
        .then((json) => {
          if (json.results && json.results.length > 0) {
            const result = json.results[0];
            // console.log('address?', result);

            const { address_components } = result;

            const formatted_result = {
              street_number: findAddressComponentByType(address_components, 'street_number'),
              route: findAddressComponentByType(address_components, 'route'),
              neighborhood: findAddressComponentByType(address_components, 'neighborhood'),
              // political: address_components[4].long_name,
              locality: findAddressComponentByType(address_components, 'locality'),
              administrative_area_level_1: findAddressComponentByType(
                address_components,
                'administrative_area_level_1',
                'short_name',
              ),
              country: findAddressComponentByType(address_components, 'country'),
              postal_code: findAddressComponentByType(address_components, 'postal_code'),
            };

            this.handleAddressSelection(formatted_result);
          }
        })
        .catch((error) => console.warn(error));
    }
  }

  // set as an event handler during navigator permissions query
  handlePermissionChange = (name) => (event) => {
    this.props.onPermissionChange({
      type: name,
      status: event.target.state,
    });
  };

  // use navigator to query permission status
  // and configure an onchange listener
  getPermissionStatus = (name) => {
    return navigator.permissions.query({ name }).then((result) => {
      result.onchange = this.handlePermissionChange(name);
      return result.state;
    });
  };

  // request microphone and camera permissions together in one prompt
  getUserMedia = () => {
    navigator.getUserMedia(
      {
        video: true,
        audio: true,
      },

      // success
      (stream) => {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());

        const grantedPermissions = [
          {
            type: browserPermissionTypes.CAMERA,
            status: browserPermissionStatus.GRANTED,
          },
          {
            type: browserPermissionTypes.MICROPHONE,
            status: browserPermissionStatus.GRANTED,
          },
        ];

        // notify that both camera and mic permissions being granted
        grantedPermissions.forEach((p) => {
          this.props.onPermissionChange(p);
        });
      },

      // error
      (err) => {
        console.log('error getting user media:', err);
      },
    );
  };

  handleAddressSelection = (selection) => {
    // propagate selection to address1 and other fields
    const addressLine1 = `${selection.street_number ? `${selection.street_number} ` : ''}${
      selection.route ? selection.route : ''
    }`;
    this.props.onLocationChange('addressLine1')(truncateText(addressLine1));

    // clear address line 2 - not integrated with autocomplete
    this.props.onLocationChange('addressLine2')('');

    const addressCity = `${selection.locality ? selection.locality : ''}`;
    this.props.onLocationChange('addressCity')(truncateText(addressCity));

    const addressState = `${
      selection.administrative_area_level_1 ? selection.administrative_area_level_1 : ''
    }`;
    this.props.onLocationChange('addressState')(truncateText(addressState));

    const addressZip = `${selection.postal_code ? selection.postal_code : ''}`;
    this.props.onLocationChange('addressZip')(truncateText(addressZip));
  };

  // require phone number by default
  // can be disabled by a facility in settings
  isPatientPhoneNumberRequired = () => {
    const facility = this.props.selectedFacility;
    const entranceConfig = this.props.entrance.clientConfig;
    let isPhoneRequired = true;

    if (!isNil(facility) && has(facility, 'skipPhone') && facility.skipPhone === true) {
      isPhoneRequired = false;
    }

    if (has(entranceConfig, 'skipPhone') && entranceConfig.skipPhone === true) {
      isPhoneRequired = false;
    }

    return isPhoneRequired;
  };

  // require patient address by default
  // can be disabled by a facility in settings
  isPatientAddressRequired = () => {
    const facility = this.props.selectedFacility;
    const entranceConfig = this.props.entrance.clientConfig;
    let isAddressRequired = true;

    if (!isNil(facility) && has(facility, 'skipAddress') && facility.skipAddress === true) {
      isAddressRequired = false;
    }

    if (has(entranceConfig, 'skipAddress') && entranceConfig.skipAddress === true) {
      isAddressRequired = false;
    }

    return isAddressRequired;
  };

  getLocationSectionTitle = () => {
    const language = Languages[this.props.selectedLanguageKey].strings;
    const isPhoneNumberRequired = this.isPatientPhoneNumberRequired();
    const isPatientAddressRequired = this.isPatientAddressRequired();

    if (isPhoneNumberRequired && isPatientAddressRequired) {
      return language.locationAndPhone;
    }

    if (isPatientAddressRequired && !isPhoneNumberRequired) {
      return language.currentLocation;
    }

    if (!isPatientAddressRequired && isPhoneNumberRequired) {
      return language.currentPhoneNumber;
    }

    return '';
  };

  shouldShowLocationSection = () => {
    return this.isPatientAddressRequired() || this.isPatientPhoneNumberRequired();
  };

  render() {
    const {
      classes,
      location,
      mobileNumber,
      permissions,
      selectedLanguageKey,
      handleChange,
      handleValidationChange,
      onLocationChange,
    } = this.props;

    const language = Languages[selectedLanguageKey].strings;

    const isPhoneNumberRequired = this.isPatientPhoneNumberRequired();
    const isPatientAddressRequired = this.isPatientAddressRequired();
    const locationSectionTitle = this.getLocationSectionTitle();
    const shouldShowLocationSection = this.shouldShowLocationSection();

    return (
      <div className={classes.formContainer}>
        <Typography variant="h6">{language.visitPermissionsLabel}</Typography>
        <Typography>{language.permissionsInfo}</Typography>

        <List style={{ marginBottom: '1rem' }}>
          {permissions.map((permission) => {
            const isPermissionGranted = permission.status === browserPermissionStatus.GRANTED;
            const isPermissionDenied = permission.status === browserPermissionStatus.DENIED;

            let secondaryElement = isPermissionGranted ? (
              <Typography component="span" color="textPrimary">
                {`${getFriendlyPermission(permission.type)} ${language.permissionsGranted}`}
              </Typography>
            ) : (
              <>
                <Button
                  className={classes.permissionButton}
                  variant="outlined"
                  onClick={this.getUserMedia}
                >
                  {`${language.request} ${getFriendlyPermission(permission.type)} ${
                    language.permission
                  }`}
                </Button>
              </>
            );

            if (isPermissionDenied) {
              secondaryElement = (
                <Typography component="span" color="textPrimary">
                  {`${getFriendlyPermission(permission.type)} ${language.permissionsBlocked}`}
                </Typography>
              );
            }

            return (
              <Paper style={{ marginBottom: '1rem' }} key={permission.type}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" color="textPrimary">
                        {`${getFriendlyPermission(permission.type)}`}
                      </Typography>
                    }
                    secondary={secondaryElement}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      checked={isPermissionGranted}
                      className={classes.permissionCheck}
                      disabled
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </Paper>
            );
          })}
        </List>

        {shouldShowLocationSection && (
          <Paper style={{ padding: '1rem' }}>
            <Typography variant="h6">{locationSectionTitle}</Typography>
            <Typography>{language.locationInfo}</Typography>
            {isPatientAddressRequired && (
              <>
                <GooglePlacesTextValidator
                  onChange={(value) => onLocationChange('addressLine1')(value)}
                  validators={[required, charLimit(TEXT_INPUT_CHAR_LIMIT), validCharacters]}
                  label={language.inputLabels.addressLineOne}
                  inputClassName={classnames([classes.formControl, classes.autocomplete])}
                  value={location.addressLine1 ? location.addressLine1 : ''}
                  onSelect={this.handleAddressSelection}
                  onValidationChange={handleValidationChange('addressLine1')}
                />
                <TextFieldValidator
                  label={language.inputLabels.addressLineTwo}
                  className={classes.formControl}
                  value={location.addressLine2 ? location.addressLine2 : ''}
                  onChange={(evt) => onLocationChange('addressLine2')(evt.target.value)}
                  validators={[charLimit(TEXT_INPUT_CHAR_LIMIT), validCharacters]}
                  margin="normal"
                  variant="outlined"
                  onValidationChange={handleValidationChange('addressLine2')}
                />
                <TextFieldValidator
                  label={language.inputLabels.city}
                  className={classes.formControl}
                  value={location.addressCity ? location.addressCity : ''}
                  onChange={(evt) => onLocationChange('addressCity')(evt.target.value)}
                  validators={[required, charLimit(TEXT_INPUT_CHAR_LIMIT), validCharacters]}
                  margin="normal"
                  variant="outlined"
                  onValidationChange={handleValidationChange('addressCity')}
                />
                <SelectInputValidator
                  label={language.inputLabels.state}
                  className={classes.formControl}
                  value={location.addressState ? location.addressState : ''}
                  onChange={(value) => onLocationChange('addressState')(value)}
                  validators={[required]}
                  margin="normal"
                  variant="outlined"
                  onValidationChange={handleValidationChange('addressState')}
                  options={getUSStateOptions()}
                  labelWidth={50}
                />
                <MaskedTextValidator
                  label={language.inputLabels.zipCode}
                  className={classes.formControl}
                  value={location.addressZip ? location.addressZip : ''}
                  onChange={onLocationChange('addressZip')}
                  validators={[required, validZip]}
                  margin="normal"
                  variant="outlined"
                  mask="99999"
                  onValidationChange={handleValidationChange('addressZip')}
                />
              </>
            )}
            {isPhoneNumberRequired && (
              <MaskedTextValidator
                label={language.inputLabels.mobileNumber}
                className={classes.formControl}
                value={mobileNumber || ''}
                onChange={handleChange('mobileNumber')}
                validators={[required, validPhoneNumber]}
                margin="normal"
                variant="outlined"
                mask="(999) 999-9999"
                onValidationChange={handleValidationChange('mobileNumber')}
              />
            )}
          </Paper>
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  formContainer: {
    margin: '1rem',
    paddingBottom: '6rem',
  },
  formControl: {
    width: '100%',
  },
  autocomplete: {
    marginTop: '1rem',
    marginBottom: '0.5rem',
  },
  button: {
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
    padding: 0,
  },
  checkboxDisabled: {
    color: theme.palette.primary.main,
  },
  permissionButton: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: 14,
    marginLeft: 'auto',
    paddingLeft: 18,
    paddingRight: 18,
  },
  permissionCheck: {
    color: theme.palette.primary.main,
  },
});

VisitPermissionsForm.propTypes = {
  classes: PropTypes.object.isRequired,

  location: PropTypes.object.isRequired,
  mobileNumber: PropTypes.string,
  permissions: PropTypes.array.isRequired,
  selectedFacility: PropTypes.object,
  selectedLanguageKey: PropTypes.string.isRequired,

  getCurrentPosition: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleValidationChange: PropTypes.func.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  onPermissionChange: PropTypes.func.isRequired,
  entrance: PropTypes.object,
};

VisitPermissionsForm.defaultProps = {
  mobileNumber: '',
  selectedFacility: null,
  entrance: null,
};

const mapStateToProps = (state) => {
  return {
    selectedLanguageKey: state.language.selectedLanguageKey,
    selectedFacility: state.facility.selectedFacility,
    entrance: state.entrance.entrance,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getCurrentPosition,
  }),
)(VisitPermissionsForm);
