import produce from 'immer';
import {
  GET_DEPENDENT_MEDICAL_HISTORY_FAILURE,
  GET_DEPENDENT_MEDICAL_HISTORY_REQUEST,
  GET_DEPENDENT_MEDICAL_HISTORY_SUCCESS,
  GET_MEDICAL_HISTORY_FAILURE,
  GET_MEDICAL_HISTORY_REQUEST,
  GET_MEDICAL_HISTORY_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  SAVE_PAYMENT_METHOD_FAILURE,
  SAVE_PAYMENT_METHOD_REQUEST,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SET_PROFILE,
  UPDATE_DEPENDENT_MEDICAL_HISTORY_FAILURE,
  UPDATE_DEPENDENT_MEDICAL_HISTORY_REQUEST,
  UPDATE_DEPENDENT_MEDICAL_HISTORY_SUCCESS,
  UPDATE_MEDICAL_HISTORY_FAILURE,
  UPDATE_MEDICAL_HISTORY_REQUEST,
  UPDATE_MEDICAL_HISTORY_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  GET_INSURANCE_INFO_REQUEST,
  GET_INSURANCE_INFO_SUCCESS,
  GET_INSURANCE_INFO_FAILURE,
  SAVE_INSURANCE_INFO_FAILURE,
  SAVE_INSURANCE_INFO_REQUEST,
  SAVE_INSURANCE_INFO_SUCCESS,
} from './profile.actions';

const initialState = {
  profile: {},
  isLoadingProfile: false,
  paymentMethods: null,
  isLoadingPaymentMethods: false,
  medicalHistory: null,
  isLoadingMedicalHistory: false,
  isSavingPaymentInfo: false,
  isLoadingInsuranceInfo: false,
};

// const samplePaymentMethod = [
//   {
//     id: 'card_1EhFq5AqzdVAPQPheKv03DJ2',
//     object: 'card',
//     address_city: null,
//     address_country: null,
//     address_line1: null,
//     address_line1_check: null,
//     address_line2: null,
//     address_state: null,
//     address_zip: null,
//     address_zip_check: null,
//     brand: 'Visa',
//     country: 'US',
//     customer: 'cus_FAaWcz3qR5tzdX',
//     cvc_check: null,
//     dynamic_last4: null,
//     exp_month: 6,
//     exp_year: 2020,
//     fingerprint: 'GdaGggsUrO3hW95Q',
//     funding: 'credit',
//     last4: '4242',
//     metadata: {},
//     name: null,
//     tokenization_method: null,
//   },
// ];

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
      console.log('GET PROFILE SUCCESS', action.response);
      draft.profile = action.response;
      draft.isLoadingProfile = false;
      break;

    case GET_PROFILE_FAILURE:
      console.log('GET PROFILE FAILURE', action.messages);
      draft.isLoadingProfile = false;
      break;

    case GET_PROFILE_REQUEST:
      draft.isLoadingProfile = true;
      break;

    case UPDATE_PROFILE_SUCCESS:
      console.log('UPDATE PROFILE SUCCESS', action.response);
      draft.profile = action.response;
      draft.isLoadingProfile = false;
      break;

    case UPDATE_PROFILE_FAILURE:
      console.log('UPDATE PROFILE FAILURE', action.messages);
      draft.isLoadingProfile = false;
      break;

    case UPDATE_PROFILE_REQUEST:
      draft.isLoadingProfile = true;
      break;

    case SET_PROFILE:
      draft.profile = action.response;
      break;

    case GET_PAYMENT_METHODS_SUCCESS:
      draft.paymentMethods = action.response.data;
      draft.isLoadingPaymentMethods = false;
      break;

    case GET_PAYMENT_METHODS_FAILURE:
      draft.isLoadingPaymentMethods = false;
      break;

    case GET_PAYMENT_METHODS_REQUEST:
      draft.isLoadingPaymentMethods = true;
      break;

    case GET_MEDICAL_HISTORY_SUCCESS:
      draft.medicalHistory = action.response;
      draft.isLoadingMedicalHistory = false;
      break;

    case GET_MEDICAL_HISTORY_FAILURE:
      draft.isLoadingMedicalHistory = false;
      break;

    case GET_MEDICAL_HISTORY_REQUEST:
      draft.isLoadingMedicalHistory = true;
      break;

    case GET_DEPENDENT_MEDICAL_HISTORY_SUCCESS:
      draft.medicalHistory = action.response;
      draft.isLoadingMedicalHistory = false;
      break;

    case GET_DEPENDENT_MEDICAL_HISTORY_FAILURE:
      draft.isLoadingMedicalHistory = false;
      break;

    case GET_DEPENDENT_MEDICAL_HISTORY_REQUEST:
      draft.isLoadingMedicalHistory = true;
      break;

    case UPDATE_MEDICAL_HISTORY_FAILURE:
      draft.isLoadingMedicalHistory = false;
      break;

    case UPDATE_MEDICAL_HISTORY_SUCCESS:
      draft.medicalHistory = action.response;
      draft.isLoadingMedicalHistory = false;
      break;

    case UPDATE_MEDICAL_HISTORY_REQUEST:
      draft.isLoadingMedicalHistory = true;
      break;

    case UPDATE_DEPENDENT_MEDICAL_HISTORY_FAILURE:
      draft.isLoadingMedicalHistory = false;
      break;

    case UPDATE_DEPENDENT_MEDICAL_HISTORY_SUCCESS:
      draft.medicalHistory = action.response;
      draft.isLoadingMedicalHistory = false;
      break;

    case UPDATE_DEPENDENT_MEDICAL_HISTORY_REQUEST:
      draft.isLoadingMedicalHistory = true;
      break;

    case SAVE_PAYMENT_METHOD_SUCCESS:
      draft.isSavingPaymentInfo = false;
      break;

    case SAVE_PAYMENT_METHOD_FAILURE:
      draft.isSavingPaymentInfo = false;
      break;

    case SAVE_PAYMENT_METHOD_REQUEST:
      draft.isSavingPaymentInfo = true;
      break;

    case SAVE_INSURANCE_INFO_SUCCESS:
      draft.isSavingInsuranceInfo = false;
      draft.insuranceInfo = action.response;
      break;

    case SAVE_INSURANCE_INFO_FAILURE:
      draft.isSavingInsuranceInfo = false;
      break;

    case SAVE_INSURANCE_INFO_REQUEST:
      draft.isSavingInsuranceInfo = true;
      break;

    case GET_INSURANCE_INFO_SUCCESS:
      draft.isLoadingInsuranceInfo = false;
      draft.insuranceInfo = action.response;
      break;

    case GET_INSURANCE_INFO_FAILURE:
      draft.isLoadingInsuranceInfo = false;
      break;

    case GET_INSURANCE_INFO_REQUEST:
      draft.isLoadingInsuranceInfo = true;
      break;

    default:
      break;
  }
}, initialState);
