import React, { Component } from 'react';
import { withStyles, Typography, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNil, has } from 'lodash';
import { compose } from 'recompose';

import {
  setPageTitle as setPageTitleAction,
  showToast as showToastAction,
} from '../layout/layout.actions';
import { VISIT_STATES } from '../../utilities/visitStateHandler';
import WaitingRoomCarousel from './waitingRoomCarousel.component';
import {
  getMedicalHistory as getMedicalHistoryAction,
  getDependentMedicalHistory as getDependentMedicalHistoryAction,
} from '../profile/profile.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import ProviderDialog from './providerDialog.component';
import {
  cancelVisit as cancelVisitAction,
  CANCEL_CURRENT_VISIT_SUCCESS,
  markCurrentVisitReady as markCurrentVisitReadyAction,
  getCurrentVisit as getCurrentVisitAction,
} from '../visit/visit.actions';
import ToastTypes from '../../types/toastTypes';
import CustomDialog from '../../common/customDialog/customDialog.component';
import { MARKETING_CONTENT_TYPES } from '../../types/marketingContentTypes';
import Languages from '../language/languages';
import { logEvent } from '../../utilities/googleAnalytics';
import ResponsiveBreakpoints from '../layout/responsiveBreakpoints';

import { config as brandConfig } from '@brand';

class WaitingRoomContainer extends Component {
  state = {
    providerDialogOpen: false,
    cancelVisitDialogOpen: false,
  };

  async componentDidMount() {
    const {
      currentVisit,
      medicalHistory,
      profile,
      selectedLanguageKey,
      getCurrentVisit,
      getMedicalHistory,
      getDependentMedicalHistory,
      setPageTitle,
    } = this.props;
    setPageTitle(Languages[selectedLanguageKey].strings.pageTitles.waitingRoom);

    if (isNil(medicalHistory) && !isNil(currentVisit)) {
      if (profile.id === currentVisit.patientId) {
        await getMedicalHistory();
      } else {
        await getDependentMedicalHistory(currentVisit.patientId);
      }
    }

    window.addEventListener('focus', getCurrentVisit);
    window.addEventListener('blur', getCurrentVisit);
  }

  componentWillUnmount() {
    const { getCurrentVisit } = this.props;

    window.removeEventListener('focus', getCurrentVisit);
    window.removeEventListener('blur', getCurrentVisit);
  }

  getBannerColor() {
    const { theme, currentVisit } = this.props;

    if (currentVisit.state === VISIT_STATES.SELECTED) {
      return theme.palette.success.main;
    }

    return theme.palette.primary.warning;
  }

  getVisitStatusText() {
    const {
      currentVisit: { isVoiceCall, state },
      selectedLanguageKey,
    } = this.props;
    const language = Languages[selectedLanguageKey].strings;

    if (isVoiceCall) return language.visitStatusMessages.isVoiceCall;

    switch (state) {
      case VISIT_STATES.NEW:
        return language.visitStatusMessages.new;

      case VISIT_STATES.SELECTED:
        return language.visitStatusMessages.selected;

      default:
        return language.visitStatusMessages.default;
    }
  }

  getActionText() {
    const { currentVisit, isLoading, selectedLanguageKey } = this.props;

    const buttonLanguage = Languages[selectedLanguageKey].strings.buttonText;

    if (isLoading) {
      return buttonLanguage.loading;
    }

    switch (currentVisit.state) {
      case VISIT_STATES.NEW:
        return buttonLanguage.waiting;

      case VISIT_STATES.SELECTED:
        return buttonLanguage.ready;

      default:
        return buttonLanguage.start;
    }
  }

  handleOpenProvider = async () => {
    this.setState({ providerDialogOpen: true });
  };

  handleCancelVisit = async () => {
    const {
      currentVisit: { patientId },
      profile,
      selectedLanguageKey,
      cancelVisit,
      showToast,
    } = this.props;
    const response = await cancelVisit();

    if (response.type === CANCEL_CURRENT_VISIT_SUCCESS) {
      logEvent('cancel_visit_waitingRoom', {
        patientId,
        entryId: brandConfig.entranceId,
        accountId: profile.id,
      });

      // history.push('/');
    } else {
      showToast(
        Languages[selectedLanguageKey].strings.showToastMessages.cancelVisitFailure,
        ToastTypes.ERROR,
        true,
        null,
        response.messages[0]
      );
    }
  };

  handleStartVisit = () => {
    this.props.markCurrentVisitReady();
  };

  render() {
    const {
      classes,
      currentVisit,
      isLoading,
      medicalHistory,
      patientEntrance,
      responsiveBreakpoint,
      selectedFacility,
      selectedLanguageKey,
    } = this.props;

    const language = Languages[selectedLanguageKey].strings;

    // explicitly do not show phi fields in carousel in facilitated visit without permission
    let phiVisible = true;
    if (!isNil(selectedFacility) && has(selectedFacility, 'phiVisible')) {
      phiVisible = selectedFacility.phiVisible;
    }

    if (isNil(medicalHistory) || isNil(currentVisit)) return <LoadingOverlay />;

    let marketingContent;
    if (responsiveBreakpoint.name === ResponsiveBreakpoints.XSMALL.name) {
      if (has(patientEntrance, ['marketingContent', MARKETING_CONTENT_TYPES.MOBILE_WAITING_ROOM])) {
        marketingContent =
          patientEntrance.marketingContent[MARKETING_CONTENT_TYPES.MOBILE_WAITING_ROOM];
      }
    } else if (
      has(patientEntrance, ['marketingContent', MARKETING_CONTENT_TYPES.DESKTOP_WAITING_ROOM])
    ) {
      marketingContent =
        patientEntrance.marketingContent[MARKETING_CONTENT_TYPES.DESKTOP_WAITING_ROOM];
    }

    return (
      <div className={classes.container}>
        <div className={classes.scrollableContainer}>
          <div style={{ backgroundColor: this.getBannerColor() }}>
            <Typography variant="h6" classes={{ root: classes.bannerText }}>
              {this.getVisitStatusText()}
            </Typography>
          </div>
          {!isNil(medicalHistory) && (
            <WaitingRoomCarousel
              medicalHistory={medicalHistory}
              currentVisit={currentVisit}
              marketingContent={marketingContent}
              responsiveBreakpoint={responsiveBreakpoint}
              selectedLanguageKey={selectedLanguageKey}
              phiVisible={phiVisible}
            />
          )}
          <div className={classes.footer}>
            {!currentVisit.isVoiceCall && (
              <div className={classes.actions}>
                <Button
                  className={classes.actionButton}
                  color="primary"
                  onClick={() => this.setState({ cancelVisitDialogOpen: true })}
                  variant="outlined"
                >
                  {language.buttonText.cancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleOpenProvider}
                  className={classes.actionButton}
                  disabled={currentVisit.state !== VISIT_STATES.SELECTED}
                >
                  {this.getActionText()}
                </Button>
              </div>
            )}
          </div>
        </div>
        <ProviderDialog
          providerId={currentVisit.providerId}
          onClose={() => this.setState({ providerDialogOpen: false })}
          open={this.state.providerDialogOpen}
          onVisitStart={this.handleStartVisit}
        />
        <CustomDialog
          open={this.state.cancelVisitDialogOpen}
          handleClose={() => this.setState({ cancelVisitDialogOpen: false })}
          content={
            <Typography variant="subtitle1">{language.alertMessages.confirmCancelVisit}</Typography>
          }
          title={language.alertMessages.cancelVisit}
          handleAction={this.handleCancelVisit}
          actionText={isLoading ? language.buttonText.cancelling : language.buttonText.cancelVisit}
          cancelText={language.buttonText.dontCancel}
        />
      </div>
    );
  }
}

const styles = theme => ({
  bannerText: {
    color: theme.palette.common.white,
    padding: '1rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '1rem',
    maxWidth: 800,
  },
  footer: {
    backgroundColor: theme.palette.primary.background,
    bottom: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
  container: {
    backgroundColor: theme.palette.primary.background,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  scrollableContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 56px)',
    overflowY: 'auto',
  },
  actionButton: {
    fontSize: 14,
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
});

WaitingRoomContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  cancelVisit: PropTypes.func.isRequired,
  currentVisit: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  medicalHistory: PropTypes.object,
  patientEntrance: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  responsiveBreakpoint: PropTypes.object.isRequired,
  selectedLanguageKey: PropTypes.string.isRequired,
  selectedFacility: PropTypes.object,

  getCurrentVisit: PropTypes.func.isRequired,
  getDependentMedicalHistory: PropTypes.func.isRequired,
  getMedicalHistory: PropTypes.func.isRequired,
  markCurrentVisitReady: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

WaitingRoomContainer.defaultProps = {
  currentVisit: null,
  medicalHistory: null,
  selectedFacility: null,
};

const mapStateToProps = state => {
  return {
    currentVisit: state.visit.currentVisit,
    isLoading: state.visit.isLoading,
    medicalHistory: state.profile.medicalHistory,
    patientEntrance: state.entrance.entrance,
    profile: state.profile.profile,
    provider: state.visit.provider,
    responsiveBreakpoint: state.layout.responsiveBreakpoint,
    selectedFacility: state.facility.selectedFacility,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    cancelVisit: cancelVisitAction,
    getCurrentVisit: getCurrentVisitAction,
    getDependentMedicalHistory: getDependentMedicalHistoryAction,
    getMedicalHistory: getMedicalHistoryAction,
    markCurrentVisitReady: markCurrentVisitReadyAction,
    setPageTitle: setPageTitleAction,
    showToast: showToastAction,
  })
)(WaitingRoomContainer);
